import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouteRoutingModule } from './routes-routing.module';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
// single pages
import { UserLockComponent } from './passport/lock/lock.component';
import { CallbackComponent } from './callback/callback.component';
import { Exception403Component } from './exception/403.component';
import { Exception404Component } from './exception/404.component';
import { Exception500Component } from './exception/500.component';
import { PublicLayoutComponent } from './public-service/public-layout/public-layout.component';
import { HomeComponent } from './public-service/home/home.component';
import { NewsListComponent } from './public-service/news-list/news-list.component';
import { NewsDetailComponent } from './public-service/news-detail/news-detail.component';
import { UserLoginSubComponent } from './passport/login-sub/login-sub.component';
import { ElecAndCoalComponent } from './elec-and-coal/elec-and-coal/elec-and-coal.component';
import { EnergyMonitoringComponent } from './energy-monitoring/energy-monitoring/energy-monitoring.component';
import { CityOverviewComponent } from './city-overview/city-overview/city-overview.component';
//OnlineCollectProject
import { OnlinecollectLoginComponent } from './onlinecollectionproject/onlinecollect-login/onlinecollect-login.component';
import { OnlinecollectRegisterComponent } from './onlinecollectionproject/onlinecollect-register/onlinecollect-register.component';
import { OnlinecollectionprojectCompanyComponent } from './onlinecollectionproject/onlinecollectionproject-company/onlinecollectionproject-company.component';
import { OnlinecollectionprojectProgressbarComponent } from './onlinecollectionproject/onlinecollectionproject-progressbar/onlinecollectionproject-progressbar.component';
import { OnlinecollectionprojectEnergyindexComponent } from './onlinecollectionproject/onlinecollectionproject-energyindex/onlinecollectionproject-energyindex.component';
import { OnlinecollectionprojectRefinecollectionComponent } from './onlinecollectionproject/onlinecollectionproject-refinecollection/onlinecollectionproject-refinecollection.component';
import { OnlinecollectionprojectEnergydisplayComponent } from './onlinecollectionproject/onlinecollectionproject-energydisplay/onlinecollectionproject-energydisplay.component';
import { DetailcollectComponent } from './onlinecollectionproject/detailcollect/detailcollect.component';
//import { SwitchPageComponent } from './switch-page/switch-page/switch-page.component';
import { AuthAuthorizationComponent } from './passport/authorization/authorization.component';
import { Exception901Component } from './exception/901.component';
import { MessageListComponent } from './message/message-list/message-list.component';
import { MessageListCompanyComponent } from './message/message-list-company/message-list-company.component';
import { LoginNingdongComponent } from './passport/login-ningdong/login-ningdong.component';
import { LoginSubNingdongComponent } from './passport/login-sub-ningdong/login-sub-ningdong.component';
import { AuthorizationNingdongComponent } from './passport/authorization-ningdong/authorization-ningdong.component';
import { IndexhomeComponent } from './indexhome/indexhome.component';
import { MonitoringPlatformComponent } from './monitoring-platform/monitoring-platform.component';

const COMPONENTS = [
  // passport pages
  UserLoginSubComponent,
  UserLoginComponent,
  UserRegisterComponent,
  UserRegisterResultComponent,
  // single pages
  UserLockComponent,
  CallbackComponent,
  Exception403Component,
  Exception404Component,
  Exception500Component,
  PublicLayoutComponent,
  HomeComponent,
  NewsListComponent,
  NewsDetailComponent,
  ElecAndCoalComponent,
  CityOverviewComponent,
  EnergyMonitoringComponent,
  //OnlineCollectProject
  OnlinecollectLoginComponent,
  OnlinecollectRegisterComponent,
  OnlinecollectionprojectCompanyComponent,
  OnlinecollectionprojectProgressbarComponent,
  OnlinecollectionprojectEnergyindexComponent,
  OnlinecollectionprojectRefinecollectionComponent,
  //大屏切换
  AuthAuthorizationComponent,
  Exception901Component,
];
//OnlineCollectProject
const COMPONENTS_NOROUNT = [
  OnlinecollectionprojectEnergydisplayComponent,
  DetailcollectComponent,

];

@NgModule({
  imports: [SharedModule, RouteRoutingModule,
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT, MessageListComponent, MessageListCompanyComponent, LoginNingdongComponent, LoginSubNingdongComponent, AuthorizationNingdongComponent, IndexhomeComponent, MonitoringPlatformComponent],
  entryComponents: COMPONENTS_NOROUNT,
})
export class RoutesModule { }
