import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DA_SERVICE_TOKEN, TokenService } from '@delon/auth';
import { GlobalConfigService } from '../global-config-service';
import { MenuService } from '@delon/theme';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RouteguardService implements CanActivate {
    constructor(
        private router: Router,
        @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
        private httpClient: HttpClient,
        private globalCongigService: GlobalConfigService,
        private menuSrevice: MenuService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Promise<boolean> {
        const name = this.tokenService.get().id;
        if (name === undefined || name === null) {
            this.router.navigateByUrl('/passport/login');
            return false;
        }
        this.menuSrevice.getPathByUrl(state.url)
        //开发环境下放行新建的页面
        if (environment.production) {
            const urlReg: RegExp = /baseinfo-report|energy-consumption-structure|energy-consumption-structure_addendum|reportbalance|report-three|energy-complete|energy-sharing|report-new-five|report-five|nergy-device|report-seven|report-instrument-manage|report-eight-two|report-nine|report-ten|report-seven|eport-eleven|report-twelve/;
            if (this.menuSrevice.getPathByUrl(state.url).length == 0 && !urlReg.test(state.url)) {
                this.router.navigateByUrl('/403');
                return false;
            }
        }

        return this.httpClient
            .get(this.globalCongigService.globalPath + '/verifylogin')
            .toPromise()
            .then(result => {
                let val = result as boolean;
                if (!val) {
                    this.router.navigateByUrl('/passport/login');
                }
                return val;
            });
    }
}
