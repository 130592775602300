import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { GXService } from './../../../shared/GX.service';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';

@Component({
  selector: 'onlinecollectionproject-progressbar',
  templateUrl: './onlinecollectionproject-progressbar.component.html',
  styleUrls: ['./onlinecollectionproject-progressbar.component.less'],
})
export class OnlinecollectionprojectProgressbarComponent implements OnInit {
  index = 0;
  userId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private http: HttpClient,
    public gxService: GXService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.userId = params['userId'];
    });
  }

  ngOnInit() {
    this.changeContent();
  }

  changeContent() {
    let url = '';

    if (this.index == 0) {
      url = '/onlinecollect/progressbar/companyinfo';
    } else if (this.index == 1) {
      url = '/onlinecollect/progressbar/energyindex';
    } else if (this.index == 2) {
      url = '/onlinecollect/progressbar/refinecollection';
    }
    this.router.navigate([url], {
      queryParams: {
        userId: this.userId,
      },
    });
  }

  pre(): void {
    this.index -= 1;
    this.changeContent();
  }

  next(): void {
    /*  this.index += 1;
    this.changeContent(); */
    if (this.index == 0) {
      //判断企业信息已保存
      let url = 'energyreport/onlineCollectProjectCompany/getCompanyInfo';
      let param = {
        userId: this.userId,
      };
      this.http
        .get(url, { params: param })
        .toPromise()
        .then((result: InvokeResult) => {
          if (GXService.isEmpty(result['data'])) {
            this.message.warning('请先填写企业信息！');
          } else {
            this.index += 1;
            this.changeContent();
          }
        });
    } else if (this.index == 1) {
      let url = 'energyreport/CompanyEnergyIndex/data/' + this.userId;
      this.http.get(url).subscribe((result: any) => {
        if (GXService.isEmpty(result.data)) {
          this.message.warning('请先选择能源品种！');
        } else {
          this.index += 1;
          this.changeContent();
        }
      });
    }
  }
}
