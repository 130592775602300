import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EnergyMonitoringService {

  constructor(private http: HttpClient) { }
  getData(url:string):any{
    return this.http.get(url).toPromise().then(result => {
      return result;
    });
  }
}