import { GXService } from './../../../shared/GX.service';
import { ConstantUtil } from '@shared/util/ConstantUtil.util';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MapData } from './mapData';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd';
import { EchartMap } from '@shared/component/echart-map/echart-map.component';
import {startOfYear, endOfYear, differenceInCalendarDays} from 'date-fns';
declare var echarts: any;

@Component({
  selector: 'city-overview',
  templateUrl: './city-overview.component.html',
  styleUrls: ['./city-overview.component.less'],
})
export class CityOverviewComponent implements OnInit {
  @ViewChild('chartMap') chartMap: EchartMap;
  @ViewChild('mapTableDiv') mapTableDiv: ElementRef;
  /*   @ViewChild('cityOverviewDiv1') cityOverviewDiv1: ElementRef;
  @ViewChild('cityOverviewDiv2') cityOverviewDiv2: ElementRef; */
  /* @ViewChild('cityOverviewDiv3') cityOverviewDiv3: ElementRef; */
  @ViewChild('sortTableDiv1') sortTableDiv1: ElementRef;
  @ViewChild('sortTableDiv2') sortTableDiv2: ElementRef;
  @ViewChild('trade') trade: ElementRef;
  @ViewChild('chartPie') chartPie: ElementRef;
  @ViewChild('chartZhnh') chartZhnh: ElementRef;
  isBottomWindowShow: boolean = false;
  colors = ['#33FFCC', '#33FF66', '#33CC33', '#339900', '#33BB99', '#669790'];
  carousel: any = [];
  predict: any = [];
  begin: any = moment(new Date())
    .add(-12, 'months')
    .format('YYYY年MM月');
  end: any = moment(new Date())
    .add(-1, 'months')
    .format('YYYY年MM月');
  nowDate = new Date();
  yiwanyishang = '-';
  wuqiandaoyiwan = '-';
  wuqianyixia = '-';
  zhnhData: any;
  map: any;
  pie: any;
  energyList: any;
  topFive: any;
  bottomFive: any;
  tradeData: any;
  plans: any;
  planCode: any;
  year: any;
  years: any;
  goalThisYearDanhao: any;
  goalThisYearZhnh: any;
  thisYearZhnh: any;
  thisYearDanhao: any;
  progressZhnh: any;
  progressDanhao: any;
  calculateNum: any;
  mapTableData: any;
  mapTableData1: any;
  mapTableData2: any;
  mapTableData3: any;
  coalSum: any;
  coalControl: any;
  preName: any;
  preZhnh: any;
  preCzdh: any;
  pageIndex: number = 1;
  tableTitle: string = '区域总量完成情况(万吨标准煤)';
  tableType = 1;
  thTitle = '目标';
  //-------2020-04-15 ludaxian---------//
  echartMapData = [];
  linesData = [];

  //2020-11-27 wangln 首页数据统计周期改为可选择
  beginDate = moment().add(-12, 'month').format('YYYY-MM');
  endDate = moment().add(-1, 'month').format('YYYY-MM');

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    public gxService: GXService,
  ) {}

  ngAfterViewInit() {
    this.searchData();
  }
  searchData(){
    let url = '/energyreport/cityOverview/dataForPCPage'+ '/' +  moment(this.beginDate).format('YYYY-MM') + '/' + moment(this.endDate).format('YYYY-MM');
    this.http
      .get(url)
      .toPromise()
      .then(result => {
        let res: any = result;
        this.yiwanyishang = res.yiwanyishang;
        this.wuqiandaoyiwan = res.wuqiandaoyiwan;
        this.wuqianyixia = res.wuqianyixia;
        this.zhnhData = res.zhnhData;
        this.map = res.map;
        this.coalControl = res.coalControl;
        this.coalSum = res.coalSum;
        /*       if (this.coalControl && this.coalControl != 0) {
        if (this.coalSum * 100 / this.coalControl > 100) {
          this.cityOverviewDiv3.nativeElement.style.width = '100%';
        } else if (this.coalSum * 100 / this.coalControl < 0 || this.coalSum * 100 / this.coalControl === 0) {
          this.cityOverviewDiv3.nativeElement.style.width = '0%';
        } else {
          this.cityOverviewDiv3.nativeElement.style.width = this.coalSum * 100 / this.coalControl + '%';
        }
      } else {
        this.cityOverviewDiv3.nativeElement.style.width = '0%';
      } */
        for (let i = 0; i < res.pie.length; i++) {
          res.pie[i].itemStyle = {
            color: this.colors[i],
          };
        }
        this.pie = res.pie;
        for (let i = 0; i < res.energyList.length; i++) {
          if (i < 5) {
            res.energyList[i].color = this.colors[i];
          } else {
            res.energyList[i].color = this.colors[5];
          }
        }
        this.energyList = res.energyList;
        let temp = [];
        for (let i = 0; i < res.energyList.length; i = i + 2) {
          let object = {
            obj1: null,
            obj2: null,
            obj3: null,
          };
          object.obj1 = res.energyList[i];
          if (res.energyList[i + 1]) {
            object.obj2 = res.energyList[i + 1];
          }
          if (res.energyList[i + 2]) {
            object.obj3 = res.energyList[i + 2];
          }
          temp.push(object);
        }
        this.carousel = temp;
        this.topFive = res.topFive;
        let abc = [];
        for (let i = 0; i < res.bottomFive.length; i++) {
          abc.push(res.bottomFive[res.bottomFive.length - 1 - i]);
        }
        this.bottomFive = abc;
        this.tradeData = res.trade;
        this.zhnhChart();
        this.drawMap();
        this.pieChart();
        this.tradeChart();
        this.getPlan();
      });
  }

  zhnhChart() {
    var tempData = this.zhnhData;
    let xData = [];
    let yDats = [];
    let yLastData = [];
    _.forEach(tempData, function(item) {
      xData.push(item.time);
      yDats.push(item.data);
      yLastData.push(item.lastData)
    });
    let chart = echarts.init(this.chartZhnh.nativeElement);
    let option = {
      grid: {
        height: '80%',
        width: '85%',
        left: '10%',
        top: '10%',
      },
      tooltip: {
        formatter: function(param) {
          if (param.seriesName === '同期') {
            var timesStr = tempData[param.dataIndex].time.split('-');
            return (
              '同期:' +
              '<br/>' +
              (timesStr[0] - 1) +
              '-' +
              timesStr[1] +
              '&nbsp;:&nbsp;' +
              param.value
            );
          } else {
            return (
              '本期:' +
              '<br/>' +
              tempData[param.dataIndex].time +
              '&nbsp;:&nbsp;' +
              param.value
            );
          }
        },
      },
      legend: {
        right: '24px',
        top: 0,
        itemHeight: 8,
        orient: 'vertical',
        textStyle: {
          color: '#FFF',
        },
      },
      xAxis: {
        axisLine: {
          lineStyle: {
            color: '#FFF',
          },
        },
        splitLine: {
          show: false,
        },
        data:xData,
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#FFF',
          },
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: '本期',
          type: 'bar',
          data: yDats,

          barGap: 0,
          barWidth: 30,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#FFF',
              },
              {
                offset: 1,
                color: '#0080FF',
              },
            ]),
          },
        },
        {
          name: '同期',
          type: 'line',
          data: yLastData,
          barGap: 0,
          barWidth: 30,
          itemStyle: {
            color: '#FFCC00',
          },
        },
      ],
    };
    chart.setOption(option);
  }

  pieChart() {
    let chart = echarts.init(this.chartPie.nativeElement);
    let option = {
      grid: {
        height: '80%',
        width: '100%',
        left: '0',
        top: '0',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
        position: 'right',
      },
      series: [
        {
          radius: [0, '80%'],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          name: '能源种类',
          type: 'pie',
          center: ['50%', '50%'],
          data: this.pie,
        },
      ],
    };
    chart.setOption(option);
  }

  tradeChart() {
    let tableName: any[] = [];
    let elecData: any[] = [];
    let coalData: any[] = [];
    this.tradeData.forEach(item => {
      tableName = [...tableName, item.name];
      elecData = [...elecData, item.elec];
      coalData = [...coalData, item.coal];
    });
    let chart = echarts.init(this.trade.nativeElement);
    let option = {
      legend: {
        textStyle: {
          color: '#FFF',
        },
        formatter: function(param) {
          if (param === '电力') {
            return '电力(亿千瓦时)';
          } else {
            return '煤炭(万吨)';
          }
        },
      },
      grid: {
        top: '10%',
        left: '2%',
        height: '80%',
        width: '85%',
        containLabel: true,
      },
      tooltip: {
        formatter: function(param) {
          if (param.seriesName === '电力') {
            return (
              '电力:<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#3c6db0;"></span>' +
              Math.abs(param.value).toFixed(2) +
              '亿千瓦时'
            );
          } else if (param.seriesName === '煤炭') {
            return (
              '煤炭:<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#478463;"></span>' +
              Math.abs(param.value).toFixed(2) +
              '万吨'
            );
          }
        },
      },
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: {
        type: 'category',
        axisLabel: {
          color: '#02cee7',
        },
        axisLine: {
          show: false,
        },
        data: [...tableName],
        label: {
          itemStyle: {
            color: '#FFF',
          },
        },
      },
      series: [
        {
          name: '电力',
          itemStyle: {
            color: '#3c6db0',
          },
          type: 'bar',
          data: [...elecData],
          stack: 'value',
          label: {
            show: true,
            position: 'left',
            formatter: function(param) {
              return Math.abs(param.value).toFixed(2);
            },
          },
        },
        {
          name: '煤炭',
          itemStyle: {
            color: '#478463',
          },
          type: 'bar',
          data: [...coalData],
          stack: 'value',
          label: {
            show: true,
            position: 'right',
            formatter: function(param) {
              return Math.abs(param.value).toFixed(2);
            },
          },
        },
      ],
    };
    chart.setOption(option);
  }

  drawMap() {
    this.echartMapData = Object.entries(this.map).map(item => {
      return {
        name: item[0],
        value: item[1]['value'],
        toolTip: [
          {
            unit: '万吨标准煤',
            indexName: ConstantUtil.ZHNYXFL,
            value: item[1]['value'],
            conversion: '10000',
          },
        ],
      };
    });
    this.linesData = [
      // {
      //   name: '乌鲁木齐市',
      //   coords: [
      //     [87.605025, 43.82046],
      //     [91.765114, 45.747706],
      //   ],
      //   visualMap: false,
      // },
      // {
      //   name: '克拉玛依市',
      //   coords: [
      //     [84.889124, 45.316987],
      //     [80.974212, 46.602005],
      //   ],
      //   visualMap: false,
      // },
      // {
      //   name: '克孜勒苏柯尔克孜自治州',
      //   coords: [
      //     [74.88, 39.581036],
      //     [74.88, 40.882141],
      //   ],
      //   visualMap: false,
      // },
      // {
      //   name: '博尔塔拉蒙古自治州',
      //   coords: [
      //     [81.696291, 44.930288],
      //     [78.396291, 44.930288],
      //   ],
      //   visualMap: false,
      // }
    ];
    this.chartMap.setMapChart(null);
    return;
    //let chartMap = echarts.init(this.chartMap.nativeElement);
    let mapData = MapData.buildData();
    echarts.registerMap('jining', mapData);
    let colors = [
      '#0941FC',
      '#0951FC',
      '#0961FC',
      '#0971FC',
      '#0981FC',
      '#0991FC',
    ];
    let option = {
      tooltip: {
        show: true,
        trigger: 'item',
        formatter: function(param) {
          return (
            param.name +
            ':<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
            param.color +
            ';"></span>综合能耗:' +
            param.value +
            '万吨标准煤'
          );
        },
      },
      series: [
        {
          type: 'map',
          map: 'jining',
          zoom: 1.2,
          data: [
            {
              name: '梁山县',
              value: this.map['梁山县'] ? this.map['梁山县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['梁山县'] ? this.map['梁山县'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '汶上县',
              value: this.map['汶上县'] ? this.map['汶上县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
                offset: [123123123, 300],
              },
              itemStyle: {
                color:
                  colors[this.map['汶上县'] ? this.map['汶上县'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '兖州市',
              value: this.map['兖州市'] ? this.map['兖州市'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['兖州市'] ? this.map['兖州市'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '曲阜市',
              value: this.map['曲阜市'] ? this.map['曲阜市'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['曲阜市'] ? this.map['曲阜市'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '泗水县',
              value: this.map['泗水县'] ? this.map['泗水县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['泗水县'] ? this.map['泗水县'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '嘉祥县',
              value: this.map['嘉祥县'] ? this.map['嘉祥县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['嘉祥县'] ? this.map['嘉祥县'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '任城区',
              value: this.map['任城区'] ? this.map['任城区'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['任城区'] ? this.map['任城区'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '鱼台县',
              value: this.map['鱼台县'] ? this.map['鱼台县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['鱼台县'] ? this.map['鱼台县'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '金乡县',
              value: this.map['金乡县'] ? this.map['金乡县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['金乡县'] ? this.map['金乡县'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '邹城市',
              value: this.map['邹城市'] ? this.map['邹城市'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['邹城市'] ? this.map['邹城市'].order : 5],
                borderColor: '#FFF',
              },
            },
            {
              name: '微山县',
              value: this.map['微山县'] ? this.map['微山县'].value : 0,
              label: {
                show: true,
                color: '#FFF',
              },
              itemStyle: {
                color:
                  colors[this.map['微山县'] ? this.map['微山县'].order : 5],
                borderColor: '#FFF',
              },
            },
          ],
        },
      ],
    };
    //chartMap.setOption(option);
  }

  changeCss() {
    this.mapTableDiv.nativeElement.children[0].children[0].children[0].children[0].style.borderWidth = 0;
    this.mapTableDiv.nativeElement.children[0].children[0].children[0].children[0].childNodes[1].childNodes[6].remove();

    this.sortTableDiv1.nativeElement.children[0].children[0].children[0].children[0].style.borderWidth = 0;
    this.sortTableDiv1.nativeElement.children[0].children[0].children[0].children[0].childNodes[1].childNodes[6].remove();

    this.sortTableDiv2.nativeElement.children[0].children[0].children[0].children[0].style.borderWidth = 0;
    this.sortTableDiv2.nativeElement.children[0].children[0].children[0].children[0].childNodes[1].childNodes[6].remove();
  }

  getPlan() {
    let url = '/energyreport/progressControl/plan';
    this.http
      .get(url)
      .toPromise()
      .then(result => {
        this.plans = result;
        this.planCode = this.plans[0].id;
        if (this.analysisPlanCode()) {
          this.message.error('业务字典配置错误!');
          return;
        } else {
          this.initTime();
          let timeStr: any = this.planCode.split('-');
          let startTime: any = parseFloat(moment(timeStr[0]).format('YYYY'));
          let url =
            '/energyreport/progressControlAnalys/data/' +
            this.year +
            '/' +
            startTime;
          this.http
            .get(url)
            .toPromise()
            .then(result => {
              let res: any = result;
              this.progressDanhao = res.progressDanhao.split('%')[0];
              this.progressZhnh = res.progressZhnh.split('%')[0];
              this.thisYearZhnh = res.thisYearZhnh;
              this.thisYearDanhao = res.thisYearDanhao;
              this.goalThisYearDanhao = res.goalThisYearDanhao;
              this.goalThisYearZhnh = res.goalThisYearZhnh;
              this.calculateNum = (
                (parseFloat(this.progressDanhao) * 100) /
                parseFloat(this.goalThisYearDanhao)
              ).toFixed(2);
              /*           if (parseFloat(this.progressZhnh) > 100) {
            this.cityOverviewDiv1.nativeElement.style.width = '100%';
          } else if (parseFloat(this.progressZhnh) < 0) {
            this.cityOverviewDiv1.nativeElement.style.width = 0;
          } else {
            this.cityOverviewDiv1.nativeElement.style.width = parseFloat(this.progressZhnh) + '%';
          } */
              /*           if (parseFloat(this.calculateNum) > 100) {
            this.cityOverviewDiv2.nativeElement.style.width = '100%';
          } else if (parseFloat(this.calculateNum) < 0) {
            this.cityOverviewDiv2.nativeElement.style.width = 0;
          } else {
            this.cityOverviewDiv2.nativeElement.style.width = parseFloat(this.calculateNum) + '%';
          } */
              this.preName = res.thisOrg;
              this.preZhnh = res.zhnhPre;
              this.preCzdh = res.danhaoPre;
              let urlDetail =
                '/energyreport/progressControlAnalys/getNyxfzlControl';
              this.http
                .post(
                  urlDetail,
                  {
                    searchType: 'region',
                    beginTime: startOfYear(new Date()).getTime(),
                    endTime: endOfYear(new Date()).getTime(),
                  },
                  {
                    headers: new HttpHeaders({
                      'Content-type':
                        'application/json; application/x-www-form-urlencodeed; charset=utf-8;',
                    }),
                  },
                )
                .toPromise()
                .then(result => {
                  let res: any = result['data'];
                  /*  res.nyxfzlData.shift();
                  res.czdhData.shift(); */
                  this.mapTableData1 = res.nyxfzlData.map(item => {
                    item['sum'] = item['thisYearZhnyxfl'];
                    return item;
                  });
                  this.mapTableData2 = res.czdhData.map(item => {
                    item['sum'] = item['thisYearCzdh'];
                    return item;
                  });
                  let urlp = '/energyreport/cityOverview/predict';
                  this.http
                    .get(urlp)
                    .toPromise()
                    .then(resultp => {
                      this.mapTableData = this.mapTableData1;
                      this.tableType = 1;
                      let resp: any = resultp;
                      this.mapTableData3 = resp.table3;
                      let component = this;
                      setInterval(function() {
                        if (
                          component.pageIndex >
                            component.mapTableData.length / 5 ||
                          component.pageIndex ==
                            component.mapTableData.length / 5
                        ) {
                          if (component.tableType === 1) {
                            component.mapTableData = component.mapTableData2;
                            component.tableTitle =
                              '区域强度控制情况(千克标准煤/万元)';
                            component.tableType = 2;
                            component.pageIndex = 1;
                            component.thTitle = '目标(%)';
                          } else if (component.tableType === 2) {
                            component.mapTableData = component.mapTableData3;
                            component.tableTitle = '区域煤炭控制情况(万吨)';
                            component.tableType = 3;
                            component.pageIndex = 1;
                            component.thTitle = '目标';
                          } else if (component.tableType === 3) {
                            component.mapTableData = component.mapTableData1;
                            component.tableTitle =
                              '区域总量完成情况(万吨标准煤)';
                            component.tableType = 1;
                            component.pageIndex = 1;
                            component.thTitle = '目标';
                          }
                        } else {
                          component.pageIndex = component.pageIndex + 1;
                        }
                      }, 10000);

                      let temp = [];
                      for (let i = 0; i < res.czdhData.length; i++) {
                        temp[res.czdhData[i].name] = res.czdhData[i];
                      }
                      let predicTemp = this.predict;
                      for (let i = 0; i < res.nyxfzlData.length; i++) {
                        let object = {
                          name: '',
                          zhnh: '',
                          czdh: '',
                          elec: '',
                          coal: '',
                        };
                        object.name = res.nyxfzlData[i].name;
                        object.zhnh = res.nyxfzlData[i].predict;
                        object.czdh = temp[res.nyxfzlData[i].name].predict;
                        object.elec =
                          resp.predictCE[object.name].elec == 0
                            ? '-'
                            : resp.predictCE[object.name].elec;
                        object.coal =
                          resp.predictCE[object.name].coal == 0
                            ? '-'
                            : resp.predictCE[object.name].coal;
                        predicTemp.push(object);
                      }
                      /*                       let object1 = {
                        name: this.preName,
                        zhnh: this.preZhnh,
                        czdh: this.preCzdh,
                        elec:
                          resp.predictCE[this.preName].elec == 0
                            ? '-'
                            : resp.predictCE[this.preName].elec,
                        coal:
                          resp.predictCE[this.preName].coal == 0
                            ? '-'
                            : resp.predictCE[this.preName].coal,
                      }; */
                      this.predict = predicTemp;
                    });
                });
            });
        }
      });
  }

  analysisPlanCode() {
    let reg = /^[0-9]+.?[0-9]*$/;
    let timeStr: any = this.planCode.split('-');
    for (let i = 0; i < timeStr.length; i++) {
      if (
        !reg.test(timeStr[i]) ||
        timeStr.length != 2 ||
        parseFloat(timeStr[0] + 4) != parseFloat(timeStr[1])
      ) {
        return false;
      }
    }
  }

  initTime() {
    let timeStr: any = this.planCode.split('-');
    this.year = parseFloat(moment().format('YYYY'));
    let object1 = {
      id: parseFloat(moment(timeStr[0]).format('YYYY')),
      text: moment(timeStr[0]).format('YYYY'),
    };
    let object2 = {
      id: parseFloat(
        moment(timeStr[0])
          .add(1, 'years')
          .format('YYYY'),
      ),
      text: moment(timeStr[0])
        .add(1, 'years')
        .format('YYYY'),
    };
    let object3 = {
      id: parseFloat(
        moment(timeStr[0])
          .add(2, 'years')
          .format('YYYY'),
      ),
      text: moment(timeStr[0])
        .add(2, 'years')
        .format('YYYY'),
    };
    let object4 = {
      id: parseFloat(
        moment(timeStr[0])
          .add(3, 'years')
          .format('YYYY'),
      ),
      text: moment(timeStr[0])
        .add(3, 'years')
        .format('YYYY'),
    };
    let object5 = {
      id: parseFloat(
        moment(timeStr[0])
          .add(4, 'years')
          .format('YYYY'),
      ),
      text: moment(timeStr[0])
        .add(4, 'years')
        .format('YYYY'),
    };
    let years = [];
    years.push(object1);
    years.push(object2);
    years.push(object3);
    years.push(object4);
    years.push(object5);
    this.years = years;
  }

  ngOnInit() {}

  formatName(param: string) {
    if (param.length > 4) {
      return param.substring(0, 4) + '...';
    } else {
      return param;
    }
  }

  formatAlarm(param: any) {
    if (this.tableType === 1) {
      if (
        param.predict != '-' &&
        param.sum != '-' &&
        param.increase != '-' &&
        param.goal != '-'
      ) {
        return parseFloat(param.predict) -
          (parseFloat(param.sum) - parseFloat(param.increase)) >
          parseFloat(param.goal)
          ? '预警'
          : '正常';
      } else {
        return '-';
      }
    } else if (this.tableType === 2) {
      if (
        param.predict != '-' &&
        param.sum != '-' &&
        param.progress != '-' &&
        param.goal != '-'
      ) {
        let progress = parseFloat(param.progress.split('%')[0]) / 100;
        let last = parseFloat(param.sum) / (1 - progress);
        if (((last - param.predict) * 100) / last < parseFloat(param.goal)) {
          return '预警';
        } else {
          return '正常';
        }
      } else {
        return '-';
      }
    } else {
      if (param.predict != '-' && param.goal != '-') {
        return param.alarm;
      }
    }
  }

  bottomWindowShowAndHide() {
    this.isBottomWindowShow = !this.isBottomWindowShow;
  }

  beginDateChange(e: any){
    this.beginDate = e;
    this.searchData();
  }

  endDateChange(e: any){
    this.endDate = e;
    this.searchData();
  }

  disabledStartDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.endDate) > 0;
  }

  disabledEndDate = (current: Date): boolean => {
    //只能选择开始时间到今天（包括今天）之间的日期
    return differenceInCalendarDays(current, this.beginDate) < 0 || differenceInCalendarDays(current, new Date()) > 0;
  };
}
