import { Component, OnInit, Injector } from '@angular/core';
import { GXService } from '@shared/GX.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';

@Component({
  selector: 'onlinecollect-login',
  templateUrl: './onlinecollect-login.component.html',
  styleUrls: ['./onlinecollect-login.component.less'],
})
export class OnlinecollectLoginComponent implements OnInit {
  form: FormGroup;
   userId:string='';
  loading = false;
  loginUrl = '/energyreport/onlineCollectProjectUser/login';
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    public gxService: GXService,
    private injector: Injector,
    private msg: NzMessageService,
    private router: Router

  ) {
    this.form = fb.group({
      name: [null, [Validators.required]],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {}
  get userName() {
    return this.form.controls.userName;
  }

  get password() {
    return this.form.controls.password;
  }
  submit() {
    let param = {
      name: this.form.value['name'],
      password: this.form.value['password'],
    };
    this.http
      .get(this.loginUrl, { params: param })
      .toPromise()
      .then((result: InvokeResult) => {
        if (result['success']) {
         // this.goTo('/onlinecollect/company');
         this.userId=result['data'];
         this.goTo();
        } else {
          this.msg.error(result['errorMessage']);
        }
      })
      .catch();
  }

  goTo() {
    this.router.navigate(['/onlinecollect/progressbar'], { queryParams: { 'userId':this.userId  } });
    //setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }
}
