import { InvokeResult } from './entity/InvokeResult.entity';
import { Observable, of } from 'rxjs';
import { deepCopy } from '@delon/util';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, pluck } from 'rxjs/operators';
import { ConstantUtil } from './util/ConstantUtil.util';
import { DictionaryItem } from './entity/DictionaryItem.entity';
import {
  subDays,
  startOfMonth,
  subMonths,
  subYears,
  startOfDay,
  startOfYear,
  endOfDay,
  endOfYear,
  endOfMonth,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

/**
 * 封装共通服务
 * @author ludaxian
 * @date 2020-03-20
 * @export
 */
@Injectable({
  providedIn: 'root',
})
export class GXService {
  getSystemParameterByCodeUrl = ConstantUtil.GET_SYSTEMPARAMTERBYCODE_URL;
  getDictionaryItemUrl = ConstantUtil.GET_DICTIONARYITEM_URL;
  getDictionaryItemListByTwoCodeUrl =
    ConstantUtil.GET_DICTIONARYITEMLISTBYTWOCODE_URL;
  getDictionaryItemByCodeUrl = ConstantUtil.GET_DICTIONITEM_URL;
  public ConstantUtil = ConstantUtil; //绑定html用
  public isEmpty = GXService.isEmpty; //绑定html用
  public ConvertToNumber = GXService.ConvertToNumber; //绑定html用
  public XinhaiConfig: { [key: string]: DictionaryItem } = {};

  constructor(private http: HttpClient) {
    this.initPlatform();
    //this.getDictionaryItem('XinhaiConfig');
    //console.dir(GXService.GxOptional);
  }

  /**
   * 按照某个字段已存在的某种顺序排序
   *
   * @author ludaxian
   * @date 2020-03-27
   * @param orderList 目标数组 [a,b,c]
   * @param sortList  排序数组 [...,{...,sortName:b},{...,sortName:a}];
   * @param sortName 排序字段
   */
  sortInOrderList(orderList: any[], sortList: any[], sortName: string): any[] {
    if (GXService.isEmpty(orderList) || GXService.isEmpty(sortList)) {
      return sortList;
    }
    if (sortList.some(item => !orderList.includes(item[sortName]))) {
      throw new Error('排序值不存在于目标数组中');
    }
    let sortListCopy = deepCopy(sortList);
    return sortListCopy.sort(
      (a, b) =>
        orderList.findIndex(item => item === a[sortName]) -
        orderList.findIndex(item => item === b[sortName]),
    );
  }
  /**
   *转换成数字
   *信海平台特殊值
   * @author ludaxian
   * @date 2020-03-13
   * @param value 值
   * @param decimalPlaces 保留小数位数
   * @returns number
   */
  static ConvertToNumber(value: any, decimalPlaces?: number): number {
    if (
      value == null ||
      value === '' ||
      value === ConstantUtil.XINHAI_EMPTYSTR
    ) {
      return Number(decimalPlaces ? new Number(0).toFixed(decimalPlaces) : 0);
    } else {
      return Number(
        decimalPlaces ? Number(value).toFixed(decimalPlaces) : value,
      );
    }
  }

  /*  static ofNullable = <T>(any: T): T =>
    GXService.isEmpty(GXService.optionalValue) ? any : GXService.optionalValue; */

  /**
   *
   *  转为小数，自动补零 无数据默认为0.00
   * @author zhangqiuju
   * @date 2020-04-27
   * @param value
   * @param  length 几位小数
   * @returns
   */
  parserPercent(value: number, length: number): string {
    if (!value) {
      return '0.00';
    }
    if (!isNaN(value)) {
      value = Math.round(value * 100) / 100;
      var str = value.toString();
      var rs = str.indexOf('.');
      if (rs < 0) {
        rs = str.length;
        str += '.';
      }
      while (str.length <= rs + length) {
        str += '0';
      }
    }
    return str;
  }

  /**
   *
   *判断输入的字符是否超过指定的长度
   * @author zhangqiuju
   * @date 2020-04-02
   * @description v1.0.1 ludaxian 修正判空、代码简化、新增类型
   * @param  value
   * @param length
   * @returns
   */
  static isMoreThanLength(value: string, length: number): boolean {
    return (!GXService.isEmpty(value)
      ? value.replace(/[^\x00-\xff]/g, '**')
      : ''
    ).length > length
      ? true
      : false;
  }

  /**
   *获取相距一个周期的初始化时间段
   *
   * @author ludaxian
   * @date 2020-05-29
   * @static
   * @param dateType
   * @returns
   */
  static getGxInitDateRangeInCycle(dateType: string): Date[] {
    let dateRange: Date[] = [];
    const now = new Date();
    switch (dateType) {
      case ConstantUtil.YEAR:
        dateRange = [
          startOfMonth(subYears(now, 1)),
          endOfMonth(subMonths(now, 1)),
        ];
        break;
      case ConstantUtil.MONTH:
        dateRange = [
          startOfMonth(subYears(now, 1)),
          endOfMonth(subMonths(now, 1)),
        ];
        break;
      case ConstantUtil.DAY:
        dateRange = [startOfDay(subMonths(now, 1)), endOfDay(subDays(now, 1))];
        break;
      default:
        break;
    }
    return dateRange;
  }
  /**
   * 获取初期化时间段
   * 日：返回当前月1日起到昨日止
   * 月：返回1月起到上月止 当前月为1月返回去年1月起到去年12月止
   * 年：返回1月起到上月止，当前月为1月返回为去年1月起到去年12月止
   * @author ludaxian
   * @param dateType year/month/day
   * @date 2020-03-21
   * @returns [Date,Date]
   */
  static getGxInitDateRange(dateType: string): Date[] {
    let dateRange: Date[] = [];
    //const now = new Date();
    const now = new Date();
    switch (dateType) {
      case ConstantUtil.YEAR:
        if (now.getMonth() + 1 == 1) {
          //跨年
          dateRange = [
            startOfYear(subYears(now, 1)),
            endOfYear(subYears(now, 1)),
          ];
        } else {
          dateRange = [
            startOfYear(subYears(now, 1)),
            endOfYear(subYears(now, 1)),
          ];
        }
        break;
      case ConstantUtil.MONTH:
        if (now.getMonth() + 1 == 1) {
          //跨年
          dateRange = [
            startOfYear(subYears(now, 1)),
            endOfYear(subYears(now, 1)),
          ];
        } else {
          dateRange = [
            //startOfMonth(subMonths(now, 1)),
            startOfYear(subYears(now, 0)),
            endOfMonth(subMonths(now, 1)),
          ];
        }
        break;
      case ConstantUtil.DAY:
        if (now.getDay() + 1 == 1) {
          //跨年
          dateRange = [
            startOfMonth(subDays(now, 1)),
            endOfMonth(subDays(now, 1)),
          ];
        } else {
          dateRange = [
            startOfMonth(subDays(now, 1)),
            endOfDay(subDays(now, 1)),
          ];
        }
        break;
      default:
        break;
    }
    return dateRange;
  }

  /**
   *Ant Desgin 时间控件禁用回调
   *不能选择今天之后的日期
   * @author ludaxian
   * @date 2020-03-24
   * @returns boolean
   */
  setDisabledDayRangeOfToday = (current: Date) => {
    return differenceInDays(startOfDay(current), startOfDay(new Date())) > 0;
  };

  /**
   *Ant Desgin 时间控件禁用回调
   *不能选择昨天之后的日期
   * @author ludaxian
   * @date 2020-03-24
   * @returns boolean
   */
  setDisabledDayRange = (current: Date) => {
    return differenceInDays(startOfDay(current), startOfDay(new Date())) > -1;
  };
  /**
   *Ant Desgin 时间控件禁用回调
   *不能选择上月之后的日期
   * @author ludaxian
   * @date 2020-03-24
   * @returns boolean
   */
  setDisabledMonthRange = (current: Date) => {
    return (
      differenceInMonths(startOfMonth(current), startOfMonth(new Date())) > -1
    );
  };

  /**
   *查询业务字典共通方法 不存在请写null
   *根据字典code查询全部的
   * @author ludaxian
   * @date 2020-03-21
   * @param dicCode
   * @returns
   */
  getDictionaryItem(dicCode: string): Observable<DictionaryItem[]> {
    return this.http
      .get<DictionaryItem[]>(`${this.getDictionaryItemUrl}/${dicCode}`)
      .pipe(switchMap(result => of(result['data']['DictionaryItemList'])));
  }
  /**
   * 根据dictionaryCode和DictionaryItemCode来查找平台配置项中字典项的子项列表
   * @author qiu
   * @date 2020-08-10
   * @param {string} dicCode
   * @param {String} itemCode
   * @returns {Observable<DictionaryItem[]>}
   */
  getDictionaryItemListByTwoCode(
    dicCode: string,
    itemCode: String,
  ): Observable<DictionaryItem[]> {
    return this.http
      .get<DictionaryItem[]>(
        `${this.getDictionaryItemListByTwoCodeUrl}/${dicCode}/${itemCode}`,
      )
      .pipe(switchMap(result => of(result['data'])));
  }

  /**
   *根据dictionarycode 和  dictionaryitemcode 来查询字典项
   *
   * @author qiu
   * @date 2020-09-25
   * @param dicCode
   * @param itemCode
   * @returns
   */
  getDictionaryItemByTwoCode(
    dicCode: string,
    itemCode: String,
  ): Observable<DictionaryItem> {
    return this.http
      .get<DictionaryItem[]>(
        `${this.getDictionaryItemByCodeUrl}/${dicCode}/${itemCode}`,
      )
      .pipe(switchMap(result => of(result['data'])));
  }
  /**
   *获取系统参数共通方法
   * @author ludaxian
   * @date 2020-03-17
   * @param code
   */
  getSystemParameter(code: string): Observable<InvokeResult> {
    return this.http.get<InvokeResult>(
      `${this.getSystemParameterByCodeUrl}/${code}`,
    );
  }
  /**
   * 排序共通
   * @param sortName 排序字段名称
   * @param sortValue  排序顺序 descend ascend null
   * @param list 排序数组
   * @param sortType 排序类型待扩展
   */
  doSort(sortName: string, sortValue: string, list: any[], sortType?: string) {
    const copyList = deepCopy(list);
    if (sortName && sortValue) {
      let noDataList = [];
      let sortList = [];
      list.forEach(item => {
        if (GXService.isEmpty(item[sortName]) || item[sortName] === '-') {
          noDataList = [...noDataList, item];
        } else {
          sortList = [...sortList, item];
        }
      });
      return [
        ...sortList.sort((a, b) => {
          return sortValue === ConstantUtil.SORT_ASCEND
            ? Number(a[sortName!]) > Number(b[sortName!])
              ? 1
              : -1
            : Number(b[sortName!]) > Number(a[sortName!])
            ? 1
            : -1;
        }),
        ...noDataList,
      ];
    } else {
      return copyList;
    }
  }

  /**
   *读取本地json文件
   *
   * @author ludaxian
   * @date 2020-03-12
   */
  readJson(): Observable<any> {
    if (
      GXService.isEmpty(this.XinhaiConfig) ||
      GXService.isEmpty(this.XinhaiConfig['GeoJsonName'])
    ) {
      return new Observable(observer => {
        observer.next(null);
      });
    }
    var url = `../../assets/json/${this.XinhaiConfig['GeoJsonName']['data']}.json`; /*json文件url，本地的就写本地的位置，如果是服务器的就写服务器的路径*/
    var request = new XMLHttpRequest();
    request.open('get', url); /*设置请求方法与路径*/
    request.send(null); /*不发送数据到服务器*/
    return new Observable(observer => {
      request.onload = function() {
        if (request.status == 200) {
          var json = JSON.parse(request.responseText);
          observer.next(json);
        }
      };
    });
  }

  /**
   * 按照字段排序 并将带有父子关联字段的一维数组 转换成 合并单元格数据结构的数组
   * @author ludaxian
   * @date 2020-04-01
   * @param list [...,{id,parentId,...}] 带有父子关联字段的一维数组
   * @param sortName 排序字段
   * @description 1.根节点的id和parentId相等  2.所有数据必须具有完整的父子关联属性（parentId,id）
   * @returns [..[{},{},..]]
   */
  editTreeTabel(list: any[]): any[] {
    let copyTreeNodes = deepCopy(list);
    //默认根节点id和parentId相同为根节点 后期可能补充其他判断根节点的情况
    const isRootNode = node => node['parentId'] === node['id'];
    //处理多根节点情况的数据（多支树）
    if (copyTreeNodes.filter(item => isRootNode(item)).length > 1) {
      const rootId = 'luRootId';
      let rootNode = { id: rootId, parentId: rootId, children: [] };
      copyTreeNodes.map(item => {
        if (isRootNode(item)) {
          item['parentId'] = rootId;
          rootNode['children'] = [...rootNode['children'], item];
        }
      });
      copyTreeNodes = [rootNode, ...copyTreeNodes];
    }

    //递归生成children树结构
    const editChildren = (childNodes = []) => {
      childNodes.forEach(item => {
        if (GXService.isEmpty(item['children'])) item['children'] = []; //强制设置children属性
        let child = copyTreeNodes.filter(
          node => node['parentId'] == item['id'] && !isRootNode(node),
        );
        if (!GXService.isEmpty(child)) {
          item['children'] = child;
          editChildren(child);
        } else {
          item['isChecked'] = true;
        }
      });
      return childNodes;
    };
    //递归将树结构的数组转换成合并单元格数据结构的数组
    const treeToTableArr = (node, tableArr = [], trArr = []) => {
      if (GXService.isEmpty(node['children'])) {
        tableArr.push(trArr);
      } else {
        node['children'].forEach((childNode, index) => {
          if (index != 0 && !GXService.isEmpty(trArr)) {
            trArr[0]['rowSpan'] = 0;
          }
          const tdArr = [...deepCopy(trArr), childNode];
          if (tdArr.length === 1) {
            tdArr[0]['rowSpan'] = 1;
          } else if (tdArr.length > 1) {
            tdArr[tdArr.length - 2]['rowSpan'] =
              index === 0 ? getLeafNum(node) : 0;
            tdArr[tdArr.length - 1]['rowSpan'] = 1;
          }
          treeToTableArr(childNode, tableArr, tdArr);
        });
      }
      return tableArr;
    };
    //计算叶子节点的数量
    const getLeafNum = node => {
      if (GXService.isEmpty(node['children'])) {
        node['rowSpan'] = 1;
        return 1;
      } else {
        let leafNum = 0;
        node['children'].forEach(childNode => {
          leafNum += getLeafNum(childNode);
        });
        node['rowSpan'] = leafNum;
        return leafNum;
      }
    };

    editChildren(copyTreeNodes);
    return treeToTableArr(
      copyTreeNodes.find(item => isRootNode(item)),
      [],
      [],
    );
  }

  /**
   *初始化平台
   * @author ludaxian
   * @date 2020-04-29
   */
  async initPlatform() {
    this.XinhaiConfig = await this.getSystemUtil();
  }

  /**
   *获取系统参数配置
   * @author ludaxian
   * @date 2020-04-29
   */
  getSystemUtil(): Promise<{ [key: string]: DictionaryItem }> {
    return this.getDictionaryItem('XinhaiConfig')
      .pipe(
        switchMap(
          item => /*  of( 方法太新 平台和浏览器不支持
            Object.fromEntries(
              item.map(dicitem => [dicitem.code, dicitem.data]),
            ),
          ), */ {
            let object = {};
            item.forEach(dic => Object.assign(object, { [dic['code']]: dic }));
            return of(object);
          },
        ),
      )
      .toPromise();
  }

  /**
   *
   * 判断参数any是否为null,undefiend,[],{}，'-','',' '等
   * @author ludaxian
   * @date 2020-05-13
   * @param any
   * @param [isZeroEmpty] 0是否为返回true 默认0返回true
   * @returns
   */
  static isEmpty(any: any, isZeroEmpty?: boolean): boolean {
    return (
      (isZeroEmpty && any == 0) ||
      ConstantUtil.XINHAI_EMPTYSTR == any ||
      '' === any ||
      ' ' === any ||
      any == undefined || any === null || any === 'null' ||
      (Array.isArray(any) && any.length == 0) ||
      (Object.prototype.isPrototypeOf(any) && Object.keys(any).length == 0)
    );
  }

  /**
   *Ant Desgin 时间控件禁用回调
   * 可选范围为上月份所在的年份
   * @author ludaxian
   * @date 2020-03-24
   * @returns boolean
   */
  setDisabledYearRange = (current: Date) => {
    let date = new Date();
    if (date.getMonth() + 1 == 1) {
      //如果为跨年月份，则当年仍未不可选
      return differenceInYears(startOfYear(current), startOfYear(date)) > -1;
    } else {
      return differenceInYears(startOfYear(current), startOfYear(date)) > 0;
    }
  };

  /**
   *Ant Desgin 结束时间禁用回调
   *结束时间不小于开始时间（比较到月份）,且不可跨年, 不可是上月之后的日期
   * @author songxiaoyue
   * @date 2020-05-07
   * @returns boolean
   */
  setEndDateDisableDay = (startDate: Date, endDate: Date) => {
    if (!endDate || !startDate) {
      return false;
    }
    return (
      endDate.getTime() < startDate.getTime() ||
      differenceInDays(startOfDay(endDate), startOfDay(new Date())) > -1 ||
      differenceInMonths(startOfMonth(endDate), startOfMonth(new Date())) > 0 ||
      differenceInYears(startOfYear(endDate), startOfYear(startDate)) > 0
    );
  };

  /**
   *Ant Desgin 结束时间禁用回调
   *结束时间不小于开始时间（比较到月份）,且不可跨年, 不可是上月之后的日期
   * @author songxiaoyue
   * @date 2020-05-07
   * @returns boolean
   */
  setEndDateDisableMonth = (startDate: Date, endDate: Date) => {
    if (!endDate || !startDate) {
      return false;
    }
    return (
      endDate.getFullYear() < startDate.getFullYear() ||
      endDate.getMonth() < startDate.getMonth() ||
      differenceInMonths(startOfMonth(endDate), startOfMonth(new Date())) >
        -1 ||
      differenceInYears(startOfYear(endDate), startOfYear(startDate)) > 0
    );
  };

  /**
   *Ant Desgin 时间控件禁用回调
   *结束时间不小于开始时间（比较到年份）
   * @author songxiaoyue
   * @date 2020-05-07
   * @returns boolean
   */
  setEndDateDisableYear = (startDate: Date, endDate: Date) => {
    if (!endDate || !startDate) {
      return false;
    }
    return (
      endDate.getFullYear() < startDate.getFullYear() ||
      endDate.getFullYear() > subMonths(new Date(), 1).getFullYear()
    );
  };

  /**
   *Ant Desgin 结束时间
   * 改变开始时间时，结束时间的初始值（日）
   * @author songxiaoyue
   * @date 2020-05-07
   * @returns Date
   */
  getEndDateDay = (startDate: Date, endDate: Date) => {
    if (startDate.getMonth() == new Date().getMonth()) {
      endDate = subDays(new Date(), 1);
    } else {
      endDate = endOfMonth(startDate);
    }
    return endDate;
  };

  /**
   *Ant Desgin 结束时间
   * 改变开始时间时，结束时间的初始值（月）
   * @author songxiaoyue
   * @date 2020-05-07
   * @returns Date
   */
  getEndDateMonth = (startDate: Date, endDate: Date) => {
    if (startDate.getFullYear() == new Date().getFullYear()) {
      endDate = subMonths(new Date(), 1);
    } else {
      endDate = new Date(startDate.getFullYear() + '-12');
    }
    return endDate;
  };

  /**
   *Ant Desgin 结束时间
   * 改变开始时间时，结束时间的初始值（年）
   * @author songxiaoyue
   * @date 2020-05-07
   * @returns Date
   */
  getEndDateYear = (startDate: Date, endDate: Date) => {
    endDate = new Date(startDate.getFullYear() + '-12');
    return endDate;
  };

  /**
   *树结构数组扁平化
   * 必须有children
   * @author ludaxian
   * @date 2020-04-26
   * @param tree
   * @returns
   */
  static formatTreeToArr(tree: any[]) {
    let arr = [];
    const format = tree => {
      if (tree.length === 0) return;
      tree.forEach(item => {
        arr = [...arr, item];
        if (
          item.hasOwnProperty('children') &&
          !this.isEmpty(item['children'])
        ) {
          format(item['children']);
        }
      });
      return arr;
    };
    return format(tree);
  }
}
//GXService.GxOptional.prototype.ofNullable = GXService.ofNullable;
