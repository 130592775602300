import { Component, OnInit } from '@angular/core';

import {NzModalService} from "ng-zorro-antd";
import {SendMessageComponent} from "../send-message/send-message.component";

@Component({
  selector: 'header-letter',
  template: `
    <nz-dropdown nzPlacement="bottomRight">
      <div class="item d-flex align-items-center px-sm" nz-dropdown>
        <a (click)="openViewModal()"><nz-avatar [nzSrc]="srcUrl" nzSize="small" class="mr-sm"></nz-avatar></a>
      </div>
    </nz-dropdown>
  `,
})
export class HeaderLetterComponent implements OnInit {
  srcUrl = 'assets/img/head_letter.jpg';
  viewModal: any;
  constructor(private modalService: NzModalService) { }

  ngOnInit() {
  }

  openViewModal(){
    this.viewModal = this.modalService.create({
      nzTitle: '即时消息',
      nzContent: SendMessageComponent,
      nzFooter: null,
      nzWidth: 600,
      nzStyle: {marginTop: "-5em"}
    });
  }
}
