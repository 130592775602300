import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class GlobalConfigService {
    public static formatNum: any = 2;

    constructor() {
        this._globalPath = '/energyreport';
    }

    private _globalPath: string;
    private _homePage: string;

    get homePage(): string {
        return 'homepage/homepage';
    }

    set homePage(value: string) {
        this._homePage = value;
    }

    public get globalPath() {
        return this._globalPath;
    }

    public set globalPath(value: string) {
        this._globalPath = value;
    }
}
