import {Component, Input, OnInit} from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { WordExport } from 'assets/global/word-export';
import {HttpClient} from "@angular/common/http";
import {forkJoin, Observable, of} from "rxjs";
import { InvokeResult } from '../../../shared/entity/InvokeResult.entity';
import {GXService} from "@shared/GX.service";

@Component({
    selector: 'onlinecollectionproject-energydisplay',
    templateUrl: './onlinecollectionproject-energydisplay.component.html',
    styleUrls: ['./onlinecollectionproject-energydisplay.component.less']
})
export class OnlinecollectionprojectEnergydisplayComponent implements OnInit {
    @Input() userId:any;
    company={
        name: '',
        code: '',
        address: '',
        tradeName:'',
        energyConsumptionLevelName: '',
        contactPerson: '',
        contactTel: '',
    };
    tabledata1=[];
    tabledata2=[];
    // companyCode = '1';
    collectTableData = [];
    constructor(
        private message: NzMessageService,
        private modalService: NzModalService,
        private  http:HttpClient,
        private gxService: GXService,
    ) { }

    ngOnInit() {

        this.getData();
        // this.tabledata2=[
        //     {
        //         // type: "煤焦炭固体类能源",
        //         type:"0",
        //         title:"1号电表",
        //         detailJson: [
        //             [{text:"皮带秤、转子秤等自动上煤", type: "text"}, {text:"铲车人工上煤", type: "text"}],
        //             [{text:"有积算仪表", type: "text"}],
        //             [{text:"有R5484通讯工具", type: "text"}, {text:"无通讯工具", type: "text"}],
        //             [{text:"被占用", type: "text"}],
        //             [{text:"填写：接入上位机系统名称", type: "text"}, {text:"填写：上位机建设厂家", type: "text"}, {text:"/assets/pages/img/comapny.jpg", type: "picture"}],
        //         ],
        //         conclusion:"结束"
        //     },
        //     {
        //         // type: "煤焦炭固体类能源",
        //         type:"1",
        //         title:"2号电表",
        //         detailJson: [
        //             [{text:"皮带秤、转子秤等自动上煤", type: "text"}, {text:"铲车人工上煤", type: "text"}],
        //             [{text:"有积算仪表", type: "text"}],
        //             [{text:"有R5484通讯工具", type: "text"}, {text:"无通讯工具", type: "text"}],
        //             [{text:"被占用", type: "text"}],
        //             [{text:"填写：接入上位机系统名称", type: "text"}, {text:"填写：上位机建设厂家", type: "text"}, {text:"/assets/pages/img/comapny.jpg", type: "picture"}],
        //         ],
        //         conclusion:"结束"
        //
        //     }
        // ];
        //     [
        //   {'no':'1','type':'固体类','title':'1#电表','col1':'皮带秤、转子秤等自动上煤','col2':'有积算仪表','col3':'有通讯功能','col4':'被占用','col5':'上位机名称、厂家、地址','endStr':'结束'},
        //   {'no':'2','type':'固体类','title':'2#电表','col1':'皮带秤、转子秤等自动上煤','col2':'有积算仪表','col3':'有通讯功能','col4':'被占用','col5':'上位机名称、厂家、地址','endStr':'结束'},
        //   {'no':'3','type':'固体类','title':'3#电表','col1':'皮带秤、转子秤等自动上煤','col2':'有积算仪表','col3':'有通讯功能','col4':'被占用','col5':'上位机名称、厂家、地址','endStr':'结束'},
        //   {'no':'4','type':'固体类','title':'4#电表','col1':'皮带秤、转子秤等自动上煤','col2':'有积算仪表','col3':'有通讯功能','col4':'被占用','col5':'上位机名称、厂家、地址','endStr':'结束'},
        //
        // ];

    }
    getData(){
        // const post1 = this.requestCompanyData();
        // const post2 = this.requestEnergyIndexData();
        forkJoin([this.requestCompanyData(),this.requestEnergyIndexData(),this.requestEnergyCollectData()]).subscribe((resultData:any)=>{
            if (resultData[0].success) {
                this.company = resultData[0].data;
            }else {
                this.message.error("获取企业信息失败！");
            }
            if (resultData[1].success) {
                this.tabledata1 = resultData[1].data;
                this.tabledata1.forEach((dataItem,index)=>{
                    dataItem['no']=index+1;
                })
            }else {
                this.message.error("获取能源品种失败！");
            }
            if (resultData[2].success){
                this.tabledata2 = resultData[2].data;
                this.tabledata2.forEach((rowItem,keyIndex) => {

                    let collectData = {};
                    collectData['no'] = keyIndex+1;
                    collectData['indexName'] = rowItem['indexName'];
                    collectData['title'] = rowItem['title'];
                    rowItem['no']=keyIndex+1;
                    collectData['conclusion']=this.conclusionStr(rowItem['conclusion']) ;
                    if (!this.gxService.isEmpty(rowItem.detailJson)) {
                        rowItem.detailJson.forEach((colItem, index) => {
                            if (index != rowItem.detailJson.length-1) {
                                colItem["colSpan"] = Math.floor(24/rowItem.detailJson.length);
                            } else {
                                colItem["colSpan"] = Math.floor(24/rowItem.detailJson.length) + 24%rowItem.detailJson.length;
                            }
                        });
                        let stepStr = '';
                        for (var index=0;index<rowItem.detailJson.length;index++){
                            for (var indexSub =0;indexSub<rowItem.detailJson[index].length;indexSub++){
                                if (rowItem.detailJson[index][indexSub]['type'] ==='picture') {
                                    // stepStr+='http://10.0.1.5:8084/'+rowItem.detailJson[index][indexSub]['text']+';';
                                    stepStr+=rowItem.detailJson[index][indexSub]['text']+';';
                                }else {
                                    stepStr+=rowItem.detailJson[index][indexSub]['text']+';';
                                }
                            }
                            if (index !=rowItem.detailJson.length-1) {
                                stepStr += '==>';
                            }

                        }
                        collectData['step'] = stepStr;
                        this.collectTableData =[...this.collectTableData,collectData];
                    }
                });





            }

        });

    }
    requestCompanyData():Observable<InvokeResult> {
        return  this.http.get<InvokeResult>("/energyreport/onlineCollectProjectCompany/getCompanyInfo",{params:{userId:this.userId}}).pipe(res=>{
                return res
            }
        );
    }

    requestEnergyIndexData():Observable<InvokeResult> {
        return  this.http.get<InvokeResult>("/energyreport/CompanyEnergyIndex/data/"+this.userId).pipe(res=>{
                return res
            }
        );
    }
    requestEnergyCollectData():Observable<any[]> {
        return  this.http.get<any[]>("/energyreport/detailcollect/getData/"+this.userId).pipe(res=>{
                return res
            }
        );
    }


    opt1 = {
        templName: 'NYCJFA.docx',
        outputName: 'NYCJFA.docx',
        enableChart: false,
        enableImg: false,
        txtCollection: {
            companyName: '',
            legCode: '',
            companyAddress: '',
            tradeName:'',
            energyLevel: '',
            contactName: '',
            contactTel: '',
            tableData1: [],
            tableData2: [],
        },
        imageCollection: {
        },
        chartCollection: {
            lineChart: {},
        },
    };
    exportWorld1(){
        if (!this.gxService.isEmpty(this.company.name)){
            this.opt1.outputName =
                '能源采集方案_' +this.company.name + '.docx';
        }else {
            this.opt1.outputName =
                '能源采集方案'  + '.docx';
        }

        this.opt1.txtCollection.companyName = this.company.name;
        this.opt1.txtCollection.legCode=this.company.code;
        this.opt1.txtCollection.tradeName =this.company.tradeName;
        this.opt1.txtCollection.energyLevel =this.company.energyConsumptionLevelName;
        this.opt1.txtCollection.companyAddress =this.company.address;
        this.opt1.txtCollection.contactName = this.company.contactPerson;
        this.opt1.txtCollection.contactTel =this.company.contactTel;
        this.opt1.txtCollection.tableData1 = this.tabledata1;
        this.opt1.txtCollection.tableData2 = this.collectTableData;


        WordExport.exportWord(this.opt1);
    }

    jumpToNewImgPage(ImgUrl) {
        window.open(ImgUrl, 'newwindow');
    }
    conclusionStr(conclusion): String{
        if(conclusion == "手工填报") {
            return conclusion;
        } else {
            return "自动采集";
        }
    }
}
