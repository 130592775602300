import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd';
import { GXService } from '@shared/GX.service';



@Component({
  selector: 'message-list-company',
  templateUrl: './message-list-company.component.html',
  styleUrls: ['./message-list-company.component.less']
})
export class MessageListCompanyComponent implements OnInit {
  user_id: any; //用户id，
  name:'admin'
  listOfMessage = [
    // {
    //   "id": 1,
    //   "content": "这是一条数据",
    //   "content_type": 1,
    //   "create_time": 1614762418000,
    //   "update_time": 1614762418000,
    //   "_delete": false,
    //   "_release": false
    // },
    // {
    //   "id": 7,
    //   "content": "这是测试数据第n条",
    //   "content_type": 2,
    //   "create_time": 1614764374000,
    //   "update_time": 1614764374000,
    //   "_delete": false,
    //   "_release": false
    // },
  ]; //用户消息
  showModalReadMessage = false; //
  constructor(private http: HttpClient, private message: NzMessageService, private gxservice: GXService) {

  }

  ngOnInit() {
    let url = '/energyreport/message/getUserId'
    this.http.get(url).toPromise().then(
      (res) => {
        if (res) {
          console.log(res)
          this.user_id = res[0].user_id
          this.getAllMessageId(res[0].user_id)
        }
      }
    );
  }


  //阜康项目独有，定时任务
  private getAllMessageId(user_id) {
    var list = []
    // let url = '/energyreport/message/getMessageByUserId' + "?user_id=" + user_id;
    let url = '/energyreport/message/getMessageByUserIdNoRead' + "?user_id=" + user_id;
    this.http.get(url).toPromise().then(
      async (res: any) => {
        if (res) {
          console.log(res)
          for await (const item of res) {
            list.push(item.message_id)
          }
        } else { this.message.create("error", "消息获取失败"); }
        if (list.length > 0) {
          this.getMessage(list)
        } else {
          console.log("没有数据")
        }
      }
    )
  }

  //取消息(定时任务的一部分)
  async getMessage(messageId: any) {
    var list = []
    for await (const id of messageId) {
      console.log(id)
      let url = '/energyreport/message/getMessageById' + "?id=" + id;
      let res: any = await this.http.get(url).toPromise()
      // console.log(res[0])
      if (res.length > 0) {
        list.push(res[0])
      } else {
        console.log("此条数据为空")
      }
    }
    //console.log("所有数据"+list);
    let  tmp = this.gxservice.doSort('update_time','descend',list)   
    this.listOfMessage = tmp
  }  

  

}
