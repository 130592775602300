import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { OnlinecollectionprojectEnergydisplayComponent } from '../onlinecollectionproject-energydisplay/onlinecollectionproject-energydisplay.component';
import { NzMessageService, NzModalService, NzModalRef } from 'ng-zorro-antd';
import { ActivatedRoute, Params } from '@angular/router';
import { DetailcollectComponent } from '../detailcollect/detailcollect.component';

@Component({
  selector: 'onlinecollectionproject-refinecollection',
  templateUrl: './onlinecollectionproject-refinecollection.component.html',
  styleUrls: ['./onlinecollectionproject-refinecollection.component.less']
})
export class OnlinecollectionprojectRefinecollectionComponent implements OnInit {
  userId:string = '';
  indexSelect: any;
  tableData:any[] = [];
  isVisible = false;
  detailCollectModal: NzModalRef;
  typeMap = {"0" : "固体类（各种煤、焦炭）", "1" : "液体类（水、油）", "2" : "气体类（天然气、煤气、蒸汽）", "3" : "电"}
  energyDatas:any[] = [];

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.userId = params['userId'];
      })
    }

  ngOnInit() {
    this.getData();
  }

  preview() {
    if (this.tableData.length > 0) {
      this.openModal();
    } else {
      this.message.warning("请先填写能源细化采集数据！")
    }
  }

  openModal() {
    let modal = this.modalService.create({
      nzTitle: '预览',
      nzContent: OnlinecollectionprojectEnergydisplayComponent,
      nzFooter: null,
      nzOkText: '关闭',
      nzCancelText: null,
      nzComponentParams: {
        userId: this.userId,
      },
      nzWidth: 1200,
    });
    // modal.afterOpen.subscribe(function(result) {
    //   modal.getContentComponent().ngOnInit();
    // });
  }

  getData() {
    let url = '/energyreport/detailcollect/getData/ ' + this.userId;
    this.http.get(url).subscribe((result: any) => {
      this.tableData = result.data;
      this.tableData.forEach(item => {
        item.detailJson = item.detailJson.filter(item => {
          if(item == null) {
            return false;
          }else {
            return true;
          }
        })
      })
      this.tableData.forEach(rowItem => {
        rowItem.detailJson.forEach((colItem, index) => {
          if (index != rowItem.detailJson.length-1) {
            colItem["colSpan"] = Math.floor(24/rowItem.detailJson.length);
          } else {
            colItem["colSpan"] = Math.floor(24/rowItem.detailJson.length) + 24%rowItem.detailJson.length;
          }
        });
      });
    });
  }

  delete(id) {
    let url = '/energyreport/detailcollect/deleteData/' + id;
    this.http.get(url).subscribe((result: any) => {
      if (result.data) {
        this.message.success('删除成功!');
        this.getData();
      } else {
        this.message.error('删除失败!');
      }
    });
  }

  showModal(): void {
    this.indexSelect = null;
    this.isVisible = true;
    let url = 'energyreport/CompanyEnergyIndex/data/' + this.userId;
    this.http.get(url).subscribe((result: any) => {
      this.energyDatas = result.data;
    });
  }

  handleOk(): void {
    if (this.indexSelect != null) {
      this.isVisible = false;
      let type;
      let indexName;
      this.energyDatas.forEach(item => {
        if (item.id == this.indexSelect) {
          type = item.type;
          indexName = item.name;
        }
      })
      this.detailCollectModal  = this.modalService.create({
        nzTitle: '新增能源细化采集',
        nzContent: DetailcollectComponent,
        nzFooter: null,
        nzOkText: '关闭',
        nzCancelText: null,
        nzComponentParams: {
          userId: this.userId,
          type: type,
          indexName: indexName,
          ng: this
        },
        nzWidth: 1200,
      });
      this.detailCollectModal.afterOpen.subscribe(function(result) {
        this.detailCollectModal.getContentComponent().ngOnInit();
      });
    } else {
      this.message.warning("请先选择能源品种！")
    }

  }

  destroyTplModal(): void {
    this.detailCollectModal.destroy();
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  jumpToNewImgPage(ImgUrl) {
    window.open(ImgUrl, 'newwindow');
  }

  conclusionStr(conclusion): String{
    if(conclusion == "手工填报") {
      return conclusion;
    } else {
      return "自动采集";
    }
  }

}
