import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd';
import { NgModule } from '@angular/core';

@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.less']
})
export class MessageListComponent implements OnInit {
  showModal = false
  contentTypeRadio = '2'; //消息确认，默认不需要回复
  contentTypeRadioEdit = '2'; //编辑状态
  contentText = '';//消息内容，
  contentTextEdit = ''; //编辑状态消息内容
  isLoadingSave = false; //加载状态
  showModalEdit = false;// 编辑状态 
  editId = ''; //编辑状态消息id(数据内部)
  saveId: any; //当前编辑要保存的哪条消息的id
  showModalViewState = false; //某条信息的详情状态 
  //测试数据
  listOfData = [
    {
      "id": 1,
      "content": "这是一条数据",
      "content_type": 1,
      "create_time": 1614762418000,
      "update_time": 1614762418000,
      "_delete": false,
      "_release": false
    },
    {
      "id": 7,
      "content": "这是测试数据第n条",
      "content_type": 2,
      "create_time": 1614764374000,
      "update_time": 1614764374000,
      "_delete": false,
      "_release": false
    },
  ]

  //测试数据2
  listOfAllData2: any[] = [
    {
      id: 1,
      name: '专家',
    }, {
      id: 2,
      name: '企业',
    }, {
      id: 3, d: 3,
      name: '政府',
    }
  ]
  listOfDisplayData: any[] = [];
  isAllDisplayDataChecked = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  isIndeterminate = false;
  listOfMessage = []; //消息数据
  listOfRole = []; //角色信息
  listMessageState = []; //发送消息的状态
  roleId = []; //角色id
  userID = []; //用户id
  editCache: { [key: string]: any } = {};

  Message = {
    content: '', //内容
    content_type: '', //内容类别，1表示需要确认，2表示不需要
  }

  constructor(private http: HttpClient, private message: NzMessageService) { }
  ngOnInit() {
    //请求消息列表
    let url2 = '/energyreport/message/allMessage';
    this.http
      .get(url2)
      .toPromise()
      .then((result: []) => {
        if (result) {
          console.log(result)
          this.listOfMessage = result;
        }
      });
    //console.log(moment(new Date()).unix())

  }


  //新增一条消息
  addMessage() {
    this.contentText = '';  //清空输入框
    this.checkNull(); //清空选择状态
    this.isLoadingSave = false; //初始化加载状态
    this.showModal = true
    this.contentTypeRadio = '2'
    //console.log("新增消息")
    //请求所有角色
    let url = '/energyreport/message/getAllRole';
    this.http
      .get(url)
      .toPromise()
      .then((result: []) => {
        if (result) {
          this.listOfRole = result;
        } else {
          this.message.create("error", "获取发送对象数据失败");
        }
      });
  }
  //关闭modal
  handleCancel() {
    this.showModal = false
    this.showModalEdit = false
    this.showModalViewState = false;
  }
  //保存消息
  saveMessage() {
    //console.log(this.contentText)
    if (this.contentText == '' || this.roleId.length <= 0) {
      this.message.info('消息或发送对象为空');
    } else {
      this.isLoadingSave = true
      var timeid = moment(new Date()).unix()
      let param = {
        id: timeid,
        content: this.contentText,
        content_type: this.contentTypeRadio
      }
      let url = '/energyreport/message/addMessage';
      this.http
        .get(url, { params: param })
        .toPromise()
        .then((result) => {
          if (result) {
            // this.listOfMessage = result;
            // //添加消息
            this.listOfMessage = [
              ...this.listOfMessage,
              {
                id: timeid,
                update_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                content: this.contentText,
                content_type: this.contentTypeRadio,
                _release: 0,
              }
            ];
            //添加后清空
            this.message.create("success", `消息保存成功`);
            this.contentText = '';
            this.saveUser(timeid, param.content_type);  //同时保存用户信息
          } else {
            this.message.create("error", "消息保存失败");
          }
        }
        );
      // this.message.info('可以发送');
    }



  }

  //删除一条信息
  deleteRow(id: string): void {
    //this.listOfData = this.listOfData.filter(d => d.id !== id);
    this.listOfMessage = this.listOfMessage.filter(d => d.id !== id);
    // console.log("用户删除消息");
    // console.log(id)
    let ID = id;
    let url = '/energyreport/message/deleteMessageById' + '?id=' + ID;
    this.http
      .get(url)
      .toPromise()
      .then((result) => {
        if (result) {
          this.message.create("success", `删除成功`);
        } else {
          this.message.create("error", "删除失败");
        }
      });



  }

  //table页面数据改变时刷新表格
  currentPageDataChange($event: Array<{ id: number, name: string; }>): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  //
  refreshStatus(): void {
    //筛选选中状态的role的id
    let role = Object.keys(this.mapOfCheckedId).filter(item => {
      return this.mapOfCheckedId[item]
    })
    //console.log(role)
    this.roleId = role;

    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
  }
  //table选择所有
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }
  //table取消全选
  checkNull(): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = false));
    this.refreshStatus();
  }

  //保存发送对象(所有的user),允许saveMessage函数调用
  async saveUser(timeid, contentType) {
    //console.log("保存用户")
    //console.log(this.roleId)
    let msgUserId: any = [];  //所有
    var msgid = 0;
    var arrRoleId = this.roleId
    //请求和发送
    arrRoleId.forEach(element => {
      //console.log(element)
      let url = '/energyreport/message/AllUserByRoleId' + '?id=' + element;
      this.http
        .get(url)
        .toPromise()
        .then((result: []) => {
          if (result.length > 0) {
            //console.log('结果:', result)
            for (let index = 0; index < result.length; index++) {
              let element: any = result[index];
              msgUserId[msgid] = element.user_id;
              msgid++;
            }
            this.userID = msgUserId
            this.saveMsgToUser(timeid, contentType);
          } else {
            // console.log("用户保存失败") //无法处理某个角色下没有用户的问题
            this.showModal = false
          }
        });
    });

  }


  //将用户的消息一同推送,被savemessage调用
  saveMsgToUser(timeid: any, type) {
    //console.log("用户发送消息");
    //组合数据
    let url = '/energyreport/message/saveUserMessage'
    //console.log(this.userID)
    var tmp = [];
    tmp = this.userID;
    var flag = 0;
    tmp.forEach(element => {
      let param = {
        user_id: element,
        message_id: timeid,
        content_type: type
      }
      this.http
        .get(url, { params: param })
        .toPromise()
        .then((result) => {
          if (result) {
            // this.listOfMessage = result;
            flag = 1
            this.showModal = false
          }
        }
        )

    });
    this.isLoadingSave = false
    if (flag) { this.message.create("success", `保存成功`); }
  }

  //推送一条消息,修改两个部分，后端同步两个接口了
  sendMessage(data) {
    //修改数据库发送状态，同时修改本地发送状态
    let url = '/energyreport/message/sendMessage' + '?id=' + data.id;
    let param = {
      update_time: data.update_time,
      content: data.content,
      content_type: data.content_type,
      _release: true
    }
    this.http
      .get(url)
      .toPromise()
      .then((result) => {
        if (result) {
          const index = this.listOfMessage.findIndex(item => item.id === data.id);
          Object.assign(this.listOfMessage[index], param);
          this.message.create("success", "发送成功");
        } else {
          this.message.create("error", "发送失败");
        }
      });



  }

  //编辑消息
  editMessage(data) {
    this.showModalEdit = true
    // console.log(this.listOfMessage)
    // console.log(data.content_type)
    this.contentTextEdit = data.content,
      this.contentTypeRadioEdit = data.content_type.toString();
    this.editId = data.id
    const index = this.listOfMessage.findIndex(item => item.id === data.id);
    // console.log(index)
    this.saveId = index

  }
  // 更新数据
  saveMessageEdit() {
    // console.log(this.editId)
    // console.log(this.saveId)
    var index = this.saveId
    // console.log(this.listOfMessage[index])
    if (this.contentTextEdit == '') {
      this.message.info('消息为空');
    } else {
      let param = {
        id: this.editId,
        content: this.contentTextEdit,
        content_type: this.contentTypeRadioEdit,
        _release: '0',

      }
      Object.assign(this.listOfMessage[index], param);
      let url = '/energyreport/message/updateMessage';
      this.http
        .get(url, { params: param })
        .toPromise()
        .then((result: any) => {
          if (result) {
            //console.log(result)
            //添加后清空
            this.message.create("success", "消息更新成功");
          } else {
            this.message.create("error", "消息更新失败");
          }
        }
        );
      // this.message.info('可以发送');
    }
    this.showModalEdit = false
  }

  //查看详情
  viewState(id) {
    let url = '/energyreport/message/viewState' + '?id=' + id;
    this.http
      .get(url)
      .toPromise()
      .then((result: any) => {
        if (result) {
          //console.log(result)
          this.listMessageState = result
        } else {
          this.message.create("error", "查看详情失败");
        }
      });
    this.showModalViewState = true
  }


}






