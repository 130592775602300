import { GXService } from './../../shared/GX.service';
import {
    Component,
    Inject,
    ComponentFactoryResolver,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    Renderer2,
    ElementRef,
} from '@angular/core';
import {
    Router,
    NavigationEnd,
    RouteConfigLoadStart,
    NavigationError,
} from '@angular/router';
import { NzMessageService, NzIconService } from 'ng-zorro-antd';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { SettingDrawerComponent } from './setting-drawer/setting-drawer.component';
import { Subscription } from 'rxjs';
import { updateHostClass } from '@delon/util';
import { ScrollService, MenuService, SettingsService } from '@delon/theme';
import * as echarts from 'echarts';
// import { echarts } from 'echarts';
// import { }

declare var G2: any;

// #region icons

import {
    MenuFoldOutline,
    MenuUnfoldOutline,
    SearchOutline,
    SettingOutline,
    FullscreenOutline,
    FullscreenExitOutline,
    BellOutline,
    LockOutline,
    PlusOutline,
    UserOutline,
    LogoutOutline,
    EllipsisOutline,
    GlobalOutline,
    ArrowDownOutline,
    // Optional
    GithubOutline,
    AppstoreOutline,
} from '@ant-design/icons-angular/icons';
import { HttpClient } from '@angular/common/http';
import { ReuseTabService } from '@delon/abc';

const ICONS = [
    MenuFoldOutline,
    MenuUnfoldOutline,
    SearchOutline,
    SettingOutline,
    FullscreenOutline,
    FullscreenExitOutline,
    BellOutline,
    LockOutline,
    PlusOutline,
    UserOutline,
    LogoutOutline,
    EllipsisOutline,
    GlobalOutline,
    ArrowDownOutline,
    // Optional
    GithubOutline,
    AppstoreOutline,
];

// #endregion

@Component({
    selector: 'layout-default',
    templateUrl: './default.component.html',
    preserveWhitespaces: false,
    host: {
        '[class.alain-default]': 'true',
    },
})
export class LayoutDefaultComponent
    implements OnInit, AfterViewInit, OnDestroy {
    private notify$: Subscription;
    isFetching = false;
    @ViewChild('settingHost', { read: ViewContainerRef })
    settingHost: ViewContainerRef;

    constructor(
        iconSrv: NzIconService,
        private router: Router,
        scroll: ScrollService,
        private _message: NzMessageService,
        private resolver: ComponentFactoryResolver,
        public menuSrv: MenuService,
        public settings: SettingsService,
        private el: ElementRef,
        private renderer: Renderer2,
        private http: HttpClient,
        @Inject(DOCUMENT) private doc: any,
        @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    ) {
        $(document).ajaxError(function(event, xhr, options, exc) {
            if (xhr.status === 401) {
                router.navigateByUrl(tokenService.login_url);
            }
        });

        iconSrv.addIcon(...ICONS);
        router.events.subscribe(evt => {
            if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
                this.isFetching = true;
            }
            if (evt instanceof NavigationError) {
                this.isFetching = false;
                _message.error(`无法加载${evt.url}路由`, {
                    nzDuration: 1000 * 3,
                });
                return;
            }
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            setTimeout(() => {
                this.isFetching = false;
            }, 100);
        });
    }

    private setClass() {
        const { el, renderer, settings } = this;
        const layout = settings.layout;
        updateHostClass(
            el.nativeElement,
            renderer,
            {
                ['alain-default']: true,
                [`alain-default__fixed`]: layout.fixed,
                [`alain-default__boxed`]: layout.boxed,
                [`alain-default__collapsed`]: layout.collapsed,
            },
            true,
        );

        this.doc.body.classList[layout.colorWeak ? 'add' : 'remove'](
            'color-weak',
        );
    }

    ngAfterViewInit(): void {
        if (!environment.production) {
            setTimeout(() => {
                const settingFactory = this.resolver.resolveComponentFactory(
                    SettingDrawerComponent,
                );
                this.settingHost.createComponent(settingFactory);
            }, 22);
        }

        setTimeout(() => {
            let userId = this.tokenService.get().id;
            let orgType = this.tokenService.get().orgType;
            if (!GXService.isEmpty(userId) && !GXService.isEmpty(orgType)) {
                let dictionaryCode = '';
                if (orgType === 'gov') {
                    dictionaryCode = 'defult_homepage';
                } else if (orgType === 'com') {
                    dictionaryCode = 'company_homepage';
                }
                this.http
                    .get(
                        `/basic/systemparameter/userparam/${userId}/${dictionaryCode}`,
                    )
                    .toPromise()
                    .then((value: any) => {
                        setTimeout(() => {
                            if (this.router.url.length > 1) {
                                this.router.navigateByUrl(this.router.url);
                            } else {
                                this.router.navigateByUrl(value.value);
                            }
                        });
                    });
            } else {
                this.router.navigateByUrl('/passport/login');
            }
        });
    }

    ngOnInit() {
        this.notify$ = this.settings.notify.subscribe(() => this.setClass());
        this.setClass();
        //设置echarts主题
        //主题
        echarts.registerTheme('transparent-blue', {
            color: [
                '#2f67de',
                '#ffc509',
                '#f7325d',
                '#aa30dd',
                '#58b773',
                '#2ec2dc',
                '#2f33dd',
                '#ff7e09',
                '#ff6a8d',
                '#cb7ae0',
                '#7edab5',
                '#7cefde',
            ],
            backgroundColor: 'rgba(0,0,0,0)',
            textStyle: {},
            title: {
                textStyle: {
                    color: '#343434',
                },
                subtextStyle: {
                    color: '#333333',
                },
            },
            line: {
                itemStyle: {
                    normal: {
                        borderWidth: '2',
                    },
                },
                lineStyle: {
                    normal: {
                        width: '2',
                    },
                },
                symbolSize: '6',
                symbol: 'emptyCircle',
                smooth: true,
            },
            radar: {
                itemStyle: {
                    normal: {
                        borderWidth: '2',
                    },
                },
                lineStyle: {
                    normal: {
                        width: '2',
                    },
                },
                symbolSize: '6',
                symbol: 'emptyCircle',
                smooth: true,
            },
            bar: {
                itemStyle: {
                    normal: {
                        barBorderWidth: 0,
                        barBorderColor: '#cccccc',
                    },
                    emphasis: {
                        barBorderWidth: 0,
                        barBorderColor: '#cccccc',
                    },
                },
            },
            pie: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            scatter: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            boxplot: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            parallel: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            sankey: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            funnel: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            gauge: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                    emphasis: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
            },
            candlestick: {
                itemStyle: {
                    normal: {
                        color: '#edafda',
                        color0: 'transparent',
                        borderColor: '#d680bc',
                        borderColor0: '#8fd3e8',
                        borderWidth: '2',
                    },
                },
            },
            graph: {
                itemStyle: {
                    normal: {
                        borderWidth: 0,
                        borderColor: '#cccccc',
                    },
                },
                lineStyle: {
                    normal: {
                        width: 1,
                        color: '#aaa',
                    },
                },
                symbolSize: '6',
                symbol: 'emptyCircle',
                smooth: true,
                color: [
                    '#2f67de',
                    '#ffc509',
                    '#f7325d',
                    '#aa30dd',
                    '#58b773',
                    '#2ec2dc',
                    '#2f33dd',
                    '#ff7e09',
                    '#ff6a8d',
                    '#cb7ae0',
                    '#7edab5',
                    '#7cefde',
                ],
                label: {
                    normal: {
                        textStyle: {
                            color: '#eeeeee',
                        },
                    },
                },
            },
            map: {
                itemStyle: {
                    normal: {
                        areaColor: '#f3f3f3',
                        borderColor: '#516b91',
                        borderWidth: 0.5,
                    },
                    emphasis: {
                        areaColor: 'rgba(165,231,240,1)',
                        borderColor: '#516b91',
                        borderWidth: 1,
                    },
                },
                label: {
                    normal: {
                        textStyle: {
                            color: '#000',
                        },
                    },
                    emphasis: {
                        textStyle: {
                            color: 'rgb(81,107,145)',
                        },
                    },
                },
            },
            geo: {
                itemStyle: {
                    normal: {
                        areaColor: '#f3f3f3',
                        borderColor: '#516b91',
                        borderWidth: 0.5,
                    },
                    emphasis: {
                        areaColor: 'rgba(165,231,240,1)',
                        borderColor: '#516b91',
                        borderWidth: 1,
                    },
                },
                label: {
                    normal: {
                        textStyle: {
                            color: '#000',
                        },
                    },
                    emphasis: {
                        textStyle: {
                            color: 'rgb(81,107,145)',
                        },
                    },
                },
            },
            categoryAxis: {
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#343434',
                    },
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#343434',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#e0e8f9'],
                    },
                },
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: [
                            'rgba(250,250,250,0.05)',
                            'rgba(200,200,200,0.02)',
                        ],
                    },
                },
            },
            valueAxis: {
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#343434',
                    },
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#343434',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#e0e8f9'],
                    },
                },
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: [
                            'rgba(250,250,250,0.05)',
                            'rgba(200,200,200,0.02)',
                        ],
                    },
                },
            },
            logAxis: {
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#343434',
                    },
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#343434',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#e0e8f9'],
                    },
                },
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: [
                            'rgba(250,250,250,0.05)',
                            'rgba(200,200,200,0.02)',
                        ],
                    },
                },
            },
            timeAxis: {
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#343434',
                    },
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#343434',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#e0e8f9'],
                    },
                },
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: [
                            'rgba(250,250,250,0.05)',
                            'rgba(200,200,200,0.02)',
                        ],
                    },
                },
            },
            toolbox: {
                iconStyle: {
                    normal: {
                        borderColor: '#999',
                    },
                    emphasis: {
                        borderColor: '#666',
                    },
                },
            },
            legend: {
                textStyle: {
                    color: '#343434',
                },
            },
            tooltip: {
                axisPointer: {
                    lineStyle: {
                        color: '#ccc',
                        width: 1,
                    },
                    crossStyle: {
                        color: '#ccc',
                        width: 1,
                    },
                },
            },
            timeline: {
                lineStyle: {
                    color: '#8fd3e8',
                    width: 1,
                },
                itemStyle: {
                    normal: {
                        color: '#8fd3e8',
                        borderWidth: 1,
                    },
                    emphasis: {
                        color: '#8fd3e8',
                    },
                },
                controlStyle: {
                    normal: {
                        color: '#8fd3e8',
                        borderColor: '#8fd3e8',
                        borderWidth: 0.5,
                    },
                    emphasis: {
                        color: '#8fd3e8',
                        borderColor: '#8fd3e8',
                        borderWidth: 0.5,
                    },
                },
                checkpointStyle: {
                    color: '#8fd3e8',
                    borderColor: 'rgba(138,124,168,0.37)',
                },
                label: {
                    normal: {
                        textStyle: {
                            color: '#8fd3e8',
                        },
                    },
                    emphasis: {
                        textStyle: {
                            color: '#8fd3e8',
                        },
                    },
                },
            },
            visualMap: {
                color: ['#516b91', '#59c4e6', '#a5e7f0'],
            },
            dataZoom: {
                backgroundColor: 'rgba(0,0,0,0)',
                dataBackgroundColor: 'rgba(255,255,255,0.3)',
                fillerColor: 'rgba(167,183,204,0.4)',
                handleColor: '#a7b7cc',
                handleSize: '100%',
                textStyle: {
                    color: '#333',
                },
            },
            markPoint: {
                label: {
                    normal: {
                        textStyle: {
                            color: '#eeeeee',
                        },
                    },
                    emphasis: {
                        textStyle: {
                            color: '#eeeeee',
                        },
                    },
                },
            },
        });
        //   设置G2主题
        const theme = G2.Util.deepMix(
            {
                colors: [
                    '#2f67de',
                    '#ffc509',
                    '#f7325d',
                    '#aa30dd',
                    '#58b773',
                    '#2ec2dc',
                    '#2f33dd',
                    '#ff7e09',
                    '#ff6a8d',
                    '#cb7ae0',
                    '#7edab5',
                    '#7cefde',
                ],
                colors_16: [
                    '#2f67de',
                    '#ffc509',
                    '#f7325d',
                    '#aa30dd',
                    '#58b773',
                    '#2ec2dc',
                    '#2f33dd',
                    '#ff7e09',
                    '#ff6a8d',
                    '#cb7ae0',
                    '#7edab5',
                    '#7cefde',
                ],
                colors_24: [
                    '#2f67de',
                    '#ffc509',
                    '#f7325d',
                    '#aa30dd',
                    '#58b773',
                    '#2ec2dc',
                    '#2f33dd',
                    '#ff7e09',
                    '#ff6a8d',
                    '#cb7ae0',
                    '#7edab5',
                    '#7cefde',
                ],
                colors_pie: [
                    '#2f67de',
                    '#ffc509',
                    '#f7325d',
                    '#aa30dd',
                    '#58b773',
                    '#2ec2dc',
                    '#2f33dd',
                    '#ff7e09',
                    '#ff6a8d',
                    '#cb7ae0',
                    '#7edab5',
                    '#7cefde',
                ],
                colors_pie_16: [
                    '#2f67de',
                    '#ffc509',
                    '#f7325d',
                    '#aa30dd',
                    '#58b773',
                    '#2ec2dc',
                    '#2f33dd',
                    '#ff7e09',
                    '#ff6a8d',
                    '#cb7ae0',
                    '#7edab5',
                    '#7cefde',
                ],
            },
            G2.Theme,
        );
        G2.Global.setTheme(theme);
    }

    ngOnDestroy() {
        this.notify$.unsubscribe();
    }
}
