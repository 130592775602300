import { Component, OnDestroy, Inject, Optional, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { SocialService, TokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ReuseTabService } from '@delon/abc';
import { StartupService } from '@core/startup/startup.service';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { GlobalConfigService } from "../../../global-config-service";
import { GXService } from '@shared/GX.service';


@Component({
    selector: 'passport-login-sub',
    templateUrl: './login-sub.component.html',
    styleUrls: ['./login-sub.component.less'],
    providers: [SocialService],
})
export class UserLoginSubComponent implements OnDestroy, AfterViewInit {
    form: FormGroup;
    error = '';
    type = 0;
    loading = false;
    orgType: string;
    title: string;
    yangzhouspecialflag = false;
    //验证码
    inputCode:string = '';
    //短信验证码
    smCode:string = '';
    reposeCode:string = '';
    check = false;
    // 验证码验证结果
    checkLoginVerifyCode = true;
    checkLoginVerifyCodeText = '';
    checkVerifyCodeText = '';
    isMobileRegistration = false;
    dictionaryItemList = [];

    constructor(
      fb: FormBuilder,
      private router: Router,
      public msg: NzMessageService,
      modalSrv: NzModalService,
      private http: HttpClient,
      @Optional()
      @Inject(ReuseTabService)
      private reuseTabService: ReuseTabService,
      @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
      private startupSrv: StartupService,
      private routeInfo: ActivatedRoute,
      private gcs: GlobalConfigService,
      public gxService: GXService
    ) {
        this.form = fb.group({
            userName: [null, [Validators.required]],
            password: [null, Validators.required],
            phone: [null, Validators.required],
            inputCode: [null, Validators.required],
            smCode: [null, Validators.required],
            mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
            captcha: [null, [Validators.required]],
            remember: [true],
            code: [null, [Validators.required]]
        });
        modalSrv.closeAll();
    }

    ngOnInit(): void {
      
        this.gxService.initPlatform();
        if(this.gxService.XinhaiConfig.isMobileRegistration !== undefined &&this.gxService.XinhaiConfig.isMobileRegistration.data =='1'){
            this.isMobileRegistration = true;
        }
     
    
        this.orgType = this.routeInfo.snapshot.params['orgType'];
        if (this.orgType === 'gov') {
            this.title = '政府';
        } else if (this.orgType === 'com') {
            this.title = '企业';
        } else if (this.orgType === 'yangzhougov') {
            this.title = '政府';
            this.orgType = 'gov';
            this.yangzhouspecialflag = true;
        } else if (this.orgType === 'yangzhoucom') {
            this.title = '企业';
            this.orgType = 'com';
            this.yangzhouspecialflag = true;
        }
     
        if (this.gxService.XinhaiConfig.verifyCode == undefined || this.gxService.XinhaiConfig.verifyCode.data == '0') this.disabled = false;
        // if (this.gxService.XinhaiConfig.loginVerifyCode == undefined || this.gxService.XinhaiConfig.loginVerifyCode.data == '0'){
        //   this.disabled = false;
        // }
        if (this.gxService.XinhaiConfig.loginVerifyCode == undefined || this.gxService.XinhaiConfig.loginVerifyCode.data == '0'){
          this.form.controls['inputCode'].clearValidators();
          this.form.patchValue({
            inputCode: null
          });
        } else {
          this.form.get('inputCode').setValidators([Validators.required]);
          this.form.patchValue({
            inputCode: null
          });
        }

    }

    ngAfterViewInit(): void {
      // throw new Error('Method not implemented.');
      // debugger;
      if (this.gxService.XinhaiConfig.loginVerifyCode !== undefined && this.gxService.XinhaiConfig.loginVerifyCode.data == '1'){
        this.createCode(4);
      }
    }




    //生成验证码的方法
    createCode(length: number) {
      var code = "";
      // var codeLength = parseInt(length); //验证码的长度
      var checkCode = document.getElementById("checkCode");
      ////所有候选组成验证码的字符，当然也可以用中文的
      var codeChars = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
      //循环组成验证码的字符串
      for (var i = 0; i < length; i++) {
          //获取随机验证码下标
          var charNum = Math.floor(Math.random() * 62);
          //组合成指定字符验证码
          code += codeChars[charNum];
      }
      if (checkCode) {
          //为验证码区域添加样式名
          checkCode.className = "code";
          //将生成验证码赋值到显示区
          checkCode.innerHTML = code;
      }
    }

    //检查验证码是否正确
    validateCode()
    {
      // debugger
      this.form.controls['inputCode'].markAsDirty();
      this.form.controls['inputCode'].updateValueAndValidity();
      if (document.getElementById("checkCode") == null) {
        return false;
      }
      //获取显示区生成的验证码
      var checkCode = document.getElementById("checkCode").innerHTML;

      if (this.inputCode.length <= 0) {
        this.checkLoginVerifyCodeText = '请输入验证码！';
        return false;
      } else if (this.inputCode.toUpperCase() != checkCode.toUpperCase()) {
        this.msg.error('验证码输入有误！');
        this.checkLoginVerifyCodeText = '验证码输入有误！';
        this.createCode(4);
        return false;
      }
      this.checkLoginVerifyCodeText = '';
      return true;
    }
 
    validatePhoneCode()
        {
       
          this.form.controls['smCode'].markAsDirty();
          this.form.controls['smCode'].updateValueAndValidity();
      
          if (this.smCode.length <= 0) {
            this.checkVerifyCodeText = '请输入验证码！';
            return false;
          } else if (this.smCode.toUpperCase() != this.reposeCode.toUpperCase()) {
            this.msg.error('验证码输入有误！');       
            return false;
          }
          return true;
        }

    get userName() {
        return this.form.controls.userName;
    }

    get code() {
        return this.form.controls.code;
    }
   
    get password() {
        return this.form.controls.password;
    }

    get mobile() {
        return this.form.controls.mobile;
    }

    get captcha() {
        return this.form.controls.captcha;
    }

    get remember() {
        return this.form.controls.remember;
    }
    get phone() {
        return this.form.controls.phone;
    }
    // endregion

    switch(ret: any) {
        this.type = ret.index;
    }

    // region: get captcha

    count = 0;
    interval$: any;

    getCaptcha() {
        this.count = 59;
        this.interval$ = setInterval(() => {
            this.count -= 1;
            if (this.count <= 0) clearInterval(this.interval$);
        }, 1000);
    }

    // endregion
    submit() {
 
      if (this.gxService.XinhaiConfig.loginVerifyCode !== undefined && this.gxService.XinhaiConfig.loginVerifyCode.data == '1') {
        this.validateCode();
      }
        this.smsCode = "000000";
        if (this.disabled == true || (this.code.value != null && this.code.value.length > 0 && this.code.value != this.smsCode)) return;
        this.error = '';
        if (this.type === 0) {
            this.userName.markAsDirty();
            this.userName.updateValueAndValidity();
            this.password.markAsDirty();
            this.password.updateValueAndValidity();
            if (this.userName.invalid || this.password.invalid || this.checkLoginVerifyCodeText != '') return;
        } else {
            this.mobile.markAsDirty();
            this.mobile.updateValueAndValidity();
            this.captcha.markAsDirty();
            this.captcha.updateValueAndValidity();
            if (this.mobile.invalid || this.captcha.invalid) return;
        }
        if(this.isMobileRegistration){
           if(!this.validatePhoneCode()){
               return;
           }
        }
        // mock http
        this.loading = true;
        let pwd = Md5.hashStr(this.password.value);
        pwd = Md5.hashStr(pwd + this.userName.value);
        this.http.get(`${this.gcs.globalPath}/xinhai/login/${this.userName.value}/${pwd}`)
            .subscribe(async (result: any) => {
        // let url = `${this.gcs.globalPath}/xinhai/login`
        // let par = {
        //     userName: this.userName.value,
        //     pwd: pwd
        // }
 
        // this.http.post(url, par).toPromise().then((result: any) => {
            this.loading = false;
            this.reuseTabService.clear();
            if (result.failed === false) {
                this.tokenService.set({
                    token: result.data.token,
                    name: result.data.name,
                    email: result.data.email,
                    id: result.data.id,
                    time: +new Date(),
                    orgType: this.orgType,
                });

                
                
                let urlPassed = '/energyreport/trainingExaminationManagement/userInfo' + '/' + result.data.id;
                this.http.get(urlPassed).toPromise().then((result: any) => {
                    if (result.success) {
                        if(result.data.passed === false){
                  
                            this.startupSrv
                           .load()
                            .then(() => this.router.navigate(['/training/training-video']));
                        }else{
                      
                            // 获取政府端配置的默认企业的首页(无法正常赋值)
                            // const urlResult: any =  this.http.get(`/basic/systemparameter/userparam/${result.data.id}/company_homepage`).toPromise();
                            // let url = "/companyHomepage/home";
                            // if (urlResult.value) {
                            //     url = urlResult.value;
                            // }
                            // 获取政府端配置的默认企业的首页
                            let url = "/companyHomepage/home";
                            this.http.get(`/basic/systemparameter/userparam/${result.data.id}/company_homepage`)
                            .subscribe((res: any) => {
                                    if(res.value){
                                        console.log(res)
                                        url=res.value
                                    }
                            });
                            //获取政府端配置的政府端首页
                            let urlGov = "/homepage/homepage";
                            let urlHome = "overviewtj/home";
                            this.http.get(`/basic/systemparameter/userparam/${result.data.id}/defult_homepage`)
                            .subscribe((res: any) => {
                                if(res.value){
                                    if(this.userName.value =='admin1'){
                                        urlGov = urlHome;
                                    }else{
                                        urlGov=res.value
                                    }
                                
                                }
                            });
                            this.http.get(`/energyreport/checkUser/userType/${this.orgType}`)
                                .subscribe((res: any) => {
                                    if (res) {
                                        if (this.orgType == 'gov') {
                                            // 重新获取 StartupService 内容，若其  包括 User 有关的信息的话
                                            this.startupSrv
                                                .load()
                                                .then(() => this.router.navigate([urlGov]));
                                            // this.startupSrv.load().then(() => this.Redirect('/'));
                                        } else {
                                            this.startupSrv
                                                .load()
                                                .then(() => this.router.navigate([url]));
                                        }
                                    } else {
                                        this.msg.error(
                                            this.orgType === 'gov'
                                                ? '非政府用户，请在企业端登录'
                                                : '非企业用户，请在政府端登录',
                                        );
                                        this.http.get('/xinhai/logout');
                                        this.tokenService.clear();
                                    }
                                });
                        }
                    }else{
                        // 获取政府端配置的默认企业的首页(无法正常赋值)
                            // const urlResult: any =  this.http.get(`/basic/systemparameter/userparam/${result.data.id}/company_homepage`).toPromise();
                            // let url = "/companyHomepage/home";
                            // if (urlResult.value) {
                            //     url = urlResult.value;
                            // }
                            // 获取政府端配置的默认企业的首页
                            let url = "/companyHomepage/home";
                            this.http.get(`/basic/systemparameter/userparam/${result.data.id}/company_homepage`)
                            .subscribe((res: any) => {
                                    if(res.value){
                                        console.log(res)
                                        url=res.value
                                    }
                            });
                            //获取政府端配置的政府端首页
                            let urlGov = "/homepage/homepage";
                            let urlHome = "overviewtj/home";
                            this.http.get(`/basic/systemparameter/userparam/${result.data.id}/defult_homepage`)
                            .subscribe((res: any) => {
                                if(res.value){
                                    if(this.userName.value =='admin1'){
                                        urlGov = urlHome;
                                    }else{
                                        urlGov=res.value
                                    }
                                
                                }
                            });
                            this.http.get(`/energyreport/checkUser/userType/${this.orgType}`)
                                .subscribe((res: any) => {
                                    if (res) {
                                        if (this.orgType == 'gov') {
                                            // 重新获取 StartupService 内容，若其  包括 User 有关的信息的话
                                            this.startupSrv
                                                .load()
                                                .then(() => this.router.navigate([urlGov]));
                                            // this.startupSrv.load().then(() => this.Redirect('/'));
                                        } else {
                                            this.startupSrv
                                                .load()
                                                .then(() => this.router.navigate([url]));
                                        }
                                    } else {
                                        this.msg.error(
                                            this.orgType === 'gov'
                                                ? '非政府用户，请在企业端登录'
                                                : '非企业用户，请在政府端登录',
                                        );
                                        this.http.get('/xinhai/logout');
                                        this.tokenService.clear();
                                    }
                                });
                    } 
                });
                // 否则直接跳转
                // this.router.navigate(['/']);
            } else {
                console.warn(result);
                this.msg.error(result.msg);
            }
        });
    }

    Redirect(jumpUrl) {
        /*行到窗口名称*/
        var d = new Date();
        var year, month, day, h, m, s;
        year = d.getFullYear();
        month = d.getMonth();
        day = d.getDay();
        h = d.getHours();
        m = d.getMinutes();
        s = d.getSeconds();
        var windowstr;
        windowstr = '' + year + month + day + h + m + s;
        /**********************************************************************************************/

        windowstr = window.open(
            jumpUrl,
            windowstr,
            'top=0, left=0, toolbar=no, menubar=no, scrollbars=yes,resizable=yes,location=yes, status=no',
        );

        window.opener = null;
        window.open('', '_self');
        windowstr.moveTo(0, 0);
        windowstr.resizeTo(screen.width, screen.height);
        window.self.close();
    }

    ngOnDestroy(): void {
        if (this.interval$) clearInterval(this.interval$);
    }

    flag: boolean = false;
    smsCode: string;
    sendSms() {
        this.flag = true;
        this.http.get("/energyreport/common/getCode", { params: { userName: this.userName.value } }).toPromise().then(result => {
            if (result["success"] == true) {
                this.smsCode = result["data"];
            } else {
                this.msg.error(result['errorMessage']);
            }
        }).catch(err => {
            this.msg.error(err['errorMessage']);
        })
    }

    sendSmsVerificationCode() {
       this.flag = true;
       if(this.flag){
        this.http.get("/energyreport/common/getCode", { params: { userName: this.userName.value,phone: this.phone.value } }).toPromise().then(result => {
            if (result["success"] == true) {
                this.reposeCode = result["data"];
                this.flag =false;
                //定时器(this.falg)
                if(!this.flag){
                    setTimeout(function(){
                        },60000);　
                }
               
            } else {
                this.msg.error(result['errorMessage']);
            }
        }).catch(err => {
            this.msg.error(err['errorMessage']);
        })
       }
       
    }
    
    disabled: boolean = true;
    loseFocus() {
        if (this.form.controls.code.value.length == 6 && this.flag) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
    }
}
