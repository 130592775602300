import { GXService } from './../../../shared/GX.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import {differenceInCalendarDays} from "date-fns";

declare var echarts: any;

@Component({
  selector: 'elec-and-coal',
  templateUrl: './elec-and-coal.component.html',
  styleUrls: ['./elec-and-coal.component.less'],
})
export class ElecAndCoalComponent implements OnInit {
  colors: any = [];
  colorsCoal: any = [];
  colorsElec: any = [];
  arrayCoal: any = [];
  arrayElec: any = [];
  arrayCoalCarousel: any = [];
  arrayElecCarousel: any = [];
  coalSortData: any = [];
  elecSortData: any = [];
  coalSortLabel: any = [];
  elecSortLabel: any = [];
  begin: any;
  end: any;
  elecChartData: any;
  coalChartData: any;
  elecChartLast: any;
  coalChartLast: any;
  listLabel: any;

  //2020-11-27 wangln 首页数据统计周期改为可选择
  beginDate = moment().add(-12, 'month').format('YYYY-MM');
  endDate = moment().add(-1, 'month').format('YYYY-MM');

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    public gxService: GXService,
  ) {
    this.end = moment()
      .add(-1, 'month')
      .format('YYYY年MM月');
    this.begin = moment()
      .add(-12, 'month')
      .format('YYYY年MM月');
    this.colors = [
      '#FFCCFF',
      '#FFCCCC',
      '#FFCC66',
      '#FF9966',
      '#FF9933',
      '#FF6600',
      '#66FFFF',
      '#66CCFF',
      '#33FFCC',
      '#33FF66',
      '#33CC33',
      '#339900',
      '#33BB99',
      '#6697900',
    ];
    this.colorsElec = [
      '#FFCCFF',
      '#FFCCCC',
      '#FFCC66',
      '#FF9966',
      '#FF9933',
      '#FF6600',
    ];
    this.colorsCoal = [
      '#66FFFF',
      '#66CCFF',
      '#33FFCC',
      '#33FF66',
      '#33CC33',
      '#339900',
    ];
  }

  @ViewChild('chartCoal') chartCoal: ElementRef;
  @ViewChild('chartElec') chartElec: ElementRef;
  @ViewChild('coalSort') coalSort: ElementRef;
  @ViewChild('elecSort') elecSort: ElementRef;
  @ViewChild('coalPie') coalPie: ElementRef;
  @ViewChild('elecPie') elecPie: ElementRef;

  ngAfterViewInit() {
    this.searchData();
  }

  searchData(){
    let url = '/energyreport/elecAndCoal/data'+ '/' +  moment(this.beginDate).format('YYYY-MM') + '/' + moment(this.endDate).format('YYYY-MM');
    this.http
      .get(url)
      .toPromise()
      .then(result => {
        let res: any = result;
        this.elecChartData = res.elecChart;
        this.coalChartData = res.coalChart;
        this.elecChartLast = res.elecChartLast;
        this.coalChartLast = res.coalChartLast;
        this.listLabel = res.listLabel;
        for (let i = 0; i < res.arrayCoal.length; i++) {
          if (this.colors[i]) {
            res.arrayCoal[i].itemStyle = {
              color: this.colors[i],
            };
          }
        }
        for (let i = 0; i < res.arrayElec.length; i++) {
          if (this.colors[i]) {
            res.arrayElec[i].itemStyle = {
              color: this.colors[i],
            };
          }
        }
        let coalTemp = [];
        for (let i = 0; i < res.arrayCoal.length; i = i + 2) {
          let object = {
            obj1: null,
            obj2: null,
          };
          object.obj1 = res.arrayCoal[i];
          if (res.arrayCoal[i + 1]) {
            object.obj2 = res.arrayCoal[i + 1];
          }
          coalTemp.push(object);
        }
        this.arrayCoalCarousel = coalTemp;
        let elecTemp = [];
        for (let i = 0; i < res.arrayElec.length; i = i + 2) {
          let object = {
            obj1: null,
            obj2: null,
          };
          object.obj1 = res.arrayElec[i];
          if (res.arrayElec[i + 1]) {
            object.obj2 = res.arrayElec[i + 1];
          }
          elecTemp.push(object);
        }
        this.arrayElecCarousel = elecTemp;
        this.arrayCoal = res.arrayCoal;
        this.arrayElec = res.arrayElec;
        for (let i = 0; i < res.elecSortData.length; i++) {
          this.coalSortLabel.push(res.coalSortData[i].name);
          if (this.colors[i]) {
            res.coalSortData[i].itemStyle = {
              color: this.colors[i],
            };
          }
          this.elecSortLabel.push(res.elecSortData[i].name);
          if (this.colors[i]) {
            res.elecSortData[i].itemStyle = {
              color: this.colors[i],
            };
          }
        }
        this.coalSortData = res.coalSortData;
        this.elecSortData = res.elecSortData;
        this.drawCoalChart();
        this.drawElecChart();
        this.drawCoalPie();
        this.drawElecPie();
        this.drawCoalSort();
        this.drawElecSort();
      });
  }

  drawElecPie() {
    let elecPieChart = echarts.init(this.elecPie.nativeElement);
    let option = {
      grid: {
        height: '80%',
        width: '100%',
        left: '0',
        top: '0',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      series: [
        {
          radius: [0, '80%'],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          name: '区域',
          type: 'pie',
          center: ['50%', '50%'],
          data: this.arrayElec,
        },
      ],
    };
    elecPieChart.setOption(option);
  }

  drawCoalPie() {
    let coalPieChart = echarts.init(this.coalPie.nativeElement);
    let option = {
      grid: {
        height: '80%',
        width: '100%',
        left: '0',
        top: '0',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      series: [
        {
          radius: [0, '80%'],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          name: '区域',
          type: 'pie',
          center: ['50%', '50%'],
          data: this.arrayCoal,
        },
      ],
    };
    coalPieChart.setOption(option);
  }

  drawElecSort() {
    let elecSortChart = echarts.init(this.elecSort.nativeElement);
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      grid: {
        height: '90%',
        width: '90%',
        left: '1%',
        top: '5%',
        containLabel: true
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        type: 'category',
        axisLine: {
          show: false,
          lineStyle: {
            color: '#FFF',
          },
        },

        axisTick: { show: false },
        splitLine: { show: false },
        data: this.elecSortLabel,
      },
      series: [
        {
          name: '用电量',
          type: 'bar',
          data: this.elecSortData,
        },
      ],
    };
    elecSortChart.setOption(option);
  }

  drawCoalSort() {
    let coalSortChart = echarts.init(this.coalSort.nativeElement);
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      grid: {
        height: '90%',
        width: '90%',
        left: '1%',
        top: '5%',
        containLabel: true
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        type: 'category',
        axisLine: {
          show: false,
          lineStyle: {
            color: '#FFF',
          },
        },

        axisTick: { show: false },
        splitLine: { show: false },
        data: this.coalSortLabel,
      },
      series: [
        {
          name: '用煤量',
          type: 'bar',
          data: this.coalSortData,
        },
      ],
    };
    coalSortChart.setOption(option);
  }

  drawElecChart() {
    let elecChart = echarts.init(this.chartElec.nativeElement);
    let option = {
      grid: {
        height: '80%',
        width: '90%',
        left: '5%',
        top: '5%',
      },
      tooltip: {},
      legend: {
        right: '24px',
        top: 0,
        itemHeight: 8,
        orient: 'vertical',
        textStyle: {
          color: '#FFF',
        },
      },
      xAxis: {
        axisLine: {
          lineStyle: {
            color: '#FFF',
          },
        },
        splitLine: {
          show: false,
        },
        data: this.listLabel,
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#FFF',
          },
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: '本期',
          type: 'bar',
          data: this.elecChartData,
          barGap: 0,
          barWidth: 30,
          itemStyle: {
            color: '#FFCC00',
          },
        },
        {
          name: '同期',
          type: 'bar',
          data: this.elecChartLast,
          barGap: 0,
          barWidth: 30,
          itemStyle: {
            color: '#66FFFF',
          },
        },
      ],
    };
    elecChart.setOption(option);
  }

  drawCoalChart() {
    let coalChart = echarts.init(this.chartCoal.nativeElement);
    let option = {
      grid: {
        height: '80%',
        width: '90%',
        left: '5%',
        top: '5%',
      },
      tooltip: {},
      legend: {
        right: '24px',
        top: 0,
        itemHeight: 8,
        orient: 'vertical',
        textStyle: {
          color: '#FFF',
        },
      },
      xAxis: {
        axisLine: {
          lineStyle: {
            color: '#FFF',
          },
        },
        splitLine: {
          show: false,
        },
        data: this.listLabel,
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#FFF',
          },
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: '本期',
          type: 'bar',
          data: this.coalChartData,
          barGap: 0,
          barWidth: 30,
          itemStyle: {
            color: '#FFCC00',
          },
        },
        {
          name: '同期',
          type: 'bar',
          data: this.coalChartLast,
          barGap: 0,
          barWidth: 30,
          itemStyle: {
            color: '#66FFFF',
          },
        },
      ],
    };
    coalChart.setOption(option);
  }

  ngOnInit() {}

  beginDateChange(e: any){
    this.beginDate = e;
    this.searchData();
  }

  endDateChange(e: any){
    this.endDate = e;
    this.searchData();
  }

  disabledStartDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.endDate) > 0;
  }

  disabledEndDate = (current: Date): boolean => {
    //只能选择开始时间到今天（包括今天）之间的日期
    return differenceInCalendarDays(current, this.beginDate) < 0 || differenceInCalendarDays(current, new Date()) > 0;
  };
}
