import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicServiceService } from '../public-service.service'
@Component({
  selector: 'news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.less']
})
export class NewsDetailComponent implements OnInit {
  typeId:any;
  typeName:any;
  contentId:any;
  newsData:any;
  // contentId="+id+"&type="+configMap.type+"&customerId="+configMap.customerId+"&region="+configMap.region

  dataUrl = '/cms/contentCD/getContent1/';
  constructor(
    private routerInfo: ActivatedRoute,
    private publicServiceService: PublicServiceService){

     }

  ngOnInit() {
    this.newsData=[];
    this.typeId = this.routerInfo.snapshot.params['typeId'];
    this.contentId= this.routerInfo.snapshot.params['contentId'];
    this.setType();
    this.setInfoData();
  }
  setInfoData(): void {
      this.publicServiceService.getData(this.dataUrl + this.contentId).then(result => {
        this.newsData=result;
      });
  }
  setType():void{
    if (this.typeId === 'ZCFG') {
      this.typeName='政策法规'
    } else if (this.typeId === 'BZGF') {
      this.typeName='标准规范'
    } else if (this.typeId === 'XWZX') {
      this.typeName='新闻资讯'
    } else if (this.typeId === 'JNJB') {
      this.typeName='通知公告'
    } else if (this.typeId === 'JNXJSTG') {
      this.typeName='节能新技术推广'
    } else if (this.typeId === 'WSFW') {
      this.typeName='能源要闻'
    }
  }

}
