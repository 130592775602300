const tianjin = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "adcode": 120101,
                "name": "和平区",
                "center": [
                    117.195907,
                    39.118327
                ],
                "centroid": [
                    117.196207,
                    39.118993
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 0,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.222503,
                                39.117489
                            ],
                            [
                                117.213271,
                                39.113636
                            ],
                            [
                                117.196315,
                                39.102315
                            ],
                            [
                                117.190056,
                                39.10095
                            ],
                            [
                                117.17929,
                                39.101991
                            ],
                            [
                                117.179806,
                                39.121256
                            ],
                            [
                                117.181164,
                                39.137091
                            ],
                            [
                                117.190355,
                                39.136852
                            ],
                            [
                                117.19421,
                                39.13467
                            ],
                            [
                                117.198962,
                                39.130495
                            ],
                            [
                                117.209768,
                                39.133528
                            ],
                            [
                                117.213257,
                                39.132029
                            ],
                            [
                                117.21414,
                                39.126438
                            ],
                            [
                                117.222503,
                                39.117489
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120102,
                "name": "河东区",
                "center": [
                    117.226568,
                    39.122125
                ],
                "cp": [
                    117.326568,
                    39.102125
                ],
                "centroid": [
                    117.256682,
                    39.122406
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 1,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.260624,
                                39.159004
                            ],
                            [
                                117.276834,
                                39.162991
                            ],
                            [
                                117.290314,
                                39.157147
                            ],
                            [
                                117.29341,
                                39.150621
                            ],
                            [
                                117.279942,
                                39.14735
                            ],
                            [
                                117.286649,
                                39.131739
                            ],
                            [
                                117.271756,
                                39.129319
                            ],
                            [
                                117.269869,
                                39.12637
                            ],
                            [
                                117.275815,
                                39.108061
                            ],
                            [
                                117.30966,
                                39.10095
                            ],
                            [
                                117.306918,
                                39.089371
                            ],
                            [
                                117.304814,
                                39.085568
                            ],
                            [
                                117.301949,
                                39.086267
                            ],
                            [
                                117.300103,
                                39.082106
                            ],
                            [
                                117.298189,
                                39.086472
                            ],
                            [
                                117.286269,
                                39.089099
                            ],
                            [
                                117.286092,
                                39.087154
                            ],
                            [
                                117.279942,
                                39.086881
                            ],
                            [
                                117.278843,
                                39.090599
                            ],
                            [
                                117.272326,
                                39.090531
                            ],
                            [
                                117.267873,
                                39.085295
                            ],
                            [
                                117.266977,
                                39.079803
                            ],
                            [
                                117.260149,
                                39.080912
                            ],
                            [
                                117.248555,
                                39.086216
                            ],
                            [
                                117.245025,
                                39.089252
                            ],
                            [
                                117.241346,
                                39.098614
                            ],
                            [
                                117.230404,
                                39.10835
                            ],
                            [
                                117.222503,
                                39.117489
                            ],
                            [
                                117.21414,
                                39.126438
                            ],
                            [
                                117.213257,
                                39.132029
                            ],
                            [
                                117.209768,
                                39.133528
                            ],
                            [
                                117.211112,
                                39.135676
                            ],
                            [
                                117.20351,
                                39.140686
                            ],
                            [
                                117.206401,
                                39.145356
                            ],
                            [
                                117.213651,
                                39.147963
                            ],
                            [
                                117.215212,
                                39.151405
                            ],
                            [
                                117.220928,
                                39.144811
                            ],
                            [
                                117.229548,
                                39.149837
                            ],
                            [
                                117.249926,
                                39.149548
                            ],
                            [
                                117.248718,
                                39.158391
                            ],
                            [
                                117.260624,
                                39.159004
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120103,
                "name": "河西区",
                "center": [
                    117.217536,
                    39.101897
                ],
                "cp": [
                    117.217536,
                    39.051897
                ],
                "centroid": [
                    117.232557,
                    39.072507
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 2,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.292446,
                                39.077432
                            ],
                            [
                                117.285997,
                                39.070473
                            ],
                            [
                                117.288468,
                                39.060202
                            ],
                            [
                                117.283092,
                                39.058991
                            ],
                            [
                                117.286825,
                                39.051023
                            ],
                            [
                                117.280458,
                                39.048924
                            ],
                            [
                                117.278042,
                                39.053548
                            ],
                            [
                                117.267697,
                                39.050357
                            ],
                            [
                                117.272476,
                                39.04278
                            ],
                            [
                                117.266502,
                                39.041227
                            ],
                            [
                                117.267344,
                                39.039231
                            ],
                            [
                                117.235047,
                                39.033069
                            ],
                            [
                                117.217778,
                                39.034093
                            ],
                            [
                                117.21771,
                                39.056227
                            ],
                            [
                                117.21118,
                                39.058155
                            ],
                            [
                                117.21414,
                                39.060185
                            ],
                            [
                                117.203876,
                                39.071718
                            ],
                            [
                                117.188535,
                                39.066924
                            ],
                            [
                                117.183458,
                                39.072878
                            ],
                            [
                                117.179725,
                                39.089337
                            ],
                            [
                                117.17929,
                                39.101991
                            ],
                            [
                                117.190056,
                                39.10095
                            ],
                            [
                                117.196315,
                                39.102315
                            ],
                            [
                                117.213271,
                                39.113636
                            ],
                            [
                                117.222503,
                                39.117489
                            ],
                            [
                                117.230404,
                                39.10835
                            ],
                            [
                                117.241346,
                                39.098614
                            ],
                            [
                                117.245025,
                                39.089252
                            ],
                            [
                                117.248555,
                                39.086216
                            ],
                            [
                                117.260149,
                                39.080912
                            ],
                            [
                                117.266977,
                                39.079803
                            ],
                            [
                                117.292446,
                                39.077432
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120104,
                "name": "南开区",
                "center": [
                    117.164143,
                    39.120474
                ],
                "cp": [117.084143, 39.110474],
                "centroid": [
                    117.155046,
                    39.109368
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 3,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.188535,
                                39.066924
                            ],
                            [
                                117.171443,
                                39.061448
                            ],
                            [
                                117.164873,
                                39.062608
                            ],
                            [
                                117.158682,
                                39.06817
                            ],
                            [
                                117.154677,
                                39.065662
                            ],
                            [
                                117.149776,
                                39.068289
                            ],
                            [
                                117.149233,
                                39.072213
                            ],
                            [
                                117.143952,
                                39.072554
                            ],
                            [
                                117.132399,
                                39.079854
                            ],
                            [
                                117.121402,
                                39.084528
                            ],
                            [
                                117.136825,
                                39.091094
                            ],
                            [
                                117.133824,
                                39.097165
                            ],
                            [
                                117.131761,
                                39.114624
                            ],
                            [
                                117.129643,
                                39.117165
                            ],
                            [
                                117.109768,
                                39.1345
                            ],
                            [
                                117.113569,
                                39.135898
                            ],
                            [
                                117.122122,
                                39.142936
                            ],
                            [
                                117.120941,
                                39.146498
                            ],
                            [
                                117.126412,
                                39.147673
                            ],
                            [
                                117.128475,
                                39.15224
                            ],
                            [
                                117.136757,
                                39.146464
                            ],
                            [
                                117.144319,
                                39.148577
                            ],
                            [
                                117.144115,
                                39.144061
                            ],
                            [
                                117.159877,
                                39.14159
                            ],
                            [
                                117.171837,
                                39.141931
                            ],
                            [
                                117.171688,
                                39.146464
                            ],
                            [
                                117.189024,
                                39.145867
                            ],
                            [
                                117.194441,
                                39.146992
                            ],
                            [
                                117.19421,
                                39.13467
                            ],
                            [
                                117.190355,
                                39.136852
                            ],
                            [
                                117.181164,
                                39.137091
                            ],
                            [
                                117.179806,
                                39.121256
                            ],
                            [
                                117.17929,
                                39.101991
                            ],
                            [
                                117.179725,
                                39.089337
                            ],
                            [
                                117.183458,
                                39.072878
                            ],
                            [
                                117.188535,
                                39.066924
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120105,
                "name": "河北区",
                "center": [
                    117.201569,
                    39.156632
                ],
                "cp": [
                    117.281569,
                    39.166632
                ],
                "centroid": [
                    117.213791,
                    39.167182
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 4,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.233485,
                                39.193054
                            ],
                            [
                                117.249967,
                                39.178458
                            ],
                            [
                                117.240952,
                                39.170759
                            ],
                            [
                                117.250781,
                                39.170913
                            ],
                            [
                                117.251704,
                                39.168102
                            ],
                            [
                                117.258655,
                                39.169959
                            ],
                            [
                                117.260529,
                                39.167557
                            ],
                            [
                                117.260624,
                                39.159004
                            ],
                            [
                                117.248718,
                                39.158391
                            ],
                            [
                                117.249926,
                                39.149548
                            ],
                            [
                                117.229548,
                                39.149837
                            ],
                            [
                                117.220928,
                                39.144811
                            ],
                            [
                                117.215212,
                                39.151405
                            ],
                            [
                                117.213651,
                                39.147963
                            ],
                            [
                                117.206401,
                                39.145356
                            ],
                            [
                                117.20351,
                                39.140686
                            ],
                            [
                                117.211112,
                                39.135676
                            ],
                            [
                                117.209768,
                                39.133528
                            ],
                            [
                                117.198962,
                                39.130495
                            ],
                            [
                                117.19421,
                                39.13467
                            ],
                            [
                                117.194441,
                                39.146992
                            ],
                            [
                                117.186486,
                                39.155103
                            ],
                            [
                                117.177172,
                                39.162054
                            ],
                            [
                                117.176127,
                                39.165018
                            ],
                            [
                                117.180051,
                                39.168408
                            ],
                            [
                                117.177661,
                                39.18195
                            ],
                            [
                                117.173086,
                                39.181422
                            ],
                            [
                                117.167547,
                                39.183994
                            ],
                            [
                                117.171783,
                                39.18631
                            ],
                            [
                                117.188603,
                                39.190227
                            ],
                            [
                                117.191916,
                                39.19193
                            ],
                            [
                                117.197265,
                                39.186106
                            ],
                            [
                                117.210773,
                                39.192918
                            ],
                            [
                                117.216122,
                                39.191283
                            ],
                            [
                                117.221946,
                                39.184692
                            ],
                            [
                                117.223982,
                                39.184965
                            ],
                            [
                                117.233485,
                                39.193054
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120106,
                "name": "红桥区",
                "center": [
                    117.163301,
                    39.172066
                ],
                "cp": [117.113301, 39.160066],
                "centroid": [
                    117.155648,
                    39.164723
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 5,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.194441,
                                39.146992
                            ],
                            [
                                117.189024,
                                39.145867
                            ],
                            [
                                117.171688,
                                39.146464
                            ],
                            [
                                117.171837,
                                39.141931
                            ],
                            [
                                117.159877,
                                39.14159
                            ],
                            [
                                117.144115,
                                39.144061
                            ],
                            [
                                117.144319,
                                39.148577
                            ],
                            [
                                117.136757,
                                39.146464
                            ],
                            [
                                117.128475,
                                39.15224
                            ],
                            [
                                117.126317,
                                39.158919
                            ],
                            [
                                117.129901,
                                39.171901
                            ],
                            [
                                117.131136,
                                39.178867
                            ],
                            [
                                117.134313,
                                39.185356
                            ],
                            [
                                117.141766,
                                39.19113
                            ],
                            [
                                117.148568,
                                39.189103
                            ],
                            [
                                117.159293,
                                39.190346
                            ],
                            [
                                117.167547,
                                39.183994
                            ],
                            [
                                117.173086,
                                39.181422
                            ],
                            [
                                117.177661,
                                39.18195
                            ],
                            [
                                117.180051,
                                39.168408
                            ],
                            [
                                117.176127,
                                39.165018
                            ],
                            [
                                117.177172,
                                39.162054
                            ],
                            [
                                117.186486,
                                39.155103
                            ],
                            [
                                117.194441,
                                39.146992
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                117.130362,
                                39.18987
                            ],
                            [
                                117.12595,
                                39.194944
                            ],
                            [
                                117.12857,
                                39.19566
                            ],
                            [
                                117.125611,
                                39.200887
                            ],
                            [
                                117.132032,
                                39.203458
                            ],
                            [
                                117.14121,
                                39.197737
                            ],
                            [
                                117.140775,
                                39.193224
                            ],
                            [
                                117.130362,
                                39.18987
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120110,
                "name": "东丽区",
                "center": [
                    117.313967,
                    39.087764
                ],
                "cp": [
                    117.423967,
                    39.077764
                ],
                "centroid": [
                    117.409531,
                    39.133692
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 6,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.266977,
                                39.079803
                            ],
                            [
                                117.267873,
                                39.085295
                            ],
                            [
                                117.272326,
                                39.090531
                            ],
                            [
                                117.278843,
                                39.090599
                            ],
                            [
                                117.279942,
                                39.086881
                            ],
                            [
                                117.286092,
                                39.087154
                            ],
                            [
                                117.286269,
                                39.089099
                            ],
                            [
                                117.298189,
                                39.086472
                            ],
                            [
                                117.300103,
                                39.082106
                            ],
                            [
                                117.301949,
                                39.086267
                            ],
                            [
                                117.304814,
                                39.085568
                            ],
                            [
                                117.306918,
                                39.089371
                            ],
                            [
                                117.30966,
                                39.10095
                            ],
                            [
                                117.275815,
                                39.108061
                            ],
                            [
                                117.269869,
                                39.12637
                            ],
                            [
                                117.271756,
                                39.129319
                            ],
                            [
                                117.286649,
                                39.131739
                            ],
                            [
                                117.279942,
                                39.14735
                            ],
                            [
                                117.29341,
                                39.150621
                            ],
                            [
                                117.290314,
                                39.157147
                            ],
                            [
                                117.276834,
                                39.162991
                            ],
                            [
                                117.260624,
                                39.159004
                            ],
                            [
                                117.260529,
                                39.167557
                            ],
                            [
                                117.258655,
                                39.169959
                            ],
                            [
                                117.251704,
                                39.168102
                            ],
                            [
                                117.250781,
                                39.170913
                            ],
                            [
                                117.240952,
                                39.170759
                            ],
                            [
                                117.249967,
                                39.178458
                            ],
                            [
                                117.233485,
                                39.193054
                            ],
                            [
                                117.247007,
                                39.20448
                            ],
                            [
                                117.259728,
                                39.210933
                            ],
                            [
                                117.27253,
                                39.215751
                            ],
                            [
                                117.291482,
                                39.219342
                            ],
                            [
                                117.297903,
                                39.223258
                            ],
                            [
                                117.295052,
                                39.240363
                            ],
                            [
                                117.292473,
                                39.243698
                            ],
                            [
                                117.283689,
                                39.242013
                            ],
                            [
                                117.282997,
                                39.244345
                            ],
                            [
                                117.290314,
                                39.248514
                            ],
                            [
                                117.299261,
                                39.244362
                            ],
                            [
                                117.324526,
                                39.237929
                            ],
                            [
                                117.326793,
                                39.234593
                            ],
                            [
                                117.333567,
                                39.235819
                            ],
                            [
                                117.347985,
                                39.234185
                            ],
                            [
                                117.368295,
                                39.233436
                            ],
                            [
                                117.386405,
                                39.230492
                            ],
                            [
                                117.384627,
                                39.233589
                            ],
                            [
                                117.395745,
                                39.239784
                            ],
                            [
                                117.403144,
                                39.240312
                            ],
                            [
                                117.410258,
                                39.236619
                            ],
                            [
                                117.408181,
                                39.225964
                            ],
                            [
                                117.417508,
                                39.223649
                            ],
                            [
                                117.425355,
                                39.228517
                            ],
                            [
                                117.426101,
                                39.231343
                            ],
                            [
                                117.442026,
                                39.230594
                            ],
                            [
                                117.447755,
                                39.231904
                            ],
                            [
                                117.451285,
                                39.236431
                            ],
                            [
                                117.45681,
                                39.23136
                            ],
                            [
                                117.46577,
                                39.228994
                            ],
                            [
                                117.472803,
                                39.229368
                            ],
                            [
                                117.488008,
                                39.220449
                            ],
                            [
                                117.487275,
                                39.211052
                            ],
                            [
                                117.482985,
                                39.206778
                            ],
                            [
                                117.486704,
                                39.204752
                            ],
                            [
                                117.493004,
                                39.204837
                            ],
                            [
                                117.503634,
                                39.208804
                            ],
                            [
                                117.501828,
                                39.201364
                            ],
                            [
                                117.491361,
                                39.198554
                            ],
                            [
                                117.502792,
                                39.195387
                            ],
                            [
                                117.502249,
                                39.199644
                            ],
                            [
                                117.510693,
                                39.202556
                            ],
                            [
                                117.509906,
                                39.207493
                            ],
                            [
                                117.513734,
                                39.204769
                            ],
                            [
                                117.51368,
                                39.192441
                            ],
                            [
                                117.517739,
                                39.191385
                            ],
                            [
                                117.537166,
                                39.182614
                            ],
                            [
                                117.54743,
                                39.180315
                            ],
                            [
                                117.549833,
                                39.181167
                            ],
                            [
                                117.568798,
                                39.177505
                            ],
                            [
                                117.56706,
                                39.173485
                            ],
                            [
                                117.566164,
                                39.161747
                            ],
                            [
                                117.555752,
                                39.159175
                            ],
                            [
                                117.553091,
                                39.153927
                            ],
                            [
                                117.552629,
                                39.14723
                            ],
                            [
                                117.540791,
                                39.142732
                            ],
                            [
                                117.518092,
                                39.15149
                            ],
                            [
                                117.514331,
                                39.150144
                            ],
                            [
                                117.509688,
                                39.1375
                            ],
                            [
                                117.503226,
                                39.107907
                            ],
                            [
                                117.517467,
                                39.106731
                            ],
                            [
                                117.535496,
                                39.106543
                            ],
                            [
                                117.536189,
                                39.099978
                            ],
                            [
                                117.553362,
                                39.099706
                            ],
                            [
                                117.552874,
                                39.078984
                            ],
                            [
                                117.563775,
                                39.078507
                            ],
                            [
                                117.557815,
                                39.065747
                            ],
                            [
                                117.559539,
                                39.054913
                            ],
                            [
                                117.538062,
                                39.056227
                            ],
                            [
                                117.530134,
                                39.057865
                            ],
                            [
                                117.52712,
                                39.046176
                            ],
                            [
                                117.523712,
                                39.04674
                            ],
                            [
                                117.518825,
                                39.037899
                            ],
                            [
                                117.520576,
                                39.036824
                            ],
                            [
                                117.517386,
                                39.026686
                            ],
                            [
                                117.521988,
                                39.023767
                            ],
                            [
                                117.525396,
                                39.026378
                            ],
                            [
                                117.526346,
                                39.021257
                            ],
                            [
                                117.52488,
                                39.015077
                            ],
                            [
                                117.526984,
                                39.005994
                            ],
                            [
                                117.523441,
                                39.004662
                            ],
                            [
                                117.511766,
                                38.995305
                            ],
                            [
                                117.508928,
                                38.996466
                            ],
                            [
                                117.502765,
                                39.007531
                            ],
                            [
                                117.495447,
                                39.015692
                            ],
                            [
                                117.489705,
                                39.016511
                            ],
                            [
                                117.481695,
                                39.013353
                            ],
                            [
                                117.475328,
                                39.014155
                            ],
                            [
                                117.469897,
                                39.018577
                            ],
                            [
                                117.471798,
                                39.029075
                            ],
                            [
                                117.468214,
                                39.03336
                            ],
                            [
                                117.464345,
                                39.032847
                            ],
                            [
                                117.459485,
                                39.02858
                            ],
                            [
                                117.448732,
                                39.022794
                            ],
                            [
                                117.437519,
                                39.020472
                            ],
                            [
                                117.420399,
                                39.020745
                            ],
                            [
                                117.412349,
                                39.022742
                            ],
                            [
                                117.398773,
                                39.029007
                            ],
                            [
                                117.393193,
                                39.033667
                            ],
                            [
                                117.388428,
                                39.041944
                            ],
                            [
                                117.384803,
                                39.05121
                            ],
                            [
                                117.380242,
                                39.053633
                            ],
                            [
                                117.371431,
                                39.052149
                            ],
                            [
                                117.358751,
                                39.051603
                            ],
                            [
                                117.347863,
                                39.053872
                            ],
                            [
                                117.33764,
                                39.059503
                            ],
                            [
                                117.328775,
                                39.068323
                            ],
                            [
                                117.313366,
                                39.075095
                            ],
                            [
                                117.305167,
                                39.076801
                            ],
                            [
                                117.292446,
                                39.077432
                            ],
                            [
                                117.266977,
                                39.079803
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120111,
                "name": "西青区",
                "center": [
                    117.012247,
                    39.139446
                ],
                "centroid": [
                    117.118434,
                    39.028628
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 7,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.188535,
                                39.066924
                            ],
                            [
                                117.203876,
                                39.071718
                            ],
                            [
                                117.21414,
                                39.060185
                            ],
                            [
                                117.21118,
                                39.058155
                            ],
                            [
                                117.21771,
                                39.056227
                            ],
                            [
                                117.217778,
                                39.034093
                            ],
                            [
                                117.235047,
                                39.033069
                            ],
                            [
                                117.267344,
                                39.039231
                            ],
                            [
                                117.263719,
                                39.033598
                            ],
                            [
                                117.262932,
                                39.025952
                            ],
                            [
                                117.265253,
                                39.020694
                            ],
                            [
                                117.259198,
                                39.022742
                            ],
                            [
                                117.256904,
                                39.020762
                            ],
                            [
                                117.262457,
                                39.018936
                            ],
                            [
                                117.266611,
                                39.02054
                            ],
                            [
                                117.274485,
                                39.014855
                            ],
                            [
                                117.277607,
                                39.002631
                            ],
                            [
                                117.28297,
                                38.999403
                            ],
                            [
                                117.29121,
                                39.002835
                            ],
                            [
                                117.295107,
                                38.998174
                            ],
                            [
                                117.288536,
                                38.996227
                            ],
                            [
                                117.294034,
                                38.994126
                            ],
                            [
                                117.289799,
                                38.99165
                            ],
                            [
                                117.292392,
                                38.988269
                            ],
                            [
                                117.285916,
                                38.980702
                            ],
                            [
                                117.284735,
                                38.968899
                            ],
                            [
                                117.282305,
                                38.972828
                            ],
                            [
                                117.273507,
                                38.978516
                            ],
                            [
                                117.268566,
                                38.972981
                            ],
                            [
                                117.273765,
                                38.970265
                            ],
                            [
                                117.269951,
                                38.967464
                            ],
                            [
                                117.268905,
                                38.963603
                            ],
                            [
                                117.260379,
                                38.952069
                            ],
                            [
                                117.246573,
                                38.954
                            ],
                            [
                                117.245473,
                                38.952992
                            ],
                            [
                                117.266041,
                                38.94332
                            ],
                            [
                                117.261289,
                                38.929305
                            ],
                            [
                                117.272489,
                                38.92375
                            ],
                            [
                                117.269516,
                                38.919869
                            ],
                            [
                                117.308018,
                                38.885961
                            ],
                            [
                                117.324295,
                                38.88709
                            ],
                            [
                                117.328639,
                                38.883772
                            ],
                            [
                                117.32978,
                                38.875493
                            ],
                            [
                                117.334002,
                                38.876468
                            ],
                            [
                                117.336038,
                                38.869249
                            ],
                            [
                                117.341089,
                                38.865023
                            ],
                            [
                                117.337477,
                                38.864921
                            ],
                            [
                                117.337912,
                                38.861054
                            ],
                            [
                                117.342147,
                                38.86102
                            ],
                            [
                                117.344523,
                                38.851763
                            ],
                            [
                                117.336147,
                                38.850035
                            ],
                            [
                                117.339256,
                                38.843532
                            ],
                            [
                                117.327377,
                                38.836737
                            ],
                            [
                                117.331314,
                                38.832732
                            ],
                            [
                                117.33016,
                                38.830182
                            ],
                            [
                                117.335998,
                                38.828744
                            ],
                            [
                                117.331504,
                                38.824687
                            ],
                            [
                                117.326929,
                                38.823728
                            ],
                            [
                                117.312905,
                                38.831072
                            ],
                            [
                                117.30423,
                                38.833417
                            ],
                            [
                                117.258886,
                                38.847365
                            ],
                            [
                                117.248799,
                                38.851729
                            ],
                            [
                                117.193966,
                                38.894854
                            ],
                            [
                                117.179005,
                                38.910363
                            ],
                            [
                                117.168077,
                                38.925203
                            ],
                            [
                                117.158736,
                                38.936176
                            ],
                            [
                                117.13696,
                                38.963603
                            ],
                            [
                                117.122529,
                                38.975031
                            ],
                            [
                                117.108655,
                                38.981813
                            ],
                            [
                                117.078611,
                                38.993973
                            ],
                            [
                                117.063066,
                                39.005021
                            ],
                            [
                                117.057975,
                                39.000872
                            ],
                            [
                                117.057147,
                                38.995868
                            ],
                            [
                                117.035317,
                                38.997388
                            ],
                            [
                                117.037571,
                                39.010468
                            ],
                            [
                                117.040517,
                                39.012738
                            ],
                            [
                                117.033335,
                                39.01559
                            ],
                            [
                                117.038209,
                                39.021206
                            ],
                            [
                                117.015795,
                                39.030304
                            ],
                            [
                                116.990218,
                                39.036636
                            ],
                            [
                                116.970315,
                                39.038838
                            ],
                            [
                                116.968388,
                                39.045494
                            ],
                            [
                                116.960581,
                                39.046688
                            ],
                            [
                                116.962387,
                                39.050767
                            ],
                            [
                                116.948974,
                                39.054282
                            ],
                            [
                                116.94558,
                                39.057046
                            ],
                            [
                                116.940924,
                                39.050101
                            ],
                            [
                                116.934706,
                                39.054435
                            ],
                            [
                                116.921659,
                                39.060117
                            ],
                            [
                                116.908667,
                                39.061175
                            ],
                            [
                                116.905002,
                                39.057421
                            ],
                            [
                                116.90101,
                                39.061328
                            ],
                            [
                                116.888995,
                                39.055067
                            ],
                            [
                                116.886769,
                                39.0594
                            ],
                            [
                                116.897182,
                                39.062761
                            ],
                            [
                                116.898186,
                                39.066668
                            ],
                            [
                                116.881474,
                                39.071718
                            ],
                            [
                                116.882818,
                                39.077944
                            ],
                            [
                                116.886606,
                                39.077279
                            ],
                            [
                                116.890638,
                                39.084852
                            ],
                            [
                                116.894195,
                                39.084323
                            ],
                            [
                                116.896707,
                                39.091674
                            ],
                            [
                                116.901893,
                                39.090974
                            ],
                            [
                                116.906047,
                                39.103099
                            ],
                            [
                                116.909549,
                                39.103065
                            ],
                            [
                                116.911097,
                                39.111044
                            ],
                            [
                                116.919283,
                                39.109237
                            ],
                            [
                                116.923696,
                                39.118614
                            ],
                            [
                                116.926112,
                                39.119636
                            ],
                            [
                                116.922162,
                                39.131211
                            ],
                            [
                                116.923071,
                                39.13421
                            ],
                            [
                                116.928352,
                                39.135318
                            ],
                            [
                                116.923153,
                                39.149411
                            ],
                            [
                                116.93833,
                                39.150655
                            ],
                            [
                                116.938548,
                                39.154302
                            ],
                            [
                                116.928854,
                                39.156176
                            ],
                            [
                                116.928203,
                                39.160487
                            ],
                            [
                                116.941562,
                                39.163281
                            ],
                            [
                                116.940475,
                                39.160759
                            ],
                            [
                                116.946503,
                                39.159328
                            ],
                            [
                                116.949056,
                                39.166143
                            ],
                            [
                                116.953821,
                                39.170367
                            ],
                            [
                                116.958016,
                                39.168902
                            ],
                            [
                                116.962401,
                                39.177113
                            ],
                            [
                                116.967261,
                                39.1742
                            ],
                            [
                                116.96798,
                                39.168408
                            ],
                            [
                                116.973384,
                                39.167659
                            ],
                            [
                                116.992309,
                                39.171424
                            ],
                            [
                                116.993503,
                                39.168528
                            ],
                            [
                                117.004255,
                                39.170674
                            ],
                            [
                                117.008002,
                                39.160878
                            ],
                            [
                                117.011654,
                                39.161798
                            ],
                            [
                                117.012958,
                                39.15057
                            ],
                            [
                                117.026534,
                                39.155273
                            ],
                            [
                                117.023547,
                                39.157011
                            ],
                            [
                                117.0228,
                                39.163451
                            ],
                            [
                                117.026479,
                                39.164507
                            ],
                            [
                                117.025678,
                                39.170998
                            ],
                            [
                                117.029344,
                                39.171424
                            ],
                            [
                                117.031977,
                                39.156687
                            ],
                            [
                                117.03844,
                                39.159975
                            ],
                            [
                                117.047929,
                                39.160027
                            ],
                            [
                                117.064397,
                                39.165427
                            ],
                            [
                                117.066746,
                                39.164729
                            ],
                            [
                                117.067492,
                                39.159226
                            ],
                            [
                                117.074552,
                                39.159822
                            ],
                            [
                                117.073805,
                                39.169482
                            ],
                            [
                                117.085603,
                                39.174115
                            ],
                            [
                                117.104093,
                                39.176244
                            ],
                            [
                                117.123792,
                                39.173894
                            ],
                            [
                                117.129901,
                                39.171901
                            ],
                            [
                                117.126317,
                                39.158919
                            ],
                            [
                                117.128475,
                                39.15224
                            ],
                            [
                                117.126412,
                                39.147673
                            ],
                            [
                                117.120941,
                                39.146498
                            ],
                            [
                                117.122122,
                                39.142936
                            ],
                            [
                                117.113569,
                                39.135898
                            ],
                            [
                                117.109768,
                                39.1345
                            ],
                            [
                                117.129643,
                                39.117165
                            ],
                            [
                                117.131761,
                                39.114624
                            ],
                            [
                                117.133824,
                                39.097165
                            ],
                            [
                                117.136825,
                                39.091094
                            ],
                            [
                                117.121402,
                                39.084528
                            ],
                            [
                                117.132399,
                                39.079854
                            ],
                            [
                                117.143952,
                                39.072554
                            ],
                            [
                                117.149233,
                                39.072213
                            ],
                            [
                                117.149776,
                                39.068289
                            ],
                            [
                                117.154677,
                                39.065662
                            ],
                            [
                                117.158682,
                                39.06817
                            ],
                            [
                                117.164873,
                                39.062608
                            ],
                            [
                                117.171443,
                                39.061448
                            ],
                            [
                                117.188535,
                                39.066924
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120112,
                "name": "津南区",
                "center": [
                    117.382549,
                    38.989577
                ],
                "centroid": [
                    117.387949,
                    38.963139
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 8,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.267344,
                                39.039231
                            ],
                            [
                                117.266502,
                                39.041227
                            ],
                            [
                                117.272476,
                                39.04278
                            ],
                            [
                                117.267697,
                                39.050357
                            ],
                            [
                                117.278042,
                                39.053548
                            ],
                            [
                                117.280458,
                                39.048924
                            ],
                            [
                                117.286825,
                                39.051023
                            ],
                            [
                                117.283092,
                                39.058991
                            ],
                            [
                                117.288468,
                                39.060202
                            ],
                            [
                                117.285997,
                                39.070473
                            ],
                            [
                                117.292446,
                                39.077432
                            ],
                            [
                                117.305167,
                                39.076801
                            ],
                            [
                                117.313366,
                                39.075095
                            ],
                            [
                                117.328775,
                                39.068323
                            ],
                            [
                                117.33764,
                                39.059503
                            ],
                            [
                                117.347863,
                                39.053872
                            ],
                            [
                                117.358751,
                                39.051603
                            ],
                            [
                                117.371431,
                                39.052149
                            ],
                            [
                                117.380242,
                                39.053633
                            ],
                            [
                                117.384803,
                                39.05121
                            ],
                            [
                                117.388428,
                                39.041944
                            ],
                            [
                                117.393193,
                                39.033667
                            ],
                            [
                                117.398773,
                                39.029007
                            ],
                            [
                                117.412349,
                                39.022742
                            ],
                            [
                                117.420399,
                                39.020745
                            ],
                            [
                                117.437519,
                                39.020472
                            ],
                            [
                                117.448732,
                                39.022794
                            ],
                            [
                                117.459485,
                                39.02858
                            ],
                            [
                                117.464345,
                                39.032847
                            ],
                            [
                                117.468214,
                                39.03336
                            ],
                            [
                                117.471798,
                                39.029075
                            ],
                            [
                                117.469897,
                                39.018577
                            ],
                            [
                                117.475328,
                                39.014155
                            ],
                            [
                                117.481695,
                                39.013353
                            ],
                            [
                                117.489705,
                                39.016511
                            ],
                            [
                                117.495447,
                                39.015692
                            ],
                            [
                                117.502765,
                                39.007531
                            ],
                            [
                                117.508928,
                                38.996466
                            ],
                            [
                                117.511766,
                                38.995305
                            ],
                            [
                                117.523441,
                                39.004662
                            ],
                            [
                                117.526984,
                                39.005994
                            ],
                            [
                                117.531152,
                                39.005841
                            ],
                            [
                                117.53999,
                                39.001111
                            ],
                            [
                                117.549697,
                                38.991497
                            ],
                            [
                                117.558005,
                                38.991975
                            ],
                            [
                                117.560164,
                                38.988884
                            ],
                            [
                                117.556974,
                                38.981591
                            ],
                            [
                                117.555168,
                                38.981317
                            ],
                            [
                                117.549357,
                                38.968164
                            ],
                            [
                                117.550308,
                                38.959109
                            ],
                            [
                                117.548679,
                                38.958716
                            ],
                            [
                                117.550987,
                                38.943029
                            ],
                            [
                                117.543615,
                                38.943047
                            ],
                            [
                                117.529251,
                                38.95318
                            ],
                            [
                                117.52169,
                                38.952035
                            ],
                            [
                                117.512865,
                                38.945183
                            ],
                            [
                                117.511508,
                                38.947456
                            ],
                            [
                                117.497063,
                                38.94279
                            ],
                            [
                                117.503294,
                                38.907765
                            ],
                            [
                                117.486881,
                                38.905473
                            ],
                            [
                                117.464494,
                                38.89075
                            ],
                            [
                                117.448284,
                                38.879444
                            ],
                            [
                                117.440587,
                                38.869745
                            ],
                            [
                                117.437464,
                                38.858607
                            ],
                            [
                                117.407529,
                                38.887278
                            ],
                            [
                                117.387803,
                                38.87279
                            ],
                            [
                                117.362946,
                                38.857529
                            ],
                            [
                                117.339256,
                                38.843532
                            ],
                            [
                                117.336147,
                                38.850035
                            ],
                            [
                                117.344523,
                                38.851763
                            ],
                            [
                                117.342147,
                                38.86102
                            ],
                            [
                                117.337912,
                                38.861054
                            ],
                            [
                                117.337477,
                                38.864921
                            ],
                            [
                                117.341089,
                                38.865023
                            ],
                            [
                                117.336038,
                                38.869249
                            ],
                            [
                                117.334002,
                                38.876468
                            ],
                            [
                                117.32978,
                                38.875493
                            ],
                            [
                                117.328639,
                                38.883772
                            ],
                            [
                                117.324295,
                                38.88709
                            ],
                            [
                                117.308018,
                                38.885961
                            ],
                            [
                                117.269516,
                                38.919869
                            ],
                            [
                                117.272489,
                                38.92375
                            ],
                            [
                                117.261289,
                                38.929305
                            ],
                            [
                                117.266041,
                                38.94332
                            ],
                            [
                                117.245473,
                                38.952992
                            ],
                            [
                                117.246573,
                                38.954
                            ],
                            [
                                117.260379,
                                38.952069
                            ],
                            [
                                117.268905,
                                38.963603
                            ],
                            [
                                117.269951,
                                38.967464
                            ],
                            [
                                117.273765,
                                38.970265
                            ],
                            [
                                117.268566,
                                38.972981
                            ],
                            [
                                117.273507,
                                38.978516
                            ],
                            [
                                117.282305,
                                38.972828
                            ],
                            [
                                117.284735,
                                38.968899
                            ],
                            [
                                117.285916,
                                38.980702
                            ],
                            [
                                117.292392,
                                38.988269
                            ],
                            [
                                117.289799,
                                38.99165
                            ],
                            [
                                117.294034,
                                38.994126
                            ],
                            [
                                117.288536,
                                38.996227
                            ],
                            [
                                117.295107,
                                38.998174
                            ],
                            [
                                117.29121,
                                39.002835
                            ],
                            [
                                117.28297,
                                38.999403
                            ],
                            [
                                117.277607,
                                39.002631
                            ],
                            [
                                117.274485,
                                39.014855
                            ],
                            [
                                117.266611,
                                39.02054
                            ],
                            [
                                117.262457,
                                39.018936
                            ],
                            [
                                117.256904,
                                39.020762
                            ],
                            [
                                117.259198,
                                39.022742
                            ],
                            [
                                117.265253,
                                39.020694
                            ],
                            [
                                117.262932,
                                39.025952
                            ],
                            [
                                117.263719,
                                39.033598
                            ],
                            [
                                117.267344,
                                39.039231
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120113,
                "name": "北辰区",
                "center": [
                    117.13482,
                    39.225555
                ],
                "centroid": [
                    117.173461,
                    39.25348
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 9,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.140775,
                                39.193224
                            ],
                            [
                                117.14121,
                                39.197737
                            ],
                            [
                                117.132032,
                                39.203458
                            ],
                            [
                                117.125611,
                                39.200887
                            ],
                            [
                                117.12857,
                                39.19566
                            ],
                            [
                                117.12595,
                                39.194944
                            ],
                            [
                                117.130362,
                                39.18987
                            ],
                            [
                                117.134313,
                                39.185356
                            ],
                            [
                                117.131136,
                                39.178867
                            ],
                            [
                                117.129901,
                                39.171901
                            ],
                            [
                                117.123792,
                                39.173894
                            ],
                            [
                                117.104093,
                                39.176244
                            ],
                            [
                                117.085603,
                                39.174115
                            ],
                            [
                                117.073805,
                                39.169482
                            ],
                            [
                                117.074552,
                                39.159822
                            ],
                            [
                                117.067492,
                                39.159226
                            ],
                            [
                                117.066746,
                                39.164729
                            ],
                            [
                                117.064397,
                                39.165427
                            ],
                            [
                                117.047929,
                                39.160027
                            ],
                            [
                                117.03844,
                                39.159975
                            ],
                            [
                                117.031977,
                                39.156687
                            ],
                            [
                                117.029344,
                                39.171424
                            ],
                            [
                                117.025678,
                                39.170998
                            ],
                            [
                                117.026479,
                                39.164507
                            ],
                            [
                                117.0228,
                                39.163451
                            ],
                            [
                                117.023547,
                                39.157011
                            ],
                            [
                                117.026534,
                                39.155273
                            ],
                            [
                                117.012958,
                                39.15057
                            ],
                            [
                                117.011654,
                                39.161798
                            ],
                            [
                                117.008002,
                                39.160878
                            ],
                            [
                                117.004255,
                                39.170674
                            ],
                            [
                                116.993503,
                                39.168528
                            ],
                            [
                                116.992309,
                                39.171424
                            ],
                            [
                                116.973384,
                                39.167659
                            ],
                            [
                                116.96798,
                                39.168408
                            ],
                            [
                                116.967261,
                                39.1742
                            ],
                            [
                                116.962401,
                                39.177113
                            ],
                            [
                                116.958016,
                                39.168902
                            ],
                            [
                                116.953821,
                                39.170367
                            ],
                            [
                                116.949056,
                                39.166143
                            ],
                            [
                                116.939987,
                                39.171815
                            ],
                            [
                                116.938928,
                                39.180826
                            ],
                            [
                                116.941399,
                                39.188592
                            ],
                            [
                                116.957839,
                                39.189665
                            ],
                            [
                                116.964614,
                                39.187775
                            ],
                            [
                                116.964328,
                                39.192254
                            ],
                            [
                                116.967207,
                                39.207919
                            ],
                            [
                                116.971198,
                                39.208873
                            ],
                            [
                                116.968143,
                                39.213844
                            ],
                            [
                                116.97208,
                                39.216551
                            ],
                            [
                                116.967695,
                                39.229419
                            ],
                            [
                                116.977334,
                                39.231989
                            ],
                            [
                                116.979954,
                                39.230764
                            ],
                            [
                                116.98939,
                                39.239563
                            ],
                            [
                                116.990096,
                                39.2454
                            ],
                            [
                                116.996178,
                                39.24329
                            ],
                            [
                                117.005817,
                                39.243017
                            ],
                            [
                                117.010772,
                                39.248803
                            ],
                            [
                                117.011505,
                                39.256698
                            ],
                            [
                                117.023004,
                                39.256902
                            ],
                            [
                                117.025393,
                                39.262618
                            ],
                            [
                                117.024131,
                                39.270648
                            ],
                            [
                                117.017641,
                                39.268232
                            ],
                            [
                                117.01684,
                                39.272553
                            ],
                            [
                                117.019651,
                                39.273029
                            ],
                            [
                                117.015795,
                                39.280921
                            ],
                            [
                                117.017709,
                                39.281007
                            ],
                            [
                                117.016637,
                                39.287775
                            ],
                            [
                                117.037245,
                                39.292197
                            ],
                            [
                                117.035371,
                                39.289
                            ],
                            [
                                117.04835,
                                39.291228
                            ],
                            [
                                117.05359,
                                39.281092
                            ],
                            [
                                117.06422,
                                39.281296
                            ],
                            [
                                117.075611,
                                39.283081
                            ],
                            [
                                117.071036,
                                39.284578
                            ],
                            [
                                117.069854,
                                39.28769
                            ],
                            [
                                117.076575,
                                39.289782
                            ],
                            [
                                117.075095,
                                39.293897
                            ],
                            [
                                117.088847,
                                39.299747
                            ],
                            [
                                117.079602,
                                39.307823
                            ],
                            [
                                117.10507,
                                39.312855
                            ],
                            [
                                117.11999,
                                39.310764
                            ],
                            [
                                117.127254,
                                39.311631
                            ],
                            [
                                117.134544,
                                39.305902
                            ],
                            [
                                117.140653,
                                39.304967
                            ],
                            [
                                117.146708,
                                39.30614
                            ],
                            [
                                117.146233,
                                39.308044
                            ],
                            [
                                117.153048,
                                39.310203
                            ],
                            [
                                117.157664,
                                39.315915
                            ],
                            [
                                117.155505,
                                39.323242
                            ],
                            [
                                117.160026,
                                39.324397
                            ],
                            [
                                117.1608,
                                39.319145
                            ],
                            [
                                117.165321,
                                39.32178
                            ],
                            [
                                117.164438,
                                39.327559
                            ],
                            [
                                117.159659,
                                39.326709
                            ],
                            [
                                117.160623,
                                39.329938
                            ],
                            [
                                117.167506,
                                39.332097
                            ],
                            [
                                117.168199,
                                39.329021
                            ],
                            [
                                117.172529,
                                39.329123
                            ],
                            [
                                117.172991,
                                39.326233
                            ],
                            [
                                117.179711,
                                39.326777
                            ],
                            [
                                117.184218,
                                39.333626
                            ],
                            [
                                117.192663,
                                39.333558
                            ],
                            [
                                117.191386,
                                39.343772
                            ],
                            [
                                117.195758,
                                39.345471
                            ],
                            [
                                117.195432,
                                39.349413
                            ],
                            [
                                117.199315,
                                39.346236
                            ],
                            [
                                117.204908,
                                39.346321
                            ],
                            [
                                117.204935,
                                39.35011
                            ],
                            [
                                117.222679,
                                39.356141
                            ],
                            [
                                117.233689,
                                39.353355
                            ],
                            [
                                117.233065,
                                39.355445
                            ],
                            [
                                117.238563,
                                39.357501
                            ],
                            [
                                117.250944,
                                39.353814
                            ],
                            [
                                117.254515,
                                39.348513
                            ],
                            [
                                117.263665,
                                39.348071
                            ],
                            [
                                117.265104,
                                39.344859
                            ],
                            [
                                117.268742,
                                39.34542
                            ],
                            [
                                117.269407,
                                39.351299
                            ],
                            [
                                117.274254,
                                39.352166
                            ],
                            [
                                117.279549,
                                39.347731
                            ],
                            [
                                117.285522,
                                39.348852
                            ],
                            [
                                117.292052,
                                39.347595
                            ],
                            [
                                117.296356,
                                39.337263
                            ],
                            [
                                117.304827,
                                39.336923
                            ],
                            [
                                117.306171,
                                39.331961
                            ],
                            [
                                117.301908,
                                39.32987
                            ],
                            [
                                117.303985,
                                39.326437
                            ],
                            [
                                117.316747,
                                39.328817
                            ],
                            [
                                117.317928,
                                39.325281
                            ],
                            [
                                117.3242,
                                39.329649
                            ],
                            [
                                117.331952,
                                39.320828
                            ],
                            [
                                117.334708,
                                39.323429
                            ],
                            [
                                117.339079,
                                39.318941
                            ],
                            [
                                117.338305,
                                39.313569
                            ],
                            [
                                117.332495,
                                39.309642
                            ],
                            [
                                117.337722,
                                39.306004
                            ],
                            [
                                117.343139,
                                39.31039
                            ],
                            [
                                117.352167,
                                39.302739
                            ],
                            [
                                117.36243,
                                39.296023
                            ],
                            [
                                117.356199,
                                39.2932
                            ],
                            [
                                117.364629,
                                39.287367
                            ],
                            [
                                117.369041,
                                39.291602
                            ],
                            [
                                117.384056,
                                39.280054
                            ],
                            [
                                117.397239,
                                39.284051
                            ],
                            [
                                117.401515,
                                39.287622
                            ],
                            [
                                117.405452,
                                39.282554
                            ],
                            [
                                117.407108,
                                39.274475
                            ],
                            [
                                117.400402,
                                39.259148
                            ],
                            [
                                117.393994,
                                39.257276
                            ],
                            [
                                117.393913,
                                39.248054
                            ],
                            [
                                117.399913,
                                39.246387
                            ],
                            [
                                117.403144,
                                39.240312
                            ],
                            [
                                117.395745,
                                39.239784
                            ],
                            [
                                117.384627,
                                39.233589
                            ],
                            [
                                117.386405,
                                39.230492
                            ],
                            [
                                117.368295,
                                39.233436
                            ],
                            [
                                117.347985,
                                39.234185
                            ],
                            [
                                117.333567,
                                39.235819
                            ],
                            [
                                117.326793,
                                39.234593
                            ],
                            [
                                117.324526,
                                39.237929
                            ],
                            [
                                117.299261,
                                39.244362
                            ],
                            [
                                117.290314,
                                39.248514
                            ],
                            [
                                117.282997,
                                39.244345
                            ],
                            [
                                117.283689,
                                39.242013
                            ],
                            [
                                117.292473,
                                39.243698
                            ],
                            [
                                117.295052,
                                39.240363
                            ],
                            [
                                117.297903,
                                39.223258
                            ],
                            [
                                117.291482,
                                39.219342
                            ],
                            [
                                117.27253,
                                39.215751
                            ],
                            [
                                117.259728,
                                39.210933
                            ],
                            [
                                117.247007,
                                39.20448
                            ],
                            [
                                117.233485,
                                39.193054
                            ],
                            [
                                117.223982,
                                39.184965
                            ],
                            [
                                117.221946,
                                39.184692
                            ],
                            [
                                117.216122,
                                39.191283
                            ],
                            [
                                117.210773,
                                39.192918
                            ],
                            [
                                117.197265,
                                39.186106
                            ],
                            [
                                117.191916,
                                39.19193
                            ],
                            [
                                117.188603,
                                39.190227
                            ],
                            [
                                117.171783,
                                39.18631
                            ],
                            [
                                117.167547,
                                39.183994
                            ],
                            [
                                117.159293,
                                39.190346
                            ],
                            [
                                117.148568,
                                39.189103
                            ],
                            [
                                117.141766,
                                39.19113
                            ],
                            [
                                117.140775,
                                39.193224
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                117.130362,
                                39.18987
                            ],
                            [
                                117.140775,
                                39.193224
                            ],
                            [
                                117.141766,
                                39.19113
                            ],
                            [
                                117.134313,
                                39.185356
                            ],
                            [
                                117.130362,
                                39.18987
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120114,
                "name": "武清区",
                "center": [
                    117.057959,
                    39.376925
                ],
                "centroid": [
                    117.028202,
                    39.451194
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 10,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.3242,
                                39.329649
                            ],
                            [
                                117.317928,
                                39.325281
                            ],
                            [
                                117.316747,
                                39.328817
                            ],
                            [
                                117.303985,
                                39.326437
                            ],
                            [
                                117.301908,
                                39.32987
                            ],
                            [
                                117.306171,
                                39.331961
                            ],
                            [
                                117.304827,
                                39.336923
                            ],
                            [
                                117.296356,
                                39.337263
                            ],
                            [
                                117.292052,
                                39.347595
                            ],
                            [
                                117.285522,
                                39.348852
                            ],
                            [
                                117.279549,
                                39.347731
                            ],
                            [
                                117.274254,
                                39.352166
                            ],
                            [
                                117.269407,
                                39.351299
                            ],
                            [
                                117.268742,
                                39.34542
                            ],
                            [
                                117.265104,
                                39.344859
                            ],
                            [
                                117.263665,
                                39.348071
                            ],
                            [
                                117.254515,
                                39.348513
                            ],
                            [
                                117.250944,
                                39.353814
                            ],
                            [
                                117.238563,
                                39.357501
                            ],
                            [
                                117.233065,
                                39.355445
                            ],
                            [
                                117.233689,
                                39.353355
                            ],
                            [
                                117.222679,
                                39.356141
                            ],
                            [
                                117.204935,
                                39.35011
                            ],
                            [
                                117.204908,
                                39.346321
                            ],
                            [
                                117.199315,
                                39.346236
                            ],
                            [
                                117.195432,
                                39.349413
                            ],
                            [
                                117.195758,
                                39.345471
                            ],
                            [
                                117.191386,
                                39.343772
                            ],
                            [
                                117.192663,
                                39.333558
                            ],
                            [
                                117.184218,
                                39.333626
                            ],
                            [
                                117.179711,
                                39.326777
                            ],
                            [
                                117.172991,
                                39.326233
                            ],
                            [
                                117.172529,
                                39.329123
                            ],
                            [
                                117.168199,
                                39.329021
                            ],
                            [
                                117.167506,
                                39.332097
                            ],
                            [
                                117.160623,
                                39.329938
                            ],
                            [
                                117.159659,
                                39.326709
                            ],
                            [
                                117.164438,
                                39.327559
                            ],
                            [
                                117.165321,
                                39.32178
                            ],
                            [
                                117.1608,
                                39.319145
                            ],
                            [
                                117.160026,
                                39.324397
                            ],
                            [
                                117.155505,
                                39.323242
                            ],
                            [
                                117.157664,
                                39.315915
                            ],
                            [
                                117.153048,
                                39.310203
                            ],
                            [
                                117.146233,
                                39.308044
                            ],
                            [
                                117.146708,
                                39.30614
                            ],
                            [
                                117.140653,
                                39.304967
                            ],
                            [
                                117.134544,
                                39.305902
                            ],
                            [
                                117.127254,
                                39.311631
                            ],
                            [
                                117.11999,
                                39.310764
                            ],
                            [
                                117.10507,
                                39.312855
                            ],
                            [
                                117.079602,
                                39.307823
                            ],
                            [
                                117.088847,
                                39.299747
                            ],
                            [
                                117.075095,
                                39.293897
                            ],
                            [
                                117.076575,
                                39.289782
                            ],
                            [
                                117.069854,
                                39.28769
                            ],
                            [
                                117.071036,
                                39.284578
                            ],
                            [
                                117.075611,
                                39.283081
                            ],
                            [
                                117.06422,
                                39.281296
                            ],
                            [
                                117.05359,
                                39.281092
                            ],
                            [
                                117.04835,
                                39.291228
                            ],
                            [
                                117.035371,
                                39.289
                            ],
                            [
                                117.037245,
                                39.292197
                            ],
                            [
                                117.016637,
                                39.287775
                            ],
                            [
                                117.017709,
                                39.281007
                            ],
                            [
                                117.015795,
                                39.280921
                            ],
                            [
                                117.019651,
                                39.273029
                            ],
                            [
                                117.01684,
                                39.272553
                            ],
                            [
                                117.017641,
                                39.268232
                            ],
                            [
                                117.024131,
                                39.270648
                            ],
                            [
                                117.025393,
                                39.262618
                            ],
                            [
                                117.023004,
                                39.256902
                            ],
                            [
                                117.011505,
                                39.256698
                            ],
                            [
                                117.010772,
                                39.248803
                            ],
                            [
                                117.005817,
                                39.243017
                            ],
                            [
                                116.996178,
                                39.24329
                            ],
                            [
                                116.990096,
                                39.2454
                            ],
                            [
                                116.98939,
                                39.239563
                            ],
                            [
                                116.979954,
                                39.230764
                            ],
                            [
                                116.977334,
                                39.231989
                            ],
                            [
                                116.967695,
                                39.229419
                            ],
                            [
                                116.97208,
                                39.216551
                            ],
                            [
                                116.968143,
                                39.213844
                            ],
                            [
                                116.971198,
                                39.208873
                            ],
                            [
                                116.967207,
                                39.207919
                            ],
                            [
                                116.964328,
                                39.192254
                            ],
                            [
                                116.964614,
                                39.187775
                            ],
                            [
                                116.957839,
                                39.189665
                            ],
                            [
                                116.941399,
                                39.188592
                            ],
                            [
                                116.938928,
                                39.180826
                            ],
                            [
                                116.939987,
                                39.171815
                            ],
                            [
                                116.949056,
                                39.166143
                            ],
                            [
                                116.946503,
                                39.159328
                            ],
                            [
                                116.940475,
                                39.160759
                            ],
                            [
                                116.941562,
                                39.163281
                            ],
                            [
                                116.928203,
                                39.160487
                            ],
                            [
                                116.928854,
                                39.156176
                            ],
                            [
                                116.938548,
                                39.154302
                            ],
                            [
                                116.93833,
                                39.150655
                            ],
                            [
                                116.923153,
                                39.149411
                            ],
                            [
                                116.928352,
                                39.135318
                            ],
                            [
                                116.923071,
                                39.13421
                            ],
                            [
                                116.922162,
                                39.131211
                            ],
                            [
                                116.926112,
                                39.119636
                            ],
                            [
                                116.919786,
                                39.119943
                            ],
                            [
                                116.918455,
                                39.12354
                            ],
                            [
                                116.923804,
                                39.124
                            ],
                            [
                                116.918998,
                                39.138573
                            ],
                            [
                                116.91536,
                                39.138266
                            ],
                            [
                                116.914002,
                                39.146157
                            ],
                            [
                                116.909251,
                                39.150758
                            ],
                            [
                                116.893964,
                                39.154728
                            ],
                            [
                                116.870071,
                                39.153654
                            ],
                            [
                                116.865808,
                                39.157522
                            ],
                            [
                                116.865713,
                                39.173468
                            ],
                            [
                                116.863106,
                                39.174967
                            ],
                            [
                                116.864328,
                                39.182052
                            ],
                            [
                                116.863201,
                                39.185816
                            ],
                            [
                                116.863174,
                                39.201364
                            ],
                            [
                                116.860771,
                                39.205604
                            ],
                            [
                                116.857051,
                                39.205382
                            ],
                            [
                                116.855789,
                                39.215682
                            ],
                            [
                                116.871659,
                                39.217691
                            ],
                            [
                                116.875623,
                                39.216483
                            ],
                            [
                                116.874591,
                                39.230066
                            ],
                            [
                                116.879397,
                                39.230049
                            ],
                            [
                                116.880307,
                                39.225624
                            ],
                            [
                                116.883551,
                                39.226202
                            ],
                            [
                                116.884651,
                                39.221232
                            ],
                            [
                                116.887855,
                                39.224449
                            ],
                            [
                                116.892634,
                                39.223939
                            ],
                            [
                                116.890502,
                                39.22753
                            ],
                            [
                                116.891941,
                                39.231155
                            ],
                            [
                                116.8859,
                                39.232738
                            ],
                            [
                                116.884923,
                                39.24055
                            ],
                            [
                                116.882425,
                                39.238661
                            ],
                            [
                                116.876628,
                                39.240924
                            ],
                            [
                                116.875772,
                                39.253839
                            ],
                            [
                                116.878759,
                                39.255371
                            ],
                            [
                                116.871645,
                                39.269287
                            ],
                            [
                                116.876329,
                                39.270342
                            ],
                            [
                                116.874754,
                                39.283166
                            ],
                            [
                                116.872392,
                                39.291279
                            ],
                            [
                                116.86688,
                                39.290768
                            ],
                            [
                                116.863215,
                                39.292673
                            ],
                            [
                                116.862549,
                                39.297485
                            ],
                            [
                                116.866907,
                                39.298029
                            ],
                            [
                                116.867939,
                                39.302518
                            ],
                            [
                                116.884217,
                                39.305392
                            ],
                            [
                                116.882085,
                                39.313722
                            ],
                            [
                                116.887081,
                                39.314334
                            ],
                            [
                                116.88943,
                                39.323106
                            ],
                            [
                                116.887068,
                                39.332182
                            ],
                            [
                                116.889851,
                                39.332692
                            ],
                            [
                                116.889606,
                                39.338164
                            ],
                            [
                                116.881597,
                                39.340662
                            ],
                            [
                                116.875772,
                                39.339184
                            ],
                            [
                                116.872378,
                                39.34124
                            ],
                            [
                                116.870967,
                                39.346881
                            ],
                            [
                                116.872664,
                                39.352777
                            ],
                            [
                                116.876071,
                                39.352675
                            ],
                            [
                                116.874605,
                                39.356328
                            ],
                            [
                                116.870749,
                                39.357484
                            ],
                            [
                                116.860242,
                                39.354188
                            ],
                            [
                                116.861586,
                                39.349549
                            ],
                            [
                                116.857757,
                                39.347612
                            ],
                            [
                                116.849164,
                                39.339473
                            ],
                            [
                                116.847127,
                                39.343755
                            ],
                            [
                                116.842932,
                                39.340713
                            ],
                            [
                                116.829193,
                                39.338861
                            ],
                            [
                                116.823138,
                                39.357891
                            ],
                            [
                                116.83351,
                                39.360423
                            ],
                            [
                                116.834922,
                                39.365961
                            ],
                            [
                                116.826872,
                                39.363617
                            ],
                            [
                                116.820627,
                                39.366623
                            ],
                            [
                                116.818143,
                                39.37352
                            ],
                            [
                                116.823749,
                                39.372942
                            ],
                            [
                                116.837597,
                                39.374097
                            ],
                            [
                                116.836959,
                                39.377885
                            ],
                            [
                                116.841167,
                                39.381519
                            ],
                            [
                                116.837923,
                                39.391115
                            ],
                            [
                                116.839728,
                                39.394035
                            ],
                            [
                                116.835099,
                                39.397041
                            ],
                            [
                                116.834108,
                                39.402678
                            ],
                            [
                                116.839226,
                                39.40463
                            ],
                            [
                                116.838153,
                                39.410436
                            ],
                            [
                                116.840081,
                                39.413645
                            ],
                            [
                                116.844303,
                                39.412542
                            ],
                            [
                                116.85196,
                                39.413849
                            ],
                            [
                                116.852517,
                                39.416853
                            ],
                            [
                                116.860771,
                                39.424237
                            ],
                            [
                                116.864599,
                                39.425204
                            ],
                            [
                                116.864314,
                                39.430517
                            ],
                            [
                                116.87098,
                                39.427377
                            ],
                            [
                                116.875881,
                                39.434556
                            ],
                            [
                                116.868903,
                                39.43722
                            ],
                            [
                                116.862848,
                                39.43683
                            ],
                            [
                                116.855504,
                                39.443346
                            ],
                            [
                                116.847181,
                                39.441395
                            ],
                            [
                                116.847263,
                                39.438663
                            ],
                            [
                                116.840244,
                                39.439647
                            ],
                            [
                                116.832506,
                                39.435489
                            ],
                            [
                                116.827075,
                                39.438612
                            ],
                            [
                                116.826763,
                                39.445671
                            ],
                            [
                                116.81574,
                                39.451728
                            ],
                            [
                                116.810323,
                                39.450795
                            ],
                            [
                                116.807431,
                                39.44562
                            ],
                            [
                                116.799883,
                                39.449641
                            ],
                            [
                                116.795973,
                                39.446842
                            ],
                            [
                                116.792335,
                                39.453883
                            ],
                            [
                                116.797969,
                                39.450625
                            ],
                            [
                                116.79805,
                                39.457344
                            ],
                            [
                                116.794425,
                                39.461195
                            ],
                            [
                                116.791629,
                                39.460838
                            ],
                            [
                                116.790149,
                                39.465385
                            ],
                            [
                                116.785099,
                                39.465894
                            ],
                            [
                                116.788859,
                                39.472017
                            ],
                            [
                                116.799109,
                                39.473408
                            ],
                            [
                                116.798498,
                                39.475579
                            ],
                            [
                                116.811232,
                                39.477546
                            ],
                            [
                                116.810716,
                                39.480311
                            ],
                            [
                                116.820722,
                                39.482397
                            ],
                            [
                                116.817274,
                                39.496573
                            ],
                            [
                                116.811015,
                                39.500829
                            ],
                            [
                                116.816405,
                                39.501609
                            ],
                            [
                                116.813988,
                                39.510289
                            ],
                            [
                                116.825677,
                                39.512306
                            ],
                            [
                                116.823098,
                                39.52734
                            ],
                            [
                                116.825636,
                                39.527171
                            ],
                            [
                                116.821998,
                                39.534458
                            ],
                            [
                                116.818373,
                                39.533001
                            ],
                            [
                                116.819609,
                                39.52851
                            ],
                            [
                                116.815672,
                                39.529578
                            ],
                            [
                                116.806209,
                                39.528849
                            ],
                            [
                                116.803019,
                                39.530374
                            ],
                            [
                                116.801919,
                                39.536712
                            ],
                            [
                                116.798471,
                                39.536458
                            ],
                            [
                                116.795267,
                                39.54576
                            ],
                            [
                                116.787502,
                                39.548742
                            ],
                            [
                                116.78909,
                                39.555756
                            ],
                            [
                                116.792375,
                                39.556231
                            ],
                            [
                                116.791561,
                                39.559754
                            ],
                            [
                                116.798104,
                                39.566716
                            ],
                            [
                                116.796883,
                                39.567885
                            ],
                            [
                                116.807227,
                                39.571476
                            ],
                            [
                                116.808463,
                                39.576489
                            ],
                            [
                                116.81164,
                                39.576963
                            ],
                            [
                                116.800277,
                                39.586091
                            ],
                            [
                                116.797901,
                                39.594371
                            ],
                            [
                                116.804485,
                                39.597893
                            ],
                            [
                                116.8083,
                                39.597402
                            ],
                            [
                                116.803671,
                                39.601381
                            ],
                            [
                                116.805232,
                                39.603378
                            ],
                            [
                                116.799869,
                                39.604005
                            ],
                            [
                                116.80982,
                                39.608677
                            ],
                            [
                                116.807255,
                                39.60458
                            ],
                            [
                                116.808164,
                                39.60045
                            ],
                            [
                                116.813948,
                                39.600145
                            ],
                            [
                                116.81206,
                                39.60453
                            ],
                            [
                                116.81574,
                                39.606561
                            ],
                            [
                                116.812386,
                                39.615922
                            ],
                            [
                                116.819527,
                                39.619002
                            ],
                            [
                                116.82398,
                                39.617175
                            ],
                            [
                                116.825039,
                                39.613874
                            ],
                            [
                                116.835398,
                                39.617005
                            ],
                            [
                                116.834121,
                                39.621491
                            ],
                            [
                                116.838439,
                                39.622218
                            ],
                            [
                                116.82637,
                                39.633134
                            ],
                            [
                                116.828922,
                                39.635148
                            ],
                            [
                                116.826899,
                                39.63821
                            ],
                            [
                                116.834556,
                                39.641848
                            ],
                            [
                                116.833565,
                                39.644116
                            ],
                            [
                                116.84076,
                                39.644251
                            ],
                            [
                                116.839145,
                                39.647533
                            ],
                            [
                                116.851404,
                                39.652845
                            ],
                            [
                                116.849951,
                                39.667545
                            ],
                            [
                                116.860486,
                                39.667258
                            ],
                            [
                                116.863826,
                                39.670336
                            ],
                            [
                                116.873166,
                                39.671384
                            ],
                            [
                                116.883226,
                                39.675359
                            ],
                            [
                                116.891154,
                                39.674073
                            ],
                            [
                                116.906658,
                                39.677422
                            ],
                            [
                                116.905205,
                                39.68165
                            ],
                            [
                                116.90902,
                                39.682867
                            ],
                            [
                                116.905952,
                                39.687923
                            ],
                            [
                                116.91259,
                                39.689225
                            ],
                            [
                                116.911287,
                                39.695379
                            ],
                            [
                                116.916867,
                                39.699352
                            ],
                            [
                                116.916921,
                                39.706385
                            ],
                            [
                                116.932425,
                                39.706672
                            ],
                            [
                                116.934828,
                                39.703545
                            ],
                            [
                                116.937679,
                                39.706402
                            ],
                            [
                                116.950848,
                                39.706825
                            ],
                            [
                                116.952667,
                                39.699657
                            ],
                            [
                                116.948377,
                                39.702818
                            ],
                            [
                                116.944575,
                                39.695143
                            ],
                            [
                                116.944141,
                                39.686807
                            ],
                            [
                                116.951458,
                                39.678149
                            ],
                            [
                                116.945471,
                                39.67661
                            ],
                            [
                                116.945689,
                                39.670792
                            ],
                            [
                                116.957513,
                                39.666243
                            ],
                            [
                                116.955341,
                                39.658665
                            ],
                            [
                                116.957744,
                                39.651069
                            ],
                            [
                                116.964369,
                                39.643591
                            ],
                            [
                                116.971388,
                                39.648887
                            ],
                            [
                                116.975352,
                                39.644843
                            ],
                            [
                                116.974565,
                                39.636789
                            ],
                            [
                                116.977036,
                                39.636366
                            ],
                            [
                                116.989919,
                                39.641493
                            ],
                            [
                                117.004405,
                                39.644505
                            ],
                            [
                                117.00788,
                                39.648582
                            ],
                            [
                                117.015768,
                                39.654063
                            ],
                            [
                                117.033131,
                                39.65068
                            ],
                            [
                                117.04087,
                                39.647956
                            ],
                            [
                                117.057066,
                                39.644572
                            ],
                            [
                                117.078597,
                                39.637906
                            ],
                            [
                                117.088277,
                                39.633489
                            ],
                            [
                                117.10093,
                                39.629648
                            ],
                            [
                                117.103278,
                                39.625163
                            ],
                            [
                                117.112374,
                                39.624402
                            ],
                            [
                                117.112659,
                                39.621372
                            ],
                            [
                                117.127064,
                                39.616971
                            ],
                            [
                                117.125109,
                                39.621914
                            ],
                            [
                                117.136078,
                                39.618072
                            ],
                            [
                                117.140083,
                                39.620949
                            ],
                            [
                                117.147875,
                                39.622608
                            ],
                            [
                                117.149681,
                                39.616616
                            ],
                            [
                                117.146708,
                                39.614348
                            ],
                            [
                                117.165606,
                                39.602769
                            ],
                            [
                                117.173833,
                                39.596539
                            ],
                            [
                                117.188983,
                                39.587107
                            ],
                            [
                                117.200143,
                                39.578945
                            ],
                            [
                                117.212212,
                                39.571171
                            ],
                            [
                                117.227661,
                                39.559771
                            ],
                            [
                                117.238712,
                                39.553469
                            ],
                            [
                                117.233092,
                                39.547878
                            ],
                            [
                                117.241848,
                                39.546387
                            ],
                            [
                                117.245229,
                                39.541389
                            ],
                            [
                                117.245527,
                                39.545049
                            ],
                            [
                                117.254067,
                                39.545422
                            ],
                            [
                                117.274879,
                                39.532781
                            ],
                            [
                                117.275938,
                                39.527612
                            ],
                            [
                                117.265647,
                                39.526256
                            ],
                            [
                                117.261927,
                                39.527866
                            ],
                            [
                                117.254392,
                                39.527052
                            ],
                            [
                                117.255139,
                                39.523646
                            ],
                            [
                                117.26357,
                                39.522612
                            ],
                            [
                                117.270426,
                                39.515713
                            ],
                            [
                                117.272028,
                                39.51078
                            ],
                            [
                                117.266312,
                                39.508339
                            ],
                            [
                                117.265552,
                                39.50049
                            ],
                            [
                                117.270276,
                                39.500863
                            ],
                            [
                                117.271865,
                                39.498506
                            ],
                            [
                                117.278816,
                                39.499506
                            ],
                            [
                                117.282359,
                                39.49564
                            ],
                            [
                                117.288699,
                                39.495827
                            ],
                            [
                                117.294509,
                                39.491452
                            ],
                            [
                                117.295188,
                                39.484856
                            ],
                            [
                                117.298189,
                                39.483465
                            ],
                            [
                                117.299505,
                                39.478377
                            ],
                            [
                                117.295161,
                                39.477411
                            ],
                            [
                                117.293532,
                                39.473136
                            ],
                            [
                                117.299519,
                                39.46988
                            ],
                            [
                                117.304406,
                                39.461941
                            ],
                            [
                                117.307502,
                                39.460991
                            ],
                            [
                                117.310312,
                                39.456071
                            ],
                            [
                                117.318865,
                                39.448012
                            ],
                            [
                                117.320725,
                                39.444687
                            ],
                            [
                                117.325327,
                                39.446248
                            ],
                            [
                                117.327241,
                                39.443804
                            ],
                            [
                                117.322286,
                                39.441819
                            ],
                            [
                                117.325463,
                                39.43666
                            ],
                            [
                                117.325626,
                                39.43218
                            ],
                            [
                                117.329888,
                                39.422777
                            ],
                            [
                                117.334463,
                                39.418517
                            ],
                            [
                                117.337179,
                                39.403951
                            ],
                            [
                                117.340179,
                                39.400997
                            ],
                            [
                                117.332386,
                                39.395105
                            ],
                            [
                                117.324241,
                                39.401303
                            ],
                            [
                                117.325083,
                                39.397499
                            ],
                            [
                                117.31896,
                                39.394273
                            ],
                            [
                                117.320331,
                                39.401727
                            ],
                            [
                                117.313041,
                                39.40609
                            ],
                            [
                                117.313326,
                                39.402508
                            ],
                            [
                                117.307922,
                                39.399825
                            ],
                            [
                                117.313461,
                                39.38945
                            ],
                            [
                                117.309253,
                                39.386411
                            ],
                            [
                                117.305628,
                                39.380568
                            ],
                            [
                                117.299519,
                                39.379023
                            ],
                            [
                                117.303089,
                                39.368917
                            ],
                            [
                                117.314928,
                                39.366386
                            ],
                            [
                                117.316625,
                                39.362665
                            ],
                            [
                                117.316312,
                                39.355819
                            ],
                            [
                                117.320752,
                                39.350552
                            ],
                            [
                                117.328504,
                                39.350076
                            ],
                            [
                                117.322775,
                                39.348717
                            ],
                            [
                                117.328015,
                                39.339914
                            ],
                            [
                                117.3242,
                                39.329649
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120115,
                "name": "宝坻区",
                "center": [
                    117.308094,
                    39.716965
                ],
                "centroid": [
                    117.405517,
                    39.60949
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 11,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.25723,
                                39.829794
                            ],
                            [
                                117.26619,
                                39.826756
                            ],
                            [
                                117.268824,
                                39.828342
                            ],
                            [
                                117.273345,
                                39.824866
                            ],
                            [
                                117.27796,
                                39.825373
                            ],
                            [
                                117.286147,
                                39.819432
                            ],
                            [
                                117.285631,
                                39.813103
                            ],
                            [
                                117.289609,
                                39.813762
                            ],
                            [
                                117.295921,
                                39.810977
                            ],
                            [
                                117.301827,
                                39.805187
                            ],
                            [
                                117.306063,
                                39.808073
                            ],
                            [
                                117.313557,
                                39.807162
                            ],
                            [
                                117.319625,
                                39.804596
                            ],
                            [
                                117.319191,
                                39.800477
                            ],
                            [
                                117.322897,
                                39.794028
                            ],
                            [
                                117.329305,
                                39.79158
                            ],
                            [
                                117.334151,
                                39.796763
                            ],
                            [
                                117.340125,
                                39.7983
                            ],
                            [
                                117.342799,
                                39.795447
                            ],
                            [
                                117.34565,
                                39.797557
                            ],
                            [
                                117.349126,
                                39.794721
                            ],
                            [
                                117.356185,
                                39.792847
                            ],
                            [
                                117.366204,
                                39.794062
                            ],
                            [
                                117.366258,
                                39.790854
                            ],
                            [
                                117.374757,
                                39.790601
                            ],
                            [
                                117.375544,
                                39.78763
                            ],
                            [
                                117.382875,
                                39.787545
                            ],
                            [
                                117.389908,
                                39.785485
                            ],
                            [
                                117.388795,
                                39.782361
                            ],
                            [
                                117.392786,
                                39.782125
                            ],
                            [
                                117.392514,
                                39.777177
                            ],
                            [
                                117.399099,
                                39.770861
                            ],
                            [
                                117.407299,
                                39.772246
                            ],
                            [
                                117.412417,
                                39.768176
                            ],
                            [
                                117.412322,
                                39.76267
                            ],
                            [
                                117.415648,
                                39.760288
                            ],
                            [
                                117.426753,
                                39.758295
                            ],
                            [
                                117.431233,
                                39.755897
                            ],
                            [
                                117.437193,
                                39.755542
                            ],
                            [
                                117.439935,
                                39.764444
                            ],
                            [
                                117.449289,
                                39.768885
                            ],
                            [
                                117.454597,
                                39.768345
                            ],
                            [
                                117.457272,
                                39.772922
                            ],
                            [
                                117.463544,
                                39.775354
                            ],
                            [
                                117.460394,
                                39.769122
                            ],
                            [
                                117.464141,
                                39.768717
                            ],
                            [
                                117.47302,
                                39.774357
                            ],
                            [
                                117.47021,
                                39.777802
                            ],
                            [
                                117.483378,
                                39.774948
                            ],
                            [
                                117.489881,
                                39.77863
                            ],
                            [
                                117.492257,
                                39.771419
                            ],
                            [
                                117.503525,
                                39.773884
                            ],
                            [
                                117.50719,
                                39.767197
                            ],
                            [
                                117.511576,
                                39.765727
                            ],
                            [
                                117.516151,
                                39.769071
                            ],
                            [
                                117.519938,
                                39.764663
                            ],
                            [
                                117.52378,
                                39.768598
                            ],
                            [
                                117.529346,
                                39.765812
                            ],
                            [
                                117.533053,
                                39.766251
                            ],
                            [
                                117.531912,
                                39.761386
                            ],
                            [
                                117.539162,
                                39.76044
                            ],
                            [
                                117.543316,
                                39.760626
                            ],
                            [
                                117.548774,
                                39.756809
                            ],
                            [
                                117.555453,
                                39.762552
                            ],
                            [
                                117.559824,
                                39.755542
                            ],
                            [
                                117.56759,
                                39.756792
                            ],
                            [
                                117.573536,
                                39.754917
                            ],
                            [
                                117.575016,
                                39.752113
                            ],
                            [
                                117.581424,
                                39.748937
                            ],
                            [
                                117.595896,
                                39.746132
                            ],
                            [
                                117.591524,
                                39.743024
                            ],
                            [
                                117.59743,
                                39.736586
                            ],
                            [
                                117.593411,
                                39.735201
                            ],
                            [
                                117.589936,
                                39.737938
                            ],
                            [
                                117.587845,
                                39.730841
                            ],
                            [
                                117.584003,
                                39.731669
                            ],
                            [
                                117.583447,
                                39.727326
                            ],
                            [
                                117.577623,
                                39.726938
                            ],
                            [
                                117.578166,
                                39.72327
                            ],
                            [
                                117.585171,
                                39.722087
                            ],
                            [
                                117.580053,
                                39.719451
                            ],
                            [
                                117.588443,
                                39.719485
                            ],
                            [
                                117.592339,
                                39.716747
                            ],
                            [
                                117.59352,
                                39.710746
                            ],
                            [
                                117.602439,
                                39.705371
                            ],
                            [
                                117.614902,
                                39.705979
                            ],
                            [
                                117.620957,
                                39.702953
                            ],
                            [
                                117.627134,
                                39.703646
                            ],
                            [
                                117.634479,
                                39.700959
                            ],
                            [
                                117.636909,
                                39.696783
                            ],
                            [
                                117.642597,
                                39.70133
                            ],
                            [
                                117.646969,
                                39.69964
                            ],
                            [
                                117.648978,
                                39.694838
                            ],
                            [
                                117.643968,
                                39.688684
                            ],
                            [
                                117.649915,
                                39.685404
                            ],
                            [
                                117.650797,
                                39.68192
                            ],
                            [
                                117.657436,
                                39.682816
                            ],
                            [
                                117.653214,
                                39.678217
                            ],
                            [
                                117.660205,
                                39.674462
                            ],
                            [
                                117.657028,
                                39.668644
                            ],
                            [
                                117.663857,
                                39.665854
                            ],
                            [
                                117.668324,
                                39.667088
                            ],
                            [
                                117.667536,
                                39.661574
                            ],
                            [
                                117.663586,
                                39.657751
                            ],
                            [
                                117.66774,
                                39.651526
                            ],
                            [
                                117.665337,
                                39.64618
                            ],
                            [
                                117.666477,
                                39.642914
                            ],
                            [
                                117.661997,
                                39.636332
                            ],
                            [
                                117.6532,
                                39.639158
                            ],
                            [
                                117.651503,
                                39.634843
                            ],
                            [
                                117.652928,
                                39.630003
                            ],
                            [
                                117.650892,
                                39.627939
                            ],
                            [
                                117.643805,
                                39.629699
                            ],
                            [
                                117.641986,
                                39.628413
                            ],
                            [
                                117.64462,
                                39.623691
                            ],
                            [
                                117.640113,
                                39.619713
                            ],
                            [
                                117.641348,
                                39.612841
                            ],
                            [
                                117.632144,
                                39.613738
                            ],
                            [
                                117.630324,
                                39.606121
                            ],
                            [
                                117.6245,
                                39.602684
                            ],
                            [
                                117.620034,
                                39.606476
                            ],
                            [
                                117.619056,
                                39.603209
                            ],
                            [
                                117.621935,
                                39.591679
                            ],
                            [
                                117.62651,
                                39.594287
                            ],
                            [
                                117.632198,
                                39.602346
                            ],
                            [
                                117.636827,
                                39.6037
                            ],
                            [
                                117.637153,
                                39.59769
                            ],
                            [
                                117.63938,
                                39.593118
                            ],
                            [
                                117.651014,
                                39.594168
                            ],
                            [
                                117.655779,
                                39.588174
                            ],
                            [
                                117.655711,
                                39.585329
                            ],
                            [
                                117.650702,
                                39.579233
                            ],
                            [
                                117.655657,
                                39.574914
                            ],
                            [
                                117.660816,
                                39.575388
                            ],
                            [
                                117.67165,
                                39.582044
                            ],
                            [
                                117.673795,
                                39.589021
                            ],
                            [
                                117.681642,
                                39.589986
                            ],
                            [
                                117.685877,
                                39.588191
                            ],
                            [
                                117.693602,
                                39.581045
                            ],
                            [
                                117.705861,
                                39.576489
                            ],
                            [
                                117.708576,
                                39.573796
                            ],
                            [
                                117.70825,
                                39.568732
                            ],
                            [
                                117.704069,
                                39.565378
                            ],
                            [
                                117.697295,
                                39.564785
                            ],
                            [
                                117.688796,
                                39.569223
                            ],
                            [
                                117.685674,
                                39.56443
                            ],
                            [
                                117.689597,
                                39.559517
                            ],
                            [
                                117.695774,
                                39.557315
                            ],
                            [
                                117.703689,
                                39.557501
                            ],
                            [
                                117.708441,
                                39.555079
                            ],
                            [
                                117.708712,
                                39.548217
                            ],
                            [
                                117.700879,
                                39.551741
                            ],
                            [
                                117.69462,
                                39.551097
                            ],
                            [
                                117.690398,
                                39.547455
                            ],
                            [
                                117.690751,
                                39.537542
                            ],
                            [
                                117.687941,
                                39.534899
                            ],
                            [
                                117.681112,
                                39.536593
                            ],
                            [
                                117.676646,
                                39.533187
                            ],
                            [
                                117.668446,
                                39.53234
                            ],
                            [
                                117.667672,
                                39.530255
                            ],
                            [
                                117.659811,
                                39.527612
                            ],
                            [
                                117.658291,
                                39.531137
                            ],
                            [
                                117.634153,
                                39.53717
                            ],
                            [
                                117.633365,
                                39.534797
                            ],
                            [
                                117.637099,
                                39.522069
                            ],
                            [
                                117.63065,
                                39.516001
                            ],
                            [
                                117.610626,
                                39.511899
                            ],
                            [
                                117.608902,
                                39.499744
                            ],
                            [
                                117.605752,
                                39.494199
                            ],
                            [
                                117.607503,
                                39.48974
                            ],
                            [
                                117.59667,
                                39.48745
                            ],
                            [
                                117.580188,
                                39.485093
                            ],
                            [
                                117.577405,
                                39.491774
                            ],
                            [
                                117.572857,
                                39.49096
                            ],
                            [
                                117.572396,
                                39.494928
                            ],
                            [
                                117.567549,
                                39.493962
                            ],
                            [
                                117.567223,
                                39.497692
                            ],
                            [
                                117.555887,
                                39.494555
                            ],
                            [
                                117.5581,
                                39.486585
                            ],
                            [
                                117.562227,
                                39.485704
                            ],
                            [
                                117.564943,
                                39.480769
                            ],
                            [
                                117.557055,
                                39.471627
                            ],
                            [
                                117.55138,
                                39.46776
                            ],
                            [
                                117.541334,
                                39.464384
                            ],
                            [
                                117.524378,
                                39.466403
                            ],
                            [
                                117.523156,
                                39.463638
                            ],
                            [
                                117.517983,
                                39.46318
                            ],
                            [
                                117.519287,
                                39.45921
                            ],
                            [
                                117.509512,
                                39.456326
                            ],
                            [
                                117.502928,
                                39.462009
                            ],
                            [
                                117.496995,
                                39.459397
                            ],
                            [
                                117.499344,
                                39.454426
                            ],
                            [
                                117.492461,
                                39.451847
                            ],
                            [
                                117.489148,
                                39.448623
                            ],
                            [
                                117.483392,
                                39.449064
                            ],
                            [
                                117.483256,
                                39.445654
                            ],
                            [
                                117.491225,
                                39.413679
                            ],
                            [
                                117.494986,
                                39.414647
                            ],
                            [
                                117.496995,
                                39.405394
                            ],
                            [
                                117.494986,
                                39.40497
                            ],
                            [
                                117.497891,
                                39.395513
                            ],
                            [
                                117.497619,
                                39.392337
                            ],
                            [
                                117.501081,
                                39.384865
                            ],
                            [
                                117.505874,
                                39.386139
                            ],
                            [
                                117.508032,
                                39.383608
                            ],
                            [
                                117.485645,
                                39.379532
                            ],
                            [
                                117.483867,
                                39.375065
                            ],
                            [
                                117.486093,
                                39.372297
                            ],
                            [
                                117.477418,
                                39.366572
                            ],
                            [
                                117.47135,
                                39.36523
                            ],
                            [
                                117.47321,
                                39.362071
                            ],
                            [
                                117.46539,
                                39.35959
                            ],
                            [
                                117.46163,
                                39.362444
                            ],
                            [
                                117.454801,
                                39.361969
                            ],
                            [
                                117.448855,
                                39.35886
                            ],
                            [
                                117.441727,
                                39.361646
                            ],
                            [
                                117.43855,
                                39.35784
                            ],
                            [
                                117.432903,
                                39.360049
                            ],
                            [
                                117.43312,
                                39.355377
                            ],
                            [
                                117.436514,
                                39.352319
                            ],
                            [
                                117.433894,
                                39.347119
                            ],
                            [
                                117.415186,
                                39.346525
                            ],
                            [
                                117.415716,
                                39.350535
                            ],
                            [
                                117.392555,
                                39.352658
                            ],
                            [
                                117.389487,
                                39.354408
                            ],
                            [
                                117.3895,
                                39.36044
                            ],
                            [
                                117.38445,
                                39.362665
                            ],
                            [
                                117.382427,
                                39.360304
                            ],
                            [
                                117.370589,
                                39.35976
                            ],
                            [
                                117.350483,
                                39.361493
                            ],
                            [
                                117.337708,
                                39.359267
                            ],
                            [
                                117.335088,
                                39.353831
                            ],
                            [
                                117.330214,
                                39.353593
                            ],
                            [
                                117.328504,
                                39.350076
                            ],
                            [
                                117.320752,
                                39.350552
                            ],
                            [
                                117.316312,
                                39.355819
                            ],
                            [
                                117.316625,
                                39.362665
                            ],
                            [
                                117.314928,
                                39.366386
                            ],
                            [
                                117.303089,
                                39.368917
                            ],
                            [
                                117.299519,
                                39.379023
                            ],
                            [
                                117.305628,
                                39.380568
                            ],
                            [
                                117.309253,
                                39.386411
                            ],
                            [
                                117.313461,
                                39.38945
                            ],
                            [
                                117.307922,
                                39.399825
                            ],
                            [
                                117.313326,
                                39.402508
                            ],
                            [
                                117.313041,
                                39.40609
                            ],
                            [
                                117.320331,
                                39.401727
                            ],
                            [
                                117.31896,
                                39.394273
                            ],
                            [
                                117.325083,
                                39.397499
                            ],
                            [
                                117.324241,
                                39.401303
                            ],
                            [
                                117.332386,
                                39.395105
                            ],
                            [
                                117.340179,
                                39.400997
                            ],
                            [
                                117.337179,
                                39.403951
                            ],
                            [
                                117.334463,
                                39.418517
                            ],
                            [
                                117.329888,
                                39.422777
                            ],
                            [
                                117.325626,
                                39.43218
                            ],
                            [
                                117.325463,
                                39.43666
                            ],
                            [
                                117.322286,
                                39.441819
                            ],
                            [
                                117.327241,
                                39.443804
                            ],
                            [
                                117.325327,
                                39.446248
                            ],
                            [
                                117.320725,
                                39.444687
                            ],
                            [
                                117.318865,
                                39.448012
                            ],
                            [
                                117.310312,
                                39.456071
                            ],
                            [
                                117.307502,
                                39.460991
                            ],
                            [
                                117.304406,
                                39.461941
                            ],
                            [
                                117.299519,
                                39.46988
                            ],
                            [
                                117.293532,
                                39.473136
                            ],
                            [
                                117.295161,
                                39.477411
                            ],
                            [
                                117.299505,
                                39.478377
                            ],
                            [
                                117.298189,
                                39.483465
                            ],
                            [
                                117.295188,
                                39.484856
                            ],
                            [
                                117.294509,
                                39.491452
                            ],
                            [
                                117.288699,
                                39.495827
                            ],
                            [
                                117.282359,
                                39.49564
                            ],
                            [
                                117.278816,
                                39.499506
                            ],
                            [
                                117.271865,
                                39.498506
                            ],
                            [
                                117.270276,
                                39.500863
                            ],
                            [
                                117.265552,
                                39.50049
                            ],
                            [
                                117.266312,
                                39.508339
                            ],
                            [
                                117.272028,
                                39.51078
                            ],
                            [
                                117.270426,
                                39.515713
                            ],
                            [
                                117.26357,
                                39.522612
                            ],
                            [
                                117.255139,
                                39.523646
                            ],
                            [
                                117.254392,
                                39.527052
                            ],
                            [
                                117.261927,
                                39.527866
                            ],
                            [
                                117.265647,
                                39.526256
                            ],
                            [
                                117.275938,
                                39.527612
                            ],
                            [
                                117.274879,
                                39.532781
                            ],
                            [
                                117.254067,
                                39.545422
                            ],
                            [
                                117.245527,
                                39.545049
                            ],
                            [
                                117.245229,
                                39.541389
                            ],
                            [
                                117.241848,
                                39.546387
                            ],
                            [
                                117.233092,
                                39.547878
                            ],
                            [
                                117.238712,
                                39.553469
                            ],
                            [
                                117.227661,
                                39.559771
                            ],
                            [
                                117.212212,
                                39.571171
                            ],
                            [
                                117.200143,
                                39.578945
                            ],
                            [
                                117.188983,
                                39.587107
                            ],
                            [
                                117.173833,
                                39.596539
                            ],
                            [
                                117.165606,
                                39.602769
                            ],
                            [
                                117.146708,
                                39.614348
                            ],
                            [
                                117.149681,
                                39.616616
                            ],
                            [
                                117.147875,
                                39.622608
                            ],
                            [
                                117.154161,
                                39.626043
                            ],
                            [
                                117.150278,
                                39.628633
                            ],
                            [
                                117.154989,
                                39.631374
                            ],
                            [
                                117.157949,
                                39.636603
                            ],
                            [
                                117.172828,
                                39.636755
                            ],
                            [
                                117.173005,
                                39.641205
                            ],
                            [
                                117.177892,
                                39.642965
                            ],
                            [
                                117.177675,
                                39.645621
                            ],
                            [
                                117.169068,
                                39.648159
                            ],
                            [
                                117.169258,
                                39.651797
                            ],
                            [
                                117.164017,
                                39.651746
                            ],
                            [
                                117.163461,
                                39.6588
                            ],
                            [
                                117.158899,
                                39.661236
                            ],
                            [
                                117.159605,
                                39.666852
                            ],
                            [
                                117.164655,
                                39.666699
                            ],
                            [
                                117.166135,
                                39.672974
                            ],
                            [
                                117.170113,
                                39.673363
                            ],
                            [
                                117.16771,
                                39.686875
                            ],
                            [
                                117.170357,
                                39.689259
                            ],
                            [
                                117.168294,
                                39.70816
                            ],
                            [
                                117.170697,
                                39.708295
                            ],
                            [
                                117.169149,
                                39.713535
                            ],
                            [
                                117.164289,
                                39.711828
                            ],
                            [
                                117.163569,
                                39.715817
                            ],
                            [
                                117.169203,
                                39.717592
                            ],
                            [
                                117.166312,
                                39.722189
                            ],
                            [
                                117.160705,
                                39.720803
                            ],
                            [
                                117.158858,
                                39.723389
                            ],
                            [
                                117.153184,
                                39.722729
                            ],
                            [
                                117.155288,
                                39.729236
                            ],
                            [
                                117.15374,
                                39.736316
                            ],
                            [
                                117.160501,
                                39.736468
                            ],
                            [
                                117.160365,
                                39.739019
                            ],
                            [
                                117.166122,
                                39.738022
                            ],
                            [
                                117.161872,
                                39.748126
                            ],
                            [
                                117.173072,
                                39.751876
                            ],
                            [
                                117.184734,
                                39.75191
                            ],
                            [
                                117.186934,
                                39.755795
                            ],
                            [
                                117.192825,
                                39.754984
                            ],
                            [
                                117.194957,
                                39.759832
                            ],
                            [
                                117.201745,
                                39.7599
                            ],
                            [
                                117.205899,
                                39.763852
                            ],
                            [
                                117.192242,
                                39.775793
                            ],
                            [
                                117.180621,
                                39.782463
                            ],
                            [
                                117.178734,
                                39.795345
                            ],
                            [
                                117.167764,
                                39.799667
                            ],
                            [
                                117.157501,
                                39.79673
                            ],
                            [
                                117.157148,
                                39.806757
                            ],
                            [
                                117.161669,
                                39.807027
                            ],
                            [
                                117.156333,
                                39.817491
                            ],
                            [
                                117.159089,
                                39.821862
                            ],
                            [
                                117.162524,
                                39.817829
                            ],
                            [
                                117.167194,
                                39.819213
                            ],
                            [
                                117.167153,
                                39.823736
                            ],
                            [
                                117.190531,
                                39.826368
                            ],
                            [
                                117.195079,
                                39.825491
                            ],
                            [
                                117.19216,
                                39.833084
                            ],
                            [
                                117.199858,
                                39.834586
                            ],
                            [
                                117.200672,
                                39.832595
                            ],
                            [
                                117.207352,
                                39.834991
                            ],
                            [
                                117.208438,
                                39.831397
                            ],
                            [
                                117.21247,
                                39.832308
                            ],
                            [
                                117.220113,
                                39.830637
                            ],
                            [
                                117.22067,
                                39.827195
                            ],
                            [
                                117.226236,
                                39.830958
                            ],
                            [
                                117.237382,
                                39.833911
                            ],
                            [
                                117.25723,
                                39.829794
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120116,
                "name": "滨海新区",
                "center": [
                    117.654173,
                    39.032846
                ],
                "cp": [
                    117.694173,
                    39.032846
                ],
                "centroid": [
                    117.597627,
                    38.911769
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 12,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.526984,
                                39.005994
                            ],
                            [
                                117.52488,
                                39.015077
                            ],
                            [
                                117.526346,
                                39.021257
                            ],
                            [
                                117.525396,
                                39.026378
                            ],
                            [
                                117.521988,
                                39.023767
                            ],
                            [
                                117.517386,
                                39.026686
                            ],
                            [
                                117.520576,
                                39.036824
                            ],
                            [
                                117.518825,
                                39.037899
                            ],
                            [
                                117.523712,
                                39.04674
                            ],
                            [
                                117.52712,
                                39.046176
                            ],
                            [
                                117.530134,
                                39.057865
                            ],
                            [
                                117.538062,
                                39.056227
                            ],
                            [
                                117.559539,
                                39.054913
                            ],
                            [
                                117.557815,
                                39.065747
                            ],
                            [
                                117.563775,
                                39.078507
                            ],
                            [
                                117.552874,
                                39.078984
                            ],
                            [
                                117.553362,
                                39.099706
                            ],
                            [
                                117.536189,
                                39.099978
                            ],
                            [
                                117.535496,
                                39.106543
                            ],
                            [
                                117.517467,
                                39.106731
                            ],
                            [
                                117.503226,
                                39.107907
                            ],
                            [
                                117.509688,
                                39.1375
                            ],
                            [
                                117.514331,
                                39.150144
                            ],
                            [
                                117.518092,
                                39.15149
                            ],
                            [
                                117.540791,
                                39.142732
                            ],
                            [
                                117.552629,
                                39.14723
                            ],
                            [
                                117.553091,
                                39.153927
                            ],
                            [
                                117.555752,
                                39.159175
                            ],
                            [
                                117.566164,
                                39.161747
                            ],
                            [
                                117.56706,
                                39.173485
                            ],
                            [
                                117.568798,
                                39.177505
                            ],
                            [
                                117.591823,
                                39.170197
                            ],
                            [
                                117.615242,
                                39.166211
                            ],
                            [
                                117.616368,
                                39.174353
                            ],
                            [
                                117.623238,
                                39.183057
                            ],
                            [
                                117.620658,
                                39.187264
                            ],
                            [
                                117.617807,
                                39.186719
                            ],
                            [
                                117.6174,
                                39.205042
                            ],
                            [
                                117.628383,
                                39.207493
                            ],
                            [
                                117.635117,
                                39.210507
                            ],
                            [
                                117.635157,
                                39.212618
                            ],
                            [
                                117.643656,
                                39.21507
                            ],
                            [
                                117.645747,
                                39.211392
                            ],
                            [
                                117.652562,
                                39.205008
                            ],
                            [
                                117.666572,
                                39.18878
                            ],
                            [
                                117.680094,
                                39.188797
                            ],
                            [
                                117.679985,
                                39.17173
                            ],
                            [
                                117.682728,
                                39.173365
                            ],
                            [
                                117.718785,
                                39.173263
                            ],
                            [
                                117.719668,
                                39.193514
                            ],
                            [
                                117.71588,
                                39.19457
                            ],
                            [
                                117.721134,
                                39.207834
                            ],
                            [
                                117.743249,
                                39.237878
                            ],
                            [
                                117.725478,
                                39.246063
                            ],
                            [
                                117.725438,
                                39.251219
                            ],
                            [
                                117.721541,
                                39.250096
                            ],
                            [
                                117.717998,
                                39.257242
                            ],
                            [
                                117.715826,
                                39.256919
                            ],
                            [
                                117.710436,
                                39.267603
                            ],
                            [
                                117.70897,
                                39.267977
                            ],
                            [
                                117.709282,
                                39.283166
                            ],
                            [
                                117.720632,
                                39.282435
                            ],
                            [
                                117.721691,
                                39.290836
                            ],
                            [
                                117.732076,
                                39.290462
                            ],
                            [
                                117.761061,
                                39.295139
                            ],
                            [
                                117.765772,
                                39.299016
                            ],
                            [
                                117.77404,
                                39.301991
                            ],
                            [
                                117.784575,
                                39.30427
                            ],
                            [
                                117.791308,
                                39.301158
                            ],
                            [
                                117.789693,
                                39.299152
                            ],
                            [
                                117.789923,
                                39.289578
                            ],
                            [
                                117.788702,
                                39.287809
                            ],
                            [
                                117.809093,
                                39.279187
                            ],
                            [
                                117.813736,
                                39.27951
                            ],
                            [
                                117.806092,
                                39.268692
                            ],
                            [
                                117.825995,
                                39.260322
                            ],
                            [
                                117.827325,
                                39.26056
                            ],
                            [
                                117.852658,
                                39.297876
                            ],
                            [
                                117.850744,
                                39.300393
                            ],
                            [
                                117.83691,
                                39.306055
                            ],
                            [
                                117.83767,
                                39.309727
                            ],
                            [
                                117.842829,
                                39.311971
                            ],
                            [
                                117.83915,
                                39.317241
                            ],
                            [
                                117.844187,
                                39.323667
                            ],
                            [
                                117.84587,
                                39.314793
                            ],
                            [
                                117.849888,
                                39.315524
                            ],
                            [
                                117.850676,
                                39.32229
                            ],
                            [
                                117.845137,
                                39.325757
                            ],
                            [
                                117.846875,
                                39.328494
                            ],
                            [
                                117.849644,
                                39.327372
                            ],
                            [
                                117.855753,
                                39.329225
                            ],
                            [
                                117.855319,
                                39.331995
                            ],
                            [
                                117.861347,
                                39.334187
                            ],
                            [
                                117.867266,
                                39.329853
                            ],
                            [
                                117.878534,
                                39.331808
                            ],
                            [
                                117.888553,
                                39.331893
                            ],
                            [
                                117.893019,
                                39.327372
                            ],
                            [
                                117.892164,
                                39.323072
                            ],
                            [
                                117.902618,
                                39.320471
                            ],
                            [
                                117.90574,
                                39.316136
                            ],
                            [
                                117.910315,
                                39.319519
                            ],
                            [
                                117.933421,
                                39.319043
                            ],
                            [
                                117.939476,
                                39.317598
                            ],
                            [
                                117.947377,
                                39.317785
                            ],
                            [
                                117.951654,
                                39.315405
                            ],
                            [
                                117.966044,
                                39.316425
                            ],
                            [
                                117.972276,
                                39.314351
                            ],
                            [
                                117.97252,
                                39.309965
                            ],
                            [
                                117.977625,
                                39.300665
                            ],
                            [
                                117.982675,
                                39.298676
                            ],
                            [
                                117.983761,
                                39.295513
                            ],
                            [
                                117.992137,
                                39.296669
                            ],
                            [
                                117.995206,
                                39.29354
                            ],
                            [
                                117.995708,
                                39.288796
                            ],
                            [
                                118.006406,
                                39.28951
                            ],
                            [
                                118.016846,
                                39.284119
                            ],
                            [
                                118.022222,
                                39.286959
                            ],
                            [
                                118.024733,
                                39.292435
                            ],
                            [
                                118.028412,
                                39.290411
                            ],
                            [
                                118.031698,
                                39.285394
                            ],
                            [
                                118.028426,
                                39.27769
                            ],
                            [
                                118.035309,
                                39.274679
                            ],
                            [
                                118.033408,
                                39.271566
                            ],
                            [
                                118.036829,
                                39.265034
                            ],
                            [
                                118.063656,
                                39.256834
                            ],
                            [
                                118.064782,
                                39.255932
                            ],
                            [
                                118.065611,
                                39.235427
                            ],
                            [
                                118.064742,
                                39.231206
                            ],
                            [
                                118.037074,
                                39.230526
                            ],
                            [
                                118.037155,
                                39.220364
                            ],
                            [
                                118.032838,
                                39.219887
                            ],
                            [
                                117.977421,
                                39.205944
                            ],
                            [
                                117.973267,
                                39.188797
                            ],
                            [
                                117.964605,
                                39.172684
                            ],
                            [
                                117.925697,
                                39.151405
                            ],
                            [
                                117.871651,
                                39.122586
                            ],
                            [
                                117.859039,
                                39.106509
                            ],
                            [
                                117.84644,
                                39.088416
                            ],
                            [
                                117.840426,
                                39.073782
                            ],
                            [
                                117.837385,
                                39.057012
                            ],
                            [
                                117.839055,
                                39.032865
                            ],
                            [
                                117.847418,
                                39.010724
                            ],
                            [
                                117.850717,
                                38.992914
                            ],
                            [
                                117.852536,
                                38.963022
                            ],
                            [
                                117.855346,
                                38.95764
                            ],
                            [
                                117.897907,
                                38.948618
                            ],
                            [
                                117.899088,
                                38.94156
                            ],
                            [
                                117.892422,
                                38.925066
                            ],
                            [
                                117.875574,
                                38.920365
                            ],
                            [
                                117.859514,
                                38.888988
                            ],
                            [
                                117.861075,
                                38.881514
                            ],
                            [
                                117.859908,
                                38.871695
                            ],
                            [
                                117.847377,
                                38.85563
                            ],
                            [
                                117.834072,
                                38.855254
                            ],
                            [
                                117.821949,
                                38.857238
                            ],
                            [
                                117.778479,
                                38.869078
                            ],
                            [
                                117.768677,
                                38.86398
                            ],
                            [
                                117.752196,
                                38.847502
                            ],
                            [
                                117.689217,
                                38.837097
                            ],
                            [
                                117.657734,
                                38.836172
                            ],
                            [
                                117.646086,
                                38.829103
                            ],
                            [
                                117.646439,
                                38.788849
                            ],
                            [
                                117.654463,
                                38.778299
                            ],
                            [
                                117.671527,
                                38.771995
                            ],
                            [
                                117.729076,
                                38.764782
                            ],
                            [
                                117.73638,
                                38.761373
                            ],
                            [
                                117.741267,
                                38.753936
                            ],
                            [
                                117.740751,
                                38.700026
                            ],
                            [
                                117.738783,
                                38.689102
                            ],
                            [
                                117.729062,
                                38.680062
                            ],
                            [
                                117.656716,
                                38.660488
                            ],
                            [
                                117.649222,
                                38.6545
                            ],
                            [
                                117.642231,
                                38.64326
                            ],
                            [
                                117.639027,
                                38.626714
                            ],
                            [
                                117.582143,
                                38.61856
                            ],
                            [
                                117.557802,
                                38.613804
                            ],
                            [
                                117.55453,
                                38.617015
                            ],
                            [
                                117.546887,
                                38.617856
                            ],
                            [
                                117.542271,
                                38.611796
                            ],
                            [
                                117.538565,
                                38.609735
                            ],
                            [
                                117.541443,
                                38.603571
                            ],
                            [
                                117.529143,
                                38.600772
                            ],
                            [
                                117.524201,
                                38.601253
                            ],
                            [
                                117.526903,
                                38.60661
                            ],
                            [
                                117.523224,
                                38.606353
                            ],
                            [
                                117.523821,
                                38.611624
                            ],
                            [
                                117.516436,
                                38.610353
                            ],
                            [
                                117.511603,
                                38.613942
                            ],
                            [
                                117.505358,
                                38.613993
                            ],
                            [
                                117.502914,
                                38.617908
                            ],
                            [
                                117.495393,
                                38.615521
                            ],
                            [
                                117.478925,
                                38.617238
                            ],
                            [
                                117.481505,
                                38.611538
                            ],
                            [
                                117.476061,
                                38.608894
                            ],
                            [
                                117.471513,
                                38.611246
                            ],
                            [
                                117.432428,
                                38.601321
                            ],
                            [
                                117.415553,
                                38.590623
                            ],
                            [
                                117.410041,
                                38.593937
                            ],
                            [
                                117.401203,
                                38.588012
                            ],
                            [
                                117.409213,
                                38.585144
                            ],
                            [
                                117.396302,
                                38.574563
                            ],
                            [
                                117.369041,
                                38.564753
                            ],
                            [
                                117.368865,
                                38.582464
                            ],
                            [
                                117.354746,
                                38.580747
                            ],
                            [
                                117.358045,
                                38.570543
                            ],
                            [
                                117.350782,
                                38.565819
                            ],
                            [
                                117.350483,
                                38.561523
                            ],
                            [
                                117.346424,
                                38.564925
                            ],
                            [
                                117.32492,
                                38.564616
                            ],
                            [
                                117.315403,
                                38.557692
                            ],
                            [
                                117.308289,
                                38.555578
                            ],
                            [
                                117.305628,
                                38.556609
                            ],
                            [
                                117.30408,
                                38.564616
                            ],
                            [
                                117.299926,
                                38.571471
                            ],
                            [
                                117.291591,
                                38.571677
                            ],
                            [
                                117.292215,
                                38.562417
                            ],
                            [
                                117.278938,
                                38.56008
                            ],
                            [
                                117.265389,
                                38.55673
                            ],
                            [
                                117.263543,
                                38.562279
                            ],
                            [
                                117.253184,
                                38.55618
                            ],
                            [
                                117.246491,
                                38.566867
                            ],
                            [
                                117.245079,
                                38.566935
                            ],
                            [
                                117.239432,
                                38.57872
                            ],
                            [
                                117.24231,
                                38.578737
                            ],
                            [
                                117.262348,
                                38.583735
                            ],
                            [
                                117.261303,
                                38.586724
                            ],
                            [
                                117.238359,
                                38.581039
                            ],
                            [
                                117.237816,
                                38.585264
                            ],
                            [
                                117.245351,
                                38.595311
                            ],
                            [
                                117.256673,
                                38.598351
                            ],
                            [
                                117.255126,
                                38.602455
                            ],
                            [
                                117.259538,
                                38.603056
                            ],
                            [
                                117.25894,
                                38.608327
                            ],
                            [
                                117.255967,
                                38.613804
                            ],
                            [
                                117.246532,
                                38.618663
                            ],
                            [
                                117.237246,
                                38.619212
                            ],
                            [
                                117.235115,
                                38.62395
                            ],
                            [
                                117.230689,
                                38.624002
                            ],
                            [
                                117.236608,
                                38.631211
                            ],
                            [
                                117.225055,
                                38.630782
                            ],
                            [
                                117.229671,
                                38.635657
                            ],
                            [
                                117.230336,
                                38.641698
                            ],
                            [
                                117.225109,
                                38.643861
                            ],
                            [
                                117.216909,
                                38.642917
                            ],
                            [
                                117.212972,
                                38.63969
                            ],
                            [
                                117.210284,
                                38.630044
                            ],
                            [
                                117.203225,
                                38.629684
                            ],
                            [
                                117.203795,
                                38.626439
                            ],
                            [
                                117.200238,
                                38.62189
                            ],
                            [
                                117.189309,
                                38.621358
                            ],
                            [
                                117.186092,
                                38.616534
                            ],
                            [
                                117.183852,
                                38.618182
                            ],
                            [
                                117.176602,
                                38.618234
                            ],
                            [
                                117.167113,
                                38.615126
                            ],
                            [
                                117.165334,
                                38.617238
                            ],
                            [
                                117.15112,
                                38.617719
                            ],
                            [
                                117.156428,
                                38.621204
                            ],
                            [
                                117.157609,
                                38.627607
                            ],
                            [
                                117.156442,
                                38.632739
                            ],
                            [
                                117.163013,
                                38.648391
                            ],
                            [
                                117.162347,
                                38.657966
                            ],
                            [
                                117.157107,
                                38.658772
                            ],
                            [
                                117.16065,
                                38.660505
                            ],
                            [
                                117.161098,
                                38.665687
                            ],
                            [
                                117.15875,
                                38.670027
                            ],
                            [
                                117.153862,
                                38.671554
                            ],
                            [
                                117.153822,
                                38.676683
                            ],
                            [
                                117.148025,
                                38.676134
                            ],
                            [
                                117.14736,
                                38.681623
                            ],
                            [
                                117.149858,
                                38.681812
                            ],
                            [
                                117.148323,
                                38.687764
                            ],
                            [
                                117.149382,
                                38.69164
                            ],
                            [
                                117.154555,
                                38.690251
                            ],
                            [
                                117.158709,
                                38.698054
                            ],
                            [
                                117.159048,
                                38.705702
                            ],
                            [
                                117.157813,
                                38.71508
                            ],
                            [
                                117.160488,
                                38.729566
                            ],
                            [
                                117.17705,
                                38.731246
                            ],
                            [
                                117.176792,
                                38.733268
                            ],
                            [
                                117.160216,
                                38.731366
                            ],
                            [
                                117.155994,
                                38.726652
                            ],
                            [
                                117.146477,
                                38.729069
                            ],
                            [
                                117.146355,
                                38.733011
                            ],
                            [
                                117.141698,
                                38.735736
                            ],
                            [
                                117.137164,
                                38.733714
                            ],
                            [
                                117.137028,
                                38.741152
                            ],
                            [
                                117.134734,
                                38.741512
                            ],
                            [
                                117.15408,
                                38.751092
                            ],
                            [
                                117.155057,
                                38.759471
                            ],
                            [
                                117.161886,
                                38.75834
                            ],
                            [
                                117.164411,
                                38.755873
                            ],
                            [
                                117.193654,
                                38.770744
                            ],
                            [
                                117.215905,
                                38.783043
                            ],
                            [
                                117.21361,
                                38.785287
                            ],
                            [
                                117.195201,
                                38.795922
                            ],
                            [
                                117.204786,
                                38.805187
                            ],
                            [
                                117.196735,
                                38.809656
                            ],
                            [
                                117.207501,
                                38.82027
                            ],
                            [
                                117.223154,
                                38.811607
                            ],
                            [
                                117.247238,
                                38.834803
                            ],
                            [
                                117.256388,
                                38.843121
                            ],
                            [
                                117.258886,
                                38.847365
                            ],
                            [
                                117.30423,
                                38.833417
                            ],
                            [
                                117.312905,
                                38.831072
                            ],
                            [
                                117.326929,
                                38.823728
                            ],
                            [
                                117.331504,
                                38.824687
                            ],
                            [
                                117.335998,
                                38.828744
                            ],
                            [
                                117.33016,
                                38.830182
                            ],
                            [
                                117.331314,
                                38.832732
                            ],
                            [
                                117.327377,
                                38.836737
                            ],
                            [
                                117.339256,
                                38.843532
                            ],
                            [
                                117.362946,
                                38.857529
                            ],
                            [
                                117.387803,
                                38.87279
                            ],
                            [
                                117.407529,
                                38.887278
                            ],
                            [
                                117.437464,
                                38.858607
                            ],
                            [
                                117.440587,
                                38.869745
                            ],
                            [
                                117.448284,
                                38.879444
                            ],
                            [
                                117.464494,
                                38.89075
                            ],
                            [
                                117.486881,
                                38.905473
                            ],
                            [
                                117.503294,
                                38.907765
                            ],
                            [
                                117.497063,
                                38.94279
                            ],
                            [
                                117.511508,
                                38.947456
                            ],
                            [
                                117.512865,
                                38.945183
                            ],
                            [
                                117.52169,
                                38.952035
                            ],
                            [
                                117.529251,
                                38.95318
                            ],
                            [
                                117.543615,
                                38.943047
                            ],
                            [
                                117.550987,
                                38.943029
                            ],
                            [
                                117.548679,
                                38.958716
                            ],
                            [
                                117.550308,
                                38.959109
                            ],
                            [
                                117.549357,
                                38.968164
                            ],
                            [
                                117.555168,
                                38.981317
                            ],
                            [
                                117.556974,
                                38.981591
                            ],
                            [
                                117.560164,
                                38.988884
                            ],
                            [
                                117.558005,
                                38.991975
                            ],
                            [
                                117.549697,
                                38.991497
                            ],
                            [
                                117.53999,
                                39.001111
                            ],
                            [
                                117.531152,
                                39.005841
                            ],
                            [
                                117.526984,
                                39.005994
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120117,
                "name": "宁河区",
                "center": [
                    117.82828,
                    39.328886
                ],
                "cp": [
                    117.62828,
                    39.278886
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 13,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.847078,
                                39.408077
                            ],
                            [
                                117.765555,
                                39.400657
                            ],
                            [
                                117.737289,
                                39.410555
                            ],
                            [
                                117.699738,
                                39.407601
                            ],
                            [
                                117.702331,
                                39.388924
                            ],
                            [
                                117.673197,
                                39.386665
                            ],
                            [
                                117.670808,
                                39.405275
                            ],
                            [
                                117.669097,
                                39.412015
                            ],
                            [
                                117.663748,
                                39.412389
                            ],
                            [
                                117.655006,
                                39.40974
                            ],
                            [
                                117.656295,
                                39.407499
                            ],
                            [
                                117.64348,
                                39.405853
                            ],
                            [
                                117.64185,
                                39.400165
                            ],
                            [
                                117.635823,
                                39.398501
                            ],
                            [
                                117.635103,
                                39.404477
                            ],
                            [
                                117.631668,
                                39.404308
                            ],
                            [
                                117.630976,
                                39.408535
                            ],
                            [
                                117.614128,
                                39.407109
                            ],
                            [
                                117.614305,
                                39.411065
                            ],
                            [
                                117.611305,
                                39.413017
                            ],
                            [
                                117.611087,
                                39.417108
                            ],
                            [
                                117.60138,
                                39.419484
                            ],
                            [
                                117.601788,
                                39.416412
                            ],
                            [
                                117.597145,
                                39.415037
                            ],
                            [
                                117.590642,
                                39.405462
                            ],
                            [
                                117.575518,
                                39.404206
                            ],
                            [
                                117.571133,
                                39.404613
                            ],
                            [
                                117.571486,
                                39.400318
                            ],
                            [
                                117.56869,
                                39.397244
                            ],
                            [
                                117.560327,
                                39.396497
                            ],
                            [
                                117.560924,
                                39.393033
                            ],
                            [
                                117.553688,
                                39.392575
                            ],
                            [
                                117.557883,
                                39.385595
                            ],
                            [
                                117.552874,
                                39.382572
                            ],
                            [
                                117.543981,
                                39.381248
                            ],
                            [
                                117.545135,
                                39.377953
                            ],
                            [
                                117.538931,
                                39.377324
                            ],
                            [
                                117.535333,
                                39.374012
                            ],
                            [
                                117.537261,
                                39.363684
                            ],
                            [
                                117.534166,
                                39.36241
                            ],
                            [
                                117.529428,
                                39.366657
                            ],
                            [
                                117.526183,
                                39.365604
                            ],
                            [
                                117.528545,
                                39.36134
                            ],
                            [
                                117.520875,
                                39.357229
                            ],
                            [
                                117.529279,
                                39.347408
                            ],
                            [
                                117.533636,
                                39.348954
                            ],
                            [
                                117.536229,
                                39.338045
                            ],
                            [
                                117.569749,
                                39.342616
                            ],
                            [
                                117.595285,
                                39.349124
                            ],
                            [
                                117.597511,
                                39.34508
                            ],
                            [
                                117.606947,
                                39.346966
                            ],
                            [
                                117.609227,
                                39.34316
                            ],
                            [
                                117.616667,
                                39.344418
                            ],
                            [
                                117.617332,
                                39.338079
                            ],
                            [
                                117.635307,
                                39.343789
                            ],
                            [
                                117.642108,
                                39.344367
                            ],
                            [
                                117.642163,
                                39.339269
                            ],
                            [
                                117.637547,
                                39.335972
                            ],
                            [
                                117.642556,
                                39.331655
                            ],
                            [
                                117.646466,
                                39.322732
                            ],
                            [
                                117.650811,
                                39.32212
                            ],
                            [
                                117.650811,
                                39.315201
                            ],
                            [
                                117.66383,
                                39.316867
                            ],
                            [
                                117.669315,
                                39.324142
                            ],
                            [
                                117.670944,
                                39.356447
                            ],
                            [
                                117.692489,
                                39.355734
                            ],
                            [
                                117.692339,
                                39.35169
                            ],
                            [
                                117.708169,
                                39.351724
                            ],
                            [
                                117.744607,
                                39.354595
                            ],
                            [
                                117.784575,
                                39.377002
                            ],
                            [
                                117.805291,
                                39.373299
                            ],
                            [
                                117.803377,
                                39.362037
                            ],
                            [
                                117.806771,
                                39.35694
                            ],
                            [
                                117.816451,
                                39.354341
                            ],
                            [
                                117.821012,
                                39.356107
                            ],
                            [
                                117.833204,
                                39.356311
                            ],
                            [
                                117.837032,
                                39.350823
                            ],
                            [
                                117.853228,
                                39.360729
                            ],
                            [
                                117.848096,
                                39.367116
                            ],
                            [
                                117.852074,
                                39.369613
                            ],
                            [
                                117.854749,
                                39.366555
                            ],
                            [
                                117.853242,
                                39.362971
                            ],
                            [
                                117.860668,
                                39.364806
                            ],
                            [
                                117.862935,
                                39.362274
                            ],
                            [
                                117.853337,
                                39.351656
                            ],
                            [
                                117.84997,
                                39.342361
                            ],
                            [
                                117.846155,
                                39.342854
                            ],
                            [
                                117.841933,
                                39.336414
                            ],
                            [
                                117.847974,
                                39.332233
                            ],
                            [
                                117.846875,
                                39.328494
                            ],
                            [
                                117.845137,
                                39.325757
                            ],
                            [
                                117.850676,
                                39.32229
                            ],
                            [
                                117.849888,
                                39.315524
                            ],
                            [
                                117.84587,
                                39.314793
                            ],
                            [
                                117.844187,
                                39.323667
                            ],
                            [
                                117.83915,
                                39.317241
                            ],
                            [
                                117.842829,
                                39.311971
                            ],
                            [
                                117.83767,
                                39.309727
                            ],
                            [
                                117.83691,
                                39.306055
                            ],
                            [
                                117.850744,
                                39.300393
                            ],
                            [
                                117.852658,
                                39.297876
                            ],
                            [
                                117.827325,
                                39.26056
                            ],
                            [
                                117.825995,
                                39.260322
                            ],
                            [
                                117.806092,
                                39.268692
                            ],
                            [
                                117.813736,
                                39.27951
                            ],
                            [
                                117.809093,
                                39.279187
                            ],
                            [
                                117.788702,
                                39.287809
                            ],
                            [
                                117.789923,
                                39.289578
                            ],
                            [
                                117.789693,
                                39.299152
                            ],
                            [
                                117.791308,
                                39.301158
                            ],
                            [
                                117.784575,
                                39.30427
                            ],
                            [
                                117.77404,
                                39.301991
                            ],
                            [
                                117.765772,
                                39.299016
                            ],
                            [
                                117.761061,
                                39.295139
                            ],
                            [
                                117.732076,
                                39.290462
                            ],
                            [
                                117.721691,
                                39.290836
                            ],
                            [
                                117.720632,
                                39.282435
                            ],
                            [
                                117.709282,
                                39.283166
                            ],
                            [
                                117.70897,
                                39.267977
                            ],
                            [
                                117.710436,
                                39.267603
                            ],
                            [
                                117.715826,
                                39.256919
                            ],
                            [
                                117.717998,
                                39.257242
                            ],
                            [
                                117.721541,
                                39.250096
                            ],
                            [
                                117.725438,
                                39.251219
                            ],
                            [
                                117.725478,
                                39.246063
                            ],
                            [
                                117.743249,
                                39.237878
                            ],
                            [
                                117.721134,
                                39.207834
                            ],
                            [
                                117.71588,
                                39.19457
                            ],
                            [
                                117.719668,
                                39.193514
                            ],
                            [
                                117.718785,
                                39.173263
                            ],
                            [
                                117.682728,
                                39.173365
                            ],
                            [
                                117.679985,
                                39.17173
                            ],
                            [
                                117.680094,
                                39.188797
                            ],
                            [
                                117.666572,
                                39.18878
                            ],
                            [
                                117.652562,
                                39.205008
                            ],
                            [
                                117.645747,
                                39.211392
                            ],
                            [
                                117.643656,
                                39.21507
                            ],
                            [
                                117.635157,
                                39.212618
                            ],
                            [
                                117.635117,
                                39.210507
                            ],
                            [
                                117.628383,
                                39.207493
                            ],
                            [
                                117.6174,
                                39.205042
                            ],
                            [
                                117.617807,
                                39.186719
                            ],
                            [
                                117.620658,
                                39.187264
                            ],
                            [
                                117.623238,
                                39.183057
                            ],
                            [
                                117.616368,
                                39.174353
                            ],
                            [
                                117.615242,
                                39.166211
                            ],
                            [
                                117.591823,
                                39.170197
                            ],
                            [
                                117.568798,
                                39.177505
                            ],
                            [
                                117.549833,
                                39.181167
                            ],
                            [
                                117.54743,
                                39.180315
                            ],
                            [
                                117.537166,
                                39.182614
                            ],
                            [
                                117.517739,
                                39.191385
                            ],
                            [
                                117.51368,
                                39.192441
                            ],
                            [
                                117.513734,
                                39.204769
                            ],
                            [
                                117.509906,
                                39.207493
                            ],
                            [
                                117.510693,
                                39.202556
                            ],
                            [
                                117.502249,
                                39.199644
                            ],
                            [
                                117.502792,
                                39.195387
                            ],
                            [
                                117.491361,
                                39.198554
                            ],
                            [
                                117.501828,
                                39.201364
                            ],
                            [
                                117.503634,
                                39.208804
                            ],
                            [
                                117.493004,
                                39.204837
                            ],
                            [
                                117.486704,
                                39.204752
                            ],
                            [
                                117.482985,
                                39.206778
                            ],
                            [
                                117.487275,
                                39.211052
                            ],
                            [
                                117.488008,
                                39.220449
                            ],
                            [
                                117.472803,
                                39.229368
                            ],
                            [
                                117.46577,
                                39.228994
                            ],
                            [
                                117.45681,
                                39.23136
                            ],
                            [
                                117.451285,
                                39.236431
                            ],
                            [
                                117.447755,
                                39.231904
                            ],
                            [
                                117.442026,
                                39.230594
                            ],
                            [
                                117.426101,
                                39.231343
                            ],
                            [
                                117.425355,
                                39.228517
                            ],
                            [
                                117.417508,
                                39.223649
                            ],
                            [
                                117.408181,
                                39.225964
                            ],
                            [
                                117.410258,
                                39.236619
                            ],
                            [
                                117.403144,
                                39.240312
                            ],
                            [
                                117.399913,
                                39.246387
                            ],
                            [
                                117.393913,
                                39.248054
                            ],
                            [
                                117.393994,
                                39.257276
                            ],
                            [
                                117.400402,
                                39.259148
                            ],
                            [
                                117.407108,
                                39.274475
                            ],
                            [
                                117.405452,
                                39.282554
                            ],
                            [
                                117.401515,
                                39.287622
                            ],
                            [
                                117.397239,
                                39.284051
                            ],
                            [
                                117.384056,
                                39.280054
                            ],
                            [
                                117.369041,
                                39.291602
                            ],
                            [
                                117.364629,
                                39.287367
                            ],
                            [
                                117.356199,
                                39.2932
                            ],
                            [
                                117.36243,
                                39.296023
                            ],
                            [
                                117.352167,
                                39.302739
                            ],
                            [
                                117.343139,
                                39.31039
                            ],
                            [
                                117.337722,
                                39.306004
                            ],
                            [
                                117.332495,
                                39.309642
                            ],
                            [
                                117.338305,
                                39.313569
                            ],
                            [
                                117.339079,
                                39.318941
                            ],
                            [
                                117.334708,
                                39.323429
                            ],
                            [
                                117.331952,
                                39.320828
                            ],
                            [
                                117.3242,
                                39.329649
                            ],
                            [
                                117.328015,
                                39.339914
                            ],
                            [
                                117.322775,
                                39.348717
                            ],
                            [
                                117.328504,
                                39.350076
                            ],
                            [
                                117.330214,
                                39.353593
                            ],
                            [
                                117.335088,
                                39.353831
                            ],
                            [
                                117.337708,
                                39.359267
                            ],
                            [
                                117.350483,
                                39.361493
                            ],
                            [
                                117.370589,
                                39.35976
                            ],
                            [
                                117.382427,
                                39.360304
                            ],
                            [
                                117.38445,
                                39.362665
                            ],
                            [
                                117.3895,
                                39.36044
                            ],
                            [
                                117.389487,
                                39.354408
                            ],
                            [
                                117.392555,
                                39.352658
                            ],
                            [
                                117.415716,
                                39.350535
                            ],
                            [
                                117.415186,
                                39.346525
                            ],
                            [
                                117.433894,
                                39.347119
                            ],
                            [
                                117.436514,
                                39.352319
                            ],
                            [
                                117.43312,
                                39.355377
                            ],
                            [
                                117.432903,
                                39.360049
                            ],
                            [
                                117.43855,
                                39.35784
                            ],
                            [
                                117.441727,
                                39.361646
                            ],
                            [
                                117.448855,
                                39.35886
                            ],
                            [
                                117.454801,
                                39.361969
                            ],
                            [
                                117.46163,
                                39.362444
                            ],
                            [
                                117.46539,
                                39.35959
                            ],
                            [
                                117.47321,
                                39.362071
                            ],
                            [
                                117.47135,
                                39.36523
                            ],
                            [
                                117.477418,
                                39.366572
                            ],
                            [
                                117.486093,
                                39.372297
                            ],
                            [
                                117.483867,
                                39.375065
                            ],
                            [
                                117.485645,
                                39.379532
                            ],
                            [
                                117.508032,
                                39.383608
                            ],
                            [
                                117.505874,
                                39.386139
                            ],
                            [
                                117.501081,
                                39.384865
                            ],
                            [
                                117.497619,
                                39.392337
                            ],
                            [
                                117.497891,
                                39.395513
                            ],
                            [
                                117.494986,
                                39.40497
                            ],
                            [
                                117.496995,
                                39.405394
                            ],
                            [
                                117.494986,
                                39.414647
                            ],
                            [
                                117.491225,
                                39.413679
                            ],
                            [
                                117.483256,
                                39.445654
                            ],
                            [
                                117.483392,
                                39.449064
                            ],
                            [
                                117.489148,
                                39.448623
                            ],
                            [
                                117.492461,
                                39.451847
                            ],
                            [
                                117.499344,
                                39.454426
                            ],
                            [
                                117.496995,
                                39.459397
                            ],
                            [
                                117.502928,
                                39.462009
                            ],
                            [
                                117.509512,
                                39.456326
                            ],
                            [
                                117.519287,
                                39.45921
                            ],
                            [
                                117.517983,
                                39.46318
                            ],
                            [
                                117.523156,
                                39.463638
                            ],
                            [
                                117.524378,
                                39.466403
                            ],
                            [
                                117.541334,
                                39.464384
                            ],
                            [
                                117.55138,
                                39.46776
                            ],
                            [
                                117.557055,
                                39.471627
                            ],
                            [
                                117.564943,
                                39.480769
                            ],
                            [
                                117.562227,
                                39.485704
                            ],
                            [
                                117.5581,
                                39.486585
                            ],
                            [
                                117.555887,
                                39.494555
                            ],
                            [
                                117.567223,
                                39.497692
                            ],
                            [
                                117.567549,
                                39.493962
                            ],
                            [
                                117.572396,
                                39.494928
                            ],
                            [
                                117.572857,
                                39.49096
                            ],
                            [
                                117.577405,
                                39.491774
                            ],
                            [
                                117.580188,
                                39.485093
                            ],
                            [
                                117.59667,
                                39.48745
                            ],
                            [
                                117.607503,
                                39.48974
                            ],
                            [
                                117.605752,
                                39.494199
                            ],
                            [
                                117.608902,
                                39.499744
                            ],
                            [
                                117.610626,
                                39.511899
                            ],
                            [
                                117.63065,
                                39.516001
                            ],
                            [
                                117.637099,
                                39.522069
                            ],
                            [
                                117.633365,
                                39.534797
                            ],
                            [
                                117.634153,
                                39.53717
                            ],
                            [
                                117.658291,
                                39.531137
                            ],
                            [
                                117.659811,
                                39.527612
                            ],
                            [
                                117.667672,
                                39.530255
                            ],
                            [
                                117.668446,
                                39.53234
                            ],
                            [
                                117.676646,
                                39.533187
                            ],
                            [
                                117.681112,
                                39.536593
                            ],
                            [
                                117.687941,
                                39.534899
                            ],
                            [
                                117.690751,
                                39.537542
                            ],
                            [
                                117.690398,
                                39.547455
                            ],
                            [
                                117.69462,
                                39.551097
                            ],
                            [
                                117.700879,
                                39.551741
                            ],
                            [
                                117.708712,
                                39.548217
                            ],
                            [
                                117.707192,
                                39.543405
                            ],
                            [
                                117.708848,
                                39.538085
                            ],
                            [
                                117.715948,
                                39.530086
                            ],
                            [
                                117.723496,
                                39.535119
                            ],
                            [
                                117.732945,
                                39.536102
                            ],
                            [
                                117.738036,
                                39.540423
                            ],
                            [
                                117.744878,
                                39.548624
                            ],
                            [
                                117.736081,
                                39.560822
                            ],
                            [
                                117.735783,
                                39.567631
                            ],
                            [
                                117.737737,
                                39.57405
                            ],
                            [
                                117.748177,
                                39.574423
                            ],
                            [
                                117.753133,
                                39.576015
                            ],
                            [
                                117.755155,
                                39.58113
                            ],
                            [
                                117.747879,
                                39.58941
                            ],
                            [
                                117.767713,
                                39.59901
                            ],
                            [
                                117.775424,
                                39.599129
                            ],
                            [
                                117.78934,
                                39.597199
                            ],
                            [
                                117.793928,
                                39.601059
                            ],
                            [
                                117.801952,
                                39.60177
                            ],
                            [
                                117.817374,
                                39.595489
                            ],
                            [
                                117.826565,
                                39.590816
                            ],
                            [
                                117.839354,
                                39.58963
                            ],
                            [
                                117.847051,
                                39.591137
                            ],
                            [
                                117.851395,
                                39.589258
                            ],
                            [
                                117.8535,
                                39.592306
                            ],
                            [
                                117.857342,
                                39.592035
                            ],
                            [
                                117.86827,
                                39.596843
                            ],
                            [
                                117.8841,
                                39.590121
                            ],
                            [
                                117.892585,
                                39.59151
                            ],
                            [
                                117.899821,
                                39.589512
                            ],
                            [
                                117.914849,
                                39.582942
                            ],
                            [
                                117.930068,
                                39.578149
                            ],
                            [
                                117.933992,
                                39.574152
                            ],
                            [
                                117.931127,
                                39.568173
                            ],
                            [
                                117.924787,
                                39.563312
                            ],
                            [
                                117.917931,
                                39.554621
                            ],
                            [
                                117.917117,
                                39.548319
                            ],
                            [
                                117.913302,
                                39.542151
                            ],
                            [
                                117.909813,
                                39.541965
                            ],
                            [
                                117.909813,
                                39.537983
                            ],
                            [
                                117.905387,
                                39.535102
                            ],
                            [
                                117.905292,
                                39.53012
                            ],
                            [
                                117.91041,
                                39.528001
                            ],
                            [
                                117.909446,
                                39.523171
                            ],
                            [
                                117.912691,
                                39.521425
                            ],
                            [
                                117.912284,
                                39.516154
                            ],
                            [
                                117.909053,
                                39.512001
                            ],
                            [
                                117.898585,
                                39.509695
                            ],
                            [
                                117.897038,
                                39.489299
                            ],
                            [
                                117.892395,
                                39.487043
                            ],
                            [
                                117.896522,
                                39.485721
                            ],
                            [
                                117.899726,
                                39.476885
                            ],
                            [
                                117.898192,
                                39.472509
                            ],
                            [
                                117.888322,
                                39.469167
                            ],
                            [
                                117.886313,
                                39.466776
                            ],
                            [
                                117.878072,
                                39.4672
                            ],
                            [
                                117.874081,
                                39.454918
                            ],
                            [
                                117.870334,
                                39.454952
                            ],
                            [
                                117.871854,
                                39.411557
                            ],
                            [
                                117.847078,
                                39.408077
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                117.805291,
                                39.373299
                            ],
                            [
                                117.784575,
                                39.377002
                            ],
                            [
                                117.782362,
                                39.39463
                            ],
                            [
                                117.765555,
                                39.400657
                            ],
                            [
                                117.847078,
                                39.408077
                            ],
                            [
                                117.848137,
                                39.401948
                            ],
                            [
                                117.859378,
                                39.403289
                            ],
                            [
                                117.862501,
                                39.39716
                            ],
                            [
                                117.860491,
                                39.384661
                            ],
                            [
                                117.865704,
                                39.378276
                            ],
                            [
                                117.858034,
                                39.373486
                            ],
                            [
                                117.8524,
                                39.380772
                            ],
                            [
                                117.849305,
                                39.37904
                            ],
                            [
                                117.838973,
                                39.37729
                            ],
                            [
                                117.820999,
                                39.376917
                            ],
                            [
                                117.805291,
                                39.373299
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120118,
                "name": "静海区",
                "center": [
                    116.925304,
                    38.935671
                ],
                "centroid": [
                    116.966349,
                    38.85579
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 14,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.258886,
                                38.847365
                            ],
                            [
                                117.256388,
                                38.843121
                            ],
                            [
                                117.247238,
                                38.834803
                            ],
                            [
                                117.223154,
                                38.811607
                            ],
                            [
                                117.207501,
                                38.82027
                            ],
                            [
                                117.196735,
                                38.809656
                            ],
                            [
                                117.204786,
                                38.805187
                            ],
                            [
                                117.195201,
                                38.795922
                            ],
                            [
                                117.21361,
                                38.785287
                            ],
                            [
                                117.215905,
                                38.783043
                            ],
                            [
                                117.193654,
                                38.770744
                            ],
                            [
                                117.164411,
                                38.755873
                            ],
                            [
                                117.161886,
                                38.75834
                            ],
                            [
                                117.155057,
                                38.759471
                            ],
                            [
                                117.15408,
                                38.751092
                            ],
                            [
                                117.134734,
                                38.741512
                            ],
                            [
                                117.137028,
                                38.741152
                            ],
                            [
                                117.137164,
                                38.733714
                            ],
                            [
                                117.141698,
                                38.735736
                            ],
                            [
                                117.146355,
                                38.733011
                            ],
                            [
                                117.146477,
                                38.729069
                            ],
                            [
                                117.155994,
                                38.726652
                            ],
                            [
                                117.160216,
                                38.731366
                            ],
                            [
                                117.176792,
                                38.733268
                            ],
                            [
                                117.17705,
                                38.731246
                            ],
                            [
                                117.160488,
                                38.729566
                            ],
                            [
                                117.157813,
                                38.71508
                            ],
                            [
                                117.159048,
                                38.705702
                            ],
                            [
                                117.158709,
                                38.698054
                            ],
                            [
                                117.154555,
                                38.690251
                            ],
                            [
                                117.149382,
                                38.69164
                            ],
                            [
                                117.148323,
                                38.687764
                            ],
                            [
                                117.149858,
                                38.681812
                            ],
                            [
                                117.14736,
                                38.681623
                            ],
                            [
                                117.148025,
                                38.676134
                            ],
                            [
                                117.153822,
                                38.676683
                            ],
                            [
                                117.153862,
                                38.671554
                            ],
                            [
                                117.15875,
                                38.670027
                            ],
                            [
                                117.161098,
                                38.665687
                            ],
                            [
                                117.16065,
                                38.660505
                            ],
                            [
                                117.157107,
                                38.658772
                            ],
                            [
                                117.162347,
                                38.657966
                            ],
                            [
                                117.163013,
                                38.648391
                            ],
                            [
                                117.156442,
                                38.632739
                            ],
                            [
                                117.157609,
                                38.627607
                            ],
                            [
                                117.156428,
                                38.621204
                            ],
                            [
                                117.15112,
                                38.617719
                            ],
                            [
                                117.150835,
                                38.609323
                            ],
                            [
                                117.147577,
                                38.606627
                            ],
                            [
                                117.138264,
                                38.603932
                            ],
                            [
                                117.136431,
                                38.598728
                            ],
                            [
                                117.124606,
                                38.599931
                            ],
                            [
                                117.12166,
                                38.597698
                            ],
                            [
                                117.119542,
                                38.589661
                            ],
                            [
                                117.109483,
                                38.589111
                            ],
                            [
                                117.109849,
                                38.584869
                            ],
                            [
                                117.098201,
                                38.586896
                            ],
                            [
                                117.097468,
                                38.590708
                            ],
                            [
                                117.093001,
                                38.594435
                            ],
                            [
                                117.097074,
                                38.595517
                            ],
                            [
                                117.096871,
                                38.600411
                            ],
                            [
                                117.087232,
                                38.599862
                            ],
                            [
                                117.086336,
                                38.60637
                            ],
                            [
                                117.071551,
                                38.607383
                            ],
                            [
                                117.070085,
                                38.616757
                            ],
                            [
                                117.064261,
                                38.635708
                            ],
                            [
                                117.060501,
                                38.635708
                            ],
                            [
                                117.060759,
                                38.639107
                            ],
                            [
                                117.055586,
                                38.639261
                            ],
                            [
                                117.051717,
                                38.6435
                            ],
                            [
                                117.056319,
                                38.644976
                            ],
                            [
                                117.056048,
                                38.654191
                            ],
                            [
                                117.06198,
                                38.674385
                            ],
                            [
                                117.068157,
                                38.680646
                            ],
                            [
                                117.063257,
                                38.68044
                            ],
                            [
                                117.063379,
                                38.682961
                            ],
                            [
                                117.054351,
                                38.684265
                            ],
                            [
                                117.052871,
                                38.686718
                            ],
                            [
                                117.04459,
                                38.68622
                            ],
                            [
                                117.045377,
                                38.692549
                            ],
                            [
                                117.039064,
                                38.688467
                            ],
                            [
                                117.039281,
                                38.694435
                            ],
                            [
                                117.041644,
                                38.701603
                            ],
                            [
                                117.045268,
                                38.70577
                            ],
                            [
                                117.042309,
                                38.70649
                            ],
                            [
                                117.015741,
                                38.700403
                            ],
                            [
                                117.014464,
                                38.689942
                            ],
                            [
                                117.002938,
                                38.690422
                            ],
                            [
                                116.992838,
                                38.692274
                            ],
                            [
                                116.994454,
                                38.695721
                            ],
                            [
                                116.951173,
                                38.689582
                            ],
                            [
                                116.947209,
                                38.689616
                            ],
                            [
                                116.945594,
                                38.685877
                            ],
                            [
                                116.930823,
                                38.685757
                            ],
                            [
                                116.926805,
                                38.687438
                            ],
                            [
                                116.92732,
                                38.680062
                            ],
                            [
                                116.917722,
                                38.679616
                            ],
                            [
                                116.921225,
                                38.686923
                            ],
                            [
                                116.910364,
                                38.685568
                            ],
                            [
                                116.903291,
                                38.681915
                            ],
                            [
                                116.896069,
                                38.680285
                            ],
                            [
                                116.877293,
                                38.680697
                            ],
                            [
                                116.875596,
                                38.682687
                            ],
                            [
                                116.875501,
                                38.693166
                            ],
                            [
                                116.871903,
                                38.701878
                            ],
                            [
                                116.875162,
                                38.712629
                            ],
                            [
                                116.87284,
                                38.716143
                            ],
                            [
                                116.866487,
                                38.717
                            ],
                            [
                                116.870138,
                                38.729892
                            ],
                            [
                                116.86745,
                                38.745882
                            ],
                            [
                                116.86035,
                                38.744974
                            ],
                            [
                                116.859115,
                                38.741307
                            ],
                            [
                                116.812916,
                                38.744323
                            ],
                            [
                                116.796543,
                                38.746688
                            ],
                            [
                                116.791221,
                                38.74506
                            ],
                            [
                                116.791221,
                                38.740055
                            ],
                            [
                                116.78404,
                                38.738342
                            ],
                            [
                                116.784515,
                                38.740518
                            ],
                            [
                                116.778867,
                                38.740621
                            ],
                            [
                                116.775025,
                                38.742798
                            ],
                            [
                                116.76635,
                                38.741941
                            ],
                            [
                                116.762807,
                                38.750544
                            ],
                            [
                                116.758938,
                                38.754879
                            ],
                            [
                                116.746299,
                                38.754245
                            ],
                            [
                                116.747018,
                                38.76288
                            ],
                            [
                                116.751308,
                                38.7802
                            ],
                            [
                                116.742321,
                                38.780834
                            ],
                            [
                                116.742769,
                                38.78402
                            ],
                            [
                                116.737338,
                                38.784482
                            ],
                            [
                                116.741425,
                                38.796214
                            ],
                            [
                                116.745769,
                                38.795751
                            ],
                            [
                                116.746095,
                                38.798594
                            ],
                            [
                                116.74076,
                                38.799228
                            ],
                            [
                                116.744018,
                                38.805324
                            ],
                            [
                                116.738479,
                                38.807053
                            ],
                            [
                                116.745253,
                                38.816744
                            ],
                            [
                                116.746244,
                                38.824636
                            ],
                            [
                                116.749082,
                                38.82443
                            ],
                            [
                                116.751199,
                                38.831277
                            ],
                            [
                                116.74376,
                                38.831568
                            ],
                            [
                                116.743081,
                                38.834923
                            ],
                            [
                                116.746027,
                                38.851455
                            ],
                            [
                                116.723124,
                                38.852533
                            ],
                            [
                                116.722337,
                                38.89706
                            ],
                            [
                                116.708028,
                                38.897026
                            ],
                            [
                                116.708136,
                                38.931852
                            ],
                            [
                                116.709996,
                                38.931937
                            ],
                            [
                                116.710186,
                                38.93903
                            ],
                            [
                                116.716228,
                                38.938962
                            ],
                            [
                                116.718359,
                                38.942978
                            ],
                            [
                                116.722622,
                                38.956529
                            ],
                            [
                                116.723043,
                                38.966592
                            ],
                            [
                                116.725364,
                                38.966456
                            ],
                            [
                                116.728609,
                                38.975356
                            ],
                            [
                                116.734895,
                                38.977867
                            ],
                            [
                                116.735329,
                                38.980617
                            ],
                            [
                                116.740366,
                                38.980839
                            ],
                            [
                                116.745457,
                                38.991411
                            ],
                            [
                                116.750521,
                                38.994007
                            ],
                            [
                                116.752394,
                                39.003467
                            ],
                            [
                                116.754607,
                                39.003228
                            ],
                            [
                                116.756331,
                                39.01308
                            ],
                            [
                                116.75496,
                                39.019123
                            ],
                            [
                                116.759087,
                                39.021189
                            ],
                            [
                                116.759359,
                                39.031636
                            ],
                            [
                                116.751715,
                                39.034503
                            ],
                            [
                                116.751742,
                                39.038958
                            ],
                            [
                                116.756616,
                                39.050306
                            ],
                            [
                                116.760743,
                                39.050562
                            ],
                            [
                                116.773016,
                                39.046876
                            ],
                            [
                                116.774835,
                                39.050425
                            ],
                            [
                                116.77884,
                                39.049419
                            ],
                            [
                                116.781379,
                                39.054342
                            ],
                            [
                                116.781596,
                                39.058462
                            ],
                            [
                                116.786809,
                                39.059332
                            ],
                            [
                                116.787325,
                                39.061925
                            ],
                            [
                                116.797181,
                                39.060253
                            ],
                            [
                                116.796136,
                                39.056329
                            ],
                            [
                                116.785017,
                                39.055698
                            ],
                            [
                                116.783347,
                                39.050187
                            ],
                            [
                                116.7878,
                                39.052678
                            ],
                            [
                                116.797073,
                                39.054418
                            ],
                            [
                                116.802679,
                                39.050903
                            ],
                            [
                                116.818984,
                                39.050937
                            ],
                            [
                                116.821984,
                                39.046757
                            ],
                            [
                                116.831474,
                                39.049521
                            ],
                            [
                                116.842145,
                                39.049385
                            ],
                            [
                                116.860445,
                                39.050562
                            ],
                            [
                                116.860309,
                                39.053531
                            ],
                            [
                                116.871645,
                                39.054674
                            ],
                            [
                                116.869921,
                                39.059622
                            ],
                            [
                                116.873722,
                                39.060544
                            ],
                            [
                                116.86988,
                                39.06991
                            ],
                            [
                                116.877809,
                                39.072588
                            ],
                            [
                                116.881474,
                                39.071718
                            ],
                            [
                                116.898186,
                                39.066668
                            ],
                            [
                                116.897182,
                                39.062761
                            ],
                            [
                                116.886769,
                                39.0594
                            ],
                            [
                                116.888995,
                                39.055067
                            ],
                            [
                                116.90101,
                                39.061328
                            ],
                            [
                                116.905002,
                                39.057421
                            ],
                            [
                                116.908667,
                                39.061175
                            ],
                            [
                                116.921659,
                                39.060117
                            ],
                            [
                                116.934706,
                                39.054435
                            ],
                            [
                                116.940924,
                                39.050101
                            ],
                            [
                                116.94558,
                                39.057046
                            ],
                            [
                                116.948974,
                                39.054282
                            ],
                            [
                                116.962387,
                                39.050767
                            ],
                            [
                                116.960581,
                                39.046688
                            ],
                            [
                                116.968388,
                                39.045494
                            ],
                            [
                                116.970315,
                                39.038838
                            ],
                            [
                                116.990218,
                                39.036636
                            ],
                            [
                                117.015795,
                                39.030304
                            ],
                            [
                                117.038209,
                                39.021206
                            ],
                            [
                                117.033335,
                                39.01559
                            ],
                            [
                                117.040517,
                                39.012738
                            ],
                            [
                                117.037571,
                                39.010468
                            ],
                            [
                                117.035317,
                                38.997388
                            ],
                            [
                                117.057147,
                                38.995868
                            ],
                            [
                                117.057975,
                                39.000872
                            ],
                            [
                                117.063066,
                                39.005021
                            ],
                            [
                                117.078611,
                                38.993973
                            ],
                            [
                                117.108655,
                                38.981813
                            ],
                            [
                                117.122529,
                                38.975031
                            ],
                            [
                                117.13696,
                                38.963603
                            ],
                            [
                                117.158736,
                                38.936176
                            ],
                            [
                                117.168077,
                                38.925203
                            ],
                            [
                                117.179005,
                                38.910363
                            ],
                            [
                                117.193966,
                                38.894854
                            ],
                            [
                                117.248799,
                                38.851729
                            ],
                            [
                                117.258886,
                                38.847365
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "adcode": 120119,
                "name": "蓟州区",
                "center": [
                    117.407449,
                    40.045342
                ],
                "centroid": [
                    117.434984,
                    40.003468
                ],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 120000
                },
                "subFeatureIndex": 15,
                "acroutes": [
                    100000,
                    120000
                ]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                117.539162,
                                39.76044
                            ],
                            [
                                117.531912,
                                39.761386
                            ],
                            [
                                117.533053,
                                39.766251
                            ],
                            [
                                117.529346,
                                39.765812
                            ],
                            [
                                117.52378,
                                39.768598
                            ],
                            [
                                117.519938,
                                39.764663
                            ],
                            [
                                117.516151,
                                39.769071
                            ],
                            [
                                117.511576,
                                39.765727
                            ],
                            [
                                117.50719,
                                39.767197
                            ],
                            [
                                117.503525,
                                39.773884
                            ],
                            [
                                117.492257,
                                39.771419
                            ],
                            [
                                117.489881,
                                39.77863
                            ],
                            [
                                117.483378,
                                39.774948
                            ],
                            [
                                117.47021,
                                39.777802
                            ],
                            [
                                117.47302,
                                39.774357
                            ],
                            [
                                117.464141,
                                39.768717
                            ],
                            [
                                117.460394,
                                39.769122
                            ],
                            [
                                117.463544,
                                39.775354
                            ],
                            [
                                117.457272,
                                39.772922
                            ],
                            [
                                117.454597,
                                39.768345
                            ],
                            [
                                117.449289,
                                39.768885
                            ],
                            [
                                117.439935,
                                39.764444
                            ],
                            [
                                117.437193,
                                39.755542
                            ],
                            [
                                117.431233,
                                39.755897
                            ],
                            [
                                117.426753,
                                39.758295
                            ],
                            [
                                117.415648,
                                39.760288
                            ],
                            [
                                117.412322,
                                39.76267
                            ],
                            [
                                117.412417,
                                39.768176
                            ],
                            [
                                117.407299,
                                39.772246
                            ],
                            [
                                117.399099,
                                39.770861
                            ],
                            [
                                117.392514,
                                39.777177
                            ],
                            [
                                117.392786,
                                39.782125
                            ],
                            [
                                117.388795,
                                39.782361
                            ],
                            [
                                117.389908,
                                39.785485
                            ],
                            [
                                117.382875,
                                39.787545
                            ],
                            [
                                117.375544,
                                39.78763
                            ],
                            [
                                117.374757,
                                39.790601
                            ],
                            [
                                117.366258,
                                39.790854
                            ],
                            [
                                117.366204,
                                39.794062
                            ],
                            [
                                117.356185,
                                39.792847
                            ],
                            [
                                117.349126,
                                39.794721
                            ],
                            [
                                117.34565,
                                39.797557
                            ],
                            [
                                117.342799,
                                39.795447
                            ],
                            [
                                117.340125,
                                39.7983
                            ],
                            [
                                117.334151,
                                39.796763
                            ],
                            [
                                117.329305,
                                39.79158
                            ],
                            [
                                117.322897,
                                39.794028
                            ],
                            [
                                117.319191,
                                39.800477
                            ],
                            [
                                117.319625,
                                39.804596
                            ],
                            [
                                117.313557,
                                39.807162
                            ],
                            [
                                117.306063,
                                39.808073
                            ],
                            [
                                117.301827,
                                39.805187
                            ],
                            [
                                117.295921,
                                39.810977
                            ],
                            [
                                117.289609,
                                39.813762
                            ],
                            [
                                117.285631,
                                39.813103
                            ],
                            [
                                117.286147,
                                39.819432
                            ],
                            [
                                117.27796,
                                39.825373
                            ],
                            [
                                117.273345,
                                39.824866
                            ],
                            [
                                117.268824,
                                39.828342
                            ],
                            [
                                117.26619,
                                39.826756
                            ],
                            [
                                117.25723,
                                39.829794
                            ],
                            [
                                117.252234,
                                39.834603
                            ],
                            [
                                117.25556,
                                39.842667
                            ],
                            [
                                117.259959,
                                39.843393
                            ],
                            [
                                117.247455,
                                39.861054
                            ],
                            [
                                117.239798,
                                39.860447
                            ],
                            [
                                117.236893,
                                39.855792
                            ],
                            [
                                117.230852,
                                39.856905
                            ],
                            [
                                117.227797,
                                39.852772
                            ],
                            [
                                117.220045,
                                39.856551
                            ],
                            [
                                117.213189,
                                39.856264
                            ],
                            [
                                117.211615,
                                39.859519
                            ],
                            [
                                117.199125,
                                39.862302
                            ],
                            [
                                117.192377,
                                39.862117
                            ],
                            [
                                117.185739,
                                39.866434
                            ],
                            [
                                117.180105,
                                39.86527
                            ],
                            [
                                117.181042,
                                39.869217
                            ],
                            [
                                117.177851,
                                39.870886
                            ],
                            [
                                117.175584,
                                39.866839
                            ],
                            [
                                117.168429,
                                39.866687
                            ],
                            [
                                117.166841,
                                39.868947
                            ],
                            [
                                117.173833,
                                39.873062
                            ],
                            [
                                117.17052,
                                39.877513
                            ],
                            [
                                117.168158,
                                39.873028
                            ],
                            [
                                117.164642,
                                39.872809
                            ],
                            [
                                117.16285,
                                39.876586
                            ],
                            [
                                117.157759,
                                39.878744
                            ],
                            [
                                117.153672,
                                39.875625
                            ],
                            [
                                117.152152,
                                39.877463
                            ],
                            [
                                117.15841,
                                39.889316
                            ],
                            [
                                117.149124,
                                39.896279
                            ],
                            [
                                117.150564,
                                39.902061
                            ],
                            [
                                117.157582,
                                39.904589
                            ],
                            [
                                117.158831,
                                39.909207
                            ],
                            [
                                117.144767,
                                39.911567
                            ],
                            [
                                117.142513,
                                39.918797
                            ],
                            [
                                117.136716,
                                39.919639
                            ],
                            [
                                117.137422,
                                39.921645
                            ],
                            [
                                117.149871,
                                39.92424
                            ],
                            [
                                117.146396,
                                39.933136
                            ],
                            [
                                117.150726,
                                39.936034
                            ],
                            [
                                117.155044,
                                39.934147
                            ],
                            [
                                117.156863,
                                39.938039
                            ],
                            [
                                117.146952,
                                39.947271
                            ],
                            [
                                117.151039,
                                39.944862
                            ],
                            [
                                117.158641,
                                39.950774
                            ],
                            [
                                117.16452,
                                39.952071
                            ],
                            [
                                117.166407,
                                39.955373
                            ],
                            [
                                117.164873,
                                39.963137
                            ],
                            [
                                117.172407,
                                39.96307
                            ],
                            [
                                117.175937,
                                39.959112
                            ],
                            [
                                117.17967,
                                39.965596
                            ],
                            [
                                117.178625,
                                39.977282
                            ],
                            [
                                117.188115,
                                39.978797
                            ],
                            [
                                117.191142,
                                39.981828
                            ],
                            [
                                117.190898,
                                39.986475
                            ],
                            [
                                117.19812,
                                39.992737
                            ],
                            [
                                117.195744,
                                40.003948
                            ],
                            [
                                117.197672,
                                40.010596
                            ],
                            [
                                117.193219,
                                40.016772
                            ],
                            [
                                117.194088,
                                40.021568
                            ],
                            [
                                117.187802,
                                40.025858
                            ],
                            [
                                117.188318,
                                40.032555
                            ],
                            [
                                117.186811,
                                40.041875
                            ],
                            [
                                117.188712,
                                40.047779
                            ],
                            [
                                117.183404,
                                40.053026
                            ],
                            [
                                117.181775,
                                40.059014
                            ],
                            [
                                117.184069,
                                40.062579
                            ],
                            [
                                117.192025,
                                40.061486
                            ],
                            [
                                117.192364,
                                40.066279
                            ],
                            [
                                117.202532,
                                40.064967
                            ],
                            [
                                117.209307,
                                40.065791
                            ],
                            [
                                117.217873,
                                40.064395
                            ],
                            [
                                117.22291,
                                40.066648
                            ],
                            [
                                117.219733,
                                40.071121
                            ],
                            [
                                117.215117,
                                40.073677
                            ],
                            [
                                117.213027,
                                40.07941
                            ],
                            [
                                117.209782,
                                40.082235
                            ],
                            [
                                117.215117,
                                40.085093
                            ],
                            [
                                117.211031,
                                40.090775
                            ],
                            [
                                117.211262,
                                40.096607
                            ],
                            [
                                117.22443,
                                40.094624
                            ],
                            [
                                117.22439,
                                40.098557
                            ],
                            [
                                117.228612,
                                40.100255
                            ],
                            [
                                117.229019,
                                40.103465
                            ],
                            [
                                117.235997,
                                40.108389
                            ],
                            [
                                117.238224,
                                40.11175
                            ],
                            [
                                117.245364,
                                40.113229
                            ],
                            [
                                117.249261,
                                40.116489
                            ],
                            [
                                117.255533,
                                40.113279
                            ],
                            [
                                117.260393,
                                40.114153
                            ],
                            [
                                117.266828,
                                40.11217
                            ],
                            [
                                117.269923,
                                40.107196
                            ],
                            [
                                117.274363,
                                40.105801
                            ],
                            [
                                117.276657,
                                40.109313
                            ],
                            [
                                117.275422,
                                40.113464
                            ],
                            [
                                117.285414,
                                40.121328
                            ],
                            [
                                117.297102,
                                40.118858
                            ],
                            [
                                117.297062,
                                40.121261
                            ],
                            [
                                117.302994,
                                40.125931
                            ],
                            [
                                117.30761,
                                40.136969
                            ],
                            [
                                117.31376,
                                40.139959
                            ],
                            [
                                117.318566,
                                40.138514
                            ],
                            [
                                117.323277,
                                40.140715
                            ],
                            [
                                117.330934,
                                40.135759
                            ],
                            [
                                117.330608,
                                40.133693
                            ],
                            [
                                117.349071,
                                40.136515
                            ],
                            [
                                117.351393,
                                40.139925
                            ],
                            [
                                117.356348,
                                40.140916
                            ],
                            [
                                117.356891,
                                40.145048
                            ],
                            [
                                117.350524,
                                40.144813
                            ],
                            [
                                117.355275,
                                40.148592
                            ],
                            [
                                117.351719,
                                40.150557
                            ],
                            [
                                117.360624,
                                40.156973
                            ],
                            [
                                117.357339,
                                40.16426
                            ],
                            [
                                117.351121,
                                40.171665
                            ],
                            [
                                117.353741,
                                40.17368
                            ],
                            [
                                117.362878,
                                40.172152
                            ],
                            [
                                117.364018,
                                40.176685
                            ],
                            [
                                117.368838,
                                40.172991
                            ],
                            [
                                117.372028,
                                40.176534
                            ],
                            [
                                117.377024,
                                40.176316
                            ],
                            [
                                117.381219,
                                40.172454
                            ],
                            [
                                117.380595,
                                40.176903
                            ],
                            [
                                117.393193,
                                40.174905
                            ],
                            [
                                117.391618,
                                40.177608
                            ],
                            [
                                117.40123,
                                40.183618
                            ],
                            [
                                117.404746,
                                40.183232
                            ],
                            [
                                117.407692,
                                40.187496
                            ],
                            [
                                117.397714,
                                40.192917
                            ],
                            [
                                117.388346,
                                40.188251
                            ],
                            [
                                117.384097,
                                40.187832
                            ],
                            [
                                117.384382,
                                40.195267
                            ],
                            [
                                117.381545,
                                40.194797
                            ],
                            [
                                117.379563,
                                40.198926
                            ],
                            [
                                117.382346,
                                40.204565
                            ],
                            [
                                117.383446,
                                40.20235
                            ],
                            [
                                117.393152,
                                40.20339
                            ],
                            [
                                117.385686,
                                40.207888
                            ],
                            [
                                117.378436,
                                40.210304
                            ],
                            [
                                117.377594,
                                40.21861
                            ],
                            [
                                117.393723,
                                40.221663
                            ],
                            [
                                117.390016,
                                40.227971
                            ],
                            [
                                117.397551,
                                40.235168
                            ],
                            [
                                117.403009,
                                40.233423
                            ],
                            [
                                117.405357,
                                40.230185
                            ],
                            [
                                117.411561,
                                40.235604
                            ],
                            [
                                117.415363,
                                40.236862
                            ],
                            [
                                117.412647,
                                40.24352
                            ],
                            [
                                117.41615,
                                40.248921
                            ],
                            [
                                117.426739,
                                40.248082
                            ],
                            [
                                117.42997,
                                40.246539
                            ],
                            [
                                117.434179,
                                40.248116
                            ],
                            [
                                117.438849,
                                40.252996
                            ],
                            [
                                117.45028,
                                40.252476
                            ],
                            [
                                117.458589,
                                40.248183
                            ],
                            [
                                117.461439,
                                40.248351
                            ],
                            [
                                117.472843,
                                40.243185
                            ],
                            [
                                117.474106,
                                40.241105
                            ],
                            [
                                117.481097,
                                40.241726
                            ],
                            [
                                117.484071,
                                40.235302
                            ],
                            [
                                117.488985,
                                40.232534
                            ],
                            [
                                117.499194,
                                40.230957
                            ],
                            [
                                117.505005,
                                40.227216
                            ],
                            [
                                117.514318,
                                40.227719
                            ],
                            [
                                117.524011,
                                40.229632
                            ],
                            [
                                117.527134,
                                40.228978
                            ],
                            [
                                117.546629,
                                40.23292
                            ],
                            [
                                117.550905,
                                40.228038
                            ],
                            [
                                117.554476,
                                40.229682
                            ],
                            [
                                117.564264,
                                40.229045
                            ],
                            [
                                117.569491,
                                40.225455
                            ],
                            [
                                117.571649,
                                40.219247
                            ],
                            [
                                117.569178,
                                40.213895
                            ],
                            [
                                117.564467,
                                40.211009
                            ],
                            [
                                117.563992,
                                40.205236
                            ],
                            [
                                117.56926,
                                40.204162
                            ],
                            [
                                117.57283,
                                40.200319
                            ],
                            [
                                117.572246,
                                40.194915
                            ],
                            [
                                117.575545,
                                40.191894
                            ],
                            [
                                117.57317,
                                40.190031
                            ],
                            [
                                117.576401,
                                40.178599
                            ],
                            [
                                117.579673,
                                40.177961
                            ],
                            [
                                117.584112,
                                40.171766
                            ],
                            [
                                117.588633,
                                40.168307
                            ],
                            [
                                117.60119,
                                40.171212
                            ],
                            [
                                117.609974,
                                40.160079
                            ],
                            [
                                117.613137,
                                40.157779
                            ],
                            [
                                117.618595,
                                40.157611
                            ],
                            [
                                117.618459,
                                40.15133
                            ],
                            [
                                117.621527,
                                40.14656
                            ],
                            [
                                117.630501,
                                40.147836
                            ],
                            [
                                117.632334,
                                40.140782
                            ],
                            [
                                117.63695,
                                40.136851
                            ],
                            [
                                117.636013,
                                40.132097
                            ],
                            [
                                117.650159,
                                40.128183
                            ],
                            [
                                117.648516,
                                40.126402
                            ],
                            [
                                117.653146,
                                40.110758
                            ],
                            [
                                117.657558,
                                40.109162
                            ],
                            [
                                117.650254,
                                40.105246
                            ],
                            [
                                117.648313,
                                40.098607
                            ],
                            [
                                117.644878,
                                40.096204
                            ],
                            [
                                117.649589,
                                40.091279
                            ],
                            [
                                117.657802,
                                40.092741
                            ],
                            [
                                117.660802,
                                40.097431
                            ],
                            [
                                117.663694,
                                40.096439
                            ],
                            [
                                117.668106,
                                40.100927
                            ],
                            [
                                117.674541,
                                40.092153
                            ],
                            [
                                117.674908,
                                40.082134
                            ],
                            [
                                117.67932,
                                40.085664
                            ],
                            [
                                117.688253,
                                40.082857
                            ],
                            [
                                117.690928,
                                40.094187
                            ],
                            [
                                117.696467,
                                40.094926
                            ],
                            [
                                117.703947,
                                40.092237
                            ],
                            [
                                117.707884,
                                40.094002
                            ],
                            [
                                117.711753,
                                40.085849
                            ],
                            [
                                117.722003,
                                40.079898
                            ],
                            [
                                117.730529,
                                40.080554
                            ],
                            [
                                117.733868,
                                40.077393
                            ],
                            [
                                117.744566,
                                40.07862
                            ],
                            [
                                117.751639,
                                40.081999
                            ],
                            [
                                117.755576,
                                40.079545
                            ],
                            [
                                117.758454,
                                40.07329
                            ],
                            [
                                117.753391,
                                40.073324
                            ],
                            [
                                117.7543,
                                40.068666
                            ],
                            [
                                117.758196,
                                40.065959
                            ],
                            [
                                117.767808,
                                40.066077
                            ],
                            [
                                117.769247,
                                40.061688
                            ],
                            [
                                117.776144,
                                40.05893
                            ],
                            [
                                117.772967,
                                40.051782
                            ],
                            [
                                117.767116,
                                40.047997
                            ],
                            [
                                117.758291,
                                40.044347
                            ],
                            [
                                117.747906,
                                40.047459
                            ],
                            [
                                117.746915,
                                40.034439
                            ],
                            [
                                117.743738,
                                40.031276
                            ],
                            [
                                117.744783,
                                40.027928
                            ],
                            [
                                117.744145,
                                40.018438
                            ],
                            [
                                117.755834,
                                40.010394
                            ],
                            [
                                117.758454,
                                40.012985
                            ],
                            [
                                117.757884,
                                40.018135
                            ],
                            [
                                117.760654,
                                40.020659
                            ],
                            [
                                117.76736,
                                40.018943
                            ],
                            [
                                117.770347,
                                40.023553
                            ],
                            [
                                117.782443,
                                40.02352
                            ],
                            [
                                117.784154,
                                40.015308
                            ],
                            [
                                117.785919,
                                40.017916
                            ],
                            [
                                117.791946,
                                40.015577
                            ],
                            [
                                117.791756,
                                40.012464
                            ],
                            [
                                117.797064,
                                40.010141
                            ],
                            [
                                117.792951,
                                40.005631
                            ],
                            [
                                117.796467,
                                39.996676
                            ],
                            [
                                117.791512,
                                39.993747
                            ],
                            [
                                117.786665,
                                39.981272
                            ],
                            [
                                117.787222,
                                39.973477
                            ],
                            [
                                117.7819,
                                39.966354
                            ],
                            [
                                117.772153,
                                39.970193
                            ],
                            [
                                117.769071,
                                39.969233
                            ],
                            [
                                117.763545,
                                39.972719
                            ],
                            [
                                117.756445,
                                39.965175
                            ],
                            [
                                117.752114,
                                39.966084
                            ],
                            [
                                117.74162,
                                39.973342
                            ],
                            [
                                117.733923,
                                39.971119
                            ],
                            [
                                117.727718,
                                39.972433
                            ],
                            [
                                117.723958,
                                39.979959
                            ],
                            [
                                117.719437,
                                39.979892
                            ],
                            [
                                117.711264,
                                39.984842
                            ],
                            [
                                117.706689,
                                39.98602
                            ],
                            [
                                117.695693,
                                39.985751
                            ],
                            [
                                117.688986,
                                39.984017
                            ],
                            [
                                117.68566,
                                39.979488
                            ],
                            [
                                117.68209,
                                39.979858
                            ],
                            [
                                117.674731,
                                39.974807
                            ],
                            [
                                117.663219,
                                39.974756
                            ],
                            [
                                117.657504,
                                39.972163
                            ],
                            [
                                117.638049,
                                39.972231
                            ],
                            [
                                117.631886,
                                39.968711
                            ],
                            [
                                117.620007,
                                39.970665
                            ],
                            [
                                117.611427,
                                39.975278
                            ],
                            [
                                117.615622,
                                39.978781
                            ],
                            [
                                117.615337,
                                39.982535
                            ],
                            [
                                117.610924,
                                39.982619
                            ],
                            [
                                117.603037,
                                39.987754
                            ],
                            [
                                117.599643,
                                39.993747
                            ],
                            [
                                117.592529,
                                39.994673
                            ],
                            [
                                117.589244,
                                39.996861
                            ],
                            [
                                117.584641,
                                39.994135
                            ],
                            [
                                117.578912,
                                39.993848
                            ],
                            [
                                117.57579,
                                39.996424
                            ],
                            [
                                117.570604,
                                39.995111
                            ],
                            [
                                117.566653,
                                39.990987
                            ],
                            [
                                117.562132,
                                39.992872
                            ],
                            [
                                117.563028,
                                39.995666
                            ],
                            [
                                117.547063,
                                39.998932
                            ],
                            [
                                117.544307,
                                39.994454
                            ],
                            [
                                117.537492,
                                39.997383
                            ],
                            [
                                117.537709,
                                39.989977
                            ],
                            [
                                117.546289,
                                39.981424
                            ],
                            [
                                117.547335,
                                39.976844
                            ],
                            [
                                117.538945,
                                39.970766
                            ],
                            [
                                117.536284,
                                39.961537
                            ],
                            [
                                117.534424,
                                39.961588
                            ],
                            [
                                117.532917,
                                39.952493
                            ],
                            [
                                117.527283,
                                39.952358
                            ],
                            [
                                117.524323,
                                39.946647
                            ],
                            [
                                117.519612,
                                39.948433
                            ],
                            [
                                117.514956,
                                39.946681
                            ],
                            [
                                117.511141,
                                39.941071
                            ],
                            [
                                117.512472,
                                39.933675
                            ],
                            [
                                117.525504,
                                39.929615
                            ],
                            [
                                117.522056,
                                39.924796
                            ],
                            [
                                117.521961,
                                39.920634
                            ],
                            [
                                117.514386,
                                39.921442
                            ],
                            [
                                117.503484,
                                39.918999
                            ],
                            [
                                117.504665,
                                39.915696
                            ],
                            [
                                117.513625,
                                39.916673
                            ],
                            [
                                117.512743,
                                39.910522
                            ],
                            [
                                117.514359,
                                39.906831
                            ],
                            [
                                117.507435,
                                39.909055
                            ],
                            [
                                117.503932,
                                39.904875
                            ],
                            [
                                117.50719,
                                39.904168
                            ],
                            [
                                117.510082,
                                39.893817
                            ],
                            [
                                117.516829,
                                39.895166
                            ],
                            [
                                117.518811,
                                39.891289
                            ],
                            [
                                117.521038,
                                39.871375
                            ],
                            [
                                117.529808,
                                39.860987
                            ],
                            [
                                117.534139,
                                39.851962
                            ],
                            [
                                117.53718,
                                39.85284
                            ],
                            [
                                117.544728,
                                39.844591
                            ],
                            [
                                117.545746,
                                39.848032
                            ],
                            [
                                117.548556,
                                39.845535
                            ],
                            [
                                117.548108,
                                39.839917
                            ],
                            [
                                117.543099,
                                39.838163
                            ],
                            [
                                117.544334,
                                39.833017
                            ],
                            [
                                117.537275,
                                39.835159
                            ],
                            [
                                117.540859,
                                39.82193
                            ],
                            [
                                117.548842,
                                39.824073
                            ],
                            [
                                117.558426,
                                39.811517
                            ],
                            [
                                117.56144,
                                39.800038
                            ],
                            [
                                117.568242,
                                39.799245
                            ],
                            [
                                117.560395,
                                39.795008
                            ],
                            [
                                117.554652,
                                39.795379
                            ],
                            [
                                117.554869,
                                39.788727
                            ],
                            [
                                117.553118,
                                39.784506
                            ],
                            [
                                117.548624,
                                39.781027
                            ],
                            [
                                117.54614,
                                39.776147
                            ],
                            [
                                117.554842,
                                39.771419
                            ],
                            [
                                117.550376,
                                39.762957
                            ],
                            [
                                117.546126,
                                39.763667
                            ],
                            [
                                117.539162,
                                39.76044
                            ]
                        ]
                    ]
                ]
            }
        }
    ]
};
export { tianjin };