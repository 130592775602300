import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GXService } from '@shared/GX.service';
import { ConstantUtil } from '@shared/util/ConstantUtil.util';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';
import { HttpClient } from '@angular/common/http';
import {
  subHours,
  differenceInMinutes,
  subMinutes,
  getMinutes,
} from 'date-fns';

declare var echarts: any;

@Component({
  selector: 'energy-consumtption-switch',
  templateUrl: './energy-consumtption-switch.component.html',
  styleUrls: ['./energy-consumtption-switch.component.less'],
})
export class EnergyConsumtptionSwitchComponent implements OnInit {
  date = new Date();
  alldata = require('../energy-consumtption-switch/data').data;
  lineData = [];
  pieChartOption: any;
  unitName = '万吨标准煤';
  topData: any;
  nowInterval: any;
  timeShowList = ['0', '15', '30', '45'];

  dateType: any = 'month';
  unitStartData: Date = new Date(
    moment()
      .subtract('1', 'month')
      .month(0),
  );
  unitEndData: Date = new Date(moment().subtract('1', 'month'));
  dateRange: Date[] = GXService.getGxInitDateRange(ConstantUtil.MONTH);
  search_beginTime: Date = new Date();
  search_endTime: Date = new Date();
  monthCount: number = 0;
  isSpinning: boolean = false;
  timer: any;
  pageSize = 7;
  typeSelect: string = 'region';

  tableData: any = [];
  tableList: any = [];
  pieValueList: any = [];
  pieNameList: any = [];
  lineChartData: any = [];
  orgType: string = 'shengji';
  orgName = '扬州市';
  orgCount = 0;
  energyConsumeSum = '';
  electricSum = '';
  coalSum = '';
  gasSum = '';
  steamSum = '';
  energyConsumeCompareSum = '';
  electricCompareSum = '';
  coalCompareSum = '';
  gasCompareSum = '';
  steamCompareSum = '';
  currentPage: number = 1;
  deviceList = []; // 设备列表
  orgId: string;

  @ViewChild('lineChart') lineChart: ElementRef;
  lineOption: any;

  constructor(private http: HttpClient, public gxShareService: GXService) {}

  ngOnInit() {
    this.getTableList();
  }

  getTableList() {
    this.tableData = [];
    this.orgId = '505703ba-0eff-40d1-a2f8-3d0e57041839';
    this.orgName = '扬州市';
    const beginTime = subMinutes(new Date(), 5);
    const endTime = new Date();
    let tableList = [];
    tableList = this.alldata.filter(item => {
      const time1 = new Date(item.time).getTime();
      return (
        differenceInMinutes(time1, beginTime) > 0 &&
        differenceInMinutes(endTime, time1) >= 0
      );
    });
    let tableDataBefore = tableList.reverse();
    if (tableDataBefore.length >= 6) {
      tableDataBefore = tableDataBefore.slice(0, 5);
    }
    this.tableData = tableDataBefore;

    this.topData = this.tableData[0];
    this.orgName = this.tableData[0].orgName;
    this.energyConsumeSum = Number(this.topData.energyConsume).toFixed(4);
    this.electricSum = Number(this.topData.electricOrigin).toFixed(4);
    this.coalSum = Number(this.topData.coalOrigin).toFixed(4);
    this.gasSum = Number(this.topData.gasOrigin).toFixed(4);
    this.steamSum = Number(this.topData.steamOrigin).toFixed(4);

    this.getLineChartData(this.topData.orgId);
  }

  getLineChartData(orgId) {
    const beginTime = subHours(new Date(), 3);
    const endTime = new Date();

    const result = this.alldata.filter(item => {
      const time = new Date(item.time).getTime();
      // 分钟数
      const minites = getMinutes(item.time).toString();

      return (
        item.orgId === orgId &&
        differenceInMinutes(time, beginTime) >= 0 &&
        differenceInMinutes(endTime, time) >= 0 &&
        this.timeShowList.indexOf(minites) !== -1
      );
    });

    this.lineData = result;
    this.initDeviceData(this.tableData[0].orgId);
    this.initLineCharts();
  }

  clearLine() {
    echarts.init(document.getElementById('lineChart')).dispose();
  }

  initLineCharts() {
    this.clearLine();
    this.alldata = require('../energy-consumtption-switch/data').data;
    const data = this.lineData;
    var months = [];
    var names = [];
    var values = [];
    names.push('能源消费总量');
    names.push('电');
    names.push('煤炭');
    names.push('天然气');
    names.push('蒸汽');
    // names.push(data[0].otherName);
    var energyConsume = [];
    var electric = [];
    var coal = [];
    var gas = [];
    var steam = [];

    for (var i = 0; i < data.length; i++) {
      months.push(data[i].time);
      energyConsume.push(Number(data[i].energyConsume).toFixed(4));
      electric.push(Number(data[i].electricOrigin).toFixed(4));
      coal.push(Number(data[i].coalOrigin).toFixed(4));
      gas.push(Number(data[i].gasOrigin).toFixed(4));
      steam.push(Number(data[i].steamOrigin).toFixed(4));
    }
    values.push(energyConsume);
    values.push(electric);
    values.push(coal);
    values.push(gas);
    values.push(steam);
    let name = this.gxShareService.XinhaiConfig.AllArea.data || '';
    let lineChart = echarts.init(this.lineChart.nativeElement);
    var legendMap = {
      '0': names[0],
      '1': names[1],
      '2': names[2],
      '3': names[3],
      '4': names[4],
    };
    var option = {
      // toolbox: {
      //     right:'10%',
      //     show: true,
      //     feature: {
      //         saveAsImage: {
      //             show:true,
      //             excludeComponents :['toolbox'],
      //             pixelRatio: 2
      //         }
      //     }
      // },
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          var results = [];
          params.forEach(function(item) {
            var name = item.name;
            var seriesName = legendMap[item.seriesName];
            var data = item.data;
            var span = '<span style="display:inline-block;';
            span += 'margin-right:5px;border-radius:10px;';
            span += 'width:9px;height:9px;background-color:';
            span += item.color;
            span += '"></span>';
            span += '<span>' + seriesName + ' : ' + data + '</span>';
            results.push(span);
          });
          return results.join('<br />');
        },
      },
      legend: {
        top: '8%',
        formatter: function(name) {
          return legendMap[name];
        },
        data: ['0', '1', '2', '3', '4'],
        selected: {
          '1': false,
          '2': false,
          '3': false,
          '4': false,
        },
        selectedMode: 'single',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: months,
        axisLabel: {
          rotate: 30,
        },
      },
      yAxis: {
        name: '吨标准煤',
        type: 'value',
        min: 0,
      },
      series: [
        {
          name: '0',
          type: 'line',
          stack: '总量',
          data: values[0],
        },
        {
          name: '1',
          type: 'line',
          stack: '总量',
          data: values[1],
        },
        {
          name: '2',
          type: 'line',
          stack: '总量',
          data: values[2],
        },
        {
          name: '3',
          type: 'line',
          stack: '总量',
          data: values[3],
        },
        {
          name: '4',
          type: 'line',
          stack: '总量',
          data: values[4],
        },
      ],
    };
    lineChart.setOption(option);
    lineChart.on('legendselectchanged', function(params) {
      var name;
      if (params.name === '0') {
        name = '吨标准煤';
      } else if (params.name === '1') {
        name = '万千瓦时';
      } else if (params.name === '2') {
        name = '吨';
      } else if (params.name === '3') {
        name = '万立方米';
      } else if (params.name === '4') {
        name = '吨';
      }
      var option = {
        yAxis: {
          name: name,
        },
      };
      lineChart.setOption(option);
    });
  }

  change(orgId, orgName) {
    this.orgName = orgName;
    this.orgId = orgId;
    this.initDeviceData(this.orgId);
  }

  initDeviceData(orgId: string) {
    var dataUrl = '/energyreport/device/getDeviceList/' + orgId;
    this.http
      .get(dataUrl)
      .toPromise()
      .then((data: InvokeResult) => {
        if (data.success) {
          this.deviceList = data.data;
        }
      });
  }
}
