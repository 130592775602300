import { Component, OnInit } from '@angular/core';
import { area } from './area.json';
declare var echarts: any;
@Component({
  selector: 'indexhome',
  templateUrl: './indexhome.component.html',
  styleUrls: ['./indexhome.component.less']
})
export class IndexhomeComponent implements OnInit {

  listType = 'all';
  echartMapData = area;
  tableLeftTopData = [
    {
      name: '全省',
      mubiao: '6.1%',
      now: '3.1%',
      progress: '51%'
    },
    {
      name: '沈阳市',
      mubiao: '5%',
      now: '3%',
      progress: '60%'
    },
    {
      name: '大连市',
      mubiao: '5%',
      now: '2%',
      progress: '40%'
    },
    {
      name: '鞍山市',
      mubiao: '6%',
      now: '4%',
      progress: '66.6%'
    },
    {
      name: '抚顺市',
      mubiao: '8%',
      now: '5%',
      progress: '62.5%'
    },
    {
      name: '本溪市',
      mubiao: '5%',
      now: '3%',
      progress: '60%'
    },
    {
      name: '丹东市',
      mubiao: '9%',
      now: '6%',
      progress: '66.6%'
    },
    {
      name: '锦州市',
      mubiao: '5%',
      now: '2%',
      progress: '40%'
    },
    {
      name: '营口市',
      mubiao: '4%',
      now: '1%',
      progress: '25%'
    },
    {
      name: '阜新市',
      mubiao: '6%',
      now: '2%',
      progress: '33.3%'
    },
    {
      name: '辽阳市',
      mubiao: '9%',
      now: '6%',
      progress: '66.6%'
    },
    {
      name: '盘锦市',
      mubiao: '3%',
      now: '2%',
      progress: '66.6%'
    },
    {
      name: '铁岭市',
      mubiao: '6%',
      now: '4%',
      progress: '66.6'
    },
    {
      name: '朝阳市',
      mubiao: '8%',
      now: '3%',
      progress: '37.5%'
    },
    {
      name: '葫芦岛市',
      mubiao: '6%',
      now: '3%',
      progress: '50%'
    }
  ];
  tableLeftTopPageIndex = 1;
  tableRightTopData = [
    {
      name: '天津军电热电',
      total: '890662',
      ele: '870679',
      coal: '19982'
    },
    {
      name: '天津华电富源热电',
      total: '87234',
      ele: '63788',
      coal: '23446'
    },
    {
      name: '天津华恒包装',
      total: '17899',
      ele: '13476',
      coal: '4423'
    },
    {
      name: '天津海光药业',
      total: '13877',
      ele: '12312',
      coal: '1565'
    },
    {
      name: '天津渤化永利热电',
      total: '56736',
      ele: '53241',
      coal: '3495'
    },
    {
      name: '渤化永利化工',
      total: '45623',
      ele: '35646',
      coal: '9977'
    },
    {
      name: '路易达孚食品',
      total: '12312',
      ele: '0',
      coal: '10231'
    },
    {
      name: '华能(天津)煤气化发电',
      total: '62456',
      ele: '51232',
      coal: '11224'
    },
    {
      name: '华能临港(天津)燃气热电',
      total: '63456',
      ele: '0',
      coal: '12312'
    },
    {
      name: '天津顶津食品',
      total: '12311',
      ele: '10213',
      coal: '2098'
    },
    {
      name: '天津钢铁',
      total: '234231',
      ele: '215674',
      coal: '18557'
    },
    {
      name: '天津天钢联合特钢',
      total: '256342',
      ele: '243123',
      coal: '13219'
    },
    {
      name: '天津天铁炼焦化工',
      total: '143121',
      ele: '131241',
      coal: '11880'
    },
    {
      name: '天津市贝特瑞新能源',
      total: '13121',
      ele: '11232',
      coal: '1889'
    },
    {
      name: '天津大无缝铜材',
      total: '345321',
      ele: '234521',
      coal: '110800'
    },
    {
      name: '长城汽车天津哈弗分公司',
      total: '83431',
      ele: '76547',
      coal: '6884'
    },
    {
      name: '中国石油天然气大港石化分公司',
      total: '11345',
      ele: '10231',
      coal: '1114'
    },
  ];
  tableRightTopPageIndex = 1;
  intervalLeftFlag = null;
  intervalRightFlag = null;

  ngOnInit() {
    this.intervalRight();
    this.intervalLeft();
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.initPie();
    this.initGauge();
    this.initUnitConsumptionOfOutput();
    this.initIndustryLine();
    this.initCarbonEmissions();
    this.initCoalConsumption();
    this.initElectricityConsumption();
  }

  initMap() {
    const dom = document.getElementById('echartsId');
    const myChart = echarts.init(dom);
    let option;
    const geoJson = area;

    echarts.registerMap('tj', geoJson);

    myChart.setOption(option = {
      tooltip: {
        trigger: 'item',
        formatter: (e) => {
          return `<div style="border-bottom:1px solid #999">24户 企业</div>
          <div style="border-bottom:1px solid #999">2户 千家</div>
          <div>22 户百家</div>`;
        }
      },

      toolbox: {
        show: false,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataView: {
            readOnly: false
          },
          restore: {},
          saveAsImage: {}
        }
      },
      visualMap: {
        min: 0,
        max: 60000,
        // text: ['High', 'Low'],
        realtime: false,
        calculable: true,
        show: true,
        right: 0,
        inRange: {
          color: ['lightskyblue', 'yellow', 'orangered']
        }
      },
      series: [{
        name: '天津',
        zoom: 1.2,
        type: 'map',
        show: false,
        roam: false,
        mapType: 'tj', // 自定义扩展图表类型
        label: {
          show: true,
          fontSize: 11,
        },
        data: [{
          name: '和平区',
          value: 20057.34
        },
        {
          name: '河北区',
          value: 54057.34
        },
        {
          name: '河东区',
          value: 15477.48
        },
        {
          name: '河西区',
          value: 31686.1
        },
        {
          name: '南开区',
          value: 6992.6
        },
        {
          name: '红桥区',
          value: 54045.49
        },
        {
          name: '东丽区',
          value: 40689.64
        },
        {
          name: '西青区',
          value: 37659.78
        },
        {
          name: '津南区',
          value: 45180.97
        },
        {
          name: '北辰区',
          value: 55204.26
        },
        {
          name: '武清区',
          value: 21900.9
        },
        {
          name: '宝坻区',
          value: 31900.9
        },
        {
          name: '滨海新区',
          value: 18900.9
        },
        {
          name: '宁河区',
          value: 11900.9
        },
        {
          name: '蓟州区',
          value: 20900.9
        },
        {
          name: '静海区',
          value: 27400.9
        },
        ],
      }]
    });

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  initPie() {
    const dom = document.getElementById('piedata');
    const myChart = echarts.init(dom);
    const option = {
      title: {
        text: '能源品种占比',
        left: '0',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: '{d}%'
      },
      legend: {
        orient: 'vertical',
        right: '2%',
      },
      series: [
        {
          type: 'pie',
          radius: '70%',
          color: ['#34f6ef', '#fcff00', '#f19610', '#0278e7', '#34d160', '#00b4ff'],
          data: [
            { value: 63, name: '煤炭' },
            { value: 30, name: '电力' },
            { value: 1, name: '其他' },
            { value: 2, name: '热力' }
          ],
          labelLine: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  initGauge() {
    this.initGauge1();
    this.initGauge2();
    this.initGauge3();
  }

  initGauge1() {
    const dom = document.getElementById('gaugeYes');
    const myChart = echarts.init(dom);
    const option = {
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 5,
        radius: '90px',
        center: ['50%', '90%'],
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2']
            ]
          }
        },
        pointer: {
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, '-20%'],
          fontSize: 20
        },
        detail: {
          show: false
        },
        data: [{
          name: '74%',
          value: 0.74
        }]
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  initGauge2() {
    const dom = document.getElementById('gauge7days');
    const myChart = echarts.init(dom);
    const option = {
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 5,
        radius: '90px',
        center: ['50%', '90%'],
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2']
            ]
          }
        },
        pointer: {
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, '-20%'],
          fontSize: 20
        },
        detail: {
          show: false
        },
        data: [{
          name: '80%',
          value: 0.8
        }]
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  initGauge3() {
    const dom = document.getElementById('gaugeLastMonth');
    const myChart = echarts.init(dom);
    const option = {
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 5,
        radius: '90px',
        center: ['50%', '90%'],
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2']
            ]
          }
        },
        pointer: {
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, '-20%'],
          fontSize: 20
        },
        detail: {
          show: false
        },
        data: [{
          name: '95%',
          value: 0.95
        }]
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 产值单耗
  initUnitConsumptionOfOutput() {
    const dom = document.getElementById('unitConsumptionOfOutput');
    const myChart = echarts.init(dom);
    const option = {
      title: {
        text: '产值单耗',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['同期', '本期']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        },
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月']
      },
      series: [
        {
          name: '同期',
          type: 'bar',
          data: [21, 15, 13, 15, 16, 24, 20]
        },
        {
          name: '本期',
          type: 'bar',
          data: [18, 13, 16, 14, 19, 20, 18]
        }
      ]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  initIndustryLine() {
    const dom = document.getElementById('industryLine');
    const myChart = echarts.init(dom);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (e) => {
          const data = e[0].data;
          return `${data.name}: ${data.numbers} 户<br />  能源消耗总量:${data.value} 万吨标准煤`;
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['电力', '化工', '铝业', '水泥', '其他'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          name: 'tce',
          type: 'value',
          axisTick: {
            show: true
          },
          axisLine: {
            show: true
          },
        },

      ],
      series: [
        {
          name: '直接访问',
          type: 'bar',
          barWidth: '35%',
          data: [{ name: '电力', value: 71.91, numbers: '5' },
          { name: '化工', value: 61.6, numbers: '33' },
          { name: '铝业', value: 17.2, numbers: '14' },
          { name: '水泥', value: 5, numbers: '11' }, { name: '其他', value: 1281.72, numbers: '44' }],
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: 'black',
                  // fontSize: 16
                },
                formatter: (e) => {
                  const data = e.data;
                  return data.numbers + '户';
                }
              }
            }
          },
        },

      ]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 碳排放量
  initCarbonEmissions() {
    const dom = document.getElementById('carbonEmissions');
    const myChart = echarts.init(dom);
    const option = {
      title: {
        text: '碳排放量',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16
        }
      },
      grid: {
        bottom: '0%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月']
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{c} 万吨'
      },
      yAxis: {
        type: 'value',
        name: '万吨',
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        },
      },
      series: [{
        data: [17.53, 28.23, 35.26, 48.79, 109.99, 98.33, 55.64],
        type: 'line',
        smooth: true
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 用煤量
  initCoalConsumption() {
    const dom = document.getElementById('coalConsumption');
    const myChart = echarts.init(dom);
    const option = {
      title: {
        text: '用煤量',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16
        }
      },
      grid: {
        bottom: '0%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月']
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{c} 万吨'
      },
      yAxis: {
        type: 'value',
        name: '万吨',
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        },
      },
      series: [{
        data: [46.63, 75.09, 93.79, 129.78, 289.91, 261.56, 148.00],
        type: 'line',
        smooth: true
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 用电量
  initElectricityConsumption() {
    const dom = document.getElementById('electricityConsumption');
    const myChart = echarts.init(dom);
    const option = {
      title: {
        text: '用电量',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16
        }
      },
      grid: {
        bottom: '0%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月']
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{c} 亿千瓦时'
      },
      yAxis: {
        type: 'value',
        name: '亿千瓦时',
        axisTick: {
          show: true
        },
        axisLine: {
          show: true
        },
      },
      series: [{
        data: [67.39, 74.93, 84.16, 82.9, 158.54, 194.35, 98.67],
        type: 'line',
        smooth: true
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  intervalLeft() {
    this.intervalLeftFlag = setInterval(() => {
      if (this.tableLeftTopPageIndex >= this.tableLeftTopData.length / 6) {
        this.tableLeftTopPageIndex = 1;
      } else {
        this.tableLeftTopPageIndex++;
      }
    }, 5000);
  }

  intervalRight() {
    this.intervalRightFlag = setInterval(() => {
      if (this.tableRightTopPageIndex >= this.tableRightTopData.length / 5) {
        this.tableRightTopPageIndex = 1;
      } else {
        this.tableRightTopPageIndex++;
      }
    }, 5000);
  }

  /* 
  左上角企业列表切换
  */
  changeCompanyList(type: string) {
    this.listType = type;
    clearInterval(this.intervalRightFlag);
    // this.tableRightTopData = [];//请求接口
    this.intervalRight();
    this.tableRightTopPageIndex = 1; //pageindex重置为1
  }
}
