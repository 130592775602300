import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {
  constructor(private httpClient: HttpClient) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json ;charset=utf-8',
    }),
  };
  private serviceUrl = '/energyreport/companyData/';
 
  private getAppModelUrl ='/datamonitoring/appmodeltree/appModelNode?modelcode=ssqmx&withtag=true&customerId=';
  private getCompanyUrl='/energyreport/companyOnlineData/getCompanys/';
  private getDataUrl='/energyreport/companyOnlineData/getCompanyData/';
  getAllCompanys(data): Promise<any> {
    return this.httpClient
      .get(this.serviceUrl + 'getCompanys/')
      .toPromise()
      .then(result => {
        return result;
      });
  } 

  getCompanyData(companyCode,startTime,endTime,type,companyId):  Promise<any> {   
     return this.httpClient.get(this.serviceUrl + 'getCompanyData/'+encodeURIComponent(companyCode)+'/'+startTime+'/'+endTime+'/'+type+'/'+companyId).toPromise().then(result=>{
      return result;
    }); 
  }
  getPridict(postParam): Promise<any> {
    return this.httpClient
      .post(this.serviceUrl + 'getYearPridict', postParam)
      .toPromise()
      .then(result => {
        return result;
      });
  }

  /**
   *获取指标模型树节点
   * @author ludaxian
   * @date 2020-04-26
   * @returns
   */
  getAppModelNode(): Observable<any> {
    return this.httpClient.post(
      this.getAppModelUrl,
      {
        extendQueryFilter: null,
        id: '',
        showLeaf: true,
      },
      this.httpOptions,
    );
  }

  /**
   *获取公司
   *
   * @author zhangqiuju
   * @date 2020-06-02
   * @returns {Observable<any>}
   */
  getCompany():Observable<any>{
    return this.httpClient.get(this.getCompanyUrl);
  }
  getTableData(companyCode,startTime,endTime,type,companyId,isTwoLevelPage,typeParam): Observable<Object> {  
    let url=this.getDataUrl+encodeURIComponent(companyCode)+'/'+startTime+'/'+endTime+'/'+type+'/'+companyId+'/'+isTwoLevelPage;
     return this.httpClient.get(url,{params: typeParam})
  }
}
