import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GXService } from '@shared/GX.service';
import { ConstantUtil } from '@shared/util/ConstantUtil.util';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';
import { org } from 'gaoxin-basic-module/assets/pages/org/org';

declare var echarts: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'energy-consumption-display-switch',
  templateUrl: './energy-consumption-display-switch.component.html',
  styleUrls: ['./energy-consumption-display-switch.component.less'],
})
export class EnergyConsumptionDisplaySwitchComponent implements OnInit {
  pieChartOption: any;
  dateType: any = 'month';
  unitStartData: Date = new Date(
    moment()
      .subtract('1', 'month')
      .month(0),
  );
  unitEndData: Date = new Date(moment().subtract('1', 'month'));
  dateRange: Date[] = GXService.getGxInitDateRange(ConstantUtil.MONTH);
  search_beginTime: Date = new Date();
  search_endTime: Date = new Date();
  monthCount: number = 0;
  isSpinning: boolean = false;

  pageSize = 7;
  typeSelect: string = 'region';

  tableData: any = [];
  tableList: any = [];
  pieValueList: any = [];
  pieNameList: any = [];
  lineChartData: any = [];
  orgType: string = 'shengji';
  orgName = '';
  orgCount = 0;
  energyConsumeSum = '';
  electricSum = '';
  coalSum = '';
  gasSum = '';
  steamSum = '';
  energyConsumeCompareSum = '';
  electricCompareSum = '';
  coalCompareSum = '';
  gasCompareSum = '';
  steamCompareSum = '';
  currentPage: number = 1;
  deviceList = []; // 设备列表
  orgId: string;

  @ViewChild('pieChart') pieChart: ElementRef;
  pieOption: any;
  @ViewChild('barChart') barChart: ElementRef;
  barOption: any;
  @ViewChild('lineChart') lineChart: ElementRef;
  lineOption: any;
  @ViewChild('pieChart2') pieChart2: ElementRef;
  @ViewChild('barChart2') barChart2: ElementRef;

  constructor(private http: HttpClient, public gxShareService: GXService) {}

  ngOnInit() {
    // this.select();
    this.isSpinning = true;
    this.select();
    this.initUnits();
  }

  select() {
    this.isSpinning = true;
    this.initData();
    this.getTableData();
    this.getIndustryData();
  }

  getTableData() {
    // this.blockString = '加载中...';
    // this.blockLoading = true;
    var dataUrl =
      '/energyreport/energyConsumptionDisplay/getTableData/' +
      this.dateToString(this.search_beginTime) +
      '/' +
      this.dateToString(this.search_endTime) +
      '/' +
      this.dateType;
    this.http
      .get(dataUrl)
      .toPromise()
      .then((data: any) => {
        if (data) {
          // let res: any =data;
          this.tableData = data.tableData;
          this.orgType = data.orgType;
          this.lineChartData = data.lineChart;
          this.orgName = this.tableData[0]['name'];
          this.orgId = this.tableData[0]['orgId'];
          this.initLineCharts(this.lineChartData['all']);
          this.initPieChart(this.tableData[0]);
          this.initBarChart(this.lineChartData['all']);
          this.initDeviceData(this.orgId);
        }
        this.isSpinning = false;
      });
  }

  getChartData(orgId, name, index) {
    index = (this.currentPage - 1) * this.pageSize + index;
    this.orgName = name;
    this.initDeviceData(orgId);
    this.initLineCharts(this.lineChartData[orgId]);
    this.initPieChart(this.tableData[index]);
    this.initBarChart(this.lineChartData[orgId]);
  }

  getIndustryData() {
    var dataUrl =
      '/energyreport/energyConsumptionOnline/getIndustryAndValue/' +
      this.dateToString(this.search_beginTime) +
      '/' +
      this.dateToString(this.search_endTime);
    this.http
      .get(dataUrl)
      .toPromise()
      .then((data: any) => {
        if (data) {
          // let res: any =data;
          this.tableList = data.tableList;
          this.pieNameList = data.industryListforPie;
          this.pieValueList = data.totalValueforPie;
          this.orgCount = data.orgCount;
        }
        this.initBarChart2(data.tableList);
        this.initPieChart2();
        this.isSpinning = false;
      });
  }

  /**
   *切换查询时间类型
   */
  changeDateType(dataType: string) {
    this.dateRange = GXService.getGxInitDateRange(dataType);
  }

  disabledEndDate = (endValue: Date): boolean => {
    return this.gxShareService.setEndDateDisableMonth(
      this.dateRange[0],
      endValue,
    );
  };

  disabledEndYearDate = (endValue: Date): boolean => {
    return this.gxShareService.setEndDateDisableYear(
      this.dateRange[0],
      endValue,
    );
  };

  changeDate($event: Date): void {
    this.dateRange[1] = this.gxShareService.getEndDateMonth(
      $event,
      this.dateRange[1],
    );
  }

  changeYearDate($event: Date): void {
    this.dateRange[1] = this.gxShareService.getEndDateYear(
      $event,
      this.dateRange[1],
    );
  }

  initData() {
    if (this.dateType == 'month') {
      this.search_beginTime = this.dateRange[0];
      this.search_endTime = this.dateRange[1];
      this.monthCount =
        this.dateRange[1].getMonth() - this.dateRange[0].getMonth() + 1;
    } else {
      this.search_beginTime = new Date(
        moment(this.dateRange[0]).format('YYYY-01-01'),
      );
      this.search_endTime = new Date(
        moment(this.dateRange[1]).format('YYYY-12-31'),
      );

      this.monthCount =
        this.dateRange[1].getFullYear() - this.dateRange[0].getFullYear() + 1;
    }
  }

  dateToString(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = '01';
    if (month.length == 1) {
      month = '0' + month;
    }
    var dateTime = year + '-' + month + '-' + day;
    return dateTime;
  }

  dateToString2(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    if (month.length == 1) {
      month = '0' + month;
    }

    var dateTime = year + '年' + month + '月';
    return dateTime;
  }

  initUnits() {
    var dataUrl =
      '/energyreport/energyConsumptionDisplay/getTableData/' +
      this.dateToString(this.unitStartData) +
      '/' +
      this.dateToString(this.unitEndData) +
      '/' +
      this.dateType;
    this.http
      .get(dataUrl)
      .toPromise()
      .then((data: any) => {
        if (data) {
          data = data.tableData[0];
          this.energyConsumeSum = Number(data.energyConsume / 10000).toFixed(2);
          this.electricSum = Number(data.electricOrigin / 10000).toFixed(2);
          this.coalSum = Number(data.coalOrigin / 10000).toFixed(2);
          //天然气的单位是万立方米
          this.gasSum = Number(data.gasOrigin).toFixed(2);
          this.steamSum = Number(data.steamOrigin / 10000).toFixed(2);
          this.energyConsumeCompareSum = data.energyConsumeCompare;
          this.electricCompareSum = data.electricCompareOrigin;
          this.coalCompareSum = data.coalCompareOrigin;
          this.gasCompareSum = data.gasCompareOrigin;
          this.steamCompareSum = data.steamCompareOrigin;
        }
      });
  }

  initPieChart(data) {
    this.pieOption = {
      toolbox: {
        show: true,
        feature: {
          // saveAsImage: {
          //     show: true,
          //     excludeComponents: ['toolbox'],
          //     pixelRatio: 2
          // }
        },
        iconStyle: {
          // color: '#fff',
          // borderColor: '#fff',
        },
        right: '5%',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        // orient: 'vertical',
        // top: 'middle',
        bottom: '5%',
        left: 'center',
        textStyle: {
          // color: '#fff',
        },
        data: ['电力', '煤炭', '天然气', '蒸汽', '其他'],
      },

      series: [
        {
          name: '能源（万吨标准煤）',
          type: 'pie',
          radius: '50%',
          center: ['50%', '40%'],
          label: {
            normal: {
              formatter: '{b}:{d}%',
              rich: {
                b: {
                  fontSize: 16,
                  lineHeight: 33,
                },
                per: {
                  // color: '#fff',
                },
              },
            },
          },
          data: [
            {
              value: Number(data.electric / 10000).toFixed(2),
              name: '电力',
              itemStyle: {
                normal: {
                  // color: '#34B2BE'
                },
              },
            },
            {
              value: Number(data.coal / 10000).toFixed(2),
              name: '煤炭',
              itemStyle: {
                normal: {
                  // color: '#2B9BD4'
                },
              },
            },
            {
              value: Number(data.gas / 10000).toFixed(2),
              name: '天然气',
              itemStyle: {
                normal: {
                  // color: '#96C5E6'
                },
              },
            },
            {
              value: Number(data.steam / 10000).toFixed(2),
              name: '蒸汽',
              itemStyle: {
                normal: {
                  // color: '#D2AECC'
                },
              },
            },
            {
              value: Number(data.other / 10000).toFixed(2),
              name: '其他',
              itemStyle: {
                normal: {
                  // color: '#D2AECC'
                },
              },
            },
          ],
        },
      ],
    };
    // 使用刚指定的配置项和数据显示图表。
  }

  /*   drawPieChart() {
    let pieChart = echarts.init(this.pieChart.nativeElement);
    pieChart.setOption(this.pieOption);
  } */
  initBarChart(data) {
    var datearr = [],
      elearr = [],
      coalarr = [],
      gasarr = [],
      steamarr = [],
      otherarr = [];
    for (var i = 0; i < data.length; i++) {
      datearr.push(data[i].date);
      elearr.push(Number(data[i].electric / 10000).toFixed(2));
      coalarr.push(Number(data[i].coal / 10000).toFixed(2));
      gasarr.push(Number(data[i].gas / 10000).toFixed(2));
      steamarr.push(Number(data[i].steam / 10000).toFixed(2));
      otherarr.push(Number(data[i].other / 10000).toFixed(2));
    }
    // }

    this.barOption = {
      // toolbox: {
      //   show: true,
      //   feature: {
      //     // saveAsImage: {
      //     //     show: true,
      //     //     excludeComponents: ['toolbox'],
      //     //     pixelRatio: 2
      //     // }
      //   },
      //   iconStyle: {
      //     // color: '#fff',
      //     // borderColor: '#fff',
      //   },
      //   right: '10%',
      // },
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          //指标名称
          var relVal = params[0].name;
          //当前类型的总值
          var value = 0;
          //获取总值
          for (var i = 0, l = params.length; i < l; i++) {
            value += params[i].value;
          }
          for (var i = 0, l = params.length; i < l; i++) {
            if (params[i].value == 0) {
              relVal += '<br/>' + params[i].seriesName + ' : ' + '0';
            } else {
              relVal +=
                '<br/>' +
                params[i].seriesName +
                ' : ' +
                parseFloat(params[i].value).toFixed(2);
            }
          }
          return relVal;
        },
        // axisPointer: {
        //   // 坐标轴指示器，坐标轴触发有效
        //   type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        // },
      },
      legend: {
        bottom: '5%',
        textStyle: {
          // color: '#fff',
        },
        data: ['电力', '煤炭', '天然气', '蒸汽', '其他'],
      },
      grid: {
        left: '3%',
        right: '4%',
        top: '13%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: datearr,
          axisLine: {
            lineStyle: {
              // color: '#FFFFFF',
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 20,
            textStyle: {
              // color: '#fff',
            },
          },
        },
      ],
      yAxis: [
        {
          name: '万吨标准煤',
          type: 'value',
          axisLine: {
            lineStyle: {
              // color: '#FFFFFF',
            },
          },
        },
      ],
      series: [
        {
          name: '电力',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: elearr,
          itemStyle: {
            normal: {
              // color: '#34B2BE'
            },
          },
          barWidth: '50%',
        },
        {
          name: '煤炭',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: coalarr,
          itemStyle: {
            normal: {
              // color: '#2B9BD4'
            },
          },
        },
        {
          name: '天然气',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: gasarr,
          itemStyle: {
            normal: {
              // color: '#96C5E6'
            },
          },
        },
        {
          name: '蒸汽',
          type: 'bar',
          stack: '能源',
          // stack: '能源类型',
          data: steamarr,
          itemStyle: {
            normal: {
              // color: '#D2AECC'
            },
          },
        },
        {
          name: '其他',
          type: 'bar',
          stack: '能源',
          // stack: '能源类型',
          data: otherarr,
          itemStyle: {
            normal: {
              // color: '#D2AECC'
            },
          },
        },
      ],
    };
    // 使用刚指定的配置项和数据显示图表。
  }

  /*  drawBarChart() {
    let barChart = echarts.init(this.barChart.nativeElement);
    barChart.setOption(this.barOption);
  } */

  //根据数据初始化折线趋势图
  initLineCharts(data) {
    var months = [];
    var names = [];
    var values = [];
    names.push('能源消费总量');
    names.push('电');
    names.push('煤炭');
    names.push('天然气');
    names.push('蒸汽');
    // names.push(data[0].otherName);
    var energyConsume = [];
    var electric = [];
    var coal = [];
    var gas = [];
    var steam = [];

    for (var i = 0; i < data.length; i++) {
      months.push(data[i].date);
      energyConsume.push(Number(data[i].energyConsume / 10000).toFixed(2));
      electric.push(Number(data[i].electricOrigin / 10000).toFixed(2));
      coal.push(Number(data[i].coalOrigin / 10000).toFixed(2));
      gas.push(Number(data[i].gasOrigin).toFixed(2));
      steam.push(Number(data[i].steamOrigin / 10000).toFixed(2));
    }
    values.push(energyConsume);
    values.push(electric);
    values.push(coal);
    values.push(gas);
    values.push(steam);
    let name = this.gxShareService.XinhaiConfig.AllArea.data || '';

    var legendMap = {
      '0': names[0],
      '1': names[1],
      '2': names[2],
      '3': names[3],
      '4': names[4],
    };
    this.lineOption = {
      // toolbox: {
      //     right:'10%',
      //     show: true,
      //     feature: {
      //         saveAsImage: {
      //             show:true,
      //             excludeComponents :['toolbox'],
      //             pixelRatio: 2
      //         }
      //     }
      // },
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          var results = [];
          params.forEach(function(item) {
            var name = item.name;
            var seriesName = legendMap[item.seriesName];
            var data = item.data;
            var span = '<span style="display:inline-block;';
            span += 'margin-right:5px;border-radius:10px;';
            span += 'width:9px;height:9px;background-color:';
            span += item.color;
            span += '"></span>';
            span += '<span>' + seriesName + ' : ' + data + '</span>';
            results.push(span);
          });
          return results.join('<br />');
        },
      },
      legend: {
        top: '8%',
        formatter: function(name) {
          return legendMap[name];
        },
        data: ['0', '1', '2', '3', '4'],
        selected: {
          '1': false,
          '2': false,
          '3': false,
          '4': false,
        },
        selectedMode: 'single',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: months,
      },
      yAxis: [
        {
          name: '万吨标准煤',
          type: 'value',
        },
        {
          name: '亿千瓦时',
          type: 'value',
        },
        {
          name: '万吨',
          type: 'value',
        },
        {
          name: '万立方米',
          type: 'value',
        },
        {
          name: '万吨',
          type: 'value',
        },
      ],

      series: [
        {
          name: '0',
          type: 'line',
          stack: '总量',
          yAxisIndex: 0,
          data: values[0],
        },
        {
          name: '1',
          type: 'line',
          stack: '总量',
          yAxisIndex: 1,
          data: values[1],
        },
        {
          name: '2',
          type: 'line',
          stack: '总量',
          yAxisIndex: 2,
          data: values[2],
        },
        {
          name: '3',
          type: 'line',
          stack: '总量',
          yAxisIndex: 3,
          data: values[3],
        },
        {
          name: '4',
          type: 'line',
          stack: '总量',
          yAxisIndex: 4,
          data: values[4],
        },
      ],
    };
  }

  /*  drawLineChart() {
    let lineChart = echarts.init(this.lineChart.nativeElement);
    lineChart.setOption(this.lineOption);
    lineChart.on('legendselectchanged', function(params) {
      var name;
      if (params.name == '0') {
        name = '万吨标准煤';
      } else if (params.name == '1') {
        name = '亿千瓦时';
      } else if (params.name == '2') {
        name = '万吨';
      } else if (params.name == '3') {
        name = '万立方米';
      } else if (params.name == '4') {
        name = '万吨';
      }
      var option = {
        yAxis: {
          name: name,
        },
      };
      lineChart.setOption(option);
    });
  } */

  //--------------------------------------------------------------------------------------------------------------------

  initPieChart2() {
    let pieChart2 = echarts.init(this.pieChart2.nativeElement);
    var pieChartoption = {
      toolbox: {
        show: true,
        feature: {
          // saveAsImage: {
          //     show: true,
          //     excludeComponents: ['toolbox'],
          //     pixelRatio: 2
          // }
        },
        iconStyle: {
          // color: '#fff',
          // borderColor: '#fff',
        },
        right: '5%',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        // orient: 'vertical',
        // top: 'middle',
        bottom: '1%',
        left: 'center',
        textStyle: {
          // color: '#fff',
        },
        data: this.pieNameList,
      },
      series: [
        {
          name: '各行业占比',
          type: 'pie',
          radius: '45%',
          center: ['50%', '35%'],
          label: {
            normal: {
              formatter: '{b}:{d}%',
              rich: {
                b: {
                  fontSize: 16,
                  lineHeight: 33,
                },
                per: {
                  // color: '#fff',
                },
              },
            },
          },
          data: [
            {
              value: Number(this.pieValueList[0]).toFixed(2),
              name: this.pieNameList[0],
              itemStyle: {
                normal: {
                  // color: '#34B2BE'
                },
              },
            },
            {
              value: Number(this.pieValueList[1]).toFixed(2),
              name: this.pieNameList[1],
              itemStyle: {
                normal: {
                  // color: '#34B2BE'
                },
              },
            },

            {
              value: Number(this.pieValueList[2]).toFixed(2),
              name: this.pieNameList[2],
              itemStyle: {
                normal: {
                  // color: '#F38076'
                },
              },
            },
            {
              value: Number(this.pieValueList[3]).toFixed(2),
              name: this.pieNameList[3],
              itemStyle: {
                normal: {
                  // color: '#7593C0'
                },
              },
            },
            {
              value: Number(this.pieValueList[4]).toFixed(2),
              name: this.pieNameList[4],
              itemStyle: {
                normal: {
                  // color: '#F39625'
                },
              },
            },
            {
              value: Number(this.pieValueList[5]).toFixed(2),
              name: this.pieNameList[5],
              itemStyle: {
                normal: {
                  // color: '#96C5E6'
                },
              },
            },
            {
              value: Number(this.pieValueList[6]).toFixed(2),
              name: this.pieNameList[6],
              itemStyle: {
                normal: {
                  // color: '#D2AECC'
                },
              },
            },
          ],
        },
      ],
    };
    // 使用刚指定的配置项和数据显示图表。
    pieChart2.setOption(pieChartoption);
  }

  initBarChart2(data) {
    var industryArr = [];
    var totalArr = [];
    var coalArr = [];
    var elecArr = [];
    var zqArr = [];
    var trqArr = [];
    var otherArr = [];
    for (var i = 0; i < data.length; i++) {
      industryArr.push(data[i].industryName);
      coalArr.push((Number(data[i].coalTotal) / 10000).toFixed(2));
      elecArr.push((Number(data[i].elecTotal) / 10000).toFixed(2));
      zqArr.push((Number(data[i].zqTotal) / 10000).toFixed(2));
      trqArr.push((Number(data[i].trqTotal) / 10000).toFixed(2));
      otherArr.push((Number(data[i].otherTotal) / 10000).toFixed(2));
    }
    // }

    let barChart2 = echarts.init(this.barChart2.nativeElement);

    var barchartoption = {
      // toolbox: {
      //   show: true,
      //   feature: {
      //     // saveAsImage: {
      //     //     show: true,
      //     //     excludeComponents: ['toolbox'],
      //     //     pixelRatio: 2
      //     // }
      //   },
      //   iconStyle: {
      //     // color: '#fff',
      //     // borderColor: '#fff',
      //   },
      //   right: '10%',
      // },
      tooltip: {
        trigger: 'axis',
        // axisPointer: {
        //   // 坐标轴指示器，坐标轴触发有效
        //   type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        // },
      },
      legend: {
        bottom: '5%',
        textStyle: {
          // color: '#fff',
        },
        data: ['电力', '煤炭', '天然气', '蒸汽', '其他'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '20%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: industryArr,
          axisLine: {
            lineStyle: {
              // color: '#FFFFFF',
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 40,
            textStyle: {
              // color: '#fff',
            },
          },
        },
      ],
      yAxis: [
        {
          name: '万吨标准煤',
          type: 'value',
          axisLine: {
            lineStyle: {
              // color: '#FFFFFF',
            },
          },
        },
      ],
      series: [
        {
          name: '电力',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: elecArr,
          itemStyle: {
            normal: {
              // color: '#34B2BE'
            },
          },
          barWidth: '50%',
        },
        {
          name: '煤炭',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: coalArr,
          itemStyle: {
            normal: {
              // color: '#2B9BD4'
            },
          },
        },
        {
          name: '天然气',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: trqArr,
          itemStyle: {
            normal: {
              // color: '#F38076'
            },
          },
        },
        {
          name: '蒸汽',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: zqArr,
          itemStyle: {
            normal: {
              // color: '#7593C0'
            },
          },
        },
        {
          name: '其他',
          type: 'bar',
          stack: '能源',

          // stack: '能源类型',
          data: otherArr,
          itemStyle: {
            normal: {
              // color: '#F39625'
            },
          },
        },
      ],
    };
    // 使用刚指定的配置项和数据显示图表。
    barChart2.setOption(barchartoption);
  }

  initDeviceData(orgId: string) {
    var dataUrl = '/energyreport/device/getDeviceList/' + orgId;
    this.http
      .get(dataUrl)
      .toPromise()
      .then((data: InvokeResult) => {
        if (data.success) {
          this.deviceList = data.data;
        }
      });
  }
}
