import { GXService } from '@shared/GX.service';
import { EchartMap } from '@shared/component/echart-map/echart-map.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as DataSet from '@antv/data-set';

import { NzMessageService, valueFunctionProp } from 'ng-zorro-antd';
import { GlobalConfigService } from '../../../global-config-service';
import { deepCopy } from '@delon/util';
import { HomepageService } from './homepage_switch.service';

declare var G2: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'homepage_switch',
  templateUrl: './homepage-switch.component.html',
  styleUrls: ['./homepage_switch.component.less'],
  providers: [HomepageService],
})
export class HomepageSwitchComponent implements OnInit {
  @ViewChild('echartMapHome') echartMap: EchartMap;
  isBottomWindowShow: boolean = false;
  tableData = [];
  sumData;
  sumConsumeData;
  indexName;
  unit2;
  conversion;
  isVisible = false;
  isVisibleTable = false;
  newRank2 = [];
  year: number;
  lastMonth: number;
  month: number;
  isshow = false;
  dateType: any[];
  energyBoardData: any[];
  typeSelect = 'report';
  radioValue = 'on';
  loading = false;
  hasMore = true;
  cardDisplay: String = '3';
  cardData: any[] = [];
  cardHtml: any[] = [];
  valueData: any[] = [];
  valueDataTemp: any[] = [];
  onlineNum = '75';
  reportNum = '63';
  focu;
  chart;
  trendChart;
  rankingChart;
  rankingChart2;
  trendOption;
  mainBlockLoading: boolean = false;
  formatNum: any = GlobalConfigService.formatNum;
  relativeOption;
  qshnyxfzl = {
    value: 0,
  };
  qshmtxfl = {
    value: 0,
  };
  qshcznh = {
    value: 0,
  };
  indexCode;
  czdhObj = {
    nodes: [
      {
        draggable: false,
        symbolSize: 0,
        x: null,
        name: '产值单耗无行业统计占比',
        itemStyle: null,
        value: 0,
      },
    ],
  };
  mapData = {
    type: 'FeatureCollection',
    features: [],
  };
  trendData: any[];
  trendDataNoFixed: any[];

  axisName: string;
  unit: string;

  total: any[];
  outputValue: any[];
  consumptionOfCoal: any[];

  gridStyle = {
    width: '33.33333333%',
    textAlign: 'center',
    height: '100px',
  };

  regionData = [];
  echartMapData = []; //2020-03-12 ludaxian封装
  linesData = [
    /*  {
      name: '济宁经开区',
      coords: [
        [116.41937255859374, 35.36217605914681],
        [116.13372802734375, 35.37337460834958],
      ],
      visualMap: false,
    },
    {
      name: '太白湖新区',
      coords: [
        [116.65283203124999, 35.31960740586162],
        [117.10601806640624, 35.08620310578525],
      ],
      visualMap: false,
    }, */
  ];
  rankingChartData = [];
  nameShow: any;
  constructor(
    private homepageService: HomepageService,
    private messageService: NzMessageService,
    public gxService: GXService,
  ) {}

  ngOnInit() {
    this.initButton();
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    if (new Date().getMonth() == 0) {
      this.lastMonth = 1;
    } else {
      this.lastMonth = new Date().getMonth() + 1;
    }

    if (moment(new Date()).month() == 0) {
      this.messageService.info(
        '本年1月暂无数据发生，系统自动为您展示上一年的数据',
      );
    }
    this.valueData = [...this.valueDataTemp];
    this.getCompanyNum();
    this.getDataType();
    this.getRegionData();
  }
  async initButton() {
    const res = await this.gxService
      .getDictionaryItem('XinhaiConfig')
      .toPromise();
    const HomePageShowRadioButton = res.find(
      item => item.code === 'HomePageShowRadioButton',
    ).data;
    if (HomePageShowRadioButton === '1') {
      this.isshow = true;
    } else {
      this.isshow = false;
    }
  }

  change() {
    const energyBoardDataUrl =
      '/energyreport/homepage/getEnergyBoardData/' + this.typeSelect;
    this.setEnergyBoardData(energyBoardDataUrl);
  }

  getCompanyNum() {
    const companyDataUrl = '/energyreport/homepage/getCompanyNum';
    this.homepageService.getData(companyDataUrl).then(result => {
      this.onlineNum = result.onlineNum;
      this.reportNum = result.reportNum;
      this.total = result.total;
      this.outputValue = result.outputValue;
      this.consumptionOfCoal = result.consumptionOfCoal;
      let vale = 0;
      _.forEach(this.total, function(item2) {
        vale += item2.value;
      });
      let consumptionOfCoalValu = 0;
      _.forEach(this.consumptionOfCoal, function(item3) {
        consumptionOfCoalValu += item3.value;
      });
      let outputValueVale = 0;
      _.forEach(this.outputValue, function(item4) {
        outputValueVale += item4.value;
      });
      this.qshnyxfzl.value = vale;
      this.qshmtxfl.value = consumptionOfCoalValu;
      this.qshcznh.value = outputValueVale;
    });
  }

  getRegionData() {
    const url = '/energyreport/homepage/getRegionData';
    this.homepageService.getRegionData(url).then(result => {
      this.regionData = result;
      for (var i = 0; i < this.regionData.length; i++) {
        if (
          this.regionData[i]['id'] == '5bed9d37-1a04-4ee2-bdd5-93c47260a780'
        ) {
          this.regionData[i]['indexName'] =
            this.year + '年' + this.regionData[i]['indexName'];
          this.regionData[i]['orderNo'] = 3;
        } else if (
          this.regionData[i]['id'] == 'aab10fe0-0e99-4bde-8b90-b62982005123'
        ) {
          this.regionData[i]['indexName'] =
            '"十三五"' + this.regionData[i]['indexName'];
          this.regionData[i]['orderNo'] = 2;
        } else {
          this.regionData[i]['indexName'] =
            '"十三五"' + this.regionData[i]['indexName'];
          this.regionData[i]['orderNo'] = 1;
        }
      }
      this.regionData = _.orderBy(this.regionData, ['orderNo'], ['asc']);
    });
  }

  getDataType(): void {
    // 首页的胶囊按钮是否显示
    /*   const HomePageShowRadioButton = this.gxService.XinhaiConfig
      .HomePageShowRadioButton.data;
    if (HomePageShowRadioButton === '1') {
      this.isshow = true;
    } else {
      this.isshow = false;
    } */
    this.typeSelect = 'report';
    const energyBoardDataUrl =
      '/energyreport/homepage/getEnergyBoardData/' + this.typeSelect;
    this.setEnergyBoardData(energyBoardDataUrl);
  }

  setEnergyBoardData(energyBoardDataUrl: string): void {
    this.homepageService.getEnergyBoardData(energyBoardDataUrl).then(result => {
      const arry = result;
      // _.forEach(arry, function (item) {
      //   if (item.indexCode === 'zhnh' || item.indexCode === 'nyxfzl') {
      //     item.value=(item.value/10000).toFixed(2)
      //   }
      // });
      _.forEach(arry, function(item) {
        if (
          item.value != null &&
          item.value != undefined &&
          item.conversion != null &&
          item.conversion != undefined
        ) {
          item.value = (item.value / item.conversion).toFixed(2);
        }
      });
      arry.filter(item => {
        if (item['indexName'] == '综合能耗')
          item['indexName'] = '综合能源消费量';
      });
      this.energyBoardData = [...arry];
      this.getData(
        this.energyBoardData[0].indexCode,
        this.energyBoardData[0].value,
        this.energyBoardData[0].indexName,
        this.energyBoardData[0].unit,
        this.energyBoardData[0].conversion,
      );
    });
  }

  onScroll(): void {
    if (this.loading) return;
    this.loading = true;
  }

  radioChange(): void {
    const valueTmp = _.cloneDeep(this.trendDataNoFixed);
    var tempValue = 0.0;
    for (let i = 0; i < valueTmp.length; i++) {
      if (this.radioValue === 'sum') {
        valueTmp[i].value = Number(valueTmp[i].valueSum).toFixed(2);
      } else {
        valueTmp[i].value = Number(valueTmp[i].value).toFixed(2);
      }
    }
    if (this.trendChart) {
      this.trendChart.destroy();
      this.trendChart = null;
    }
    this.setTrendChart(valueTmp, this.axisName);
  }

  getData(indexCode, sumData, indexName, unit, conversion): void {
    //let sumData = indexData * conversion;
    this.mainBlockLoading = true;
    const total = this.total;
    const consumptionOfCoal = this.consumptionOfCoal;
    const outputValue = this.outputValue;
    this.axisName = indexName;
    this.unit = unit;
    this.focu = indexCode;
    const energyBoardDataUrl =
      '/energyreport/homepage/getChartData/' +
      this.typeSelect +
      '/' +
      indexCode;
    this.homepageService.getChartData(energyBoardDataUrl).then(result => {
      try {
        this.echartMapData = deepCopy(result['map']);
        // this.echartMap.setMapChart();
        const aaava = result.trend;
        var bbbva = [];
        _.forEach(aaava, function(item) {
          // if (indexCode === 'zhnh' || indexCode === 'nyxfzl') {
          //   item.value=Number((item.value/10000).toFixed(2));
          // }
          if (
            item.value != null &&
            item.value != undefined &&
            conversion != null &&
            conversion != undefined
          ) {
            item.value = Number(item.value / conversion);
            item.valueSum = Number(item.valueSum / conversion);
            bbbva.push(_.cloneDeep(item));
            item.value = Number(item.value).toFixed(2);
            item.valueSum = Number(item.valueSum).toFixed(2);
          } else {
            bbbva.push(item);
          }
        });
        this.trendData = [...aaava];
        this.trendDataNoFixed = [...bbbva];

        this.radioValue = 'on';
        if (this.trendChart) {
          this.trendChart.destroy();
          this.trendChart = null;
        }
        this.setTrendChart(aaava, this.axisName);

        if (this.chart) {
          this.chart.destroy();
          this.chart = null;
        }
        // const valueTmp = _.cloneDeep(this.mapData);
        // _.forEach(valueTmp, function(item1) {
        //   _.forEach(result.map, function(item2) {
        //     if (item1.name === item2.name) {
        //       item1.value = item2.value;
        //       item1.tooltip = item2.toolTip;
        //     }
        //   });
        // });
        const userData = [];
        const geoDv = new DataSet.View()
          .source(this.mapData, {
            type: 'GeoJSON',
          })
          .transform({
            type: 'map',
            callback: function callback(row) {
              userData.push({
                longitude: row.centroidX,
                latitude: row.centroidY,
                name: row.name,
                value: 0,
                toolTip: [],
              });
              return row;
            },
          });
        _.forEach(result.map, function(item) {
          _.forEach(item.toolTip, function(item2) {
            // if(item2.indexCode==='nyxfzl'||item2.indexCode==='zhnh'){
            //   item2.value=(item2.value/10000).toFixed(2)
            // }
            if (
              item2.value != null &&
              item2.value != undefined &&
              conversion != null &&
              conversion != undefined
            ) {
              item2.value = item2.value;
            }
          });
        });
        this.cardData = result.map;
        _.forEach(result.map, function(item1) {
          _.forEach(total, function(item2) {
            if (item1.regionId === item2.orgId) {
              item1.toolTip.unshift(item2);
            }
          });
        });
        _.forEach(userData, function(item) {
          _.forEach(result.map, function(item2) {
            if (item.name === item2.name) {
              item.value = item2.value;
              // item.value = Math.random() * 1000;
              item.tooltip = item2.toolTip;
            }
          });
        });
        //  this.setMapChart(geoDv, userData);
        const ranking = _.orderBy(result.map, ['value'], ['desc']);
        const newRank = [];
        let dataLength = ranking.length;
        if (dataLength >= 5) {
          for (let i = 0; i < 5; i++) {
            newRank.push(ranking[4 - i]);
          }
        } else {
          for (let i = 0; i < dataLength; i++) {
            newRank.push(ranking[dataLength - 1 - i]);
          }
        }

        this.newRank2 = [];

        for (let i = 0; i < dataLength; i++) {
          this.newRank2.push(ranking[dataLength - 1 - i]);
        }
        this.sumData = sumData;
        this.indexName = indexName;
        this.unit2 = unit;
        this.conversion = conversion;
        /*     if (this.rankingChart) {
          this.rankingChart.destroy();
          this.rankingChart = null;
        } */
        this.indexCode = indexCode;
        this.getRankingChartData(
          newRank,
          sumData,
          indexName,
          indexCode,
          unit,
          conversion,
        );
        // this.setRankingChart();
        // this.setRankingChart2(sumData, indexName, indexCode, unit, conversion);
        if (indexCode == 'czdh') {
          this.initRelativeCZDH(this.czdhObj);
        } else {
          this.initRelative(result.relation, unit);
        }
        this.mainBlockLoading = false;
      } catch (error) {
        console.log(error);
      }
    });
  }

  @ViewChild('trendChartElement')
  trendChartElement: ElementRef;

  setTrendChart(data, axisName): void {
    var xazisData = [];
    _.forEach(data, function(item) {
      xazisData.push(item.date);
    });
    this.trendOption = {
      color: ['#318ef8'],
      tooltip: {
        trigger: 'axis',
      },

      grid: {
        top: '10%',
        bottom: '10%',
        left: '17%',
        // right:'2%',
      },
      legend: {
        show: false,
      },
      xAxis: [
        {
          type: 'category',
          data: xazisData,
          axisPointer: {
            type: 'shadow',
          },
          axisLabel: {
            // color: '#ffffff',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: this.unit,
          nameLocation: 'end',
        },
      ],
      series: [
        {
          name: axisName,
          type: 'bar',
          data: data,
        },
      ],
    };
  }

  @ViewChild('mapChart')
  mapChart: ElementRef;

  setMapChart(geoDv, userData): void {
    this.chart = new G2.Chart({
      container: this.mapChart.nativeElement,
      height: this.mapChart.nativeElement.offsetHeight + 50,
      width: this.mapChart.nativeElement.offsetWidth,
      padding: [20, 20, 120, 20], // 上右下左
    });
    this.chart.scale({
      latitude: {
        sync: true,
        nice: false,
      },
      longitude: {
        sync: true,
        nice: false,
      },
    });
    this.chart.legend({
      textStyle: {
        textAlign: 'center', // 文本对齐方向，可取值为： start middle end
        fill: '#404040', // 文本的颜色
        fontSize: '12', // 文本大小
        fontWeight: 'bold', // 文本粗细
        textBaseline: 'top', // 文本基准线，可取 top middle bottom，默认为middle
      },
      offset: 100,
    });
    this.chart.axis(false);
    this.chart.tooltip({
      useHtml: true,
      follow: true,
      position: 'bottom',
      htmlContent: function(title, items) {
        let tooltip = '';
        _.forEach(items[0].point._origin.tooltip, function(item) {
          tooltip +=
            item.indexName + '(' + item.unit + '):' + item.value + '</br>';
        });
        return (
          '<div style="width:300px;position:absolute;left:0;padding:10px;border-radius:4px;background-color:#fff;text-align:left;">' +
          items[0].point._origin.name +
          ' </br>' +
          tooltip +
          '</div>'
        );
      },
    });

    const geoView = this.chart.view();
    geoView.source(geoDv);
    geoView
      .polygon()
      .position('longitude*latitude')
      .color('#185be1')
      .label('name', {
        offset: 0,
      })
      .style({
        // stroke: '#2aa8d9',
        // lineWidth: 3,
      });

    const userView = this.chart.view();
    userView.source(userData);
    userView
      .heatmap()
      .position('longitude*latitude')
      .color('value', '#ff7e80-#ff9446-#fff192-#c3ffa3-#71fffa-#27cffe-#757cff')
      .size(window.innerHeight / 20)
      .style({
        blur: window.innerHeight / 15,
      });
    this.chart.render();
  }

  @ViewChild('regionChartElement')
  regionChartElement: ElementRef;

  unitName: any;
  getRankingChartData(
    data: any,
    sumData: any,
    indexName: string,
    indexCode: string,
    unit: string,
    conversion: any,
  ) {
    this.unitName = unit;
    let sumData2: any = 0;
    data.forEach(item => {
      if (item.value != null && item.value != undefined) {
        sumData2 += item.value;
      }
    });

    data.forEach(itme => {
      let value = 0;
      let value2 = 0;
      if (
        itme.value != null &&
        itme.value != undefined &&
        conversion != null &&
        conversion != undefined
      ) {
        value = itme.value / conversion;
        value2 = itme.value;
      }
      let label;
      if (indexCode == 'czdh') {
        label = '0.00';
        if (parseFloat(sumData) != 0) {
          label = parseFloat(value.toFixed(2)) + '';
          this.nameShow = indexName + '(' + unit + ')';
        }
      } else {
        label = '0.00%';
        if (parseFloat(sumData) != 0) {
          // label =
          //   ((value * 100) / parseFloat(sumData)).toFixed(this.formatNum) + '%';
          label =
            ((value2 * 100) / parseFloat(sumData2)).toFixed(this.formatNum) +
            '%';
          this.nameShow = indexName + '(' + unit + ')';
        }
      }
      this.rankingChartData.push({
        [this.nameShow]: parseFloat(value.toFixed(2)),
        label: label,
        name: itme.name,
      });
    });
  }
  setRankingChart(): void {
    if (this.rankingChart) {
      this.rankingChart.destroy();
      this.rankingChart = null;
    }
    this.rankingChart = new G2.Chart({
      container: this.regionChartElement.nativeElement,
      height: this.regionChartElement.nativeElement.offsetHeight + 50,
      width: this.regionChartElement.nativeElement.offsetWidth,
      padding: [20, 20, 50, 70], // 上右下左
    });

    this.rankingChart.source(this.rankingChartData);
    this.rankingChart.axis(this.nameShow, false);
    this.rankingChart.axis('name', {
      // title: {
      //   offset: 70,
      // },
      label: {
        formatter: val => {
          // if (val > 0) {
          //   return val + ' gr';
          // }
          var str = '';
          var num = 4; //每行显示字数
          var valLength = val.length; //该项x轴字数
          var rowNum = Math.ceil(valLength / num); // 行数

          if (rowNum > 1) {
            for (var i = 0; i < rowNum; i++) {
              var temp = '';
              var start = i * num;
              var end = start + num;

              temp = val.substring(start, end) + '\n';
              str += temp;
            }
            return str;
          } else {
            return val;
          }
        },
      },
    });
    this.rankingChart.coord().transpose();
    this.rankingChart
      .interval()
      .position('name*' + this.nameShow)
      .label('label', function() {
        return {
          position: 'middle',
        };
      })
      .color('name', '#3371E0')
      .tooltip('name*' + this.nameShow, (name, nameShow) => {
        return {
          name: name,
          value: nameShow + this.unitName,
        };
      });
    this.rankingChart.tooltip(true);
    this.rankingChart.render();
  }

  showModal() {
    if (this.rankingChart2) {
      this.rankingChart2.destroy();
      this.rankingChart2 = null;
    }
    this.isVisible = true;
  }

  showModalTable() {
    this.isVisibleTable = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleTable = false;
  }

  @ViewChild('regionChartElement2')
  regionChartElement2: ElementRef;

  setRankingChart2(): void {
    if (this.rankingChart2) {
      this.rankingChart2.destroy();
      this.rankingChart2 = null;
    }
    this.rankingChart2 = new G2.Chart({
      container: this.regionChartElement2.nativeElement,
      height: this.regionChartElement2.nativeElement.offsetHeight + 50,
      width: this.regionChartElement2.nativeElement.offsetWidth,
      padding: [20, 20, 50, 100], // 上右下左
    });

    let charData = [];
    let nameShow = '';
    let sumData2: any = 0;
    this.newRank2.forEach(item => {
      if (item.value != null && item.value != undefined) {
        sumData2 += item.value;
      }
    });
    this.newRank2.forEach(itme => {
      let value = 0;
      let value2 = 0;
      if (
        itme.value != null &&
        itme.value != undefined &&
        this.conversion != null &&
        this.conversion != undefined
      ) {
        value = itme.value / this.conversion;
        value2 = itme.value;
      }
      let label;
      if (this.indexCode == 'czdh') {
        label = '0.00';
        if (parseFloat(this.sumData) != 0) {
          label = parseFloat(value.toFixed(2)) + '';
        }
      } else {
        label = '0.00%';
        if (parseFloat(this.sumData) != 0) {
          label =
            ((value2 * 100) / parseFloat(sumData2)).toFixed(this.formatNum) +
            '%';
        }
      }
      nameShow = this.indexName + '(' + this.unit2 + ')';

      charData.push({
        [nameShow]: parseFloat(value.toFixed(2)),
        label: label,
        name: itme.name,
      });
    });
    this.rankingChart2.source(charData);
    this.rankingChart2.axis(nameShow, false);
    this.rankingChart2.coord().transpose();
    this.rankingChart2.legend(false);
    this.rankingChart2
      .interval()
      .position('name*' + nameShow)
      .label('label', function() {
        return {
          position: 'middle',
        };
      })
      .color('name', '#3371E0')
      .tooltip('name*' + nameShow, (name, nameShow) => {
        return {
          name: name,
          value: nameShow + this.unit2,
        };
      });
    this.rankingChart2.tooltip(true);
    this.rankingChart2.render();
  }

  initRelative(graph, unit) {
    this.tableData = _.orderBy(graph.nodes, ['value'], ['desc']);
    const categories = [];
    this.sumConsumeData = 0;
    for (let i = 0; i < graph.nodes.length; i++) {
      categories[i] = {
        name: graph.nodes[i].name,
      };
      this.sumConsumeData += graph.nodes[i].value;
    }
    for (let i = 0; i < this.tableData.length; i++) {
      if (this.tableData[i].value != 0) {
        var p =
          ((this.tableData[i].value * 100) / this.sumConsumeData).toFixed(
            this.formatNum,
          ) + '%';
        this.tableData[i]['zhanbi'] = p;
      } else {
        this.tableData[i]['zhanbi'] = '0.00%';
      }
    }
    let axisName = this.axisName;
    this.relativeOption = {
      title: {
        show: false,
      },
      tooltip: {
        // trigger: 'item',
        // formatter: '{a} <br/>{b} : {c} ({d}%)',
        formatter: function(data) {
          return (
            data.name + ':' + data.value + unit + '(占比:' + data.percent + '%)'
          );
        },
      },
      legend: {
        type: 'scroll',
        bottom: '10',
      },
      series: [
        {
          // name: '行业',
          type: 'pie',
          // radius: '55%',
          center: ['50%', '50%'],
          data: graph.nodes,
          label: {
            show: false,
          },
          selectedMode: 'single',
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      // color: [
      //   '#27cffe',
      //   '#53e59e',
      //   '#ffc954',
      //   '#ff7575',
      //   '#a992fc',
      //   '#88e3fc',
      //   '#88c2fc',
      //   '#aac0ff',
      // ],
    };
  }

  initRelativeCZDH(graph) {
    const categories = [];
    for (let i = 0; i < graph.nodes.length; i++) {
      categories[i] = {
        name: graph.nodes[i].name,
      };
    }
    this.relativeOption = {
      title: {
        show: false,
      },
      tooltip: {
        // trigger: 'item',
        // formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        type: 'scroll',
        bottom: '10',
      },
      series: [
        {
          // name: '行业',
          type: 'pie',
          // radius: '55%',
          center: ['50%', '50%'],
          silent: true,
          data: graph.nodes,
          label: {
            show: false,
          },
          selectedMode: 'single',
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      color: ['#C4C4C4'],
    };
  }

  toNumber(str: string): any {
    if (str == null || str == undefined) {
      return 0;
    }
    return Number(str);
  }

  showtimes(data) {
    this.cardDisplay = '1';
    switch (data.currentTarget.id) {
      case 'yanzhou':
        this.encapsulationCardData('兖州区');
        break;
      case 'qufu':
        this.encapsulationCardData('曲阜市');
        break;
      case 'sishui':
        this.encapsulationCardData('泗水县');
        break;
      case 'zoucheng':
        this.encapsulationCardData('邹城市');
        break;
      case 'yutai':
        this.encapsulationCardData('鱼台县');
        this.cardDisplay = '2';
        break;
      case 'jinxiang':
        this.encapsulationCardData('金乡县');
        break;
      case 'weishan':
        this.encapsulationCardData('微山县');
        this.cardDisplay = '2';
        break;
      case 'taibai':
        this.encapsulationCardData('太白湖新区');
        break;
      case 'gaoxinqu':
        this.encapsulationCardData('高新区');
        break;
      case 'rencheng':
        this.encapsulationCardData('任城区');
        break;
      case 'jingjijishukaifaqu':
        this.encapsulationCardData('济宁经开区');
        break;
      case 'jiaxiang':
        this.encapsulationCardData('嘉祥县');
        break;
      case 'wenshang':
        this.encapsulationCardData('汶上县');
        break;
      case 'liangshan':
        this.encapsulationCardData('梁山县');
        break;
    }
  }

  closetimes(data) {
    this.cardDisplay = '3';
  }

  encapsulationCardData(area) {
    this.cardHtml = [];
    var toolTip = [];
    var cardDataTooltip = [];
    _.forEach(this.cardData, function(item) {
      if (item.name === area) {
        cardDataTooltip = item.toolTip;
        toolTip.push(item.name);
      }
    });
    _.forEach(cardDataTooltip, function(item) {
      if (item.indexName == '全社会能源消费总量') {
      } else {
        toolTip.push(item.indexName + '(' + item.unit + '):' + item.value);
      }
    });
    this.cardHtml = toolTip;
  }

  checkthisyear() {
    if (new Date().getMonth() == 0) {
      return false;
    } else {
      return true;
    }
  }
  getUnit() {
    if (this.focu == 'czdh') {
      return this.unit;
    } else {
      return '(' + this.unit + ')';
    }
  }

  bottomWindowShowAndHide() {
    this.isBottomWindowShow = !this.isBottomWindowShow;
  }
}
