import { GXService } from '@shared/GX.service';
import { Component, Inject, ViewChild } from '@angular/core';
import { SettingsService } from '@delon/theme';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { interval, Observer } from 'rxjs';
import { CacheService } from '@delon/cache';
import { tick } from '@angular/core/src/render3';
import { NzMessageService } from 'ng-zorro-antd';
import { filter, switchMap } from 'rxjs/operators';
import { DA_SERVICE_TOKEN, TokenService } from '@delon/auth';


@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {
  searchToggleStatus: boolean;
  pageAuth = false;
  timer: any; //定时，阜康独有
  user_id: any; //用户id，阜康独有
  timeInter: number = 300; //时间间隔，默认五分钟
  listOfMessage = [
    // {
    //   "id": 1,
    //   "content": "这是一条数据",
    //   "content_type": 1,
    //   "create_time": 1614762418000,
    //   "update_time": 1614762418000,
    //   "_delete": false,
    //   "_release": false
    // },
    // {
    //   "id": 7,
    //   "content": "这是测试数据第n条",
    //   "content_type": 2,
    //   "create_time": 1614764374000,
    //   "update_time": 1614764374000,
    //   "_delete": false,
    //   "_release": false
    // },
  ]; //用户消息
  showModalReadMessage = false; //
  // 未读信息条数
  nonReadedMessageNum = 0;

  constructor(
    public settings: SettingsService,
    private router: Router,
    private http: HttpClient,
    public gxService: GXService,
    public cache: CacheService,
    private message: NzMessageService,
    @Inject(DA_SERVICE_TOKEN) public tokenService: TokenService,
  ) {
    this.checkAuth();
    this.TaskSwitch();
    this.getRoleList();
    this.getMessageNum();
  }
  //定时任务开关
  TaskSwitch() {
    let url = '/energyreport/common/getDictionaryItem/XinhaiConfig/MessageSwitch'
    this.http.get(url).toPromise().then(
      (res: any) => {
        if (res.data != null && res.data.data != 0) {
          // console.log("业务字典",res)
          // console.log("业务字典",res.data)
          //console.log("业务字典",res.data.data)
          this.timeInter = res.data.data * 1
          //console.log("时间",this.timeInter)
          this.TimeTask()
        } else {
          // console.log("消息功能关闭")
        }
      }
    )
  }

  //阜康项目独有，定时任务
  TimeTask() {
    let url = '/energyreport/message/getUserId'
    this.http.get(url).toPromise().then((res) => {
      if (res) {
        //console.log(res)
        this.user_id = res[0].user_id
        //console.log(this.user_id);
        this.timing(this.user_id); //执行定时任务
      } else {
        // console.log("没有获取到用id")
      }
    })
  }
  //定时任务,目前阜康独有，其他项目请勿调用
  private timing(user_id) {
    const observer: Observer<any> = {
      next: (res: any) => {
        //console.log("成功了？")
        //console.log(this.user_id)
        var list = []
        let url = '/energyreport/message/getMessageByUserId' + "?user_id=" + user_id;
        this.http
          .get(url)
          .toPromise()
          .then(async (result: any) => {
            if (result) {
              for await (const iterator of result) {
                // console.log(iterator.message_id)
                list.push(iterator.message_id)
              }
            } else {
              this.message.create("error", "查看失败");
            }
            //console.log(list)
            if (list.length > 0) {
              this.getMessage(list)
            } else {
              console.log("没有数据")
              this.showModalReadMessage = false
            }
          });
      },
      error: (err) => {
        console.error(err);
      }, complete: () => {
        console.log("定时任务执行完成")
      }
    }
    const url = "/energyreport/message/getMessageByUserId" + "?user_id=" + user_id;
    const httpRequest = this.http.get(url);
    httpRequest.subscribe(observer);
    //console.log(observer)
    //console.log("time",this.timeInter)
    this.timer = interval(this.timeInter * 1e3)
      .pipe(switchMap(() => {
        return httpRequest;
      }))
      .subscribe(observer)
  }

  //取消息(定时任务的一部分)
  async getMessage(messageId: any) {
    var list = []
    for await (const id of messageId) {
      let url = '/energyreport/message/getMessageById' + "?id=" + id;
      let res: any = await this.http.get(url).toPromise()
      // console.log(res[0])
      if (res.length > 0) {
        list.push(res[0])
      } else {
        console.log("此条数据为空")
      }

    }
    //console.log(list)
    // console.log(list.length)
    this.listOfMessage = list
    //console.log(this.listOfMessage)
    if (this.listOfMessage.length > 0) {
      this.showModalReadMessage = true
    }
  }

  //已读(修改当前消息的状态)
  is_read(data) {
    // console.log("用户已经阅读")
    // console.log(this.listOfMessage)
    //console.log(data.id)
    let param = {
      user_id: this.user_id,
      message_id: data.id
    }
    let url = '/energyreport/message/updateReadState'
    this.http.get(url, { params: param }).toPromise().then(
      (res: any) => {
        //console.log("用户已读")
        this.listOfMessage = this.listOfMessage.filter(d => d.id !== data.id);
        //console.log(this.listOfMessage.length)
        if (this.listOfMessage.length == 0) {
          this.showModalReadMessage = false
        } else {
          //console.log(this.listOfMessage.length)
          console.log("长度不是0")
        }
      }
    )


  }

  handleCancel() {
    this.showModalReadMessage = false
  }

  toggleCollapsedSidebar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
  }

  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }

  goto(param: number) {
    let url = '';
    if (param === 1) {
      url = '/elecAndCoal';
    } else if (param === 2) {
      url = '/cityOverview';
    } else if (param === 3) {
      url = '/energyMonitoring';
    } else if (param === 4) {
      url = '/overviewtj/overviewtj1';
    }

    this.router.navigate([url]);
  }

  checkAuth() {
    this.http.get(`/energyreport/checkUser/pageAuth`).subscribe((res: any) => {
      this.pageAuth = res;
    });
  }

  // 获取角色列表，判断用来显示不显示右上角的消息
  showMessage = false
  getRoleList() {
    let url = 'energyreport/common/getRoleList'
    this.http.get(url).subscribe((res: any) => {
      if (res.success) {
        let arr = res.data
        // console.log("当前登陆用户的角色", res.data)
        arr.forEach(e => {
          if (e == "1bb8dc87-aebd-4367-bc67-5aadd0f8c25c") {
            // 辽宁管理员 角色id, 可以配置成业务字典
            this.showMessage = true
          }
        });
      }

    });
  }
  //获取当前用户的消息()
  getMessageNum(){
    let url ="energyreport/platformMessage/getMessageRequestOfRecipient"
    let param={
      "textSel": "",
      "readSel": 0,
      "startDateLong": null,
      "endDateLong": null
    }
    this.http.post(url, param).toPromise().then((result:any) => {
      // console.log("消息",result)
      if(result.data.length>0){
        this.nonReadedMessageNum=result.data.length
        this.iconRotation()
      }else{
        clearInterval(this.interval$);
      }

    });
  }
  showIcon=false
  interval$: any;
  //图标闪烁
  iconRotation(){
    this.interval$ = setInterval(() => {
      this.showIcon=!this.showIcon
  }, 800);
  }

}










