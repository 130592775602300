import { NgxEchartsModule } from 'ngx-echarts';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// delon
import { AlainThemeModule } from '@delon/theme';
import { DelonABCModule } from '@delon/abc';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
// i18n
import { TranslateModule } from '@ngx-translate/core';

// region: third libs
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CountdownModule } from 'ngx-countdown';
import { UEditorModule } from 'ngx-ueditor';
import { NgxTinymceModule } from 'ngx-tinymce';
import { EnergyChartComponent } from './component/energy-chart/energy-chart.component';
import { EchartMap } from './component/echart-map/echart-map.component';
import { EchartMap2} from './component/echart-map2/echart-map2.component';
import { PipeModule } from '../pipe/pipe.moudle';
/* import { CompanyDataQualityComponent } from 'app/routes/company-data/company-dataquality/company-dataquality.component'; */
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { CompanyDataQualityComponent } from 'app/routes/company-data/company-dataquality/company-dataquality.component';
import { EnergyAnalysisCompanyEchartComponent } from 'app/routes/energy-consumption-analysis/energy-analysis-company-echart/energy-analysis-company-echart.component';
import { CityOverviewSwitchComponent } from 'app/routes/page-switch/city-overview-switch/city-overview-switch.component';
import { HomepageSwitchComponent } from 'app/routes/page-switch/homepage-switch/homepage-switch.component';
import { EnergyConsumtptionSwitchComponent } from 'app/routes/page-switch/energy-consumtption-switch/energy-consumtption-switch.component';
import { EnergyConsumptionDisplaySwitchComponent } from 'app/routes/page-switch/energy-consumption-display-switch/energy-consumption-display-switch.component';
import { LoginSwitchComponent } from 'app/routes/page-switch/login-switch/login-switch.component';

const THIRDMODULES = [
  NgZorroAntdModule,
  CountdownModule,
  UEditorModule,
  NgxTinymceModule,
  NgxEchartsModule,
  PipeModule,
];
// endregion

// region: your componets & directives
const COMPONENTS = [
  EnergyChartComponent,
  EchartMap,
  EchartMap2,
  CompanyDataQualityComponent,
  EnergyAnalysisCompanyEchartComponent,
  CityOverviewSwitchComponent,
  HomepageSwitchComponent,
  EnergyConsumptionDisplaySwitchComponent,
  EnergyConsumtptionSwitchComponent,
  LoginSwitchComponent,
];
const DIRECTIVES = [];
// endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonABCModule,
    DelonACLModule,
    DelonFormModule,
    // third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  entryComponents: [...COMPONENTS],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonABCModule,
    DelonACLModule,
    DelonFormModule,
    // i18n
    TranslateModule,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
