import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GlobalConfigService } from 'app/global-config-service';
import { EnergyMonitoringService } from '../energy-monitoring.service';
import { ExcelPluginOption } from 'exceljs-plugin/dist/interface';
import { GaoxinExcelExport } from 'exceljs-plugin';

declare class DataReceived {
  top: any;
  tradeDayEnergyConsumption: any;
  regionDayEnergyConsumption: any;
  enterpriseOnlineData: any;
  monthDataTrendArray: any;
  companyAccessDetails: any;
  onLineMonitoringQuantity: any;
  onLineMonitoringRegion: any;
  abnormalCompanyList: any;
  indexNameList: any;
  indexUnitList: any;
  indexKeyList: any;
}

declare var echarts: any;

@Component({
  selector: 'energy-monitoring',
  templateUrl: './energy-monitoring.component.html',
  styleUrls: ['./energy-monitoring.component.less'],
})
export class EnergyMonitoringComponent implements OnInit {
  iconList: any[] = [];
  top: any = [];
  tradeDayEnergyConsumption: any = [];
  regionDayEnergyConsumption: any = [];
  enterpriseOnlineData: any = [];
  monthDataTrendArray: any = [];
  companyAccessDetails: any = [];
  onLineMonitoringQuantity: any = {};
  onLineMonitoringRegion: any = [];
  formatNum: any = GlobalConfigService.formatNum;
  timeValue: String = moment(new Date())
    .subtract(1, 'days')
    .format('YYYY年MM月DD日');
  statisticalPeriod: String =
    moment(new Date())
      .subtract(12, 'months')
      .format('YYYY年MM月') +
    '-' +
    moment(new Date())
      .subtract(1, 'months')
      .format('YYYY年MM月');
  //5秒轮循所用参数
  nzPageIndex1: number = 1;
  nzPageIndex2: number = 1;
  tradeValue: number = 1;
  tradeStartValue: number = 0;
  tradeEndValue: number = 4;
  regionValue: number = 1;
  regionStartValue: number = 0;
  regionEndValue: number = 4;
  //行业日用能图表
  tradeyAxisData: any = [];
  trendyData: any = [];
  //区域日用能图表
  regionyAxisData: any = [];
  regionyData: any = [];
  //月趋势
  trendxAxisData: any = [];
  trendxData: any = [];

  indexNameList: any = [];
  indexUnitList: any = [];
  indexKeyList: any = [];
  colorList: any = ['#FFCC00', '#F47353', '#4093FF', '#66FFFF'];
  iconMap:any = {'majorCoal':'icon-meitan', 'majorElectric':'icon-dian', 'vapour':'icon-qi', 'gas':'icon-ranliao'};
  nzSpan;
  abnormalCompanyList: any[];
  isVisibleTable = false;
  isVisible = false;

  constructor(private service: EnergyMonitoringService) { }

  ngOnInit() {
  }

  toNumber(str: string): any {
    if (str == null || str == undefined) {
      return 0;
    }
    return Number(str);
  }

  @ViewChild('tradeChartDay') tradeChartDay: ElementRef;
  @ViewChild('regionChartDay') regionChartDay: ElementRef;
  @ViewChild('monthTrendChart') monthTrendChart: ElementRef;

  ngAfterViewInit() {
    let url = '/energyreport/EnergyMonitoring/getData';
    this.service.getData(url).then(result => {
      let data = result as DataReceived;
      this.top = data.top;
      this.nzSpan = 24 / this.top.length;
      this.tradeDayEnergyConsumption = data.tradeDayEnergyConsumption;
      this.regionDayEnergyConsumption = data.regionDayEnergyConsumption;
      this.enterpriseOnlineData = data.enterpriseOnlineData;
      this.monthDataTrendArray = data.monthDataTrendArray;
      this.companyAccessDetails = data.companyAccessDetails;
      this.onLineMonitoringQuantity = data.onLineMonitoringQuantity;
      this.onLineMonitoringRegion = data.onLineMonitoringRegion;
      this.abnormalCompanyList = data.abnormalCompanyList;
      this.indexNameList = data.indexNameList;
      this.indexUnitList = data.indexUnitList;
      this.indexKeyList = data.indexKeyList;
      this.indexKeyList.forEach(item => {
        this.iconList.push(this.iconMap[item]);        
      });
      
      let temp = this;

      //行业日用能
      _.forEach(temp.tradeDayEnergyConsumption, function (item) {
        temp.tradeyAxisData.push(item.name);
      });
      _.forEach(this.indexNameList, function (indexKey, index) {
        let data = [];
        _.forEach(temp.tradeDayEnergyConsumption, function (item) {
          data.push(item.indexValueList[index].dayValue);
        });
        temp.trendyData.push(data);
      })
      this.drawTradeChartDay();

      //区域日用能
      _.forEach(this.regionDayEnergyConsumption, function (item) {
        temp.regionyAxisData.push(item.regionName);
      });
      _.forEach(this.indexNameList, function (indexKey, index) {
        let data = [];
        _.forEach(temp.regionDayEnergyConsumption, function (item) {
          data.push(item.indexValueList[index].dayValue);
        });
        temp.regionyData.push(data);
      })
      this.drawRegionChartDay();

      //月趋势折线图
      _.forEach(temp.monthDataTrendArray, function (item) {
        temp.trendxAxisData.push(item.date);
      });
      _.forEach(this.indexNameList, function (indexKey, index) {
        let data = [];
        _.forEach(temp.monthDataTrendArray, function (item) {
          data.push(item.indexValueList[index].monthValue);
        });
        temp.trendxData.push(data);
      })
      this.drawMonthTrendChart();
    });
    let temp = this;
    setInterval(function () {
      //区域日用能
      if (
        temp.regionValue >
        (temp.regionDayEnergyConsumption.length -
          (temp.regionDayEnergyConsumption.length % 5)) /
        5
      ) {
        temp.regionValue = 1;
      } else {
        temp.regionValue++;
      }
      if (
        temp.regionValue >
        (temp.regionDayEnergyConsumption.length -
          (temp.regionDayEnergyConsumption.length % 5)) /
        5 &&
        temp.regionDayEnergyConsumption.length % 5 > 0
      ) {
        temp.regionStartValue = (temp.regionValue - 1) * 5;
        temp.regionEndValue =
          (temp.regionValue - 1) * 5 +
          (temp.regionDayEnergyConsumption.length % 5) -
          1;
      } else {
        temp.regionStartValue = (temp.regionValue - 1) * 5;
        temp.regionEndValue = temp.regionValue * 5 - 1;
      }
      temp.drawTradeChartDay();
      //行业日用能
      if (
        temp.tradeValue >
        (temp.tradeDayEnergyConsumption.length -
          (temp.tradeDayEnergyConsumption.length % 5)) /
        5
      ) {
        temp.tradeValue = 1;
      } else {
        temp.tradeValue++;
      }
      if (
        temp.tradeValue >
        (temp.tradeDayEnergyConsumption.length -
          (temp.tradeDayEnergyConsumption.length % 5)) /
        5 &&
        temp.tradeDayEnergyConsumption.length % 5 > 0
      ) {
        temp.tradeStartValue = (temp.tradeValue - 1) * 5;
        temp.tradeEndValue =
          (temp.tradeValue - 1) * 5 +
          (temp.tradeDayEnergyConsumption.length % 5) -
          1;
      } else {
        temp.tradeStartValue = (temp.tradeValue - 1) * 5;
        temp.tradeEndValue = temp.tradeValue * 5 - 1;
      }
      temp.drawRegionChartDay();

      //企业在线数据表格
      if (temp.nzPageIndex1 >= temp.enterpriseOnlineData.length / 5) {
        temp.nzPageIndex1 = 1;
      } else {
        temp.nzPageIndex1++;
      }
      //企业在线数据表格
      if (temp.nzPageIndex2 >= temp.companyAccessDetails.length / 5) {
        temp.nzPageIndex2 = 1;
      } else {
        temp.nzPageIndex2++;
      }
    }, 5000);
  }

  drawTradeChartDay() {
    var seriesList = [];
    this.trendyData.forEach((item, index) => {
      var data = {
        name: this.indexNameList[index],
        type: 'bar',
        stack: '总量',
        data: item,
        barMaxWidth: '30',
        itemStyle: {
          color: this.colorList[index],
        },
      };
      seriesList.push(data);
    })
    let tradeChartDay = echarts.init(this.tradeChartDay.nativeElement);
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '1%',
        containLabel: true,
      },
      legend: {
        data: this.indexNameList,
        show: false,
      },
      xAxis: {
        type: 'value',
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#FFFFFF',
        },
      },
      yAxis: {
        type: 'category',
        data: this.tradeyAxisData,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#FFFFFF',
        },
      },
      dataZoom: [
        {
          show: true,
          type: 'inside',
          startValue: this.tradeStartValue,
          endValue: this.tradeEndValue,
          yAxisIndex: 0,
          filterMode: 'empty',
          showDataShadow: false,
        },
      ],
      series: seriesList
    };
    tradeChartDay.setOption(option);
  }

  drawRegionChartDay() {
    var seriesList = [];
    this.regionyData.forEach((item, index) => {
      var data = {
        name: this.indexNameList[index],
        type: 'bar',
        stack: '总量',
        data: item,
        barMaxWidth: '36',
        itemStyle: {
          color: this.colorList[index],
        },
      };
      seriesList.push(data);
    })
    let regionChartDay = echarts.init(this.regionChartDay.nativeElement);
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      legend: {
        data: this.indexNameList,
        show: false,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: this.regionyAxisData,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#FFFFFF',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#FFFFFF',
        },
      },
      dataZoom: [
        {
          show: false,
          type: 'inside',
          startValue: this.regionStartValue,
          endValue: this.regionEndValue,
        },
      ],
      series: seriesList
    };
    regionChartDay.setOption(option);
  }

  drawMonthTrendChart() {
    var seriesList = [];
    this.trendxData.forEach((item, index) => {
      var data = {
        name: this.indexNameList[index],
        type: 'line',
        data: item,
        itemStyle: {
          color: this.colorList[index],
        },
        lineStyle: {
          width: 3,
        },
      };
      seriesList.push(data);
    })
    let monthTrendChart = echarts.init(this.monthTrendChart.nativeElement);
    let option = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: this.indexNameList,
        textStyle: {
          color: '#FFF',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        axisLabel: {
          color: '#FFFFFF',
        },
        data: this.trendxAxisData,
      },
      yAxis: {
        type: 'value',

        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#FFFFFF',
        },
      },
      series: seriesList
    };
    monthTrendChart.setOption(option);
  }

  output() {
    const mergeCells = [];
    //标题
    const title1 = [];
    title1.push("采集异常企业列表");
    mergeCells.push('A1:D1');
    //表头
    const head = ['采集异常企业名称', '所属地区', '联系人', '联系电话'];
    //内容
    const body = [];
    this.abnormalCompanyList.forEach(item => {
      const o = [];
      o.push(item["fullName"]);
      o.push(item["regionName"]);
      o.push(item["legalName"]);
      o.push(item["legalTelephone"]);
      body.push(o);
    })

    const result = [title1, head, ...body];
    const opt: ExcelPluginOption = {
      fileName: "采集异常企业列表" + '_' + moment(new Date()).format('YYYYMMDD'),
      data: result,
      mergeCells: mergeCells,
      columnWidth: {
        A: 50,
        B: 30,
        C: 30,
        D: 30,
      },
      columnStyle: {
        A: {
          alignment: {
            horizontal: 'center',
            vertical: 'middle',
          },
        },
        B: {
          alignment: {
            horizontal: 'center',
            vertical: 'middle',
          },
        },
        C: {
          alignment: {
            horizontal: 'center',
            vertical: 'middle',
          },
        },
        D: {
          alignment: {
            horizontal: 'center',
            vertical: 'middle',
          },
        }
      },
      styleToCellList: [
        {
          cellStyle: {
            border: null,
          },
          cellList: ['A1', 'D1'],
        },
      ],
    };
    GaoxinExcelExport.exportUserDefine(opt);
  }

  cutString(str: any, len: number) {
    if (str) {
      str = str.toString();
      if (str.length > len) {
        return str.substr(0, len) + '...';
      } else {
        return str;
      }
    } else {
      return str;
    }
  }

  showModalTable() {
    this.isVisibleTable = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isVisibleTable = false;
  }
}
