import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicServiceService } from '../public-service.service'
@Component({
  selector: 'news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.less']
})
export class NewsListComponent implements OnInit {
  customId = "629841ec-2d28-4d74-9d5c-5c070b821bcd";
  current = 1;
  typeId: any;
  parentId: any;
  typeName: any;
  newsData: any;
  dataUrl = '/cms/content/getByCusAndCol/' + this.customId + '/';
  constructor(
    private routerInfo: ActivatedRoute,
    private publicServiceService: PublicServiceService) {

  }

  ngOnInit() {
    this.newsData = [];
    this.typeId = this.routerInfo.snapshot.params['typeId'];
    this.parentId = 'content_' + this.typeId;
    this.setType();
    this.setInfoData();
  }
  setInfoData(): void {
    this.publicServiceService.getData(this.dataUrl + this.parentId).then(result => {
      this.newsData = [...result];
      var tmpDataList = [];
      for (var i = this.newsData.length - 1; i > -1; i--) {
        tmpDataList.push(this.newsData[i]);
      }
      this.newsData = tmpDataList;
    });
  }
  setType(): void {
    if (this.typeId === 'ZCFG') {
      this.typeName = '政策法规'
    } else if (this.typeId === 'BZGF') {
      this.typeName = '行业标准'
    } else if (this.typeId === 'XWZX') {
      this.typeName = '信息公开'
    } else if (this.typeId === 'JNJB') {
      this.typeName = '通知公告'
    } else if (this.typeId === 'JNXJSTG') {
      this.typeName = '节能产品和技术'
    } else if (this.typeId === 'WSFW') {
      this.typeName = '能源要闻'
    } else if (this.typeId === 'LBTP') {
      this.typeName = '首页轮播'
    }
  }
  loadData(pi: number): void {

  }

  formatDate(dateTime) {

    return moment(new Date(dateTime)).format("YYYY-MM-DD hh:mm:ss");
  }
}
