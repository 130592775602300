/**
 * Created by zhang on 2017/3/31.
 */
declare const JSZipUtils: any;
declare const saveAs: any;
declare const Docxtemplater: any;
declare const JSZip: any;
declare const ImageModule: any;
declare const ChartModule: any;
declare const echarts: any;
export namespace WordExport {
    'use strict';
    class WordExportClass {
        public configMap = null;
        public exprotJS = null;

        constructor() {
            this.configMap = {

            };
            this.exprotJS = this.exportWordJS;
        }

        private loadFile(url, callback) {
            JSZipUtils.getBinaryContent(url, callback);
        }

        private  base64ToArrayBuffer(base64) {
            const binary_string = window.atob(base64);
            const len = binary_string.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }

        private exportWordJS(opt) {
            let curr = this;
            const imgOpts = {
                centered: null,
                getImage: null,
                getSize: null
            };

            imgOpts.centered = true;
            imgOpts.getImage = function (tagValue, tagName) {
                const outstr = tagValue.replace('data:image/png;base64,', '');
                const abImage = curr.base64ToArrayBuffer(outstr);
                return abImage;
            }

            imgOpts.getSize = function (img, tagValue, tagName) {
                return [500, 400];
            }

            const imageModule = new ImageModule(imgOpts);
            const chartModule = new ChartModule();
            let docxtemplater=new Docxtemplater();
            const docData:any={};


            _.forEach(opt.txtCollection,function (value,key) {
                docData[key]=value;
            });
            if(opt.enableChart===true){
                docxtemplater.attachModule(chartModule);
                _.forEach(opt.chartCollection,function (value,key) {
                    docData[key]=value;
                });
            }
            if(opt.enableImg===true){
                docxtemplater.attachModule(imageModule);
                _.forEach(opt.imageCollection,function (value,key) {
                    docData[key]=value;
                });
            }
            curr.loadFile('/energyreport/assets/wordTemp/'+opt.templName, function (error, content) {
                if (error) {
                    throw error
                }

                var zip = new JSZip(content);

                var doc = docxtemplater.loadZip(zip);

                doc.setData(docData);
                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render()
                }
                catch (error) {
                    var e = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                        properties: error.properties,
                    }
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: 'blob',
                    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                });
                saveAs(out, opt.outputName)
            })
        }
    }


    export function exportWord(opt){
        var wordExportClass = new WordExportClass();
        wordExportClass.exprotJS(opt);
    }
}

//# sourceURL=word-export/word-export.js
