import { Component, OnInit } from '@angular/core';
import {NzMessageService, NzModalRef, UploadFile} from "ng-zorro-antd";
import {SendMessageService} from "./send-message.service";
import {GXService} from "@shared/GX.service";
import {filter, map} from "rxjs/operators";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {InvokeResult} from "@shared/entity/InvokeResult.entity";

@Component({
  selector: 'send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.less']
})
export class SendMessageComponent implements OnInit {

  constructor(private message: NzMessageService,
              private sendMessageService:SendMessageService,
              private http: HttpClient,
              private modal: NzModalRef,
              private gxService: GXService) { }
  titleName: string = '';
  sendContent: string = '';
  selectChangeType: string[] = [];
  sendTypes: any[] = [];
  filterData = [];
  fileList = [];
  file:string=''; // 上传附件名
  companyChoiceList = ['all'];
  disableAllFlag = false;
  disableFlag = true;
  companyInfoList: any;
  companyInfoCopyList: any;
  companyInfoMap = {};
  progress:Number=0;
  companyLoading = false;
  fileStatus="loading"; //文件上传的状态
  disabled=false; //发送按钮是否可用
  flag=false; // 选择附件是否可用flag
  fileName:string = '';
  sendRangeFlag = 0;
  regionNodes = []; // 地区列表
  expandKeys = ['505703ba-0eff-40d1-a2f8-3d0e57041839'];
  organizationValue: any = '';
  choiceRegionIdCompany = []; //选择区域下的公司条数

  ngOnInit() {
    this.initSendTypes();
    this.getAllCompanyInfo();
    this.getRegionTreeData();
  }

  async sendQuickMsg() {
    if (this.titleName.trim().length === 0) {
      this.message.warning('请输入发送标题！');
      return;
    } else if (this.titleName.trim().length > 50) {
      this.message.warning('发送标题的长度不能超过50！');
      return;
    }else if (this.sendContent.trim().length === 0) {
      this.message.warning('请输入发送内容！');
      return;
    } else if (this.sendContent.trim().length > 250) {
      this.message.warning('发送内容的长度不能超过250！');
      return;
    } else if (this.sendRangeFlag === 0 && this.companyChoiceList.length === 0) {
      this.message.warning('请选择发送企业！');
      return;
    } else if (this.sendRangeFlag === 1 && this.gxService.isEmpty(this.organizationValue)) {
      this.message.warning('请选择发送区域！');
      return;
    } else if (this.selectChangeType.length === 0) {
      this.message.warning('请选择发送方式！');
      return;
    } /*else if (this.fileList.length === 0) {
      this.message.warning('请添加附件！');
      return;
    }*/
    // 如果选择的区域下没有一家公司，给出提示
    if (this.sendRangeFlag === 1 && !this.gxService.isEmpty(this.organizationValue)) {
      this.choiceRegionIdCompany = [];
      await this.sendMessageService
        .getComForHeadLetter(this.organizationValue)
        .toPromise()
        .then((result: InvokeResult) => {
          if (result.success) {
            if (!GXService.isEmpty(result.data)) {
              this.choiceRegionIdCompany = result.data
            }
          }
        });
      if (this.choiceRegionIdCompany.length == 0) {
        this.message.warning('该区域下没有一家所属企业，请重新选择区域！');
        return;
      }
    }

    let companyCodeList = [];
    let loopCompanyData = [];
    if (this.sendRangeFlag === 0) {
      if (this.companyChoiceList.indexOf('all') != -1) {
        loopCompanyData = this.companyInfoCopyList;
        if (loopCompanyData.length > 0) {
          loopCompanyData.forEach(item =>{
            if (this.companyInfoMap[item.orgId] != null) {
              companyCodeList.push(this.companyInfoMap[item.orgId]);
            }
          });
        }
      } else {
        loopCompanyData = this.companyChoiceList;
        if (loopCompanyData.length > 0) {
          loopCompanyData.forEach(item =>{
            if (this.companyInfoMap[item] != null) {
              companyCodeList.push(this.companyInfoMap[item]);
            }
          });
        }
      }
    }

    this.file=encodeURIComponent(this.file);
    this.sendMessageService
      .sendQuickMsg({
        sendTitle:this.titleName.trim(),
        sendContent:this.sendContent.trim(),
        sendType:this.selectChangeType.toString(),
        sendFilePath:this.file,
        orgId:this.companyChoiceList,
        sendRangeFlag:this.sendRangeFlag,
        organizationValue:this.organizationValue
      })
      .then(res => {
        if (res['hasErrors'] === true) {
          return this.message.warning(res['errorMessage']);
        } else {
          this.message.success('信息发送成功');
        }
      })
      .catch(err => {
        return this.message.warning(err);
      });
  }

  /**
   * 上传文件前做的验证
   *
   */
  beforeUpload = (file: UploadFile): boolean => {
    this.flag=true;
    this.fileName='';
    this.fileList=[];
    this.disabled=true;
    this.fileList = this.fileList.concat(file);
    //重新上传时progress！=0
    this.progress=0.1;
    this.fileStatus="loading";
    this.upload();
    return false;
  };

  upload(){
    if (GXService.isEmpty(this.fileList)) {
      this.message.warning('请选择文件！');
      return;
    }
    const formData = new FormData();
    formData.append('files', this.fileList[0]);
    this.http.post('/energyreport/platformMessage/uploadHeadLetterFile', formData,{reportProgress:true,observe:'events'}).pipe(
      filter((event)=>{
        switch(event['type']){
          case HttpEventType.UploadProgress:{
            if(this.fileStatus!='removed'){
              this.progress=Number(((event['loaded']/event['total'])*100).toFixed(2));
            }else{
              this.progress=0;
              return true;
            }
            break;
          }
          case HttpEventType.Response:{
            return true;
          }
        }
        return false;
      }),
      map((res:HttpResponse<InvokeResult>)=>res.body)
    ).subscribe((result: InvokeResult) => {
        if (result.success) {
          this.message.success('上传附件成功！');
          this.file = result.data;
          this.fileName = result.data;
          this.disabled=false;
          this.fileStatus="";
          this.flag=false;
        } else {
          this.message.error('上传附件失败，请重新上传！');
          this.fileStatus="";
          this.flag=false;
          this.disabled=false;
        }
      }
    );
  }

  changeFlag() {
    if (this.companyChoiceList.indexOf('all') != -1) {
      this.disableFlag = true;
      this.disableAllFlag = false;
    } else if (this.companyChoiceList.length != 0) {
      this.disableFlag = false;
      this.disableAllFlag = true;
    } else {
      this.disableAllFlag = false;
      this.disableFlag = false;
    }
  }

  initSendTypes(): void {
    this.sendMessageService.getSelectData().then(res => {
      const dataTypes = res.data.value.split(',');
      const dafeultData = [
        { type: 'sms', name: '短信' },
        { type: 'email', name: '电子邮件' },
        { type: 'pms', name: '站内信' },
      ];
      const sendTypes = [];
      dafeultData.forEach(item => {
        for (let index = 0; index < dataTypes.length; index++) {
          if (item.type == dataTypes[index]) {
            sendTypes.push(item);
          }
        }
      });
      this.sendTypes = sendTypes;
    });
  }

  getAllCompanyInfo(){
    this.companyLoading = true;
    let regionId = '';
    this.sendMessageService
      .getComForHeadLetter(regionId)
      .toPromise()
      .then((result: InvokeResult) => {
        this.companyInfoList = [];
        if (result.success) {
          if (!GXService.isEmpty(result.data)) {
            this.companyInfoList = result.data;
            this.companyInfoCopyList = result.data;
            this.companyInfoCopyList.forEach(item => {
              this.companyInfoMap[item.orgId] = item.ltdLegalCode;
            });
          }
        }
      }).finally(() => {
      this.companyLoading = false;
    })
   }

  handleChange(e){
    this.fileStatus=e['type'];
    //如果是删除上传的文件
    if(e['type']=='removed'){
      this.fileList=[];
      this.progress=0;
      this.file="";
      this.flag=false;
      this.disabled=false;
      this.fileName ='';
    }
  }

  getRegionTreeData() {
    this.sendMessageService.getRegionTreeNodes().toPromise().then(
      (val: any) => {
        this.regionNodes = val;
        this.expandKeys = val[0]['key'];
        this.organizationValue = val[0]['key'];
      },
      error => {
        this.message.error(error['errorMessage']);
      }
    );
  }
}
