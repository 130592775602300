import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpClient,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import {
  NzMessageService,
  NzNotificationService,
  NzModalService,
} from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { GlobalConfigService } from '../../global-config-service';

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private http: HttpClient, private globalCongigService: GlobalConfigService, private router: Router,) { }
  private modal(): NzModalService {
    return this.injector.get(NzModalService);
  }
  get msg(): NzMessageService {
    return this.injector.get(NzMessageService);
  }
  private get notification(): NzNotificationService {
    return this.injector.get(NzNotificationService);
  }
  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private handleData(
    event: HttpResponse<any> | HttpErrorResponse,
  ): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    this.injector.get(_HttpClient).end();
    // 业务处理：一些通用操作
    switch (event.status) {
      case 200:
        break;
      case 401: // 未登录状态码
        this.goTo('/passport/login')
        break;
      case 403:
        this.msg.warning('暂无权限');
        break;
      case 404:
        this.msg.error("页面请求错误！")
        break;
      case 500:
        this.msg.error("系统错误！");
        break;
      case 901:
        this.goTo('/passport/authorize');
        break;
      default:
        if (event instanceof HttpErrorResponse) {
          console.warn(
            '未可知错误，大部分是由于后端不支持CORS或无效配置引起',
            event,
          );
          this.msg.error('未知' + event.message);
        }
        break;
    }
    return of(event);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    // 统一加上服务端前缀
    // let url = req.url;
    // if (!url.startsWith('https://') && !url.startsWith('http://')) {
    //   url = environment.SERVER_URL + url;
    // }
    // const newReq = req.clone({
    //   url: url,
    // });
    return next.handle(req).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理，这是因为一个请求若是业务上错误的情况下其HTTP请求的状态是200的情况下需要
        if (event instanceof HttpResponse && event.status === 200) {
          return this.handleData(event);
        }
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
}
