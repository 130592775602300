export namespace MapData {
  export function buildData() {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {name: '嘉祥县'},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.497345, 35.263843],
                  [116.509537382813, 35.2604201484376],
                  [116.500767851563, 35.2516506171875],
                  [116.497345, 35.263843],
                ],
              ],
              [
                [
                  [116.497345, 35.263843],
                  [116.47271609375, 35.2484706855469],
                  [116.417345, 35.223843],
                  [116.394073515625, 35.2325868964844],
                  [116.3245715625, 35.2255019355469],
                  [116.30474734375, 35.179790265625],
                  [116.267345, 35.193843],
                  [116.278726835938, 35.2215419746094],
                  [116.268551054688, 35.2730336738281],
                  [116.224205351563, 35.2642702460938],
                  [116.213170195313, 35.2796681953125],
                  [116.201519804688, 35.2880178046875],
                  [116.183170195313, 35.3195497871094],
                  [116.227349882813, 35.3730837226563],
                  [116.211519804688, 35.3980178046875],
                  [116.19810671875, 35.4649221015625],
                  [116.161910429688, 35.4577687812501],
                  [116.127574492188, 35.4795680976563],
                  [116.117345, 35.493843],
                  [116.1120325, 35.5790944648438],
                  [116.12283328125, 35.58874534375],
                  [116.107808867188, 35.6163893867188],
                  [116.127345, 35.633843],
                  [116.201871367188, 35.6265138984375],
                  [116.302345, 35.6184413886719],
                  [116.312584257813, 35.6192641425781],
                  [116.327345, 35.613843],
                  [116.331641875, 35.5865883613281],
                  [116.353013945313, 35.555630109375],
                  [116.373116484375, 35.5601369453125],
                  [116.38142703125, 35.5379274726563],
                  [116.397345, 35.5138430000001],
                  [116.402271757813, 35.4715859199219],
                  [116.448404570313, 35.4537929511719],
                  [116.456329375, 35.4136684394532],
                  [116.442506132813, 35.3447096992188],
                  [116.413170195313, 35.3236830878906],
                  [116.451519804688, 35.2880178046875],
                  [116.490904570313, 35.2728273750001],
                  [116.497345, 35.263843],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '金乡县'},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.433204375, 35.1937734199219],
                  [116.477135039063, 35.178802716797],
                  [116.491158476563, 35.1376552558594],
                  [116.507345, 35.1338430000001],
                  [116.502628203125, 35.1285622382813],
                  [116.413082304688, 35.1165859199219],
                  [116.432994414063, 35.0987953925781],
                  [116.3841028125, 35.067700421875],
                  [116.402061796875, 35.0285622382813],
                  [116.414268828125, 35.0061037421875],
                  [116.410933867188, 34.9501784492188],
                  [116.432628203125, 34.9191237617188],
                  [116.437345, 34.893843],
                  [116.41170046875, 34.8594863105469],
                  [116.407345, 34.843843],
                  [116.373170195313, 34.8596681953125],
                  [116.281519804688, 34.8780178046875],
                  [116.253170195313, 34.8996681953125],
                  [116.198316679688, 34.9085353828125],
                  [116.183170195313, 34.9296681953125],
                  [116.177345, 34.9338430000001],
                  [116.15080203125, 34.9414321113282],
                  [116.154898710938, 34.9743886542969],
                  [116.111793242188, 35.0282900214844],
                  [116.102896757813, 35.0456484199219],
                  [116.132896757813, 35.0582900214845],
                  [116.141793242188, 35.0993959785156],
                  [116.147345, 35.113843],
                  [116.185045195313, 35.1184499335938],
                  [116.214732695313, 35.1676723457032],
                  [116.210494414063, 35.196352765625],
                  [116.232379179688, 35.1995864082032],
                  [116.267345, 35.193843],
                  [116.30474734375, 35.179790265625],
                  [116.3245715625, 35.2255019355469],
                  [116.394073515625, 35.2325868964844],
                  [116.417345, 35.223843],
                  [116.433204375, 35.1937734199219],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '梁山县', cp: [116.1, 35.8]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.054483671875, 35.9683315253906],
                  [116.051529570313, 35.9483315253907],
                  [116.06298953125, 35.9394863105469],
                  [116.083516875, 35.9051638007813],
                  [116.142569609375, 35.8879457832031],
                  [116.16615359375, 35.902173078125],
                  [116.20478640625, 35.88698753125],
                  [116.22295046875, 35.8896718574219],
                  [116.237159453125, 35.8223366523437],
                  [116.293170195313, 35.7994130683594],
                  [116.291226835938, 35.7862831855469],
                  [116.307345, 35.7738430000001],
                  [116.325030546875, 35.7509291816406],
                  [116.358448515625, 35.7372524238281],
                  [116.340714140625, 35.7078493476563],
                  [116.321314726563, 35.6584987617188],
                  [116.34093875, 35.6433498359375],
                  [116.34459109375, 35.6186452460938],
                  [116.327345, 35.613843],
                  [116.312584257813, 35.6192641425781],
                  [116.302345, 35.6184413886719],
                  [116.201871367188, 35.6265138984375],
                  [116.127345, 35.633843],
                  [116.1058215625, 35.6854323554688],
                  [115.997222929688, 35.7701357246094],
                  [115.892061796875, 35.8085622382813],
                  [115.862628203125, 35.8228224921876],
                  [115.877345, 35.863843],
                  [115.885933867188, 35.8867067695313],
                  [115.853267851563, 35.913843],
                  [115.90388796875, 35.9286977363281],
                  [115.900386992188, 35.9630373359375],
                  [115.992345, 35.9724098945313],
                  [116.032608671875, 35.968305890625],
                  [116.047345, 35.9738430000001],
                  [116.054483671875, 35.9683315253906],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '曲阜市', cp: [117.0, 35.6]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.996295195313, 35.8151113105469],
                  [117.0249621875, 35.794985578125],
                  [117.042345, 35.8004006171875],
                  [117.057345, 35.795727765625],
                  [117.089928007813, 35.8058779121094],
                  [117.101158476563, 35.7876552558594],
                  [117.123531523438, 35.7800307441407],
                  [117.127345, 35.7738430000001],
                  [117.104097929688, 35.7552248359375],
                  [117.091632109375, 35.6695839667969],
                  [117.092965117188, 35.6588430000001],
                  [117.091724882813, 35.648843],
                  [117.094000273438, 35.6305165839844],
                  [117.132896757813, 35.6193959785157],
                  [117.145211210938, 35.590171125],
                  [117.191807890625, 35.5768483710938],
                  [117.211793242188, 35.5634194160157],
                  [117.188546171875, 35.523872296875],
                  [117.222896757813, 35.5093959785157],
                  [117.231793242188, 35.4982900214844],
                  [117.237345, 35.493843],
                  [117.231783476563, 35.4493959785156],
                  [117.181793242188, 35.4582900214844],
                  [117.158428984375, 35.4874648261719],
                  [117.141983671875, 35.4895095039063],
                  [117.118892851563, 35.4759352851563],
                  [117.061793242188, 35.4882900214844],
                  [117.018580351563, 35.5048989082032],
                  [116.978785429688, 35.4881288886719],
                  [116.956143828125, 35.4598537421876],
                  [116.922896757813, 35.4793959785156],
                  [116.887345, 35.483843],
                  [116.844420195313, 35.5484853339844],
                  [116.853468046875, 35.5888430000001],
                  [116.847789335938, 35.6141677070313],
                  [116.86408328125, 35.6391909003907],
                  [116.85142703125, 35.6479274726563],
                  [116.84326296875, 35.6620009589844],
                  [116.862345, 35.6577223945313],
                  [116.876939726563, 35.6609938789063],
                  [116.867345, 35.683843],
                  [116.873531523438, 35.6876552558595],
                  [116.881158476563, 35.7000307441406],
                  [116.946070585938, 35.7218752265626],
                  [116.9386340625, 35.7457619453126],
                  [116.977135039063, 35.7588832832032],
                  [116.996295195313, 35.8151113105469],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '任城区', cp: [116.56, 35.4]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.497345, 35.263843],
                  [116.500767851563, 35.2516506171875],
                  [116.509537382813, 35.2604201484376],
                  [116.490904570313, 35.2728273750001],
                  [116.451519804688, 35.2880178046875],
                  [116.413170195313, 35.3236830878906],
                  [116.442506132813, 35.3447096992188],
                  [116.456329375, 35.4136684394532],
                  [116.448404570313, 35.4537929511719],
                  [116.402271757813, 35.4715859199219],
                  [116.397345, 35.5138430000001],
                  [116.42388796875, 35.5214321113281],
                  [116.420186796875, 35.5511855292969],
                  [116.528819609375, 35.5358925605469],
                  [116.567545195313, 35.558657453125],
                  [116.597345, 35.563843],
                  [116.609595976563, 35.5460964179687],
                  [116.65326296875, 35.5297585273438],
                  [116.66142703125, 35.5079274726563],
                  [116.68326296875, 35.4797585273438],
                  [116.69142703125, 35.4479274726563],
                  [116.70408328125, 35.4284950996094],
                  [116.68435671875, 35.4148744941407],
                  [116.74326296875, 35.3997585273438],
                  [116.747345, 35.3938430000001],
                  [116.756641875, 35.3559828925781],
                  [116.747345, 35.333843],
                  [116.74197390625, 35.3292153144532],
                  [116.6818371875, 35.2514784980469],
                  [116.633043242188, 35.2860744453125],
                  [116.613917265625, 35.3289321113281],
                  [116.573306914063, 35.3384706855469],
                  [116.56271609375, 35.2650612617188],
                  [116.58271609375, 35.2292153144531],
                  [116.597345, 35.1638430000001],
                  [116.5593371875, 35.1726503730469],
                  [116.507345, 35.1338430000001],
                  [116.491158476563, 35.1376552558594],
                  [116.477135039063, 35.178802716797],
                  [116.433204375, 35.1937734199219],
                  [116.417345, 35.223843],
                  [116.47271609375, 35.2484706855469],
                  [116.497345, 35.263843],
                ]
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '微山县', cp: [117.04, 34.85]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [117.247345, 34.473843],
                  [117.243922148438, 34.4860353828125],
                  [117.235152617188, 34.4772658515625],
                  [117.242896757813, 34.4582900214844],
                  [117.221793242188, 34.4493959785157],
                  [117.187769804688, 34.4293959785157],
                  [117.157027617188, 34.4399221015625],
                  [117.131651640625, 34.5287795234375],
                  [117.137345, 34.5538430000001],
                  [117.146168242188, 34.5599355292969],
                  [117.115513945313, 34.6070119453125],
                  [117.101573515625, 34.6442739082032],
                  [117.064146757813, 34.635884015625],
                  [117.05326296875, 34.6841188789062],
                  [117.074283476563, 34.6986330390626],
                  [117.008360625, 34.7713124824219],
                  [116.987345, 34.7666017890626],
                  [116.965933867188, 34.7714003730469],
                  [116.94654421875, 34.7994826484375],
                  [116.967345, 34.813843],
                  [116.970767851563, 34.8016506171876],
                  [116.979537382813, 34.8104201484376],
                  [116.967345, 34.813843],
                  [116.960904570313, 34.8357949042969],
                  [116.9232434375, 34.8521279121094],
                  [116.943541289063, 34.8689882636719],
                  [116.921881132813, 34.8783803535156],
                  [116.912808867188, 34.8993056464844],
                  [116.891881132813, 34.9083803535156],
                  [116.862691679688, 34.9293886542969],
                  [116.824298125, 34.925473859375],
                  [116.817345, 34.9338430000001],
                  [116.811812773438, 34.965639875],
                  [116.765948515625, 34.984966046875],
                  [116.709932890625, 35.0297646308594],
                  [116.641793242188, 35.0682900214844],
                  [116.625269804688, 35.1074953437501],
                  [116.604215117188, 35.1243556953125],
                  [116.597345, 35.1638430000001],
                  [116.58271609375, 35.2292153144531],
                  [116.56271609375, 35.2650612617188],
                  [116.573306914063, 35.3384706855469],
                  [116.613917265625, 35.3289321113281],
                  [116.633043242188, 35.2860744453125],
                  [116.6818371875, 35.2514784980469],
                  [116.74197390625, 35.3292153144532],
                  [116.747345, 35.333843],
                  [116.757906523438, 35.3244057441407],
                  [116.776783476563, 35.3032802558594],
                  [116.795484648438, 35.2865724921875],
                  [116.782628203125, 35.2585622382813],
                  [116.761822539063, 35.2287783027344],
                  [116.781929960938, 35.2108119941407],
                  [116.7826575, 35.1986171699219],
                  [116.762838164063, 35.1621498847657],
                  [116.807345, 35.153843],
                  [116.81298953125, 35.1494863105469],
                  [116.82677859375, 35.1316237617188],
                  [116.883780546875, 35.0919802070313],
                  [116.90170046875, 35.0481996894532],
                  [116.91298953125, 35.0394863105469],
                  [116.92170046875, 35.0281996894532],
                  [116.950484648438, 35.0164174628907],
                  [116.953140898438, 34.998452375],
                  [116.94088015625, 34.9781276679688],
                  [116.972857695313, 34.9367018867188],
                  [117.016832304688, 34.9432009101563],
                  [117.045889921875, 34.9256728339844],
                  [117.103331328125, 34.9341616035156],
                  [117.12568484375, 34.853891828125],
                  [117.165343046875, 34.8299697089844],
                  [117.187345, 34.8238430000001],
                  [117.182896757813, 34.8182900214844],
                  [117.163448515625, 34.8027150703125],
                  [117.16166140625, 34.788344953125],
                  [117.182896757813, 34.7793959785157],
                  [117.2476184375, 34.7276332832032],
                  [117.262706328125, 34.7295095039063],
                  [117.281983671875, 34.7181764960938],
                  [117.299249296875, 34.7203237128907],
                  [117.312896757813, 34.7093959785157],
                  [117.331793242188, 34.6782900214845],
                  [117.342896757813, 34.6693959785157],
                  [117.351793242188, 34.6582900214844],
                  [117.362896757813, 34.6493959785156],
                  [117.371846953125, 34.62815940625],
                  [117.390181914063, 34.6304396796876],
                  [117.407345, 34.623843],
                  [117.39142703125, 34.5997585273438],
                  [117.387345, 34.573843],
                  [117.359718046875, 34.5851955390625],
                  [117.298580351563, 34.5666237617188],
                  [117.2758215625, 34.5711208320313],
                  [117.257823515625, 34.4813539863282],
                  [117.247345, 34.473843],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '鱼台县', cp: [116.55, 35.03]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.811812773438, 34.965639875],
                  [116.817345, 34.9338430000001],
                  [116.781783476563, 34.9396852851563],
                  [116.771143828125, 34.9136940742188],
                  [116.727345, 34.923843],
                  [116.703170195313, 34.9296681953125],
                  [116.6318371875, 34.9380178046875],
                  [116.583170195313, 34.9180178046875],
                  [116.483370390625, 34.8980361152344],
                  [116.437345, 34.893843],
                  [116.432628203125, 34.9191237617188],
                  [116.410933867188, 34.9501784492188],
                  [116.414268828125, 35.0061037421875],
                  [116.402061796875, 35.0285622382813],
                  [116.3841028125, 35.067700421875],
                  [116.432994414063, 35.0987953925781],
                  [116.413082304688, 35.1165859199219],
                  [116.502628203125, 35.1285622382813],
                  [116.507345, 35.1338430000001],
                  [116.5593371875, 35.1726503730469],
                  [116.597345, 35.1638430000001],
                  [116.604215117188, 35.1243556953125],
                  [116.625269804688, 35.1074953437501],
                  [116.641793242188, 35.0682900214844],
                  [116.709932890625, 35.0297646308594],
                  [116.765948515625, 34.984966046875],
                  [116.811812773438, 34.965639875],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '邹城市', cp: [117.05, 35.35]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [117.061793242188, 35.4882900214844],
                  [117.118892851563, 35.4759352851563],
                  [117.141983671875, 35.4895095039063],
                  [117.158428984375, 35.4874648261719],
                  [117.181793242188, 35.4582900214844],
                  [117.231783476563, 35.4493959785156],
                  [117.237345, 35.493843],
                  [117.286475859375, 35.4826821113282],
                  [117.327345, 35.4775991035157],
                  [117.342564726563, 35.4794924140626],
                  [117.381793242188, 35.4682900214844],
                  [117.427345, 35.453843],
                  [117.443975859375, 35.4410060859375],
                  [117.467423125, 35.4021315742188],
                  [117.43298953125, 35.3450429511719],
                  [117.44170046875, 35.3281996894532],
                  [117.47170046875, 35.3159206367187],
                  [117.459327421875, 35.2907521796875],
                  [117.417345, 35.273843],
                  [117.405694609375, 35.282192609375],
                  [117.388155546875, 35.3066640449219],
                  [117.311890898438, 35.3219533515625],
                  [117.307345, 35.293843],
                  [117.262388945313, 35.2883290839844],
                  [117.250748320313, 35.2895156074219],
                  [117.252901640625, 35.2683974433594],
                  [117.201881132813, 35.2593056464844],
                  [117.192808867188, 35.2483803535156],
                  [117.1381653125, 35.2323451972656],
                  [117.01685671875, 35.2187416816406],
                  [116.949893828125, 35.1765444160156],
                  [116.917345, 35.1798622871094],
                  [116.901954375, 35.1782936835938],
                  [116.888721953125, 35.1942263007813],
                  [116.852398710938, 35.1680849433594],
                  [116.81740359375, 35.1881264472656],
                  [116.807345, 35.153843],
                  [116.762838164063, 35.1621498847657],
                  [116.7826575, 35.1986171699219],
                  [116.781929960938, 35.2108119941407],
                  [116.761822539063, 35.2287783027344],
                  [116.782628203125, 35.2585622382813],
                  [116.795484648438, 35.2865724921875],
                  [116.776783476563, 35.3032802558594],
                  [116.757906523438, 35.3244057441407],
                  [116.747345, 35.333843],
                  [116.756641875, 35.3559828925781],
                  [116.747345, 35.3938430000001],
                  [116.78763796875, 35.3980873847657],
                  [116.806300078125, 35.42837425],
                  [116.841949492188, 35.4405239082032],
                  [116.85287234375, 35.4371218085938],
                  [116.881871367188, 35.460610578125],
                  [116.887345, 35.483843],
                  [116.922896757813, 35.4793959785156],
                  [116.956143828125, 35.4598537421876],
                  [116.978785429688, 35.4881288886719],
                  [117.018580351563, 35.5048989082032],
                  [117.061793242188, 35.4882900214844],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '兖州市'},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.715318632813, 35.7003395820313],
                  [116.737345, 35.6975991035157],
                  [116.761890898438, 35.7006520820313],
                  [116.786920195313, 35.6693959785157],
                  [116.803018828125, 35.6884316230469],
                  [116.801241484375, 35.7027260566407],
                  [116.867345, 35.683843],
                  [116.876939726563, 35.6609938789063],
                  [116.862345, 35.6577223945313],
                  [116.84326296875, 35.6620009589844],
                  [116.85142703125, 35.6479274726563],
                  [116.86408328125, 35.6391909003907],
                  [116.847789335938, 35.6141677070313],
                  [116.853468046875, 35.5888430000001],
                  [116.844420195313, 35.5484853339844],
                  [116.887345, 35.483843],
                  [116.881871367188, 35.460610578125],
                  [116.85287234375, 35.4371218085938],
                  [116.841949492188, 35.4405239082032],
                  [116.806300078125, 35.42837425],
                  [116.78763796875, 35.3980873847657],
                  [116.747345, 35.3938430000001],
                  [116.74326296875, 35.3997585273438],
                  [116.68435671875, 35.4148744941407],
                  [116.70408328125, 35.4284950996094],
                  [116.69142703125, 35.4479274726563],
                  [116.68326296875, 35.4797585273438],
                  [116.66142703125, 35.5079274726563],
                  [116.65326296875, 35.5297585273438],
                  [116.609595976563, 35.5460964179687],
                  [116.597345, 35.563843],
                  [116.59084109375, 35.5807656074219],
                  [116.621793242188, 35.6334194160156],
                  [116.612896757813, 35.6593959785157],
                  [116.593355742188, 35.6926406074219],
                  [116.607345, 35.7038430000001],
                  [116.632896757813, 35.7082900214844],
                  [116.682589140625, 35.7195778632813],
                  [116.715318632813, 35.7003395820313],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '汶上县', cp: [116.47, 35.68]},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [116.681016875, 35.8160927558595],
                  [116.683082304688, 35.7814577460937],
                  [116.66259890625, 35.7585305],
                  [116.625094023438, 35.7607656074219],
                  [116.612061796875, 35.7491237617188],
                  [116.607345, 35.7038430000001],
                  [116.593355742188, 35.6926406074219],
                  [116.612896757813, 35.6593959785157],
                  [116.621793242188, 35.6334194160156],
                  [116.59084109375, 35.5807656074219],
                  [116.597345, 35.563843],
                  [116.567545195313, 35.558657453125],
                  [116.528819609375, 35.5358925605469],
                  [116.420186796875, 35.5511855292969],
                  [116.42388796875, 35.5214321113281],
                  [116.397345, 35.5138430000001],
                  [116.38142703125, 35.5379274726563],
                  [116.373116484375, 35.5601369453125],
                  [116.353013945313, 35.555630109375],
                  [116.331641875, 35.5865883613281],
                  [116.327345, 35.613843],
                  [116.34459109375, 35.6186452460938],
                  [116.34093875, 35.6433498359375],
                  [116.321314726563, 35.6584987617188],
                  [116.340714140625, 35.7078493476563],
                  [116.358448515625, 35.7372524238281],
                  [116.325030546875, 35.7509291816406],
                  [116.307345, 35.7738430000001],
                  [116.311519804688, 35.7796681953125],
                  [116.342393828125, 35.8017971015625],
                  [116.367345, 35.7968666816407],
                  [116.382345, 35.7998305488281],
                  [116.417056914063, 35.7929714179688],
                  [116.461519804688, 35.8296681953126],
                  [116.533980742188, 35.8441957832032],
                  [116.554678984375, 35.8978639960937],
                  [116.593170195313, 35.9080178046875],
                  [116.607603789063, 35.9281581855469],
                  [116.633170195313, 35.9380178046875],
                  [116.637345, 35.943843],
                  [116.667345, 35.943843],
                  [116.667345, 35.9238430000001],
                  [116.661265898438, 35.9041493964844],
                  [116.602857695313, 35.8519655585938],
                  [116.681016875, 35.8160927558595],
                ],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {name: '泗水县'},
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [117.332896757813, 35.7893959785156],
                  [117.365318632813, 35.7703395820313],
                  [117.382345, 35.7682216621094],
                  [117.40982546875, 35.7716396308594],
                  [117.446060820313, 35.7451735664063],
                  [117.491793242188, 35.7182900214844],
                  [117.52302859375, 35.7093581367187],
                  [117.520533476563, 35.6892958808594],
                  [117.532896757813, 35.6793959785156],
                  [117.541793242188, 35.6682900214845],
                  [117.587345, 35.633843],
                  [117.580753203125, 35.6036305976563],
                  [117.584849882813, 35.5526601386719],
                  [117.527345, 35.5480397773438],
                  [117.492906523438, 35.5508071113281],
                  [117.4919153125, 35.5385195136719],
                  [117.52197390625, 35.5126247382813],
                  [117.488834257813, 35.5079201484375],
                  [117.45986453125, 35.5102480292969],
                  [117.427345, 35.453843],
                  [117.381793242188, 35.4682900214844],
                  [117.342564726563, 35.4794924140626],
                  [117.327345, 35.4775991035157],
                  [117.286475859375, 35.4826821113282],
                  [117.237345, 35.493843],
                  [117.231793242188, 35.4982900214844],
                  [117.222896757813, 35.5093959785157],
                  [117.188546171875, 35.523872296875],
                  [117.211793242188, 35.5634194160157],
                  [117.191807890625, 35.5768483710938],
                  [117.145211210938, 35.590171125],
                  [117.132896757813, 35.6193959785157],
                  [117.094000273438, 35.6305165839844],
                  [117.091724882813, 35.648843],
                  [117.092965117188, 35.6588430000001],
                  [117.091632109375, 35.6695839667969],
                  [117.104097929688, 35.7552248359375],
                  [117.127345, 35.7738430000001],
                  [117.172345, 35.7794655585938],
                  [117.245382109375, 35.7703798652344],
                  [117.297345, 35.793843],
                  [117.332896757813, 35.7893959785156],
                ],
              ],
            ],
          },
        },
      ],
    };
  }
}
