import { GXService } from '@shared/GX.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  MenuService,
  SettingsService,
  TitleService,
  ALAIN_I18N_TOKEN,
} from '@delon/theme';
import { ACLService } from '@delon/acl';
import { TranslateService } from '@ngx-translate/core';
import { I18NService } from '../i18n/i18n.service';
import { DA_SERVICE_TOKEN, TokenService } from '@delon/auth';
import { ReuseTabService } from '@delon/abc';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
    public gxService: GXService,
  ) { }

  load(): Promise<any> {
    this.reuseTabService.keepingScroll = true;
    if (this.reuseTabService.keepingScroll) {
      return new Promise((resolve, reject) => {
        zip(
          this.httpClient.get(`assets/tmp/i18n/zh-CN.json`),
          this.httpClient.get('/energyreport/getsysinfo'),
          this.httpClient.get('/energyreport/menu'),
        )
          .pipe(
            // 接收其他拦截器后产生的异常消息
            catchError(([langData, sysInfo, menuData]) => {
              resolve(null);
              return [langData, sysInfo, menuData];
            }),
          )
          .subscribe(
            ([langData, sysInfo, menuData]) => {
              this.translate.setTranslation(this.i18n.defaultLang, langData);
              this.translate.setDefaultLang(this.i18n.defaultLang);
              const app = {
                name: sysInfo.title,
                description: sysInfo.title,
              };

              // 应用信息：包括站点名、描述、年份
              this.settingService.setApp(app);
              // 用户信息：包括姓名、头像、邮箱地址
              const user = {
                name: sysInfo.userName,
                email: sysInfo.email,
                avatar: 'assets/img/head.jpg',
              };
              this.settingService.setUser(user);
              // ACL：设置权限为全量
              this.aclService.setFull(true);
              const mainMenu = {
                text: '菜单',
                i18n: 'main_navigation',
                group: true,
                hideInBreadcrumb: true,
                children: menuData.menus,
                shortcut_root: true,
              };
              // 初始化菜单
              this.menuService.add([mainMenu]);
              setTimeout(() => {
                // this.titleService.suffix = this.gxService.XinhaiConfig.CommonHeaderTitle.data;
              }, 100);
            },
            () => { },
            () => {
              resolve(null);
            },
          );
      });
    }


  }
}
