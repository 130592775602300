import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';
@Injectable({
  providedIn: 'root',
})
/**
 *
 *
 * @author zhangqiuju
 * @date 2020-03-22
 * @export
 * @class EnergyAnalysisCompanyService
 */
export class EnergyAnalysisCompanyService {
  constructor(private http: HttpClient) {}
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-type':
        'application/json; application/x-www-form-urlencodeed; charset=utf-8;',
    }),
  };

  private getRegionTradeDataUrl ='/energyreport/energyAnalysisCompany/getRegionTradeData';
  private getCompanyDataUrl ='/energyreport/energyAnalysisCompany/getCompanyData';
  private getRegionListByLoginUserUrl ='/energyreport/energy-consumption-analysis/getRegionListByLoginUser';
  private getTradeTreeDataUrl ='/energyreport/energybenchmarking/getTradeTree';
  private getTradeWithNodeDataUrl ='/energyreport/energybenchmarking/getTradeWithNode/';
  getRegionListByLoginUser(): Observable<InvokeResult> {
    return this.http.get<InvokeResult>(this.getRegionListByLoginUserUrl);
  }

  getRegionTradeData( dataType: string, dateBegin: string, dateEnd: string, regionId: string, industyId:string,companyName:string ): Promise<any> {
    let url = this.getRegionTradeDataUrl + '/' + dataType + '/' + dateBegin + '/' + dateEnd + '/' + regionId+'/'+companyName+'/'+industyId;
    return this.http .get(url) .toPromise() .then(data => { return data; });
  }

  getCompanyData( dataType: string, dateBegin: string, dateEnd: string, companyOrgId, ): Promise<any> {
    let url = this.getCompanyDataUrl + '/' + dataType + '/' + dateBegin + '/' + dateEnd + '/' + companyOrgId;
    return this.http .get(url) .toPromise() .then(data => { return data; });
  }

  getTradeData(): Promise<any> {
    return this.http .get(this.getTradeTreeDataUrl) .toPromise() .then((res: any) => { return res; });
  }

  getTradeWithNodeData(key: any): Promise<any> {
    return this.http .get(this.getTradeWithNodeDataUrl + key) .toPromise() .then((res: any) => { return res; });
  }
}
