import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService, NzModalService, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'onlinecollectionproject-energyindex',
  templateUrl: './onlinecollectionproject-energyindex.component.html',
  styleUrls: ['./onlinecollectionproject-energyindex.component.less']
})
export class OnlinecollectionprojectEnergyindexComponent implements OnInit {
  userId: string = '';
  isVisible = false;
  treeNodes: [] = [];
  defaultExpandedKeys: string[] = [];
  defaultCheckedKeys: string[] = [];
  listOfData:any[] = [];
  @ViewChild('nzTreeComponent') nzTreeComponent!: NzTreeComponent;

  checked = false;
  indeterminate = false;
  listOfCurrentPageData:[] = [];
  setOfCheckedId = new Set<string>();

  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.userId = params['userId'];
    }); 
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    let url = 'energyreport/CompanyEnergyIndex/data/' + this.userId;
    this.http.get(url).subscribe((result: any) => {
      this.listOfData = result.data;
    });
  }

  add() {
    this.isVisible = true;
    let kind_ids: any = [1];
    let url = 'energyreport/CompanyEnergyIndex/tree/' + kind_ids;
    this.http.get(url).subscribe((result: any) => {
      this.treeNodes = result.data;
      this.defaultExpandedKeys = [];
      this.defaultCheckedKeys = [];
      this.defaultExpandedKeys.push(result[0]['key']);
    });
  }

  delete() {
    let nodes = [];
    this.setOfCheckedId.forEach(item => {
      nodes.push(item);
    })
    let url = '/energyreport/CompanyEnergyIndex/delete/' + nodes + '/' + this.userId;
    this.http.get(url).subscribe((result: any) => {
      if (result.data) {
        this.message.success('删除成功!');
        this.getData();
      } else { 
        this.message.error('删除失败!');
      }
    });
  }

  handleOk(): void {
    this.isVisible = false;
    let nodes = []
    let checks: NzTreeNode[]  = this.nzTreeComponent.getCheckedNodeList();
    let haltChecks: NzTreeNode[]  = this.nzTreeComponent.getHalfCheckedNodeList();
    checks.forEach((item : NzTreeNode) => {
      nodes.push(item.key);
    });
    haltChecks.forEach((item : NzTreeNode) => {
      nodes.push(item.key);
    });
    let url = '/energyreport/CompanyEnergyIndex/save/' + nodes + '/' + this.userId;
    this.http.get(url).subscribe((result: any) => {
      if (result.data) {
        this.message.success('保存成功!');
        this.getData();
      } else {
        this.message.error('保存失败!');
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item["id"], value));
    this.refreshCheckedStatus();
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item["id"]));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item["id"])) && !this.checked;
  }

  onCurrentPageDataChange($event: []): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

}
