import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginInfo } from './login-info';
@Component({
  selector: 'public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.less']
})
export class PublicLayoutComponent implements OnInit {
  typeId: any;
  title: any;
  loginInfo: LoginInfo;
  constructor(private http: HttpClient) {

  }

  ngOnInit() {
    this.typeId = 'XWZX';
    this.loginInfo = new LoginInfo();
    this.loginInfo.title = '';
    this.loginInfo.desc = '';
    this.loginInfo.company = '';
    this.loginInfo.time = '';
    this.http.get('assets/tmp/login-info.json').subscribe((data: LoginInfo) => {
      if (data.title) this.loginInfo.title = data.publicTitle;
      if (data.desc) this.loginInfo.desc = data.desc;
      if (data.company) this.loginInfo.company = data.company;
      if (data.company) this.loginInfo.company = data.company;
      if (data.time) this.loginInfo.time = data.time;
    });
  }

}
