import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { GXService } from '@shared/GX.service';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { concatMap, map, first } from 'rxjs/operators';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';

@Component({
  selector: 'onlinecollect-register',
  templateUrl: './onlinecollect-register.component.html',
  styleUrls: ['./onlinecollect-register.component.less'],
})
export class OnlinecollectRegisterComponent implements OnInit, OnDestroy {
  form: FormGroup;
  error = '';
  loading = false;
  registerUrl = '/energyreport/onlineCollectProjectUser/register';
  confirmPassWord = (
    control: FormControl,
  ): Observable<{ [s: string]: boolean }> => {
    return control.valueChanges
      ? control.valueChanges.pipe(
          map(value => {
            if (value !== control.parent.get('password').value) {
              return { confirmPassWord: true, error: true };
            } else {
              return null;
            }
          }),
          first(),
        )
      : null;
  };
  validSub$: Subscription;
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    public gxService: GXService,
    private msg: NzMessageService,
    private injector: Injector,
  ) {
    this.form = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      checkPwd: [null, [Validators.required], this.confirmPassWord],
    });
    this.validSub$ = this.form
      .get('password')
      .valueChanges.subscribe(result => {
        const checkPwdControl = this.form.get('checkPwd');
        checkPwdControl.dirty && checkPwdControl.updateValueAndValidity();
      });
  }
  ngOnDestroy(): void {
    this.validSub$.unsubscribe();
  }

  ngOnInit() {}

  //提交注册信息
  submit() {
    let param = {
      name: this.form.value['username'],
      password: this.form.value['password'],
    };
    this.http
      .post(this.registerUrl, param)
      .toPromise()
      .then((result: InvokeResult) => {
        if (result['success']) {
          // this.msg.success("注册成功！即将跳转至登录界面!");
          const id = this.msg.loading('注册成功！即将跳转至登录界面!', {
            nzDuration: 0,
          }).messageId;
          setTimeout(() => {
            this.msg.remove(id);
            this.goTo('/onlinecollect/login');
          }, 2000);
        } else {
          this.msg.error(result['errorMessage']);
        }
      })
      .catch(err => {
        this.msg.error(err['errorMessage']);
      });
  }

  goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }
}
