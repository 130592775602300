import { OnInit, Component, Input } from '@angular/core';
import { GXService } from '@shared/GX.service';
import { ConstantUtil } from '@shared/util/ConstantUtil.util';
import { NzModalRef } from 'ng-zorro-antd';
import { EnergyAnalysisCompanyService } from '../energy-analysis-company/energy-analysis-company.service';

@Component({
  selector: 'energy-analysis-company-echart',
  templateUrl: './energy-analysis-company-echart.component.html',
  styleUrls: ['./energy-analysis-company-echart.component.less'],
})
export class EnergyAnalysisCompanyEchartComponent implements OnInit {
  @Input() queryParams: any;
  public isEmpty = GXService.isEmpty;
  isLoading: boolean = false;
  dateType: any = 'month'; //选择日期类型: 年/月
  dateRange: Date[] = GXService.getGxInitDateRange(ConstantUtil.MONTH);
  isShowSearch: boolean; //是否显示查询条件
  topData = []; //顶部卡片数据
  tplModal: NzModalRef;
  companyInfo: any = {};
  iconList = [
    'icon-dian',
    'icon-meitan',
    'icon-reli',
    'icon-you',
    'icon-jiaotan',
    'icon-qi',
    'icon-qita',
  ];
  orgId: string;
  leftEchartOption: any; //饼图数据
  rightEchartOption: any; //折线图数据
  companyName: string;
  regionOrIndustyName: string;
  searchType: string; //区域；行业
  orderList = ['电力', '煤炭', '气体', '焦炭', '热力', '油品', '其他能源'];
  companyOrgId: string = '';
  constructor(
    public gxShareService: GXService,
    private energyAnalysisCompanyEchartService: EnergyAnalysisCompanyService,
  ) {}

  ngOnInit() {
    if (this.queryParams) {
      this.isShowSearch = false;
      this.dateType = this.queryParams['dateType'];
      this.dateRange = this.queryParams['dateRange'];
      this.companyOrgId = this.queryParams['companyOrgId'];
    }
    this.changeCompany(this.companyOrgId);
  }

  //获取顶部卡片数据
  getTopData(energyData?) {
    this.topData = [];
    for (let key in energyData) {
      this.topData = [...this.topData, energyData[key]];
    }
    this.topData = this.gxShareService
      .sortInOrderList(this.orderList, this.topData, 'name')
      .slice(0, 5);
  }

  //获取左侧饼图(消费能源结构及占比)
  getLeftPieData(energyData: { name: string; value: number }[]) {
    let data = JSON.parse(ConstantUtil['PIE_NODATA_DEFAULT']);
    const pieData = energyData.filter(item => Number(item['value']) != 0);
    if (!GXService.isEmpty(pieData) && pieData['value'] != '-') {
      data = pieData;
    }
    this.leftEchartOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        data: data.map(item => item['name']),
      },
      series: [
        {
          name: '能源消费总量(吨标准煤)',
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          data: data,
          label: {
            formatter: '{b}:{d}%',
            borderRadius: 4,
            rich: {
              b: {
                fontSize: 14,
              },
            },
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }
  //获取右侧折线图数据(能源消费总量月度趋势)
  getRightLineData(data) {
    var lineData = [];
    var datearr = [],
      elearr = [],
      coalarr = [],
      heatarr = [],
      oilsarr = [],
      cokearr = [],
      gasarr = [],
      otherarr = [];
    for (var i = 0; i < data.length; i++) {
      datearr.push(data[i].date);
      elearr.push(data[i].electricValue);
      coalarr.push(data[i].coalValue);
      heatarr.push(data[i].heatValue);
      oilsarr.push(data[i].oilsValue);
      cokearr.push(data[i].cokeValue);
      gasarr.push(data[i].gasValue);
      otherarr.push(data[i].otherValue);
    }
    lineData = [
      { name: '电力', type: 'line', data: elearr },
      { name: '煤炭', type: 'line', data: coalarr },
      { name: '热力', type: 'line', data: heatarr },
      { name: '油品', type: 'line', data: oilsarr },
      { name: '焦炭', type: 'line', data: cokearr },
      { name: '气体', type: 'line', data: gasarr },
      { name: '其他能源', type: 'line', data: otherarr },
    ];
    this.rightEchartOption = {
      legend: {
        orient: 'horizontal',
        x: 'center', //可设定图例在左、右、居中
        y: 'bottom', //可设定图例在上、下、居中},
        icon: 'roundRect',
        data: lineData.map(item => item['name']),
      },
      tooltip: { trigger: 'axis' },
      xAxis: { type: 'category', data: datearr },
      yAxis: { type: 'value', name: '(吨标准煤)' },
      series: lineData,
    };
  }

  //查看各个公司的详情数据
  changeCompany(companyOrgId) {
    this.isLoading = true;
    this.topData = [];
    this.leftEchartOption = [];
    this.rightEchartOption = [];
    this.energyAnalysisCompanyEchartService
      .getCompanyData(
        this.dateType,
        moment(this.dateRange[0]).format('YYYY-MM-DD'),
        moment(this.dateRange[1]).format('YYYY-MM-DD'),
        companyOrgId,
      )
      .then(data => {
        this.companyInfo = data['companyInfo'];
        this.companyName = data['companyInfo']['fullName'];
        this.getTopData(data['energyData']);
        this.getLeftPieData(
          data['pieData']
            .filter(item => GXService.ConvertToNumber(item['valueBQ']) != 0)
            .map(item => {
              return { name: item['name'], value: item['valueBQ'] };
            }),
        );
        this.getRightLineData(data['lineData']);
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
