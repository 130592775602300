import { filter } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { GXService } from '@shared/GX.service';
import { ConstantUtil } from '@shared/util/ConstantUtil.util';

declare var echarts: any;

@Component({
  selector: 'echart-map2',
  templateUrl: './echart-map2.component.html',
  styleUrls: ['./echart-map2.component.less'],
})
export class EchartMap2 implements OnInit, AfterViewInit {
  @ViewChild('mapChart') mapChart: ElementRef;
  mapEchartsOption: any = null;
  geoJsonData = {};
  @Input() visualMapColor?= [
    '#0991FC',
    '#0981FC',
    '#0971FC',
    '#0961FC',
    '#0951FC',
    '#0941FC',
  ];//地图的颜色
  @Input() showVisualMap?= false;//是否显示虚拟地图(色块筛选)
  @Input() mapData = []; //地图显示数据
  @Input() linesData = []; //折线引出的区域数据
  @Input() zoom: number = 1.2;
  @Input() echartsId = ''; // 地图id名
  constructor(public shareService: GXService) { }

  ngAfterViewInit(): void { }
  ngOnInit(): void { }

  /**
   * 获取GeoJson数据
   * @param callback
   */
  getGeoJson(callback?) {
    /* 代码级别获取地图数据
       GeoJson.getGeoJson().subscribe(res => {
      this.geoJsonData['features'] = res['features'];
    }); */
    //兼容地图数据本地文件读取
    this.shareService.readJson().subscribe(res => {
      if (GXService.isEmpty(res)) return;
      this.geoJsonData['features'] = res['features'];
      if (callback) callback(res);
    });
  }
  /**
   *地图echarts重写 2020-03-13
   *
   * @author ludaxian
   * @date 2020-03-12
   * @param [mapData]
   */
  setMapChart(): void {
    this.getGeoJson(res => {
      let luechart = echarts.init(document.getElementById(this.echartsId));
      luechart.clear();
      echarts.registerMap('gaoxinMap', res);
      const geoRegionList = res['features'].map(
        item => item['properties']['name'],
      );
      // geoRegionList.filter(item=>this.mapData.mapitem.name )
      let energyTypeMap = new Map(
        this.mapData.map(item => [item['name'], item]) as any,
      );
      this.mapData = geoRegionList.map(name => {
        let res = energyTypeMap.get(name);
        if (GXService.isEmpty(res)) {
          res = {
            name: name,
            label: { show: true },
          };
        }
        return res;
      });
      if (GXService.isEmpty(this.mapData)) return;
      this.mapData = this.mapData.map(item => {
        if (this.linesData.map(item1 => item1.name).includes(item['name'])) {
          item['label'] = { show: false };
        }
        return item;
      });
      this.mapEchartsOption = {
        tooltip: {
          trigger: 'item',
          formatter: tipData => {
            let tip = '';
            if (
              !GXService.isEmpty(tipData['data']) &&
              !GXService.isEmpty(tipData['data']['companyNum'])
            ) {
              tip =
                `${tipData['name']}(${tipData['data']['companyNum']}户ss企业)<br/>` +
                `<table border="1" cellspacing="0"> <tr> <th>名称</th> <th>数值</th> <th>单位</th> </tr>`;
            } else {
              tip =
                `${tipData['name']}<br/>` +
                `<table > <tr> <th>名称</th> <th>数值</th> <th>单位</th> </tr>`;
            }
            if (
              !GXService.isEmpty(tipData['data']) &&
              !GXService.isEmpty(tipData['data']['toolTip'])
            ) {
              tipData.data.toolTip.filter(data => {
                if (data['indexName'] == '综合能耗')
                  data['indexName'] = ConstantUtil.ZHNYXFL;
              });
              tipData.data.toolTip.forEach(item => {
                tip +=
                  `<tr> <td>` +
                  `${item.indexName}</td>` +
                  `<td>${item.value}</td>` +
                  `<td>${item.unit}</td></tr>`;
              });
            }
            tip += '</table>';
            return tip;
          },
        },
        geo: { map: 'gaoxinMap', zoom: this.zoom, label: { show: false } },
        visualMap: {
          text: ['高', '低'],
          realtime: true,
          show: this.showVisualMap,
          right: "5%",
          calculable: true,
          min: 0,
          max: Math.max(
            ...this.mapData.map(item =>
              Number(GXService.ConvertToNumber(item['value']).toFixed(2)),
            ),
          ) || 1 /* Math.max(
            ...this.mapData.map(item =>
              Number(item['toolTip'][0]['value'].toFixed(2)),
            ) */,
          // max:10,max最大的最小值设置为1,避免出现黄色颜色
          inRange: {
            color: this.visualMapColor,
            //color: [ '#185be1'],
          },
        },
        series: [
          {
            type: 'lines',
            symbol: 'circle',
            symbolSize: [10, 10],
            color: '#ff8003',
            tooltip: {
              trigger: 'item',
              formatter: e => {
                // let tip = `${e['name']}<br/>`;
                const mapData = this.mapData.find(item => item.name == e.name);
                let tip = '';
                if (
                  !GXService.isEmpty(mapData) &&
                  !GXService.isEmpty(mapData['companyNum'])
                ) {
                  tip =
                    `${e['name']}(${mapData['companyNum']}户xxx企业)<br/>` +
                    `<table border="1" cellspacing="0"> <tr> <th>名称</th> <th>数值</th> <th>单位</th> </tr>`;
                } else {
                  tip =
                    `${e['name']}<br/>` +
                    `<table > <tr> <th>名称</th> <th>数值</th> <th>单位</th> </tr>`;
                }

                if (
                  !GXService.isEmpty(mapData) &&
                  !GXService.isEmpty(mapData['toolTip'])
                ) {
                  //   mapData['toolTip'].forEach(item => {
                  //     tip += `${item.indexName}(${item.unit}) : ${item.value}</br>`;
                  //   });
                  mapData['toolTip'].forEach(item => {
                    tip +=
                      `<tr> <td>` +
                      `${item.indexName}</td>` +
                      `<td>${item.value}</td>` +
                      `<td>${item.unit}</td></tr>`;
                  });
                }
                tip += '</table>';

                return tip;
              },
            },
            opacity: 1,
            label: {
              borderRadius: 10,
              show: true,
              padding: [10, 15],
              color: '#fff',
              backgroundColor: '#185be1',
              formatter: e => {
                let tip = `${e['name']}`;
                var str = '';
                var num = 5; //每行显示字数
                var valLength = tip.length; //该项x轴字数
                var rowNum = Math.ceil(valLength / num); // 行数

                if (rowNum > 1) {
                  for (var i = 0; i < rowNum; i++) {
                    var temp = '';
                    var start = i * num;
                    var end = start + num;
                    if (i == rowNum - 1) {
                      temp = tip.substring(start, end);
                    } else {
                      temp = tip.substring(start, end) + '\n';
                    }

                    str += temp;
                  }
                  return ['{b|' + str + '}'];
                } else {
                  return ['{b|' + tip + '}'];
                }
              },
              rich: {
                b: {
                  fontSize: 14,
                  align: 'center',
                },
              },
            },
            zlevel: 2,
            lineStyle: {
              type: 'solid',
              opacity: 1,
              color: '#f7f7f7',
            },
            data: this.linesData,
          },
          {
            name: '全局数据统计',
            type: 'map',
            mapType: 'gaoxinMap', // 自定义扩展图表类型 // center: [104.114129, 37.550339],//当前视角的中心点
            zoom: this.zoom, //当前视角的缩放比例
            //roam: true, //是否开启平游或缩放
            /*   scaleLimit: {
              //滚轮缩放的极限控制
              min: 1,
              max: 3,
            }, */
            itemStyle: {
              normal: { label: { show: true } },
              emphasis: { label: { show: true } },
            },
            label: {
              normal: {
                textStyle: {
                  fontSize: 14,
                  color: '#f7f7f7',
                },
              },
            },
            //mapData
            data: this.mapData.map(item => {
              item['value'] = Number(
                GXService.ConvertToNumber(item['value']),
              ).toFixed(2);
              return item;
            }),
          },
        ],
      };

      luechart.setOption(this.mapEchartsOption);
      window.addEventListener('resize', () => {
        luechart.resize();
      });
    });
  }
}
