import { Component, OnInit } from '@angular/core';
import { tianjin } from './tianjin1.json';
const moment = require("moment")
declare var echarts: any;

@Component({
  selector: 'monitoring-platform',
  templateUrl: './monitoring-platform.component.html',
  styleUrls: ['./monitoring-platform.component.less']
})
export class MonitoringPlatformComponent implements OnInit {


  listType: string = "all"
  echartMapData = tianjin;
  tableLeftTopData = [
    {
      name: "全市",
      mubiao: "6.1%",
      now: "3.1%",
      progress: "51"
    },
    {
      name: "河东区",
      mubiao: "5%",
      now: "3%",
      progress: "60"
    },
    {
      name: "河西区",
      mubiao: "5%",
      now: "2%",
      progress: "40"
    },
    {
      name: "南开区",
      mubiao: "6%",
      now: "4%",
      progress: "66.6"
    },
    {
      name: "河北区",
      mubiao: "8%",
      now: "5%",
      progress: "62.5"
    },
    {
      name: "红桥区",
      mubiao: "5%",
      now: "3%",
      progress: "60"
    },
    {
      name: "滨海新区",
      mubiao: "9%",
      now: "6%",
      progress: "66.6"
    },
    {
      name: "东丽区",
      mubiao: "5%",
      now: "2%",
      progress: "40"
    },
    {
      name: "西青区",
      mubiao: "4%",
      now: "1%",
      progress: "25"
    },
    {
      name: "津南区",
      mubiao: "6%",
      now: "2%",
      progress: "33.3"
    },
    {
      name: "北辰区",
      mubiao: "9%",
      now: "6%",
      progress: "66.6"
    },
    {
      name: "武清区",
      mubiao: "3%",
      now: "2%",
      progress: "66.6"
    },
    {
      name: "宝坻区",
      mubiao: "6%",
      now: "4%",
      progress: "66."
    },
    {
      name: "宁河区",
      mubiao: "8%",
      now: "3%",
      progress: "37.5"
    },
    {
      name: "静海区",
      mubiao: "6%",
      now: "3%",
      progress: "50"
    },
    {
      name: "蓟州区",
      mubiao: "5%",
      now: "2%",
      progress: "40"
    },
  ];
  tableLeftTopPageIndex = 1;
  tableRightTopData = [
    {
      name: "天津军电热电",
      total: "890662",
      ele: "870679",
      coal: "19982"
    },
    {
      name: "华电富源热电",
      total: "87234",
      ele: "63788",
      coal: "23446"
    },
    {
      name: "天津华恒包装",
      total: "17899",
      ele: "13476",
      coal: "4423"
    },
    {
      name: "天津海光药业",
      total: "13877",
      ele: "12312",
      coal: "1565"
    },
    {
      name: "渤化永利热电",
      total: "56736",
      ele: "53241",
      coal: "3495"
    },
    {
      name: "渤化永利化工",
      total: "45623",
      ele: "35646",
      coal: "9977"
    },
    {
      name: "路易达孚食品",
      total: "12312",
      ele: "0",
      coal: "10231"
    },
    {
      name: "华能煤气化发电",
      total: "62456",
      ele: "51232",
      coal: "11224"
    },
    {
      name: "华能临港热电",
      total: "63456",
      ele: "0",
      coal: "12312"
    },
    {
      name: "天津顶津食品",
      total: "12311",
      ele: "10213",
      coal: "2098"
    },
    {
      name: "天津钢铁",
      total: "234231",
      ele: "215674",
      coal: "18557"
    },
    {
      name: "天钢联合特钢",
      total: "256342",
      ele: "243123",
      coal: "13219"
    },
    {
      name: "天铁炼焦化工",
      total: "143121",
      ele: "131241",
      coal: "11880"
    },
    {
      name: "贝特瑞新能源",
      total: "13121",
      ele: "11232",
      coal: "1889"
    },
    {
      name: "天津大无缝铜材",
      total: "345321",
      ele: "234521",
      coal: "110800"
    },
    {
      name: "长城汽车天津哈弗分公司",
      total: "83431",
      ele: "76547",
      coal: "6884"
    },
    {
      name: "中国石油天然气大港石化分公司",
      total: "11345",
      ele: "10231",
      coal: "1114"
    },
  ];
  tableRightTopPageIndex = 1;
  intervalLeftFlag = null;
  intervalRightFlag = null;
  realTime: any[string] = moment().format("YYYY年MM月DD日 HH:mm:ss");
  ngOnInit() {
    this.intervalRight();
    this.intervalLeft();
    this.startLocalTime();
  }

  startLocalTime() {
    setInterval(() => {
      this.realTime = moment().format("YYYY年MM月DD日 HH:mm:ss")
    }, 1000)
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.initPie();
    this.initGauge();
    this.initUnitConsumptionOfOutput();
    this.initIndustryLine();
    this.initCarbonEmissions();
    this.initCoalConsumption();
    this.initElectricityConsumption();
  }

  initMap() {
    let dom = document.getElementById("echartsId");
    let myChart = echarts.init(dom);
    let option;
    let geoJson = tianjin

    echarts.registerMap('tj', geoJson);

    myChart.setOption(option = {
      tooltip: {
        trigger: 'item',
        formatter: (e) => {
          return `${e.name}<br /><div style="border-bottom:1px solid #999">24户 企业</div>
          <div style="border-bottom:1px solid #999">2户 千家</div>
          <div>22 户百家</div>`
        }
      },
      grid: {
        top: "15%",
        bottom: "0"
      },
      visualMap: {
        min: 0,
        max: 60000,
        // text: ['High', 'Low'],
        realtime: false,
        calculable: true,
        show: true,
        right: "5%",
        bottom: "5%",
        textStyle: {
          color: "#fff"
        },
        inRange: {
          color: ['lightskyblue', 'yellow', 'orangered']
        }
      },
      series: [{
        name: '天津',
        zoom: 1.1,
        type: 'map',
        show: true,
        roam: false,
        top: "10%",
        mapType: 'tj', // 自定义扩展图表类型
        label: {
          show: true,
          fontSize: 11,
        },
        data: [{
          name: '和平区',
          value: 1057.34
        },
        {
          name: '河北区',
          value: 4057.34
        },
        {
          name: '河东区',
          value: 1547.48
        },
        {
          name: '河西区',
          value: 3166.1
        },
        {
          name: '南开区',
          value: 6992.6
        },
        {
          name: '红桥区',
          value: 6704.49
        },
        {
          name: '东丽区',
          value: 9689.64
        },
        {
          name: '西青区',
          value: 10893.78
        },
        {
          name: '津南区',
          value: 13943.97
        },
        {
          name: '北辰区',
          value: 9204.26
        },
        {
          name: '武清区',
          value: 39900.9
        },
        {
          name: '宝坻区',
          value: 48900.9
        },
        {
          name: '滨海新区',
          value: 58900.9
        },
        {
          name: '宁河区',
          value: 31900.9
        },
        {
          name: '蓟州区',
          value: 40900.9
        },
        {
          name: '静海区',
          value: 37400.9
        },
        ],
      }]
    });

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  initPie() {
    let dom = document.getElementById("piedata");
    let myChart = echarts.init(dom);
    let option = {
      title: {
        text: '能源品种占比',
        left: '2%',
        top: "3%",
        textStyle: {
          color: "#00ddff",
          fontWeight: "bolder",
          fontSize: 18
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: "{d}%"
      },
      legend: {
        orient: 'vertical',
        right: '2%',
        top: "5%",
        textStyle: {
          color: "#fff"
        }
      },
      series: [
        {
          type: 'pie',
          radius: '70%',
          color: ['#34f6ef', '#fcff00', '#f19610', '#0278e7', '#34d160', '#00b4ff'],
          data: [
            { value: 63, name: '煤炭' },
            { value: 30, name: '电力' },
            { value: 1, name: '其他' },
            { value: 2, name: '热力' }
          ],
          labelLine: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  initGauge() {
    this.initGauge1();
    this.initGauge2();
    this.initGauge3();
  }

  initGauge1() {
    let dom = document.getElementById("gaugeYes");
    let myChart = echarts.init(dom);
    let option = {
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 5,
        radius: "150%",
        center: ["50%", "90%"],
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2']
            ]
          }
        },
        pointer: {
          offsetCenter: [0, '-50%'],
          length: "45%",
          width: 3,
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, '-20%'],
          fontSize: 20
        },
        detail: {
          show: true,
          offsetCenter: [0, '-20%'],
          color: 'auto',
          fontSize: 18,
          formatter: function (value) {
            return Math.round(value * 100) + '%';
          },
        },
        data: [{
          value: 0.89
        }]
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  initGauge2() {
    let dom = document.getElementById("gauge7days");
    let myChart = echarts.init(dom);
    let option = {
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 5,
        radius: "150%",
        center: ["50%", "90%"],
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2']
            ]
          }
        },
        pointer: {
          offsetCenter: [0, '-50%'],
          length: "45%",
          width: 3,
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, '-20%'],
          fontSize: 20
        },
        detail: {
          show: true,
          offsetCenter: [0, '-20%'],
          color: 'auto',
          fontSize: 18,
          formatter: function (value) {
            return Math.round(value * 100) + '%';
          },
        },
        data: [{
          value: 0.8
        }]
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  initGauge3() {
    let dom = document.getElementById("gaugeLastMonth");
    let myChart = echarts.init(dom);
    let option = {
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 1,
        splitNumber: 5,
        radius: "150%",
        center: ["50%", "90%"],
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2']
            ]
          }
        },
        pointer: {
          offsetCenter: [0, '-50%'],
          length: "45%",
          width: 3,
          itemStyle: {
            color: 'auto'
          }
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5
          }
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: true,
          offsetCenter: [0, '-20%'],
          color: 'auto',
          fontSize: 18,
          formatter: function (value) {
            return Math.round(value * 100) + '%';
          },
        },
        data: [{
          value: 0.95
        }]
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 产值单耗
  initUnitConsumptionOfOutput() {
    let dom = document.getElementById("unitConsumptionOfOutput");
    let myChart = echarts.init(dom);
    let option = {
      title: {
        text: '产值单耗',
        left: '2%',
        top: "3%",
        textStyle: {
          color: "#00ddff",
          fontWeight: "bolder",
          fontSize: 18
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['同期', '本期'],
        right: '2%',
        top: "5%",
        textStyle: {
          color: "#fff"
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月'],
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      series: [
        {
          name: '同期',
          type: 'bar',
          data: [21, 15, 13, 15, 16, 24, 20],
          itemStyle: {
            color: "#ffd200"
          }
        },
        {
          name: '本期',
          type: 'bar',
          data: [18, 13, 16, 14, 19, 20, 18],
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#00fffc'
              }, {
                offset: 1,
                color: '#6c00ff'
              }])
            }
          }
        }
      ]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  initIndustryLine() {
    let dom = document.getElementById("industryLine");
    let myChart = echarts.init(dom);
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (e) => {
          let data = e[0].data
          return `${data.name}: ${data.numbers} 户<br />  能源消耗总量:${data.value} 万吨标准煤`;
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['电力', '化工', '钢铁','铝业', '水泥', '其他'],
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#fff"
            }
          }
        }
      ],
      yAxis: [
        {
          name: "tce",
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#fff"
            }
          }
        },

      ],
      series: [
        {
          name: '直接访问',
          type: 'bar',
          barWidth: '35%',
          data: [{ name: "电力", value: 871.91, numbers: "18" },
          { name: "化工", value: 561.6, numbers: "12" },
          { name: "钢铁", value: 1561.6, numbers: "33" },
          { name: "铝业", value: 217.2, numbers: "14" },
          { name: "水泥", value: 485.6, numbers: "17" },
           { name: "其他", value: 181.72, numbers: "24" }],

          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#00b03e'
              }, {
                offset: 1,
                color: '#00c6ff'
              }]),
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#fff',
                  // fontSize: 16
                },
                formatter: (e) => {
                  let data = e.data;
                  return data.numbers + "户";
                }
              }
            }
          },
        },

      ]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 碳排放量
  initCarbonEmissions() {
    let dom = document.getElementById("carbonEmissions");
    let myChart = echarts.init(dom);
    let option = {
      title: {
        text: "碳排放量",
        left: '2%',
        top: "3%",
        textStyle: {
          color: "#00ddff",
          fontWeight: "bolder",
          fontSize: 18
        }
      },
      grid: {
        bottom: '0%',
        right: "3%",
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        formatter: "{c} 万吨"
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月'],
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      yAxis: {
        type: 'value',
        name: "万吨",
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      series: [{
        data: [17.53, 28.23, 35.26, 48.79, 109.99, 98.33, 55.64],
        showSymbol: false,
        type: 'line',
        smooth: true,
        itemStyle: {
          normal: {
            color: "auto"
          }
        },
        lineStyle: { color: '#00ffff' },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgb(0,194,213)'
          }, {
            offset: 1,
            color: 'rgba(0,194,213,0.3)'
          }])
        }
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 用煤量
  initCoalConsumption() {
    let dom = document.getElementById("coalConsumption");
    let myChart = echarts.init(dom);
    let option = {
      title: {
        text: "用煤量",
        left: '2%',
        top: "3%",
        textStyle: {
          color: "#00ddff",
          fontWeight: "bolder",
          fontSize: 18
        }
      },
      grid: {
        bottom: '0%',
        right: "3%",
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月'],
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        formatter: "{c} 万吨"
      },
      yAxis: {
        type: 'value',
        name: "万吨",
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      series: [{
        data: [46.63, 75.09, 93.79, 129.78, 289.91, 261.56, 148.00],
        showSymbol: false,
        type: 'line',
        smooth: true,
        itemStyle: {
          normal: {
            color: "auto"
          }
        },
        lineStyle: { color: 'rgb(40,247,25)' },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgb(30,191,26)'
          }, {
            offset: 1,
            color: 'rgba(30,191,26,0.3)'
          }])
        }
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  // 用电量
  initElectricityConsumption() {
    let dom = document.getElementById("electricityConsumption");
    let myChart = echarts.init(dom);
    let option = {
      title: {
        text: "用电量",
        left: '2%',
        top: "3%",
        textStyle: {
          color: "#00ddff",
          fontWeight: "bolder",
          fontSize: 18
        }
      },
      grid: {
        bottom: '0%',
        right: "3%",
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['9月', '10月', '11月', '12月', '1月', '2月', '3月'],
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        formatter: "{c} 亿千瓦时"
      },
      yAxis: {
        type: 'value',
        name: "亿千瓦时",
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff"
          }
        },
        axisLabel: {
          textStyle: {
            color: "#fff"
          }
        }
      },
      series: [{
        data: [67.39, 74.93, 84.16, 82.9, 158.54, 194.35, 98.67],
        showSymbol: false,
        type: 'line',
        smooth: true,
        itemStyle: {
          normal: {
            color: "auto"
          }
        },
        lineStyle: { color: 'rgb(245,226,3)' },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgb(184,176,25)'
          }, {
            offset: 1,
            color: 'rgba(184,176,25,0.3)'
          }])
        }
      }]
    };
    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
  intervalLeft() {
    this.intervalLeftFlag = setInterval(() => {
      if (this.tableLeftTopPageIndex >= this.tableLeftTopData.length / 7) {
        this.tableLeftTopPageIndex = 1;
      } else {
        this.tableLeftTopPageIndex++;
      }
    }, 5000)
  }

  intervalRight() {
    this.intervalRightFlag = setInterval(() => {
      if (this.tableRightTopPageIndex >= this.tableRightTopData.length / 4) {
        this.tableRightTopPageIndex = 1;
      } else {
        this.tableRightTopPageIndex++;
      }
    }, 5000)
  }

  /* 
  左上角企业列表切换
  */
  changeCompanyList(type: string) {
    this.listType = type;
    clearInterval(this.intervalRightFlag);
    // this.tableRightTopData = [];//请求接口
    this.intervalRight();
    this.tableRightTopPageIndex = 1;//pageindex重置为1
  }

}
