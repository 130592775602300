import { InvokeResult } from './../../shared/entity/InvokeResult.entity';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';

@Injectable()
export class ProgressControlAnalysService {
  private getDetailCompamyUrl: string =
    '/energyreport/progressControlAnalys/company/';
  private getEnergyChartUrl: string =
    '/energyreport/progressControlAnalys/getEnergyChart';
  getTopCardDataUrl: string =
    '/energyreport/progressControlAnalys/getTopCardData';
  getNyxfzlControlUrl = '/energyreport/progressControlAnalys/getNyxfzlControl';
  constructor(private http: HttpClient) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-type':
        'application/json; application/x-www-form-urlencodeed; charset=utf-8;',
    }),
  };
  /**
   *获取头部卡片双控目标数据
   * @author ludaxian
   * @date 2020-03-29
   * @param searchtDto
   */
  getTopCardData(searchDto: any): Observable<InvokeResult> {
    return this.http.post<InvokeResult>(
      this.getTopCardDataUrl,
      searchDto,
      this.httpOptions,
    );
  }
  /**
   *获取能耗增量表格
   * @author ludaxian
   * @date 2020-03-29
   * @param searchDto
   * @returns
   */
  getNyxfzlControl(searchDto: any): Observable<InvokeResult> {
    return this.http.post<InvokeResult>(
      this.getNyxfzlControlUrl,
      searchDto,
      this.httpOptions,
    );
  }
  /**
   *获取公司tab双控预警信息
   * @author ludaxian
   * @date 2020-02-21
   * @returns
   */
  getDetailCompamy(
    year: number,
    startTime: number,
    searchType: string,
    searchIds: string[],
  ): Observable<InvokeResult> {
    return this.http.post<InvokeResult>(
      this.getDetailCompamyUrl,
      JSON.stringify({
        year: year,
        startTime: startTime,
        searchType: searchType,
        searchIds: searchIds,
      }),
      this.httpOptions,
    );
  }

  /**
   *
   *点击本年累计共通获取chart
   * @author ludaxian
   * @date 2020-02-25
   * @param year
   * @param dataType
   * @param energyType
   * @param ids
   * @returns
   */
  getEnergyChart(
    year: number,
    dataType: string,
    energyType: string,
    ids: string[],
  ): Observable<InvokeResult> {
    return this.http.post<InvokeResult>(
      this.getEnergyChartUrl,
      JSON.stringify({
        year: year,
        dataType: dataType,
        energyType: energyType,
        ids: ids,
      }),
      this.httpOptions,
    );
  }
}
