import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HomepageService {
  constructor(private http: HttpClient) {}

  getEnergyBoardData(energyBoardDataUrl: string): Promise<any> {
    return this.http
      .get(energyBoardDataUrl)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getChartData(chartDataUrl: string): Promise<any> {
    return this.http
      .get(chartDataUrl)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getData(DataUrl: string): Promise<any> {
    return this.http
      .get(DataUrl)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getRegionData(url: string): Promise<any> {
    return this.http
      .get(url)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
}
