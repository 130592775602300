import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InvokeResult } from '@shared/entity/InvokeResult.entity';

@Injectable({
  providedIn: 'root',
})
export class OnlinecollectCompanyService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-type':
        'application/json; application/x-www-form-urlencodeed; charset=utf-8;',
    }),
  };
  private getnergyConsumptionLevelListUrl =
    '/energyreport/onlineCollectProjectCompany/getEnergyConsumptionLevelList';

  private getTradeTreeUrl =
    '/energyreport/onlineCollectProjectCompany/getTradeTree1/';

  private saveCompanyInfoUrl =
    '/energyreport/onlineCollectProjectCompany/saveOnlineCollectProjectCompany';
  private updateCompanyInfoUrl =
    '/energyreport/onlineCollectProjectCompany/updateOnlineCollectProjectCompany';

    private getCompanyInfoUrl='/energyreport/onlineCollectProjectCompany/getCompanyInfo'
  constructor(private http: HttpClient) {}

  getnergyConsumptionLevelList(): Observable<InvokeResult> {
    return this.http.get<InvokeResult>(this.getnergyConsumptionLevelListUrl);
  }
  getTradeTree(tradeId: string): Observable<InvokeResult> {
    return this.http.get<InvokeResult>(this.getTradeTreeUrl + tradeId);
  }
  getTradeTree1(): Observable<InvokeResult> {
    return this.http.get<InvokeResult>(this.getTradeTreeUrl);
  }
  saveCompanyInfo(companyInfo: companyInfo): Observable<InvokeResult> {
    return this.http.post<InvokeResult>(
      this.saveCompanyInfoUrl,
      companyInfo,
      this.httpOptions,
    );
  }
  updateCompanyInfo(companyInfo: companyInfo): Observable<InvokeResult> {
    return this.http.post<InvokeResult>(
      this.updateCompanyInfoUrl,
      companyInfo,
      this.httpOptions,
    );
  }

  getCompanyInfo(userId:string):Observable<InvokeResult>{
    let param={userId:userId};
    return this.http.get<InvokeResult>(this.getCompanyInfoUrl,{params:param} );
  }
  
}
