import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConfigService } from '../../../../global-config-service';

@Component({
  selector: 'info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less'],
})
export class InfoComponent implements OnInit {
  constructor(private http: HttpClient, private gcs: GlobalConfigService) {}
  isSpinning: boolean = false;
  versionData: any[];
  ngOnInit() {
    this.versionData = [];
    this.info();
  }

  info() {
    this.http.get(this.gcs.globalPath + '/platformversion').subscribe(
      (result: any) => {
        if (result.success) {
          result.data.forEach(data => {
            if(data.name=="basic-model"||data.name=="basic-module"){
              data.description=data.name;
            }
          });
          this.versionData = result.data;
        } else {
          console.error(result.errorMessage);
        }
      },
      error => {
        console.error(error);
      },
      () => {
        this.isSpinning = false;
      },
    );
  }
}
