import {Component, OnInit, Inject, Optional} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {SocialService, TokenService, DA_SERVICE_TOKEN} from '@delon/auth';
import {ReuseTabService} from '@delon/abc';
import {StartupService} from '@core/startup/startup.service';
import {HttpClient} from '@angular/common/http';
import {Md5} from 'ts-md5';
import {GlobalConfigService} from "../../../global-config-service";
import {GXService} from '@shared/GX.service';

@Component({
    selector: 'login-sub-ningdong',
    templateUrl: './login-sub-ningdong.component.html',
    styleUrls: ['./login-sub-ningdong.component.less'],
    providers: [SocialService]
})
export class LoginSubNingdongComponent implements OnInit {

    form: FormGroup;
    error = '';
    type = 0;
    loading = false;
    orgType: string;
    title: string;
    yangzhouspecialflag = false;

    ngOnInit(): void {
        this.orgType = this.routeInfo.snapshot.params['orgType'];
        if (this.orgType === 'gov') {
            this.title = '政府';
        } else if (this.orgType === 'com') {
            this.title = '企业';
        } else if (this.orgType === 'yangzhougov') {
            this.title = '政府';
            this.orgType = 'gov';
            this.yangzhouspecialflag = true;
        } else if (this.orgType === 'yangzhoucom') {
            this.title = '企业';
            this.orgType = 'com';
            this.yangzhouspecialflag = true;
        }
    }

    constructor(
        fb: FormBuilder,
        private router: Router,
        public msg: NzMessageService,
        modalSrv: NzModalService,
        private http: HttpClient,
        @Optional()
        @Inject(ReuseTabService)
        private reuseTabService: ReuseTabService,
        @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
        private startupSrv: StartupService,
        private routeInfo: ActivatedRoute,
        private gcs: GlobalConfigService,
        public gxService: GXService
    ) {
        this.form = fb.group({
            userName: [null, [Validators.required]],
            password: [null, Validators.required],
            mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
            captcha: [null, [Validators.required]],
            remember: [true],
        });
        modalSrv.closeAll();
    }

    // region: fields

    get userName() {
        return this.form.controls.userName;
    }

    get password() {
        return this.form.controls.password;
    }

    get mobile() {
        return this.form.controls.mobile;
    }

    get captcha() {
        return this.form.controls.captcha;
    }

    get remember() {
        return this.form.controls.remember;
    }

    // endregion

    switch(ret: any) {
        this.type = ret.index;
    }

    // region: get captcha

    count = 0;
    interval$: any;

    getCaptcha() {
        this.count = 59;
        this.interval$ = setInterval(() => {
            this.count -= 1;
            if (this.count <= 0) clearInterval(this.interval$);
        }, 1000);
    }

    // endregion
    submit() {
        this.error = '';
        if (this.type === 0) {
            this.userName.markAsDirty();
            this.userName.updateValueAndValidity();
            this.password.markAsDirty();
            this.password.updateValueAndValidity();
            if (this.userName.invalid || this.password.invalid) return;
        } else {
            this.mobile.markAsDirty();
            this.mobile.updateValueAndValidity();
            this.captcha.markAsDirty();
            this.captcha.updateValueAndValidity();
            if (this.mobile.invalid || this.captcha.invalid) return;
        }
        // mock http
        this.loading = true;
        let pwd = Md5.hashStr(this.password.value);
        pwd = Md5.hashStr(pwd + this.userName.value);
        this.http.get(`${this.gcs.globalPath}/xinhai/login/${this.userName.value}/${pwd}`)
            .subscribe(async (result: any) => {
                this.loading = false;
                this.reuseTabService.clear();
                if (result.failed === false) {
                    this.tokenService.set({
                        token: result.data.token,
                        name: result.data.name,
                        email: result.data.email,
                        id: result.data.id,
                        time: +new Date(),
                        orgType: this.orgType,
                    });

                    // 获取政府端配置的默认企业的首页
                    const urlResult: any = await this.http.get(`/basic/systemparameter/userparam/${result.data.id}/company_homepage`).toPromise();
                    let url = "/companyHomepage/home";
                    if (urlResult.value) {
                        url = urlResult.value;
                    }

                    this.http.get(`/energyreport/checkUser/userType/${this.orgType}`)
                        .subscribe((res: any) => {
                            if (res) {
                                if (this.orgType == 'gov') {
                                    // 重新获取 StartupService 内容，若其  包括 User 有关的信息的话
                                    this.startupSrv
                                        .load()
                                        .then(() => this.router.navigate(['/homepage/homepage']));
                                    // this.startupSrv.load().then(() => this.Redirect('/'));
                                } else {
                                    this.startupSrv
                                        .load()
                                        .then(() => this.router.navigate([url]));
                                }
                            } else {
                                this.msg.error(
                                    this.orgType === 'gov'
                                        ? '非政府用户，请在企业端登录'
                                        : '非企业用户，请在政府端登录',
                                );
                                this.http.get('/xinhai/logout');
                                this.tokenService.clear();
                            }
                        });
                    // 否则直接跳转
                    // this.router.navigate(['/']);
                } else {
                    console.warn(result);
                    this.msg.error(result.msg);
                }
            });
    }

    Redirect(jumpUrl) {
        /*行到窗口名称*/
        var d = new Date();
        var year, month, day, h, m, s;
        year = d.getFullYear();
        month = d.getMonth();
        day = d.getDay();
        h = d.getHours();
        m = d.getMinutes();
        s = d.getSeconds();
        var windowstr;
        windowstr = '' + year + month + day + h + m + s;
        /**********************************************************************************************/

        windowstr = window.open(
            jumpUrl,
            windowstr,
            'top=0, left=0, toolbar=no, menubar=no, scrollbars=yes,resizable=yes,location=yes, status=no',
        );

        window.opener = null;
        window.open('', '_self');
        windowstr.moveTo(0, 0);
        windowstr.resizeTo(screen.width, screen.height);
        window.self.close();
    }

    ngOnDestroy(): void {
        if (this.interval$) clearInterval(this.interval$);
    }

}
