import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';
// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';

// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserLoginSubComponent } from './passport/login-sub/login-sub.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { UserLockComponent } from './passport/lock/lock.component';
import { Exception403Component } from './exception/403.component';
import { Exception404Component } from './exception/404.component';
import { Exception500Component } from './exception/500.component';
import { RouteguardService } from './routeguard.service';
import { PublicLayoutComponent } from './public-service/public-layout/public-layout.component';
import { HomeComponent } from './public-service/home/home.component';
import { NewsListComponent } from './public-service/news-list/news-list.component';
import { NewsDetailComponent } from './public-service/news-detail/news-detail.component';
import { ElecAndCoalComponent } from './elec-and-coal/elec-and-coal/elec-and-coal.component';
import { CityOverviewComponent } from './city-overview/city-overview/city-overview.component';
import { EnergyMonitoringComponent } from './energy-monitoring/energy-monitoring/energy-monitoring.component';
import { OnlinecollectLoginComponent } from './onlinecollectionproject/onlinecollect-login/onlinecollect-login.component';
import { OnlinecollectRegisterComponent } from './onlinecollectionproject/onlinecollect-register/onlinecollect-register.component';
import { OnlinecollectionprojectCompanyComponent } from './onlinecollectionproject/onlinecollectionproject-company/onlinecollectionproject-company.component';
import { OnlinecollectionprojectRefinecollectionComponent } from './onlinecollectionproject/onlinecollectionproject-refinecollection/onlinecollectionproject-refinecollection.component';
import { OnlinecollectionprojectEnergyindexComponent } from './onlinecollectionproject/onlinecollectionproject-energyindex/onlinecollectionproject-energyindex.component';
import { OnlinecollectionprojectProgressbarComponent } from './onlinecollectionproject/onlinecollectionproject-progressbar/onlinecollectionproject-progressbar.component';
import { LayoutPassportComponent2 } from '../layout/passport2/passport.component';
// import { SwitchPageComponent } from './switch-page/switch-page/switch-page.component';
import { AuthAuthorizationComponent } from './passport/authorization/authorization.component';
import { Exception901Component } from './exception/901.component';
import { MessageListComponent } from './message/message-list/message-list.component';
import { MessageListCompanyComponent } from './message/message-list-company/message-list-company.component';
import { PassportNingdongComponent } from 'app/layout/passport-ningdong/passport-ningdong.component';
import { LoginNingdongComponent } from './passport/login-ningdong/login-ningdong.component';
import { LoginSubNingdongComponent } from './passport/login-sub-ningdong/login-sub-ningdong.component';
import { AuthorizationNingdongComponent } from './passport/authorization-ningdong/authorization-ningdong.component';
import { IndexhomeComponent } from './indexhome/indexhome.component';
import { MonitoringPlatformComponent } from './monitoring-platform/monitoring-platform.component';

const routes: Routes = [
    { path: 'indexhome', component: IndexhomeComponent },
    { path: 'platform', component: MonitoringPlatformComponent },
    { path: 'overviewtj', loadChildren: './large-screen-demo/large-screen-demo.module#LargeScreenDemoModule', canActivate: [RouteguardService], },
    { path: 'overviewtj', loadChildren: './large-screen-demo/large-screen-demo.module#LargeScreenDemoModule', canActivate: [RouteguardService], },
    { path: 'training', loadChildren: './training/training.module#TrainingModule', canActivate: [RouteguardService], },
    {
        path: '',
        component: LayoutDefaultComponent,
        children: [
            {
                path: 'index',
                loadChildren: './index/index.module#IndexModule',
                canActivate: [RouteguardService],
            },
            {
                path:'clzl',
                loadChildren:'./clzl/clzl.module#ClzlModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-costanalyze',
                loadChildren: './energy-costanalyze/energy-costanalyze.module#EnergyCostanalyzeModule',
                canActivate: [RouteguardService],
            },

            {
                path: 'limit',
                loadChildren: './limit-match/limit-match.module#LimitMatchModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'adjustmentcatalogue',
                loadChildren:
                    './adjustmentcatalogue/adjustmentcatalogue.module#AdjustmentcatalogueModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'threecontrol',
                loadChildren:
                    './threecontrol/threecontrol.module#ThreecontrolModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'qinghaiqiangduyusuan',
                loadChildren:
                    './qinghaiqiangduyusuan/qinghaiqiangduyusuan.module#QinghaiqiangduyusuanModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'industrialenergyconsumption',
                loadChildren:
                    './industrialenergyconsumption/industrialenergyconsumption.module#IndustrialenergyconsumptionModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'company-data',
                loadChildren:
                    './company-data/company-data.module#CompanyDataModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'devicemanage',
                loadChildren:
                    './devicemanage/devicemanage.module#DevicemanageModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'indexcode-warning',
                loadChildren:
                    './indexcode-warning/indexcode-warning.module#IndexcodeWarningModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'progress-company',
                loadChildren:
                    './progress-company/progress-company.module#ProgressCompanyModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'infoUpload',
                loadChildren:
                    './info-upload/info-upload.module#InfoUploadModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'gisMapJining',
                loadChildren:
                    './gis-map-jining/gis-map-jining.module#GisMapJiningModule',
                canActivate: [RouteguardService],
            },

            {
                path: 'gisMapQinghai',
                loadChildren:
                    './gis-map-qinghai/gis-map-qinghai.module#GisMapQinghaiModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'dataBenchMarking',
                loadChildren:
                    './data-benchmarking/data-benchmarking.module#DataBenchmarkingModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'companystatus-management',
                loadChildren:
                    './company-status-management/company-status-management.module#CompanyStatusManagementModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'progress-control-analys',
                loadChildren:
                    './progress-control-analys/progress-control-analys.module#ProgressControlAnalysModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'progress-control',
                loadChildren:
                    './progress-control/progress-control.module#ProgressControlModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'basic-model',
                loadChildren:
                    './basic-model/basic-model.module#BasicModelModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'basic',
                loadChildren:
                    './basic-module/basic-module.module#BasicModuleModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'jxenergy',
                loadChildren: './jxenergy/jxenergy.module#JxenergyModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'gxbenchmarking',
                loadChildren:
                    './gaoxin-benchmarking/gaoxin-benchmarking.module#GaoxinBenchmarkingModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'gxreport',
                loadChildren: './gx-report/gx-report.module#GxReportModule',
                // canActivate: [RouteguardService],//一旦注释,report下的所有路由都能无权限访问
            },
            {
                path: 'gxcms',
                loadChildren: './gx-cms/gx-cms.module#GxCmsModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'gxlinechart',
                loadChildren:
                    './gaoxin-linechart/gaoxin-linechart.module#GaoxinLinechartModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'gzenergy',
                loadChildren: './gzenergy/gzenergy.module#GzenergyModule',
                canActivate: [RouteguardService],
            },
            {
                path:'jnjc',
                loadChildren:'./jnjc/jnjc.module#JnjcModule',
                canActivate:[RouteguardService],
            },
            {
                path: 'demo',
                loadChildren: './demo/demo.module#DemoModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'homepage',
                loadChildren: './homepage/homepage.module#HomepageModule',
                canActivate: [RouteguardService],
                // data: { title: '首页', titleI18n: '首页' },
            },
            {
                path: 'downloadapp',
                loadChildren:
                    './download-app/download-app.module#DownloadAppModule',
                canActivate: [RouteguardService],
                data: { title: 'APP下载', titleI18n: 'APP下载' },
            },
            {
                path: 'tradeAnalysis',
                loadChildren:
                    './trade-analysis/trade-analysis.module#TradeAnalysisModule',
                // data: { title: '行业分析', titleI18n: '行业分析' },
                canActivate: [RouteguardService],
            },
            {
                path: 'regionalHome',
                loadChildren:
                    './regional-analysis/regional-analysis.module#RegionalAnalysisModule',
                // data: { title: '区域分析', titleI18n: '区域分析' },
                canActivate: [RouteguardService],
            },
            {
                path: 'singleRegional',
                loadChildren:
                    './regional-analysis/regional-analysis.module#RegionalAnalysisModule',
                data: {
                    title: '单区域分析',
                    titleI18n: '单区域分析',
                    reuse: true,
                },
                // canActivate: [RouteguardService],
            },
            {
                path: 'singleTradeAnalysis',
                loadChildren:
                    './single-trade-analysis/single-trade-analysis.module#SingleTradeAnalysisModule',
                data: { title: '单行业分析', titleI18n: '单行业分析' },
                // canActivate: [RouteguardService],
            },
            {
                path: 'abnormalEnergy',
                loadChildren:
                    './large-screen-demo/large-screen-demo.module#LargeScreenDemoModule',
                data: { title: '异常分析', titleI18n: '异常分析' }
            },
            {
                path: 'benchmarking',
                loadChildren:
                    './benchmarking/benchmarking.module#BenchmarkingModule',
                // data: { title: '企业能效分析', titleI18n: '企业能效分析' },
                canActivate: [RouteguardService],
            },
            {
                path: 'companybenchmarking',
                loadChildren:
                    './benchmarking/benchmarking.module#BenchmarkingModule',
                data: { title: '能效对标', titleI18n: '能效对标' },
                canActivate: [RouteguardService],
            },
            {
                path: 'companycontrol',
                loadChildren:
                    './doublecontrol/doublecontrol.module#DoublecontrolModule',
                data: { title: '企业双控分析', titleI18n: '企业双控分析' },
                canActivate: [RouteguardService],
            },
            {
                path: 'doublecontrol',
                loadChildren:
                    './doublecontrol/doublecontrol.module#DoublecontrolModule',
                data: { title: '双控指标预警', titleI18n: '双控指标预警' },
                canActivate: [RouteguardService],
            },
            {
                path: 'doublecontrolsetting',
                loadChildren:
                    './doublecontrol/doublecontrol.module#DoublecontrolModule',
                data: { title: '双控指标分配', titleI18n: '双控指标分配' },
                canActivate: [RouteguardService],
            },
            {
                path: 'energyaudit',
                loadChildren:
                    './energyaudit/energyaudit.module#EnergyauditModule',
                data: { title: '能源审计', titleI18n: '能源审计' },
                canActivate: [RouteguardService],
            },
            {
                path: 'governmentaudit',
                loadChildren:
                    './energyaudit/energyaudit.module#EnergyauditModule',
                data: { title: '能源审计', titleI18n: '能源审计' },
                canActivate: [RouteguardService],
            },
            {
                path: 'company-analysis',
                loadChildren:
                    './company-analysis/company-analysis.module#CompanyAnalysisModule',
                // data: { title: '企业分析', titleI18n: '企业分析' },
                // canActivate: [RouteguardService],
            },
            {
                path: 'companyChartAnalysis',
                loadChildren:
                    './company-chart-analysis/company-chart-analysis.module#CompanyChartAnalysisModule',
                data: {
                    title: '企业能源消费分析',
                    titleI18n: '企业能源消费分析',
                },
                canActivate: [RouteguardService],
            },
            {
                path: 'cleanProduce',
                loadChildren:
                    './clean-produce/clean-produce.module#CleanProduceModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energyOverview',
                loadChildren:
                    './energy-overview/energy-overview.module#EnergyOverviewModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'companyHomepage',
                loadChildren:
                    './company-homepage/company-homepage.module#CompanyHomepageModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'multidimensionalReport',
                loadChildren:
                    './multidimensional-report/multidimensional-report.module#MultidimensionalReportModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'co2Emission',
                loadChildren:
                    './co2-emission/co2-emission.module#Co2EmissionModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'carbonQuota',
                loadChildren:
                    './carbon-quota/carbon-quota.module#CarbonQuotaModule',
                canActivate: [RouteguardService],
            },

            {
                path: 'change-type',
                loadChildren: './change-type/change-type.module#ChangeTypeModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'analyze-repory',
                loadChildren:
                    './analyze-repory/analyze-repory.module#AnalyzeReporyModule',
                data: { title: '用能报告', titleI18n: '用能报告' },
                canActivate: [RouteguardService],
            },
            //---

            {
                path: 'energyPostFile',
                loadChildren:
                    './energy-post-file/energy-post-file.module#EnergyPostFileModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energyProject',
                loadChildren:
                    './energy-project/energy-project.module#EnergyProjectModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'instrumentManagement',
                loadChildren:
                    './instrument-management/instrument-management.module#InstrumentManagementModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'jump-other-system',
                loadChildren:
                    './jump-other-system/jump-other-system.module#JumpOtherSystemModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'coal-total',
                loadChildren: './coal-total/coal-total.module#CoalTotalModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-efficiency-rank',
                loadChildren:
                    './energy-efficiency-rank/energy-efficiency-rank.module#EnergyEfficiencyRankModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'coal-control',
                loadChildren:
                    './coal-control/coal-control.module#CoalControlModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'coal-table',
                loadChildren: './coal-table/coal-table.module#CoalTableModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energyRegionConsumption',
                loadChildren:
                    './energy-region-consumption/energy-region-consumption.module#EnergyRegionConsumptionModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'statisticsdata',
                loadChildren:
                    './statisticsdata/statisticsdata.module#StatisticsdataModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'powerTransformer',
                loadChildren:
                    './power-transformer-evaluation/power-transformer-evaluation.module#PowerTransformerEvaluationModule',
                canActivate: [RouteguardService],
            },
            {
              path: 'energy-saving-project-management',
              loadChildren:
                  './energy-saving-project-management/energy-saving-project-management.module#EnergySavingProjectManagementModule',
              canActivate: [RouteguardService],
            },
            {
                path: 'region-transfer-index-management',
                loadChildren:
                    './region-transfer-index-management/region-transfer-index-management.module#RegionTransferIndexManagementModule',
                canActivate: [RouteguardService],
            },
            /*       {
        path: 'controlreport',
        loadChildren:
          './controlreport/controlreport.module#ControlreportModule',
        // data: {title: '总量及强度填报', titleI18n: '总量及强度填报'},
        canActivate: [RouteguardService],
      }, */
            /*       {
        path: 'energyConsumption',
        loadChildren:
          './energy-consumption/energy-consumption.module#EnergyConsumptionModule',
        canActivate: [RouteguardService],
      }, */
            /*       {
        path: 'measure-management',
        loadChildren:
          './measure-management/measure-management.module#MeasureManagementModule',
        // data: {title: '能源计量管理', titleI18n: '能源计量管理'},
        canActivate: [RouteguardService],
      }, */
            /*       {
        path: 'masuringInstruments',
        loadChildren:
          './masuring-instruments/masuring-instruments.module#MasuringInstrumentsModule',
        canActivate: [RouteguardService],
      }, */
            /*       {
        path: 'materialsReported',
        loadChildren:
          './materials-reported/materials-reported.module#MaterialsReportedModule',
        canActivate: [RouteguardService],
      }, */
            /*       {
        path: 'reportManage',
        loadChildren:
          './report-management/report-management.module#ReportManagementModule',
        canActivate: [RouteguardService],
      },
      /*      {
        path: 'advanceIncrement',
        loadChildren:
          './advance-increment/advance-increment.module#AdvanceIncrementModule',
        canActivate: [RouteguardService],
      }, */
            /*       {
        path: 'codeManage',
        loadChildren: './code-manage/code-manage.module#CodeManageModule',
        canActivate: [RouteguardService],
      }, */
            {
                path: 'energy-consumption-analysis',
                loadChildren:
                    './energy-consumption-analysis/energy-consumption-analysis.module#EnergyConsumptionAnalysisModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-consumption-display',
                loadChildren:
                    './energy-consumption-display/energy-consumption-display.module#EnergyConsumptionDisplayModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-consumption-display-xinjiang',
                loadChildren:
                    './energy-consumption-display-xinjiang/energy-consumption-display-xinjiang.module#EnergyConsumptionDisplayXinjiangModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'company-comprehensive-evaluation',
                loadChildren:
                    './comprehensive-evaluation/comprehensive-evaluation.module#ComprehensiveEvaluationModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'elec-xinjiang',
                loadChildren:
                    './elec-xinjiang/elec-xinjiang.module#ElecXinjiangModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'quality-analysis',
                loadChildren:
                    './quality-analysis/quality-analysis.module#QualityAnalysisModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'report-situation',
                loadChildren:
                    './report-situation/report-situation.module#ReportSituationModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-consumption-trendency',
                loadChildren:
                    './energy-consumption-trendency/energy-consumption-trendency.module#EnergyConsumptionTrendencyModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'index-upload',
                loadChildren:
                    './index-upload/index-upload.module#IndexUploadModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'onlinecollectionproject',
                loadChildren:
                    './onlinecollectionproject/onlinecollectionproject.module#OnlinecollectionprojectModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-saving-business-management',
                loadChildren:
                    './energy-saving-business-management/energy-saving-business-management.module#EnergySavingBusinessManagementModule',
                canActivate: [RouteguardService],
            },
            // 端设备手动上传数据
            {
                path: 'year-index-statistics',
                loadChildren:
                    './year-index-statistics/year-index-statistics.module#YearIndexStatisticsModule',
                canActivate: [RouteguardService],
            },

            {
                path: 'warn-message',
                loadChildren:
                    './warn-message/warn-message.module#WarnMessageModule',
                canActivate: [RouteguardService],
            },

            {
                path: 'device-input-value',
                loadChildren:
                    './device-input-value/device-input-value.module#DeviceInputValueModule',
                canActivate: [RouteguardService],
            },
            // 双控目标考核
            {
                path: 'double-control-assessment',
                loadChildren:
                    './double-control-assessment/double-control-assessment.module#DoubleControlAssessmentModule',
                canActivate: [RouteguardService],
            },
            // 一企一案
            {
                path: 'company-one-enterprise-policy',
                loadChildren:
                    './company-one-enterprise-policy/company-one-enterprise-policy.module#CompanyOneEnterprisePolicyModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-saving-assessment',
                loadChildren:
                    './energy-saving-assessment/energy-saving-assessment.module#EnergySavingAssessmentModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'security-log-management',
                loadChildren:
                    './security-log-management/security-log-management.module#SecurityLogManagementModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'access-system-acceptance',
                loadChildren:
                    './access-system-acceptance/access-system-acceptance.module#AccessSystemAcceptanceModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'product-output-consumption',
                loadChildren:
                    './product-output-consumption/product-output-consumption.module#ProductOutputConsumptionModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'training-management',
                loadChildren:
                    './training-management/training-management.module#TrainingManagementModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'warning',
                loadChildren: './warning/warning.module#WarningModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'collect-item-config',
                loadChildren:
                    './collect-item-config/collect-item-config.module#CollectItemConfigModule',
                canActivate: [RouteguardService],
            },
            //双控管理
            {
                path: 'double-control-management',
                loadChildren:
                    './double-control-management/double-control-management.module#DoubleControlManagementModule',
                canActivate: [RouteguardService],
            },
            {
                path: 'energy-consumption-planning',
                loadChildren:
                    './energy-consumption-planning/energy-consumption-planning.module#EnergyConsumptionPlanningModule',
                canActivate: [RouteguardService],
            },

            //工序分析
            {
                path: 'process-analysis',
                loadChildren:
                    './process-analysis/process-analysis.module#ProcessAnalysisModule',
                canActivate: [RouteguardService],
            },

            //message-list 消息列表
            {
                path: 'message-list',
                component: MessageListComponent,
                data: { title: '站内消息推送' },
            },
            {
                path: 'message-list-company',
                component: MessageListCompanyComponent,
                data: { title: '历史消息列表' },
            },
            {
                path: 'yearly-metrics-analysis',
                loadChildren: './yearly-metrics-analysis/yearly-metrics-analysis.module#YearlyMetricsAnalysisModule',
                canActivate: [RouteguardService],
            },
            //两高项目
            {
                path: 'two-high-items',
                loadChildren: './two-high-items/two-high-items.module#TwoHighItemsModule',
                canActivate: [RouteguardService],

            },
            //碳中和碳达峰
            {
                path:"carbon-data-control",
                loadChildren:'./carbon-data-control/carbon-data-control.module#CarbonDataControlModule',
                canActivate:[RouteguardService],
            },
            //用能权交易管理
            {
                path:"energy-trading",
                loadChildren:'./energy-trading/energy-trading.module#EnergyTradingModule',
                canActivate:[RouteguardService],
            },
            //能源预算管理
            {
                path:"indicator-prediction",
                loadChildren:'./indicator-prediction/indicator-prediction.module#IndicatorPredictionModule',
                canActivate:[RouteguardService],
            },
            //站内信息
            {
              path:"message-log",
              loadChildren:'./message-log/message-log.module#MessageLogModule',
              canActivate:[RouteguardService],
          },

          {
            path:"combination-pages",
            loadChildren:'./combination-pages/combination-pages.module#CombinationPagesModule',
            canActivate:[RouteguardService],
         },
         {
            path:"report-data",
            loadChildren:'./report-data/report-data.module#ReportDataModule',
            canActivate:[RouteguardService]

         },
         {
            path: 'index-calculate',
            loadChildren: './index-calculate/index-calculate.module#IndexCalculateModule',
            canActivate: [RouteguardService],
        },
        {
            path: 'index-allocation',
            loadChildren: './index-allocation/index-allocation.module#IndexAllocationModule',
            canActivate: [RouteguardService],
        },
        {
            path: 'balanced-budget-allocation',
            loadChildren: './balanced-budget-allocation/balanced-budget-allocation.module#BalancedBudgetAllocationModule',
            canActivate: [RouteguardService],
        },
        {
            path: 'budget-tail-warning',
            loadChildren: './budget-tail-warning/budget-tail-warning.module#BudgetTailWarningModule',
            canActivate: [RouteguardService],
        },
        {
            path: 'power-of-use-design',
            loadChildren: './power-of-use-design/power-of-use-design.module#PowerOfUseDesignModule',
            canActivate: [RouteguardService],
        },
        {
            path: 'enterprise-energy-interior-allocation',
            loadChildren: './enterprise-energy-interior-allocation/enterprise-energy-interior-allocation.module#EnterpriseEnergyInteriorAllocationModule',
            canActivate: [RouteguardService],
        },
        { 
            path: 'cms-log',
            loadChildren: './cms-log/cms-log.module#CmsLogModule',
            canActivate: [RouteguardService],
        },
        {
            path:'all-energy',
            loadChildren:'./all-energy/all-energy.module#AllEnergyModule',
            canActivate:[RouteguardService],
        },
        {
            path:'energy-statistics',
            loadChildren:'./energy-statistics/energy-statistics.module#AllEnergyModule2',
            canActivate:[RouteguardService],
        },
        {
            path:'access-log',
            loadChildren:'./access-log/access-log.module#AccessLogModule',
            canActivate:[RouteguardService],
        },
        {
            path:'contrastive-analysis',
            loadChildren:'./contrastive-analysis/contrastive-analysis.module#ContrastiveAnalysisModule',
            canActivate:[RouteguardService],
        },
        {
            path:'energy-measure-appliance',
            loadChildren:'./energy-measure-appliance/energy-measure-appliance.module#EnergyMeasureApplianceModule',
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-appliance-collect',
            loadChildren:"./energy-measure-appliance-collect/energy-measure-appliance-collect.module#EnergyMeasureApplianceCollectModule",
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-appliance-device-collect',
            loadChildren:"./energy-measure-appliance-device-collect/energy-measure-appliance-device-collect.module#EnergyMeasureApplianceDeviceCollectModule",
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-guide',
            loadChildren:"./energy-measure-guide/energy-measure-guide.module#EnergyMeasureGuideModule",
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-review-record',
            loadChildren:"./energy-measure-review-record/energy-measure-review-record.module#EnergyMeasureReviewRecordModule",
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-investigate-inconformity',
            loadChildren:"./energy-measure-investigate-inconformity/energy-measure-investigate-inconformity.module#EnergyMeasureInvestigateInconformityModule",
            canActivate:[RouteguardService]
        },
        {
            path:'two-height-save',
            loadChildren:"./two-height-save/two-height-save.module#TwoHeightSaveModule",
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-review-collect',
            loadChildren:"./energy-measure-review-collect/energy-measure-review-collect.module#EnergyMeasureReviewCollectModule",
            canActivate:[RouteguardService]
        },
        {
            path:'energy-measure-appliance-collect-implemental',
            loadChildren:"./energy-measure-appliance-collect-implemental/energy-measure-appliance-collect-implemental.module#EnergyMeasureApplianceCollectImplementalModule",
            canActivate:[RouteguardService]
        },
        {
            path: 'fixed-investments-project',
            loadChildren: './fixed-investments-project/fixed-investments-project.module#FixedInvestmentsProjectModule',
            canActivate: [RouteguardService],

        },
        {
            path: 'stock-project-energy-level',
            loadChildren: './stock-project-energy-level/stock-project-energy-level.module#StockProjectEnergyLevelModule',
            canActivate: [RouteguardService],

        },
        {
            path: 'plan-commissioning-project',
            loadChildren: './plan-commissioning-project/plan-commissioning-project.module#PlanCommissioningProjectModule',
            canActivate: [RouteguardService],

        },
        ],
    },
    {
        path: 'jxenergys',
        loadChildren: './jxenergy/jxenergy.module#JxenergyModule',
        canActivate: [RouteguardService],
    },
    /*
     * passport 开发宁东以外的项目需要取消以下的passport注释,并注释掉 底下的passport-ningdong内容
    */
    {
        path: 'passport',
        component: LayoutPassportComponent,
        children: [
            {
                path: 'login',
                component: UserLoginComponent,
                data: { title: '登录', titleI18n: '登录' },
            },
            {
                path: 'login-sub/:orgType',
                component: UserLoginSubComponent,
                data: { title: '登录', titleI18n: '登录' },
            },
            {
                path: 'register',
                component: UserRegisterComponent,
                data: { title: '注册', titleI18n: 'pro-register' },
            },
            {
                path: 'register-result',
                component: UserRegisterResultComponent,
                data: { title: '注册结果', titleI18n: 'pro-register-result' },
            },
            {
                path: 'authorize',
                component: AuthAuthorizationComponent,
                data: { title: '软件授权', titleI18n: '软件授权' },
            },
        ],
    },
    /*
     *passport-ningdong 开发宁东需要取消以下的passport注释,并注释掉 上方的passport内容
    */
    // {
    //     path: 'passport',
    //     component: PassportNingdongComponent,
    //     children: [
    //         {
    //             path: 'login',
    //             component: LoginNingdongComponent,
    //             data: { title: '登录', titleI18n: '登录' },
    //         },
    //         {
    //             path: 'login-sub/:orgType',
    //             component: LoginSubNingdongComponent,
    //             data: { title: '登录', titleI18n: '登录' },
    //         },
    //         {
    //             path: 'authorize',
    //             component: AuthorizationNingdongComponent,
    //             data: { title: '软件授权', titleI18n: '软件授权' },
    //         },
    //     ],
    // },
    {
        path: 'passport2',
        component: LayoutPassportComponent2,
        children: [
            {
                path: 'login',
                component: UserLoginComponent,
                data: { title: '登录', titleI18n: '登录' },
            },
            {
                path: 'login-sub/:orgType',
                component: UserLoginSubComponent,
                data: { title: '登录', titleI18n: '登录' },
            },
            {
                path: 'register',
                component: UserRegisterComponent,
                data: { title: '注册', titleI18n: 'pro-register' },
            },
            {
                path: 'register-result',
                component: UserRegisterResultComponent,
                data: { title: '注册结果', titleI18n: 'pro-register-result' },
            },
            {
                path: 'authorize',
                component: AuthAuthorizationComponent,
                data: { title: '软件授权', titleI18n: '软件授权' },
            },
        ],
    },
    //public-service
    {
        path: 'public',
        component: PublicLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: '/home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                component: HomeComponent,
                data: {
                    title: '重点用能在线监测信息平台',
                    titleI18n: '重点用能在线监测信息平台',
                },
            },
            {
                path: 'newslist',
                // component:NewsListComponent,
                children: [
                    {
                        path: ':typeId',
                        component: NewsListComponent,
                        data: {
                            title: '重点用能在线监测信息平台',
                            titleI18n: '重点用能在线监测信息平台',
                        },
                    },
                    {
                        path: ':typeId/:contentId',
                        component: NewsDetailComponent,
                        data: {
                            title: '重点用能在线监测信息平台',
                            titleI18n: '重点用能在线监测信息平台',
                        },
                    },
                ],
            },
        ],
        // loadChildren:
        //       './public-service/public-service.module#PublicServiceModule',
    },
    //onlinecollectproject
    {
        path: 'onlinecollect',
        children: [
            {
                path: 'login',
                component: OnlinecollectLoginComponent,
                data: {
                    title: '登录',
                    titleI18n: '登录',
                },
            },
            {
                path: 'register',
                component: OnlinecollectRegisterComponent,
                data: {
                    title: '注册',
                    titleI18n: '注册',
                },
            },
            {
                path: 'progressbar',
                component: OnlinecollectionprojectProgressbarComponent,
                children: [
                    {
                        path: 'companyinfo',
                        component: OnlinecollectionprojectCompanyComponent,
                    },
                    {
                        path: 'energyindex',
                        component: OnlinecollectionprojectEnergyindexComponent,
                    },
                    {
                        path: 'refinecollection',
                        component: OnlinecollectionprojectRefinecollectionComponent,
                    },
                ],
            },
        ],
    },
    // {
    //     path: 'switch-page',
    //     component: SwitchPageComponent,
    // },

    // 单页不包裹Layout
    // {
    //   path:'',
    //   redirectTo:'/public',
    //   pathMatch:'full'
    // },
    {
        path: 'elecAndCoal',
        component: ElecAndCoalComponent,
        data: { title: '工业用电&用煤概况', titleI18n: '工业用电&用煤概况' },
    },

    {
        path: 'cityOverview',
        component: CityOverviewComponent,
        data: { title: '宏观能耗', titleI18n: '宏观能耗', reuse: false },
    },
    {
        path: 'energyMonitoring',
        component: EnergyMonitoringComponent,
        data: { title: '能耗在线监测', titleI18n: '能耗在线监测', reuse: false },
    },
    { path: 'callback/:type', component: CallbackComponent },
    {
        path: 'lock',
        component: UserLockComponent,
        data: { title: '锁屏', titleI18n: 'lock' },
    },
    { path: '403', component: Exception403Component },
    { path: '404', component: Exception404Component },
    { path: '500', component: Exception500Component },
    { path: '901', component: Exception901Component },
    { path: '**', component: Exception404Component },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: environment.useHash,
        }),
    ],
    exports: [RouterModule],
})
export class RouteRoutingModule { }
