import { GXService } from '../../../shared/GX.service';
import { Component, OnInit, ViewChild, ElementRef, Input,Inject,ChangeDetectorRef } from '@angular/core';
import { CompanyDataService } from '../company-data-service';
import { EnergyData } from '../EnergyData';
import { subDays } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ThirdPartyService } from 'app/routes/energy-saving-project-management/third-party/third-party.service';


@Component({
  selector: 'company-dataquality',
  templateUrl: './company-dataquality.component.html',
  styleUrls: ['./company-dataquality.component.less'],
})
export class CompanyDataQualityComponent implements OnInit {
  @Input() queryParams: any;
  isTwoLevelPage: boolean = false; //从二级页面跳转
  //开始结束时间的date；time；时间戳
  // startDate: Date = subDays(new Date(), 15);
  // endDate: Date = subDays(new Date(), 1);
  startDate: Date = (new Date().getDate()==1 || new Date().getDate()==2) ? (moment(new Date()).subtract(1,'month').startOf('month').format("YYYY-MM-DD")):(moment(new Date()).startOf("month").format("YYYY-MM-DD") )
  endDate: Date = (new Date().getDate()==1 || new Date().getDate()==2) ? (moment(new Date()).subtract(1,'month').endOf("month").format("YYYY-MM-DD")):(moment(new Date()).endOf("month").format("YYYY-MM-DD") )
  startTime: Date;
  endTime: Date;
  startTimeParam;
  endTimeParam;

  //时间类型，时间类型名称，时间类型格式
  dateType: string;
  dateTypeName: string;
  dateTypeFormat: string;

  //采集周期type 0：实时 1：日 2：月 3;年  即数据周期 statType
  type = '1';
  //复选框选择日，月，年  默认全选
  dayChecked: boolean = true;
  monthChecked: boolean = true;
  yearChecked: boolean = true;

  companys: any[];
  companyCode: string;
  companyId = '';
  companyMap = {};

  tableList:any = [];
  tableList2:any= [];
  SelectType="left"
  tableListCopy = [];
  resultMap = [];

  //echart图相关变量
  dataOption;
  chartTitle: string = '';
  chartUnit: string = '';
  chartEnergyName: string = '';
  echartData = [];
  chartType: string = 'bar'
  unitName: string = ''
  pageSize = 5;
  isLoading = {
    tableLoading: false,
    echartLoading: false,
  };
  sumEnergy=0; //总数
  averageSum:any=0; //平均数
  sumEnergyShow; //保留两位小数后
  //企业基础信息
  companyInfo: any = {};
  //是否显示折标系数
  ifShowScaleCoefficient: boolean = false;
  showStopTime=false; //是否展示“设置停产时间”
  showStopTime2=false; //是否展示底部 停产时间表格
  constructor(
    public companyDataService: CompanyDataService,
    private msgService: NzMessageService,
    public gxService: GXService,
    private http:HttpClient,
    private cdRef:ChangeDetectorRef,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  @ViewChild('dataChartElement')
  dataChartElement: ElementRef;
  loading=false

  ngOnInit(): void {
    this.updateEditCache()
    if (!GXService.isEmpty(this.queryParams)) {
      this.isTwoLevelPage =(this.queryParams['isTwoLevelPage']==null|| this.queryParams['isTwoLevelPage']==undefined)?true:this.queryParams['isTwoLevelPage'];
      this.ifShowScaleCoefficient = false;
      this.companyInfo = this.queryParams;
      this.companyCode = this.queryParams['companyCode'];
      this.dayChecked = this.queryParams['dayChecked'];
      this.monthChecked = this.queryParams['monthChecked'];
      this.yearChecked = this.queryParams['yearChecked'];
      this.startTimeParam = new Date(this.queryParams['startTime']).getTime();
      this.endTimeParam = new Date(this.queryParams['endTime']).getTime();
      this.type = this.queryParams['dateType'] == 'day' ? '1' : this.queryParams['dateType'] == 'month' ? '2' : this.queryParams['dateType'] == 'year' ? '3' : '0';
    } else {
      this.isTwoLevelPage = false;
      // 默认查询开始时间：当前时间-15天    查询结束时间：当前时间-1天  （两周）
      this.startTime = moment('00:00:00', 'HH:mm:ss').toDate();
      this.endTime = moment('23:59:59', 'HH:mm:ss').toDate();
    }
    this.getAllCompany();
    if(this.tokenService.get().orgType=="com"){
      this.showStopTime=true
    }
  }

  //时间改变
  timeChange(){
    this.endDate= moment(this.startDate).endOf('month').format('YYYY-MM-DD')
  }

  /**
   * 获取所有企业
   */
  getAllCompany() {
    this.companyDataService
      .getCompany()
      .toPromise()
      .then(result => {
        //console.log(result)
        this.companys = result;
        result.map(item => { this.companyMap[item['code']] = item['id']; });
        if (!this.isTwoLevelPage) {
          this.initCompanyCode();
        }
        //获取表格数据
        this.getDataList();
      });
  }
  /**
   * 左侧菜单栏进入页面时 默认的企业为 配置项中的默认企业 如果没有配置 则为下拉框中第一个企业
   */
  initCompanyCode() {
    this.companyCode = this.gxService.XinhaiConfig.CollectionAndReportCompany ?
      this.gxService.XinhaiConfig.CollectionAndReportCompany.data : null;
    if (GXService.isEmpty(this.companyCode) || this.companyMap[this.companyCode] == null) {
      this.companyCode = this.companys[0]['code'];
    }
  }

  /**
   *  获取表格数据
   */
  getDataList() {
    this.loading=true
    if(this.type=='2'){
        this.startDate = moment(this.startDate).startOf("month").format("YYYY-MM-DD");
        this.endDate = moment(this.endDate).endOf("month").format("YYYY-MM-DD");
    }else if(this.type=='3'){
      this.startDate = moment(this.startDate).startOf("year").format("YYYY-MM-DD");
      this.endDate = moment(this.endDate).endOf("year").format("YYYY-MM-DD");
    }


    if (!this.isTwoLevelPage) {
      this.startTimeParam = this.getTime(this.startDate, this.startTime);
      this.endTimeParam = this.getTime(this.endDate, this.endTime);
    }

    if (this.startTimeParam > this.endTimeParam) {
      this.msgService.warning('开始时间必须小于等于结束时间！');
      return;
    }
    this.isLoading['tableLoading'] = true;
    this.isLoading['echartLoading'] = true;
    this.tableList = [];
    this.resultMap = [];

    let companyId = this.companyMap[this.companyCode];
    let typeParam = {
      dayType: this.dayChecked ? 1 : 0,
      monthType: this.monthChecked ? 1 : 0,
      yearType: this.yearChecked ? 1 : 0,
    };

    this.companyDataService
      .getTableData(
        this.companyCode,
        this.startTimeParam,
        this.endTimeParam,
        this.type,
        companyId,
        this.isTwoLevelPage,
        typeParam
      ).toPromise()
      .then(result => {
        if (!GXService.isEmpty(result)) {
          this.resultMap = result['resultMap'];
          this.tableListCopy = result['tableList'];
          if (this.ifShowScaleCoefficient) {
            this.tableList = result['tableList'];
          } else {
            this.tableList = result['tableList'].filter(item => item['scaleCoefficient'] == false);
          }
          this.loading=false
        }
        this.selectDataDetail(this.tableList[0]);

      }).catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading['tableLoading'] = false;
        this.isLoading['echartLoading'] = false;
        this.loading=false
      });
    //2020-04-25 ludaxian新增前端指标节点排序
    this.companyDataService
      .getAppModelNode()
      .toPromise()
      .then(res => {
        if (!GXService.isEmpty(res)) {
          const company = this.companys.find(item => item.code == this.companyCode,);
          const appModel = res.find(item => item['title'] == company['name']);
          if (!GXService.isEmpty(appModel)) {
            let modelCodeArr = GXService.formatTreeToArr([appModel])
              .filter(item => item.hasOwnProperty('code'))
              .map(item => item['code']);
            let noDataList = [];
            let sortList = [];
            //指标模型里竟然没有端设备配置的上传数据？这里把没配置的指标模型放最后
            this.tableList.forEach(item => {
              if (!modelCodeArr.includes(item['dataCode'])) {
                noDataList = [...noDataList, item];
              } else {
                sortList = [...sortList, item];
              }
            });
            this.tableList = [
              ...sortList
                .sort((a, b) => {
                  return (
                    modelCodeArr.findIndex(item => item == a['dataCode']) -
                    modelCodeArr.findIndex(item => item == b['dataCode'])
                  );
                })
                .map(item => item),
              ...noDataList,
            ];
             //console.log(modelCodeArr, this.tableList);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading['tableLoading'] = false;
        this.isLoading['echartLoading'] = false;
      });

      //查询停产时间段
        let begin =moment(this.startTimeParam).format("YYYY-MM-DD")
        let end =moment( this.endTimeParam).format("YYYY-MM-DD")
       // let url=`energyreport/intervaltime/getListByPath/${begin}/${end}`
        let url=`energyreport/intervaltime/getListByOrgId/${companyId}/${begin}/${end}`
        this.http.get(url).toPromise().then((result:any)=>{
          if(result.success){
            this.listOfData2=result.data
            if(result.data.length>0){
              this.showStopTime2=true
            }else{
              this.showStopTime2=false
            }
          }else{
            this.msgService.info("停产时间查询失败")
            this.showStopTime2=false
          }
        });
  }

  /**
   * 获取周期类型
   * @param type
   */
  getDateType(type) {
    switch (String(type)) {
      case '1':
        this.dateType = 'days';
        this.dateTypeName = '日';
        this.dateTypeFormat = 'YYYY-MM-DD';
        return '日';
        break;
      case '2':
        this.dateType = 'months';
        this.dateTypeName = '月';
        this.dateTypeFormat = 'YYYY-MM';
        return '月';
        break;
      case '3':
        this.dateType = 'years';
        this.dateTypeName = '年';
        this.dateTypeFormat = 'YYYY';
        return '年';
        break;

      default:
        this.dateType = 'hours';
        this.dateTypeName = '时';
        this.dateTypeFormat = 'YYYY-MM-DD HH';
        return '实时';
        break;
    }
  }

  /**
   * 设置图标类型类型
   * @param type
   */
  setChartType(type: string): void {
    this.chartType = type;
    if (type == "table") return;
    this.setChartData();
  }
  /**
   * 初始echart内容
   */
  initChartInfo() {
    this.echartData = [];
    this.chartTitle = '';
    this.chartUnit = '';
    this.chartEnergyName = '';
  }
  /**
   * 设置柱状图样式和数据
   */
  setChartData(): void {
    let xData = this.echartData.map(item => item['xData']);
    let yData = this.echartData.map(item => item['yData']);
    // console.log(xData);
    // console.log(yData);
    this.sumEnergy=0
    let count=0
    yData.forEach(element => {
      if(element!=0){
        this.sumEnergy+=element
        count++;
      }

    });
    if(count==0){
      this.averageSum=0
    }else{
      this.averageSum= (this.sumEnergy /count).toFixed(2)
    }
    this.sumEnergyShow =this.sumEnergy.toFixed(2)
    // console.log(this.sumEnergyShow)
    this.dataOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: e => {
          let tip = e[0]['name'] + '<br/>';
          tip += (this.chartEnergyName ? this.chartEnergyName : '数据') + ': ' + e[0]['data'] + (this.chartUnit ? this.chartUnit : '');

          return tip;
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xData,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: this.chartUnit,
        },
      ],
      series: [
        {
          name: '数值',
          type: this.chartType,
          barWidth: '60%',
          data: yData,
          smooth: false
        },
      ],
    };
  }

  /**
   * 柱状图 具体数据
   * @param data
   */
  selectDataDetail(data: EnergyData) {
    this.isLoading['echartLoading'] = true;
    this.initChartInfo();

    //echart标题名,单位，指标名
    if (!GXService.isEmpty(data)) {
      this.unitName = data['unitName'];
      this.getDateType(data['statType']);
      this.chartTitle =
        data['enterpriseName'] +
        ' ' +
        (data['indexName'] ? data['indexName'] : '') +
        moment(new Date(this.startTimeParam)).format(this.dateTypeFormat) +
        this.dateTypeName +
        '至' +
        moment(new Date(this.endTimeParam)).format(this.dateTypeFormat) +
        this.dateTypeName;

      this.chartUnit = data['unitName'];
      this.chartEnergyName = data['indexName'];
      let dataList: any[] = this.resultMap[data.dataCode];
      //console.log(dataList)
      for (
        var k = moment(new Date(this.startTimeParam));
        k < moment(new Date(this.endTimeParam));
        k = k.add(1, this.dateType)
      ) {
        let dataObject1 = {};
        dataObject1['xData'] = k.format(this.dateTypeFormat);
        dataObject1['yData'] = 0;
        this.echartData.push(dataObject1);
      }
      dataList.forEach(item => {
        this.echartData.map(item1 => {
          if (
            moment(item['statDate']).format(this.dateTypeFormat) ==
            item1['xData']
          )
            item1['yData'] = item['dataValue'];
        });
      });
    } else {
      const company = this.companys.find(item => item.code == this.companyCode);
      this.chartTitle = company.name + moment(new Date(this.startTimeParam)).format(this.dateTypeFormat) +
        this.dateTypeName +
        '至' +
        moment(new Date(this.endTimeParam)).format(this.dateTypeFormat) +
        this.dateTypeName;
      this.echartData = [];
    }
    // debugger
    this.setChartData();
    this.isLoading['echartLoading'] = false;
  }

  /**
   * 根据选择的日期和时间转化为 yyyy-MM-dd HH:mm:ss形式
   * @param data
   * @param time
   */

  getTime(data, time) {
    this.getDateType(this.type);
    let dataParam =
      moment(data).format('YYYY-MM-DD') +
      ' ' +
      moment(time)
        .format('HH:mm:ss')
        .valueOf();
    return new Date(dataParam).getTime();
  }

  /**
   * 单选框切换，显示折标系数
   * 添加loading 直观一点
   * @param event
   */
  showScaleCoefficient(event) {
    this.isLoading['tableLoading'] = true;
    // console.log(event);
    let list = [];
    list = event ? this.tableListCopy : this.tableListCopy.filter(item => item['scaleCoefficient'] == false)
    this.tableList = list;
    setTimeout(() => {
      this.isLoading['tableLoading'] = false;
    }, 200);


  }
  //获取序号
  getOrderNo(dataCode: string) {
    return (
      Number(this.tableList.findIndex(item => item.dataCode == dataCode)) + 1
    );
  }
  // 下面停产时间相关的操作
  isVisible=false
  editCache: { [key: string]: any } = {};
  listOfData: any[] = [
    {
      id:"11123",
      beginTime:new Date(),
      endTime:new Date(),
      remark:"备注",
    },
    {
      id:"12332",
      beginTime:new Date(),
      endTime:new Date(),
      remark:"备注1",
    }
  ];
  listOfData2=[]//展示底部数据
  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }
  //增加一行
  addRow(){
    this.listOfData = [
      ...this.listOfData,
      {
        name:"无需填写",
        region:"无需填写",
        trade:"无需填写",
        id:moment().unix(),
        beginTime:new Date(),
        endTime:new Date(),
        remark:"请编辑并保存"
      }
    ];
    this.updateEditCache()
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }
  saveEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    Object.assign(this.listOfData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    //上报或更新数据
    let url='/energyreport/intervaltime/addOrUpdate'
    this.http.post(url,this.editCache[id].data)
    .toPromise()
    .then(result => {
      return result;
    });

  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }
  setStopTime(){
    let url=`energyreport/intervaltime/getListByPath/null/null`
    this.http.get(url).toPromise().then((result:any)=>{
      if(result.success){
        this.listOfData=result.data
        this.updateEditCache()
      }else{
        this.msgService.info("停产时间查询失败")
      }
    });

    this.isVisible=true
    this.updateEditCache()

  }
  handleCancel(){
    this.isVisible=false
  }
  //删除一条记录
  del(id){
    let url=`energyreport/intervaltime/delById/${id}`
    this.http.delete(url).toPromise().then((result:any)=>{
      if(result.success){
        this.msgService.success("删除成功")
        this.setStopTime()
      }else{
        this.msgService.info("删除失败")
      }
    });
  }



}
