import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UploadFile, NzMessageService} from "ng-zorro-antd";
import {Observable} from "rxjs";

@Component({
  selector: 'detailcollect',
  templateUrl: './detailcollect.component.html',
  styleUrls: ['./detailcollect.component.less']
})
export class DetailcollectComponent implements OnInit {

  constructor(private http: HttpClient, private message: NzMessageService) {
  }

  gutiPicurl: string = "";
  qitiPicurl: string = "";
  yetiPicurl: string = "";
  elecPicurl1: string = "";
  elecPicurl2: string = "";

  userId: string;
  energyName: string;
  type = 0;
  ng;
  guti = [[{"text": "", "type": "text"}], [{"text": "", "type": "text"}], [{"text": "", "type": "text"}], [{
    "text": "",
    "type": "text"
  }], [{"text": "", "type": "text"}, {"text": "", "type": "text"}, {"text": "", "type": "text"}, {
    "text": this.gutiPicurl,
    "type": "picture"
  }]];
  yeti = [[{"text": "", "type": "text"}, {"text": "", "type": "text"}, {"text": "", "type": "text"}],
    [{"text": "", "type": "text"}], [{"text": "", "type": "text"}], [{"text": "", "type": "text"}, {
      "text": "",
      "type": "text"
    }, {"text": "", "type": "text"}, {
      "text": this.yetiPicurl,
      "type": "picture"
    }]
  ];
  qiti = [[{"text": "", "type": "text"}, {"text": "", "type": "text"}, {"text": "", "type": "text"}],
    [{"text": "", "type": "text"}], [{"text": "", "type": "text"}], [{"text": "", "type": "text"}, {
      "text": "",
      "type": "text"
    }, {"text": "", "type": "text"}, {
      "text": this.qitiPicurl,
      "type": "picture"
    }]
  ];
  dian = [[{"text": "", "type": "text"}, {"text": "", "type": "text"}, {"text": "", "type": "text"}], [{
    "text": "",
    "type": "text"
  }, {"text": "", "type": "text"}, {"text": "", "type": "text"}, {
    "text": this.elecPicurl1,
    "type": "picture"
  }], [{"text": "", "type": "text"}], [{"text": "", "type": "text"}], [{"text": "", "type": "text"}, {
    "text": "",
    "type": "text"
  }, {"text": "", "type": "text"}, {
    "text": this.elecPicurl2,
    "type": "picture"
  }]];
  saveButton;
  saveJSON;
  conclusion: string;
  checkResult = [false, false, false, false, false, false];
  fileList = [];
  fileList1 = [];
  title;
  indexName;
  account;

  ngOnInit() {


    this.fileList = [];
    this.fileList1 = [];
    // this.route.params.subscribe(params => {
    //   console.warn(params);
    //   this.userId = params['userId'];
    //   this.type = params['type'];
    // });

  }


  // checkSaveButton() {
  //   if (this.type == 0) {
  //     if (this.guti[0][0].text == "铲车人工上煤"
  //       || this.guti[1][0].text == "无积算仪表"
  //       || this.guti[2][0].text == "无接口通讯"
  //       || this.guti[3][0].text == "有通讯功能"
  //       || this.guti[3][0].text != ""
  //     ) {
  //       this.saveButton = true;
  //     } else {
  //       this.saveButton = false;
  //     }
  //   }
  // }
  //

  check1() {
    if (this.type == 0) {
      if (this.guti[0][0].text == "皮带称、转子称等自动上煤") {
        this.checkResult[0] == true;
        return true;
      } else {
        this.conclusion = "手工填报"
        this.guti[1][0].text = "";
        this.guti[2][0].text = "";
        this.guti[3][0].text = "";
        this.guti[4][0].text = "";
        return false;
      }
    } else {
      return true;
    }
  }

  check2() {
    if (!this.check1()) {
      return false;
    }
    if (this.type == 0) {
      if (this.guti[1][0].text == "有积算仪表") {
        return true;
      } else {
        this.conclusion = "手工填报";
        this.guti[2][0].text = "";
        this.guti[3][0].text = "";
        this.guti[4][0].text = "";
        this.guti[4][1].text = "";
        this.guti[4][2].text = "";
        this.saveButton = true;
        return false;
      }
    } else if (this.type == 1) {
      if (this.yeti[1][0].text == "有通讯接口") {
        this.checkResult[0] == true;
        return true;
      } else {
        this.conclusion = "智能仪表改造"
        this.yeti[2][0].text = "";
        this.yeti[3][0].text = "";
        this.yeti[3][1].text = "";
        this.yeti[3][2].text = "";
        this.saveButton = true;

        return false;
      }
    }
    if (this.type == 2) {
      if (this.qiti[1][0].text == "有通讯接口") {
        this.checkResult[0] == true;
        return true;
      } else {
        this.conclusion = "智能仪表改造"
        this.qiti[2][0].text = "";
        this.qiti[3][0].text = "";
        this.qiti[3][1].text = "";
        this.qiti[3][2].text = "";
        this.saveButton = true;

        return false;
      }
    } else {
      return true;
    }
  }

  check3() {
    if (!this.check1()) {
      return false;
    }
    if (!this.check2()) {
      return false;
    }
    if (this.type == 0) {
      if (this.guti[2][0].text == "有接口通讯") {
        return true;
      } else {
        this.saveButton = true;
        this.conclusion = "通讯改造，增加RS485板";
        this.guti[3][0].text = "";
        this.guti[4][0].text = "";

        return false;
      }
    } else if (this.type == 1) {
      if (this.yeti[2][0].text == "被占用") {
        this.checkResult[0] == true;
        this.saveButton = true;
        this.conclusion = "接口采集";

        return true;
      } else {
        this.conclusion = "仪表RS485采集"
        this.yeti[3][0].text = "";
        this.yeti[3][0].text = "";
        this.yeti[3][0].text = "";
        this.saveButton = true;

        return false;
      }
    } else if (this.type == 2) {
      if (this.qiti[2][0].text == "被占用") {
        this.checkResult[0] == true;
        this.saveButton = true;
        this.conclusion = "接口采集"

        return true;
      } else {
        this.conclusion = "仪表RS485采集"
        this.qiti[3][0].text = "";
        this.qiti[3][0].text = "";
        this.qiti[3][0].text = "";
        this.saveButton = true;

        return false;
      }
    } else if (this.type == 3) {
      if (this.dian[2][0].text == "有RS485通讯口") {
        this.checkResult[0] == true;
        return true;
      } else {
        this.conclusion = "仪表RS485采集"
        this.dian[3][0].text = "";
        this.dian[4][0].text = "";
        this.dian[4][1].text = "";
        this.dian[4][2].text = "";
        this.saveButton = true;

        return false;
      }
    } else {
      return true;
    }
  }

  check4() {
    if (!this.check1()) {
      return false;
    }
    if (!this.check2()) {
      return false;
    }
    if (!this.check3()) {
      return false;
    }
    if (this.type == 0) {
      if (this.guti[3][0].text == "被占用") {
        this.saveButton = true;
        this.conclusion = "接口采集";
        return true;
      } else {
        this.conclusion = "积算仪RS485采集"
        this.saveButton = true;
        return false;
      }
    } else if (this.type == 3) {
      if (this.dian[3][0].text == "被占用") {
        this.checkResult[3] == true;
        this.saveButton = true;
        this.conclusion = "接口采集"


        return true;
      } else {
        this.conclusion = "智能仪表采集"
        this.dian[4][0].text = "";
        this.dian[4][1].text = "";
        this.dian[4][2].text = "";
        this.saveButton = true;

        return false;
      }
    } else {
      return true;
    }
  }

  check5() {
    if (this.type == 0) {
    }
  }

  beforeUpload = (file: UploadFile): boolean => {

    if (this.fileList.length > 0) {
      this.message.warning('😮一次仅允许上传一个文件');
      return false;
    }
    this.fileList.push(file);
    return false;
  }

  beforeUpload1 = (file: UploadFile): boolean => {

    if (this.fileList.length > 0) {
      this.message.warning('😮一次仅允许上传一个文件');
      return false;
    }
    this.fileList1.push(file);
    return false;
  }

  handleUpload(e: MouseEvent, key): void {
    e.preventDefault();
    const formData = new FormData();

    if (this.type == 3 && key == 1) {
      this.fileList1.forEach((file: any) => {
        formData.append('files', file);
      });
    } else {
      this.fileList.forEach((file: any) => {
        formData.append('files', file);
      });
    }

    const url = '/energyreport/detailcollect/uploadFile/';
    this.http.post(url, formData).subscribe((result: any) => {
      if (!result.hasError) {
        if (this.type == 0) {
          this.gutiPicurl = result.data;
          this.guti[4][3].text = result.data;
          this.message.success("上传成功");
        } else if (this.type == 1) {
          this.yetiPicurl = result.data;
          this.yeti[3][3].text = result.data;
          this.message.success("上传成功");

        } else if (this.type == 2) {
          this.qitiPicurl = result.data;
          this.qiti[3][3].text = result.data;
          this.message.success("上传成功");
        } else if (this.type == 3) {
          if (key == 1) {
            this.dian[4][3].text = result.data;
          } else {
            this.dian[1][3].text = result.data;
          }
          this.message.success("上传成功");


        }
      } else {
        this.message.error("上传失败，错误：" + result.errorMessage);
      }
    });
  }


  cancelDefult(e: MouseEvent): void {
    e.preventDefault();
  };

  save() {
    var paramJson = [];
    if (this.type == 0) {
      paramJson.push(this.guti[0]);

      if (this.check1()) {
        paramJson.push(this.guti[1]);
      }

      if (this.check2()) {
        paramJson.push(this.guti[2]);
      }

      if (this.check3()) {
        paramJson.push(this.guti[3]);
      }
      if (this.check4()) {
        paramJson.push(this.guti[4]);
      }

    } else if (this.type == 1) {
      paramJson.push(this.yeti[0]);
      paramJson.push(this.yeti[1]);
      if (this.check2()) {
        paramJson.push(this.yeti[2]);
      }
      if (this.check3()) {
        paramJson.push(this.yeti[3]);
      }
    }
    else if (this.type == 2) {
      paramJson.push(this.qiti[0]);
      paramJson.push(this.qiti[1]);
      if (this.check2()) {
        paramJson.push(this.qiti[2]);
      }
      if (this.check3()) {
        paramJson.push(this.qiti[3]);
      }
    } else if (this.type == 3) {
      paramJson.push(this.dian[0]);
      paramJson.push(this.dian[1]);
      paramJson.push(this.dian[2]);
      if (this.check3()) {
        paramJson.push(this.dian[3]);
      }
      if (this.check4()) {
        paramJson.push(this.dian[4]);
      }
    }

    var
      param = {};
    param
      ["title"] = this.title;
    param
      ["userId"] = this.userId;
    param
      ["type"] = this.type;
    param
      ["conclusion"] = this.conclusion;
    param
      ["indexName"] = this.indexName;
    param
      ["json"] = paramJson;
    const url = '/energyreport/detailcollect/saveDetail/';

    this.http.post(url, param).subscribe((result: any) => {
      if (!result.hasError) {
        this.message.success("保存成功");
        this.ng.destroyTplModal();
        this.ng.getData();
      } else {
        this.message.error("保存失败，错误：" + result.errorMessage);
      }
    });
  }

}
