import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PublicServiceService {
  constructor(private http: HttpClient) { }

  getData(DataUrl: string): Promise<any> {
    return this.http
      .get(DataUrl)
      .toPromise();
  }

  getPictureData(data: any): Promise<any> {
    const url = data.url;
    const parameter = data.parameter;
    return this.http.post(url, parameter).toPromise().then(result => {
      return result;
    });
  }
}