import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { GlobalConfigService } from '../../../global-config-service';

@Component({
    selector: 'auth-authorization',
    templateUrl: './authorization.component.html',
})
export class AuthAuthorizationComponent implements OnInit {
    authCode: any;

    constructor(
        private http: _HttpClient,
        private config: GlobalConfigService,
    ) {}

    ngOnInit() {
        this.http
            .get(this.config.globalPath + '/requestCode', null, {responseType: 'text'})
            .subscribe(result => {
                this.authCode = result;
            });
    }
}
