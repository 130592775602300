import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {

  constructor(private http: HttpClient) { }

  private getSelectUrl = '/energyreport/common/getSystemParameterByCode/message_send_type';
  private sendQuickMsgUrl = '/energyreport/platformMessage/sendHeadMessage';
  private getCompanyInfoUrl='/energyreport/platformMessage/getCompanyForHeadLetter';
  private getRegionTreeNodesUrl = '/energyreport/companyEditNew/getRegionTree';

  getSelectData(): Promise<any> {
    return this.http
      .get(this.getSelectUrl)
      .toPromise()
      .then(result => {
        return result;
      });
  }

  // 发送即时消息
  sendQuickMsg(data: sendMsg): Promise<any> {
    return this.http
      .post(this.sendQuickMsgUrl, data)
      .toPromise()
      .then(result => {
        return result;
      });
  }

  getComForHeadLetter(organizationValue): Observable<Object> {
    return this.http.get(this.getCompanyInfoUrl,{params:{
      organizationValue:organizationValue}
    });
  }


  getRegionTreeNodes(): Observable<any> {
    return this.http.get<any>(this.getRegionTreeNodesUrl);
  }
}

interface sendMsg {
  sendTitle:string;
  sendContent: string;
  sendType: string;
  sendFilePath:string;
  orgId: string[];
  sendRangeFlag:number;
  organizationValue:string;
}
