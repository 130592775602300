import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { GlobalConfigService } from '../../../global-config-service';

@Component({
  selector: 'authorization-ningdong',
  templateUrl: './authorization-ningdong.component.html',
  styleUrls: ['./authorization-ningdong.component.less']
})
export class AuthorizationNingdongComponent implements OnInit {

  authCode: any;

  constructor(
      private http: _HttpClient,
      private config: GlobalConfigService,
  ) {}

  ngOnInit() {
      this.http
          .get(this.config.globalPath + '/requestCode', null, {responseType: 'text'})
          .subscribe(result => {
              this.authCode = result;
          });
  }

}
