import { Component, OnInit } from '@angular/core';
import { GXService } from '@shared/GX.service';

@Component({
  selector: 'login-ningdong',
  templateUrl: './login-ningdong.component.html',
  styleUrls: ['./login-ningdong.component.less']
})
export class LoginNingdongComponent implements OnInit {

  srcCom: '';
  srcGov: '/assets/img/jining-gov.png';
  srcHome: '';

  ngOnInit(): void {

  }

  Redirect(jumpUrl) {
    /*行到窗口名称*/
    var d = new Date();
    var year, month, day, h, m, s;
    year = d.getFullYear();
    month = d.getMonth();
    day = d.getDay();
    h = d.getHours();
    m = d.getMinutes();
    s = d.getSeconds();
    var windowstr;
    windowstr = "" + year + month + day + h + m + s;
    /**********************************************************************************************/
    windowstr = window.open(jumpUrl, windowstr, 'top=0, left=0, toolbar=no, menubar=no, scrollbars=yes,resizable=yes,location=yes, status=no');

    window.opener = null;
    window.open('', '_self');
    windowstr.moveTo(0, 0);
    windowstr.resizeTo(screen.width, screen.height);
    window.self.close();
  }

  constructor(public gxService:GXService) {
  }
}
