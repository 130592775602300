import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { GXService } from '@shared/GX.service';
import { OnlinecollectCompanyService } from './onlinecollect-company.service';
import { forkJoin } from 'rxjs';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'onlinecollectionproject-company',
  templateUrl: './onlinecollectionproject-company.component.html',
  styleUrls: ['./onlinecollectionproject-company.component.less'],
})
export class OnlinecollectionprojectCompanyComponent implements OnInit {
  current = 0;
  loading: boolean = false;
  form: FormGroup;
  userId: string = '';
  nodes: any;
  tradeId: string;
  energyConsumptionLevelList: string;
  companyId: string = '';
  companyCode: string = '';
  TEL_PATTERN = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
  constructor(
    private fb: FormBuilder,
    private gxService: GXService,
    private companyService: OnlinecollectCompanyService,
    private msg: NzMessageService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.userId = params['userId'];
    });

    this.form = fb.group({
      name: [null, [Validators.required]],
      code: [null, [Validators.required]],
      address: [null, [Validators.required]],
      tradeId: [null, [Validators.required]],
      energyConsumptionLevel: [null, [Validators.required]],
      contactPerson: [null, [Validators.required]],
      contactTel: [null, [Validators.required,Validators.pattern(this.TEL_PATTERN)]],
    });
  }

  ngOnInit() {
    this.initPageSelect();
  }

  getCompanyInfo() {
    this.loading = true;
    // const res=await  this.companyService.getCompanyInfo(this.userId).toPromise();
    this.companyService
      .getCompanyInfo(this.userId)
      .toPromise()
      .then(result => {
        if (result['success']) {
          let data: companyInfo = result['data'];
          this.companyId = data['id'];
          this.form.patchValue({
            name: data['name'],
            code: data['code'],
            address: data['address'],
            tradeId: data['tradeId'],
            energyConsumptionLevel: data['energyConsumptionLevel'],
            contactPerson: data['contactPerson'],
            contactTel: data['contactTel'],
          });
          this.tradeId = data['tradeId'];
        }
      })
      .catch(err => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * 初始化页面中的选择内容
   */
  initPageSelect() {
    forkJoin(
      //this.companyService.getTradeTree('root'),
      this.companyService.getTradeTree1(),
      this.companyService.getnergyConsumptionLevelList(),
    )
      .toPromise()
      .then(result => {
        if (result[0]['success']) {
          this.nodes = result[0]['data'];
        }
        if (result[1]['success']) {
          this.energyConsumptionLevelList = result[1]['data'];
        }
        this.getCompanyInfo();
      });
  }

  /* onExpandChange(e: Required<NzFormatEmitEvent>): void {
    let node = e.node;
    if (node && node.getChildren().length === 0 && node.isExpanded) {
      this.companyService
        .getTradeTree(node.key)
        .toPromise()
        .then(result => {
          if (result['success']) {
            let children = [];
            _.forEach(result['data'], function(item) {
              children.push(item);
            });
            node.addChildren(children);
          }
        });
    }
  } */

  save() {
    let param: companyInfo = this.form.value;
    param['userId'] = this.userId;
    param['id'] = this.companyId;
    //companyId 不存在则为新增
    if (GXService.isEmpty(this.companyId)) {
      this.companyService
        .saveCompanyInfo(param)
        .toPromise()
        .then(result => {
          if (result['success']) {
            this.msg.success('保存成功');
          } else {
            this.msg.error(result['errorMessage']);
          }
        })
        .catch(err => {
          this.msg.error('保存失败');
        });
    } else {
      //companyId存在则为更新
      this.companyService
        .updateCompanyInfo(param)
        .toPromise()
        .then(result => {
          if (result['success']) {
            this.msg.success('保存成功');
          } else {
            this.msg.error(result['errorMessage']);
          }
        })
        .catch(err => {
          this.msg.error('保存失败');
        });
    }
  }
}
