import {Component, OnInit, AfterViewInit} from '@angular/core';
import {NzMessageService} from "ng-zorro-antd";

@Component({
  selector: 'beautiful-china',
  templateUrl: './beautiful-china.component.html',
  styleUrls: ['./beautiful-china.component.less']
})
export class BeautifulChinaComponent implements OnInit, AfterViewInit {

  constructor(
    private message: NzMessageService,
  ) {
    this.init()
  }

  init(){
    if (this.calcShowDate() !== false) {
      let keepTime = window.localStorage.getItem('keepTime')
      if (keepTime) {
        this.showFloatBox = +keepTime - new Date().getTime() < 0;
      } else {
        this.showFloatBox = true;
      }
    }else{
      this.showFloatBox = false;
    }
  }

  showFloatBox: boolean = true;

  ngOnInit() {


  }

  ngAfterViewInit() {
    console.log(this.calcShowDate() )
    if (this.showFloatBox === false) return
    if (this.calcShowDate() === false) {
      return
    }
    this.initFloatAd()
    window.addEventListener('resize', () => {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.initFloatAd();
      }
    });
  }

  timer: number | null | any;

  div1 = null;
  w: number = 0;
  h: number = 0;
  gox: number = 1;
  goy: number = 1;
  showQrCodePoster: boolean = false;

  initFloatAd() {
    this.div1 = document.getElementById("float-box");

    this.w = document.documentElement.clientWidth;
    this.h = document.documentElement.clientHeight;
    this.timer = setInterval(() => {
      this.move()
    }, 10);
    this.div1.onmouseover = () => {
      clearInterval(this.timer);
      this.timer = null
    };
    this.div1.onmouseout = () => {
      this.timer = setInterval(() => {
        this.move()
      }, 10);
    };

  }

  move() {
    let x = this.div1.offsetLeft;
    var y = this.div1.offsetTop;
    if (x > this.w - 180 || x < 0) this.gox = -this.gox;
    this.div1.style.left = x + this.gox + "px";
    if (y > this.h - 80 || y < 0) this.goy = -this.goy;
    this.div1.style.top = y + this.goy + "px";
  }

  closeAd(from?: string) {
    let div1 = document.getElementById("float-box");
    div1.style.display = "none";
    clearInterval(this.timer);
    this.timer = null;
    if (from) {
      this.message.info('24小时内不会弹出');
      window.localStorage.setItem('keepTime', String(new Date().getTime() + 24 * 3600 + 1000));
    }
  }

  closePoster() {
    this.showQrCodePoster = false
    // this.init()
    // this.ngAfterViewInit()
    this.showFloatBox=true
  }

  viewQrCodePoster() {
    //this.closeAd()
    this.showFloatBox=false
    this.showQrCodePoster = true
  }

  calcShowDate() {
    // @ts-ignore
    return new Date('2023-10-31 23:59:59') - new Date() > 0
  }
}
