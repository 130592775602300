import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-switch',
  templateUrl: './login-switch.component.html',
  styleUrls: ['./login-switch.component.less']
})
export class LoginSwitchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
