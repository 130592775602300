import { InvokeResult } from '../../entity/InvokeResult.entity';
import { ProgressControlAnalysComponent } from '../../../routes/progress-control-analys/progress-control-analys/progress-control-analys.component';
import { forEach } from '@angular/router/src/utils/collection';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';
import { ProgressControlAnalysService } from 'app/routes/progress-control-analys/progress-control-analys.service';

@Component({
  selector: 'energy-chart',
  templateUrl: './energy-chart.component.html',
  styleUrls: ['./energy-chart.component.less'],
})
export class EnergyChartComponent implements OnInit {
  @Input() id: string[] = [];
  @Input() energyType: string = '';
  @Input() dataType: string = '';
  @Input() tableType: string = '';
  @Input() year: number = 2019;
  isDataExsit: boolean = true;
  isLoading: boolean = false;
  echartsOption: any;
  @Input() title: string = '';
  ngOnInit(): void {
    this.getData();
  }
  /**
   * 初始化管线、管道、测点湿度echarts
   */
  initEchart(data: any[]) {
    this.echartsOption = {
      /*  title: {
        left: 'center',
        text: '1',
        textStyle: {
          fontSize: 17,
          fontWeight: '600',
        },
      }, */
      xAxis: {
        type: 'category',
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: `{value}`,
        },
        name: this.title,
      },
      legend: {
        top: '30px',
        data: ['月份'],
      },
      tooltip: {
        trigger: 'axis',
        formatter: params => {
          return (
            `${params[0]['value']['month']}月${this.title}<br/>` +
            (params[0]['value']['value'] == '-'
              ? '暂无数据'
              : `
           ${params[0]['value']['value']} (` +
                (this.energyType == 'zhnh' ? '吨标准煤' : '千克标准煤/万元') +
                ')')
          );
        },
      },
      toolbox: {
        itemSize: 17,
        show: true,
        showTitle: false,
        feature: {
          magicType: {
            type: ['line', 'bar'],
          }, //切换为折线图，切换为柱状图
        },
      },
      series: [
        {
          type: 'bar',
          itemStyle: {
            normal: {
              color: '#185be1',
            },
          },
        },
      ],
      dataset: {
        source: data,
      },
      /*       dataZoom: [
        {
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          left: '9%',
          bottom: -5,
          start: 0,
          end: 100, //初始化滚动条
        },
      ], */
    };
  }
  getData() {
    this.isLoading = true;
    this.progressCtrlService
      .getEnergyChart(this.year, this.dataType, this.energyType, this.id)
      .toPromise()
      .then(res => {
        if(res.data){
          if(res.data.every(item=>item.value == "-")){//若所有数据都为空
            this.isDataExsit = false;
            return;
          }
          this.initEchart(res.data);

        }else{
          this.isDataExsit = false;
        }
      }).finally(()=>{
        this.isLoading = false;
      });
  }

  constructor(private progressCtrlService: ProgressControlAnalysService) {}
}
