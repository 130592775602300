import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {PublicServiceService} from '../public-service.service'
import {ConvertActionBindingResult} from '@angular/compiler/src/compiler_util/expression_converter';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  customId = "629841ec-2d28-4d74-9d5c-5c070b821bcd";
  carousel_parentId = 'content_LBTP'
  zcfgData: any;
  bzgfData: any;
  xwzxData: any;
  jnjbData: any;
  jnxjstgData: any;
  wsfwData: any;
  newsData: any;
  typeId: any;
  parentId: any;
  carouselData: any=[];
  newsDataUrl = '/cms/content/getByCusAndCol/' + this.customId + '/';
  pictureUrl = '/cms/content/searchByTerms';

  constructor(private publicServiceService: PublicServiceService) {

  }

  ngOnInit() {
    this.zcfgData = [];
    this.bzgfData = [];
    this.xwzxData = [];
    this.jnjbData = [];
    this.jnxjstgData = [];
    this.wsfwData = [];

    this.getPicture();
    
    // this.carouselData = [
    //   {
    //     filePath:'/assets/img',
    //     fileName:'zhuanjiayantaohui.jpg'
    //   },
    //   {
    //     filePath:'/assets/img',
    //     fileName:'dierqixuexiyantaohui.jpg'
    //   }
    // ]
    this.setInfoData();
    // this.getCarouselData();


  }

  newsTypes = ['ZCFG', 'BZGF', 'XWZX', 'JNJB', 'JNXJSTG', 'WSFW'];

  setInfoData(): void {
    this.newsTypes.forEach(res => {
      this.parentId = 'content_' + res;
      this.publicServiceService.getData(this.newsDataUrl + this.parentId).then(result => {
        if (res === 'ZCFG') {
          this.zcfgData = [...result];
          this.typeId = res;
          var tmpDataList=[];
          for(var i=this.zcfgData.length-1;i>-1;i--){
            tmpDataList.push(this.zcfgData[i]);
          }
          this.zcfgData = tmpDataList;
        } else if (res === 'BZGF') {
          this.bzgfData = [...result];
          var tmpDataList=[];
          for(var i=this.bzgfData.length-1;i>-1;i--){
            tmpDataList.push(this.bzgfData[i]);
          }
          this.bzgfData = tmpDataList;
        } else if (res === 'XWZX') {
          this.xwzxData = [...result];
          var tmpDataList=[];
          for(var i=this.xwzxData.length-1;i>-1;i--){
            tmpDataList.push(this.xwzxData[i]);
          }
          this.xwzxData = tmpDataList;
        } else if (res === 'JNJB') {
          this.jnjbData = [...result];
          var tmpDataList=[];
          for(var i=this.jnjbData.length-1;i>-1;i--){
            tmpDataList.push(this.jnjbData[i]);
          }
          this.jnjbData = tmpDataList;
        } else if (res === 'JNXJSTG') {
          this.jnxjstgData = [...result];
          var tmpDataList=[];
          for(var i=this.jnxjstgData.length-1;i>-1;i--){
            tmpDataList.push(this.jnxjstgData[i]);
          }
          this.jnxjstgData = tmpDataList;
        } else if (res === 'WSFW') {
          this.wsfwData = [...result];
          var tmpDataList=[];
          for(var i=this.wsfwData.length-1;i>-1;i--){
            tmpDataList.push(this.wsfwData[i]);
          }
          this.wsfwData = tmpDataList;
        }
      });
    });
  }

  getCarouselData(): void {
    this.publicServiceService.getData(this.newsDataUrl + this.carousel_parentId).then(result => {
      this.carouselData = result;
    });
  }

  getPicture(){
    this.carouselData = [];
    let data = {
      url : this.pictureUrl,
      parameter :{
        parentId:"content_LBTP"
      }
    }
    this.publicServiceService.getPictureData(data).then(result => {
      if(result != null) {
        result.forEach(item => {
          let d = {
            picturePath:item["picturePath"],
            pictureName:item["pictureName"],
            contentId:item["contentId"],
            name:item["name"]
          }
          this.carouselData.push(d);
        });
      }
    });

  }
}
