import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LayoutDefaultComponent } from './default/default.component';
import { LayoutFullScreenComponent } from './fullscreen/fullscreen.component';
import { HeaderComponent } from './default/header/header.component';
import { SidebarComponent } from './default/sidebar/sidebar.component';
import { HeaderSearchComponent } from './default/header/components/search.component';
import { HeaderNotifyComponent } from './default/header/components/notify.component';
import { HeaderTaskComponent } from './default/header/components/task.component';
import { HeaderIconComponent } from './default/header/components/icon.component';
import { HeaderFullScreenComponent } from './default/header/components/fullscreen.component';
import { HeaderI18nComponent } from './default/header/components/i18n.component';
import { HeaderStorageComponent } from './default/header/components/storage.component';
import { HeaderUserComponent } from './default/header/components/user.component';

import { LayoutModule as CDKLayoutModule } from '@angular/cdk/layout';
import { SettingDrawerComponent } from './default/setting-drawer/setting-drawer.component';
import { SettingDrawerItemComponent } from './default/setting-drawer/setting-drawer-item.component';

const SETTINGDRAWER = [SettingDrawerComponent, SettingDrawerItemComponent];

const COMPONENTS = [
  LayoutDefaultComponent,
  LayoutFullScreenComponent,
  HeaderComponent,
  SidebarComponent,
  ...SETTINGDRAWER,
  InfoComponent,
  SendMessageComponent
];

const HEADERCOMPONENTS = [
  HeaderSearchComponent,
  HeaderNotifyComponent,
  HeaderTaskComponent,
  HeaderIconComponent,
  HeaderFullScreenComponent,
  HeaderI18nComponent,
  HeaderStorageComponent,
  HeaderUserComponent,
  changePassWordComponent,
  HeaderLetterComponent
];

// passport
import { LayoutPassportComponent } from './passport/passport.component';
import { changePassWordComponent } from './default/header/changePassWord/changePassWord.component';
import {InfoComponent} from "./default/header/info/info.component";
import {LayoutPassportComponent2} from "./passport2/passport.component";
import { PassportNingdongComponent } from './passport-ningdong/passport-ningdong.component';
import { HeaderLetterComponent } from "./default/header/components/letter.component";
import { SendMessageComponent } from './default/header/send-message/send-message.component';
import { BeautifulChinaComponent } from './beautiful-china/beautiful-china.component';
const PASSPORT = [PassportNingdongComponent,LayoutPassportComponent,LayoutPassportComponent2];

@NgModule({
  imports: [SharedModule, CDKLayoutModule],
  declarations: [...COMPONENTS, ...HEADERCOMPONENTS, ...PASSPORT, BeautifulChinaComponent],
  exports: [...COMPONENTS, ...PASSPORT],
  entryComponents: [InfoComponent,changePassWordComponent, SendMessageComponent, ...SETTINGDRAWER],
})
export class LayoutModule {}
