import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GXService } from '@shared/GX.service';
import { LoginInfo } from 'app/routes/public-service/public-layout/login-info';

@Component({
  selector: 'passport-ningdong',
  templateUrl: './passport-ningdong.component.html',
  styleUrls: ['./passport-ningdong.component.less']
})
export class PassportNingdongComponent implements OnInit {

  links = [
    {
      title: '帮助',
      href: '',
    },
    {
      title: '隐私',
      href: '',
    },
    {
      title: '条款',
      href: '',
    },
  ];
  loginInfo: LoginInfo;
  isVisible = false;

  constructor(private http: HttpClient,public gxService:GXService) {}

  ngOnInit() {
    this.loginInfo = new LoginInfo();
    this.loginInfo.title = '';
    this.loginInfo.desc = '';
    this.loginInfo.company = '';
    this.loginInfo.time = '';
    this.http.get('assets/tmp/login-info.json').subscribe((data: LoginInfo) => {
      if (data.title) this.loginInfo.title = data.title;
      if (data.desc) this.loginInfo.desc = data.desc;
      if (data.company) this.loginInfo.company = data.company;
      if (data.company) this.loginInfo.company = data.company;
      if (data.time) this.loginInfo.time = data.time;
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
